import { Icon } from "@iconify/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { decodeAuthToken } from "../../amplitudeAnalytics";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useOwnerShip, useOwnersOfPlan } from "../../queries";
import { useGetAssociatedUsers } from "../../queries/usersRoles";
import { useAuthStore } from "../../store/useAuthStore";
import { EsopPlan } from "../../types/EsopPlan";
import { PlanOwnershipDto, UserPlanOwnershipDto } from "../../types/Ownership";
import { AssociatedUsers } from "../../utils/interfaces/Companies";

type AddOrEditOwnershipProps = {
  data?: EsopPlan;
  onClose: () => void;
};
type OptionType = { value: string; label: string };
export const AddOrEditOwnership = (props: AddOrEditOwnershipProps) => {
  const { data: _userData } = useGetAssociatedUsers();
  const { data, onClose } = props;
  const { data: _ownerData, isFetched } = useOwnersOfPlan(
    data?.esopPlanId || ""
  );
  const { user: userData, accessToken } = useAuthStore();
  const decodedToken = decodeAuthToken(accessToken || "");
  const [user, setUser] = useState<UserPlanOwnershipDto[]>();
  const userNames =
    _userData
      ?.filter(
        (usr: AssociatedUsers) =>
          decodedToken.id.toString() !== usr.userId?.toString()
      )
      ?.map((userData: AssociatedUsers) => ({
        value: userData.name,
        label: `${userData.name} (${userData.email})`,
      })) || [];
  const [planMode, setPlanMode] = useState(data?.scope === "PRIVATE");
  const [planModeType, setPlanModeType] = useState(data?.scope);
  useEffect(() => {
    const ownerNames = _ownerData?.data?.users
      ?.filter(
        (usr: UserPlanOwnershipDto) =>
          decodedToken.id.toString() !== usr.userId?.toString()
      )
      ?.map((ownerData: UserPlanOwnershipDto) => ({
        value: ownerData.name,
        label: `${ownerData.name} (${ownerData.emailId})`,
      }));
    setSelectedValue(ownerNames);
  }, [isFetched]);

  function showEmployeeList(e: React.MouseEvent<HTMLElement>) {
    setPlanMode(!planMode);
  }
  useEffect(() => {
    if (planMode === true) {
      setPlanModeType("PRIVATE");
    } else {
      setPlanModeType("PUBLIC");
    }
  }, [planMode]);
  const [selectedValue, setSelectedValue] = useState<OptionType[]>();
  function getSelectedUser(e: OptionType[]) {
    setSelectedValue(e);
  }
  useEffect(() => {
    if (!selectedValue) return;
    const selectedUsers: UserPlanOwnershipDto[] = [];
    _userData?.forEach((userDetail) => {
      selectedValue.forEach((currentUser) => {
        if (userDetail.name === currentUser?.value) {
          selectedUsers.push({
            userId: Number(userDetail.userId),
            emailId: userDetail.email,
            name: userDetail.name,
            isOwning: true,
          });
        }
      });
    });
    setUser(selectedUsers);
  }, [selectedValue]);

  const { mutate: savePlanOwner, isLoading } = useOwnerShip();
  function submit() {
    const owners = [...(_ownerData?.data?.users || []), ...(user || [])].map(
      (usr) => ({
        userId: usr.userId,
        emailId: usr.emailId,
        name: usr.name,
        isOwning: user?.some((_user) => _user.userId === usr.userId) || false,
      })
    );
    const ownerShipData: PlanOwnershipDto = {
      esopPlanId: data?.esopPlanId || "",
      isPublic: !planMode,
      users: _.uniqBy(owners, (owner) => owner.userId),
    };
    const currUserIndex = ownerShipData.users.findIndex(
      (user) => user.userId.toString() === decodedToken.id?.toString()
    );
    if (currUserIndex >= 0) ownerShipData.users[currUserIndex].isOwning = true;
    savePlanOwner(ownerShipData, {
      onSuccess: () => {
        toast("Ownership modified successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
      },
      onError: () => {},
    });
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Modify Plan Ownership{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>
        </h6>
      </div>
      <VStack className="px-8">
        <HStack className="gap-4 p-8 items-center">
          <div>
            <SwitchButton
              value={planMode}
              label={planModeType ?? ""}
              onClick={(e) => showEmployeeList(e)}
            />
          </div>

          {planModeType === "PRIVATE" ? (
            <VStack className="w-full">
              <p>Along with you give access to other users</p>
              <div className="w-full">
                <Select
                  options={userNames}
                  isMulti
                  value={selectedValue}
                  onChange={(e) => getSelectedUser(e as OptionType[])}
                />
              </div>
            </VStack>
          ) : (
            <div className="w-full">
              <Select
                placeholder={"This plan is public for Everyone"}
                isDisabled
              />
            </div>
          )}
        </HStack>
        <HStack className="justify-end px-8 pt-32 row-reverse">
          <ButtonPrimary onClick={() => submit()} loading={isLoading}>
            Submit{" "}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddOrEditOwnership;
