/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import {
  ButtonCancelSecondary,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useGetOrgStructureV2 } from "../../queries/organisationStructure";
import { useGetAllHiringPlans } from "../../queries/hiringPlan";
import { useGetAllCompensationModelling } from "../../queries/compensationModelling";
import Tooltip from "../../components/shared/Tooltip";

const Modelling = () => {
  const navigate = useNavigate();
  const { data: orgData, isFetched } = useGetOrgStructureV2();
  const { data: hiringData, isFetched: hiringDataFetched } =
    useGetAllHiringPlans();
  const { data: compModellingData, isFetched: modellingFetched } =
    useGetAllCompensationModelling();
  const [orgDataExist, setOrgDataExist] = useState(false);
  const [hiringDataExist, setHiringDataExist] = useState(false);
  const [modellingExist, setModellingExist] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(orgData)) {
      setOrgDataExist(true);
    }
  }, [isFetched, orgData]);
  useEffect(() => {
    if (!_.isEmpty(hiringData)) {
      setHiringDataExist(true);
    }
  }, [hiringDataFetched, hiringData]);
  useEffect(() => {
    if (!_.isEmpty(compModellingData)) {
      setModellingExist(true);
    }
  }, [modellingFetched, compModellingData]);
  return (
    <VStack className="h-full gap-4">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          className="items-baseline"
          bcTitle="Modelling"
          bcDescription="Home"
        />
      </div>
      <VStack className="flex h-full min-h-full gap-8 ">
        <VStack className="flex justify-start bg-white rounded-lg min-w-72 px-7">
          <VStack className="font-semibold text-lg1 pt-7">
            Organisation Structure
          </VStack>
          <HStack className="justify-around pt-4 mx-auto">
            <VStack className="w-1/4">
              <img
                src="../assets/dataExtraction.svg"
                alt="llll"
                height={"100px"}
                width={"200px"}
              />
            </VStack>
            <VStack className="w-2/3 gap-5">
              <p className="font-medium text-sm1"></p>
              <p className="font-medium text-sm3">
                Efficiently create and manage your organisation's structure.
                Define Department, Levels, and Key roles to ensure clarity and
                enhance operational efficiency across all levels.
              </p>
            </VStack>
          </HStack>
          <VStack className="justify-between pb-4">
            <HStack></HStack>
            <HStack className="justify-end">
              <ButtonPrimary
                onClick={() => {
                  navigate(
                    `/modelling/companyDetails?${
                      orgDataExist ? `id=5` : `id=1`
                    }`
                  );
                }}
              >
                {orgDataExist
                  ? "Edit Organisation Structure"
                  : "Create Organisation Structure"}
              </ButtonPrimary>
            </HStack>
          </VStack>
        </VStack>
        <VStack className="flex justify-start bg-white rounded-lg min-w-72 px-7">
          <VStack className="font-semibold text-lg1 pt-7">Hiring Plan</VStack>
          <HStack className="justify-around pt-4">
            <VStack className="w-1/4">
              <img
                src="../assets/profiling.svg"
                alt="llll"
                height={"25px"}
                width={"200px"}
              />
            </VStack>
            <VStack className="w-2/3 gap-5">
              <p className="font-medium text-sm1"></p>
              <p className="font-medium text-sm3">
                Develop a comprehensive hiring plan tailored to your company's
                needs. Identify key positions, set recruitment timelines.
              </p>
            </VStack>
          </HStack>
          <VStack className="justify-between pb-4">
            <HStack></HStack>
            <HStack className="justify-end gap-4">
              <Tooltip
                text={"Create Organisation Structure"}
                disabled={orgDataExist}
              >
                <ButtonPrimary
                  onClick={() => {
                    navigate(`/modelling/hiringPlan`);
                  }}
                  disabled={!orgDataExist}
                >
                  Create Hiring Plan
                </ButtonPrimary>
              </Tooltip>
              {hiringDataExist && (
                <HStack className="pb-4">
                  <ButtonSecondary
                    onClick={() => {
                      navigate(`/modelling/hiringPlan`);
                    }}
                  >
                    View Hiring Plan
                  </ButtonSecondary>
                </HStack>
              )}
            </HStack>
          </VStack>
        </VStack>
        <VStack className="flex justify-start bg-white rounded-lg min-w-72 px-7">
          <VStack className="font-semibold text-lg1 pt-7">
            Compensation Modelling
          </VStack>
          <HStack className="justify-around pt-4 mx-auto">
            <VStack className="w-1/4">
              <img src="../assets/3dModelling.svg" alt="llll" width={"200px"} />
            </VStack>
            <VStack className="w-2/3 gap-5">
              <p className="font-medium text-sm1"></p>
              <p className="font-medium text-sm3">
                Create competitive and equitable compensation models by
                integrating hiring plan. Balance internal equity with market
                standards, including salaries , to attract and retain top talent
                effectively.
              </p>
            </VStack>
          </HStack>
          <VStack className="justify-between pb-4">
            <HStack></HStack>
            <HStack className="justify-end gap-4">
              <Tooltip
                text={"Create Organisation Structure"}
                disabled={orgDataExist}
              >
                <ButtonPrimary
                  onClick={() => {
                    navigate(`/modelling/compensationModelling`);
                  }}
                  disabled={!orgDataExist}
                >
                  Model a Compensation
                </ButtonPrimary>
              </Tooltip>
              {modellingExist && (
                <div className="pb-4">
                  <ButtonSecondary
                    onClick={() => {
                      navigate(`/modelling/compensationModelling`);
                    }}
                  >
                    View Compensation Modelling
                  </ButtonSecondary>
                </div>
              )}
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Modelling;
