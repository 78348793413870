export interface ExercisableOptionsDetails {
  optionHolderId: string;
  optionHolderName: string;
  esopPlanName: string;
  numberOfShares: number;
  dateOfExercise?: string;
  exercisePrice: number;
  isCashExercise: boolean;
  conversionRatio: number;
}
export interface ExerciseReq {
  isCashExercise: boolean;
  isFractional: boolean;
  dateOfExercise: string;
  optionsAvailable: number;
  numberOfShares: number;
  note: string;
  raisedBy: RaisedBy;
  optionHolderId: string;
  optionHolderName: string;
  esopPlanName: string;
  planType: string;
  sellingPrice?: number;
  amountReceived?: number;
  isSellToCover: boolean;
  sharesSoldToCover?: number;
  exercisePrice: number;
  conversionRatio: number;
  fmv?: number;
  identifier: string;
  status: string;
  id: string;
  conversionMethodology?: string;
}

export interface Result {
  resultCode: string;
  reason: string;
}

export interface ApproveExerciseReq {
  isCashExercise: boolean;
  dateOfExercise: string;
  numberOfShares: number;
  note?: string | null;
  optionHolderId: string;
  optionHolderName: string;
  esopPlanName: string;
  exercisePrice: number;
  optionState: string;
}

export interface PlanExercise {
  exerciseDate: string;
  cashExercisedOptions: number;
  stockExercisedOptions: number;
  cumulativeExercisedOptions: number;
}

export enum RaisedBy {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
  RESIGNATION = "RESIGNATION",
  ONBOARDING = "ONBOARDING",
}

export enum ConversionMethodology {
  ROUND_UP = "ROUND_UP",
  ROUND_DOWN = "ROUND_DOWN",
}
