import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import {
  CheckBoxWithLabel,
  Input,
  Label,
} from "../../../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  Error,
} from "../../../../components/utils";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import { Select } from "../../../../components/shared/Select";
import OrganiSationStructureDataProcessor from "../../companyDetails/utils/OrgStructureJSONDataProc";
import {
  CompensationDepartment,
  LevelPercentageAndCount,
} from "../../../../types/modelling";

const AddGeneralAllocationDialog = ({
  open,
  mode,
  department,
  onClose = () => {},
}: {
  open: boolean;
  mode?: "Edit" | "Add";
  department?: CompensationDepartment;
  onClose: () => void;
}) => {
  const {
    compensationModellingData,
    addCompensationGeneralDataToModelling,
    selectedCompanyData,
  } = useOrgTemplateStore();

  const [departments, setDepartments] = useState<string[]>([]);

  const [applyAll, setApplyAll] = useState(false);

  const [processedData, setProcessedData] =
    useState<OrganiSationStructureDataProcessor>();

  const initialModel: CompensationDepartment = {
    department: "",
    percentageOfSalary: 0,
    levelsData: [],
  };

  const [data, setData] = useState<CompensationDepartment>(
    mode === "Edit"
      ? (department as unknown as CompensationDepartment)
      : initialModel
  );

  useEffect(() => {
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      compensationModellingData?.data
    );
    setDepartments(processedData.getDepartmentNames());
  }, [compensationModellingData]);

  useEffect(() => {
    setProcessedData(
      new OrganiSationStructureDataProcessor(
        undefined,
        compensationModellingData?.data
      )
    );
  }, [compensationModellingData]);

  function handleSubmit() {
    if (data.department === "") {
      toast.error("Please select a Department");
      return;
    }
    if (data.percentageOfSalary <= 0) {
      toast.error("Percentage of salary is mandatory");
      return;
    }
    addCompensationGeneralDataToModelling(data);
    onClose();
  }
  return (
    <VStack className="w-full">
      <VStack className="p-4 text-lg font-medium border-b">
        <div className="flex items-center justify-between">
          <VStack>
            <p> General Allocation</p>
            <p className="text-xs text-gray-400">
              Provide the salary range in percentage for non-key employees in
              different roles within a department in the organisation.
            </p>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </VStack>
      <VStack className="w-full p-4">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Department</Label>
            <Select
              options={departments}
              value={data.department}
              onChange={(e) => {
                const levels = processedData?.getLevels(e.target.value);
                const levelsData: LevelPercentageAndCount[] = [];
                if (levels) {
                  levels.forEach((level) => {
                    levelsData.push({
                      [level]: {
                        percentage: applyAll ? data.percentageOfSalary : 0,
                        count:
                          compensationModellingData?.data?.departments[
                            e.target.value
                          ]?.levels[level]?.totalEmployeeCount || 0,
                      },
                    });
                  });
                }
                setData((prev) => ({
                  ...prev,
                  department: e.target.value,
                  levelsData,
                }));
              }}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Percentage of Salary</Label>
            <Input
              type="text"
              value={data.percentageOfSalary}
              onChange={(e) => {
                let updatedPercentages = data.levelsData;
                if (applyAll) {
                  updatedPercentages = data.levelsData.map((level) => {
                    const levelName = Object.keys(level)[0];
                    const obj: LevelPercentageAndCount = {
                      [levelName]: {
                        ...level[levelName],
                        percentage: Number(e.target.value),
                      },
                    };
                    return obj;
                  });
                }
                setData((prev) => ({
                  ...prev,
                  percentageOfSalary: Number(e.target.value),
                  levelsData: updatedPercentages,
                }));
              }}
            />
            <div className="pt-2 pl-1">
              <CheckBoxWithLabel
                label={"Apply to all levels"}
                labelclassname={"pl-2"}
                onChange={(e) => {
                  setApplyAll(!applyAll);
                  if (e.target.checked) {
                    setData((prev) => {
                      const updatedPercentages = data.levelsData.map(
                        (level) => {
                          const levelName = Object.keys(level)[0];
                          const obj: LevelPercentageAndCount = {
                            [levelName]: {
                              ...level[levelName],
                              percentage: data.percentageOfSalary,
                            },
                          };
                          return obj;
                        }
                      );
                      return { ...prev, levelsData: updatedPercentages };
                    });
                  }
                }}
              />
            </div>
          </div>
        </HStack>
        <VStack>
          <HStack className="items-center pt-10">
            <HStack className=" h-0.5 w-14  border-b border-dashed  "></HStack>
            <HStack className="px-2 text-sm whitespace-nowrap text-orange-501">
              Custom Allocation
            </HStack>
            <HStack className="h-0.5 w-full  border-b border-dashed"></HStack>
          </HStack>
          <span className="text-gray-400 pl-14">
            Please select the level range and allocate custom percentage for
            each level
          </span>
          <HStack className="gap-4 px-20 pt-4">
            {data.levelsData.map((level, index) =>
              Object.entries(level).map(([levelName, value]) => (
                <InputBoxWithIncrement
                  levelName={levelName}
                  value={value.percentage}
                  index={index}
                  key={index}
                  includePercentage={true}
                  onChange={(val) => {
                    setData((prev) => {
                      const updatedPercentages = data.levelsData;
                      updatedPercentages[index] = {
                        [levelName]: {
                          ...updatedPercentages[index][levelName],
                          percentage: val,
                        },
                      };
                      return { ...prev, levelsPercentage: updatedPercentages };
                    });
                  }}
                />
              ))
            )}
          </HStack>
          {!selectedCompanyData?.employeeListUploaded && (
            <>
              <HStack className="items-center pt-10">
                <HStack className=" h-0.5 w-14  border-b border-dashed  "></HStack>
                <HStack className="px-2 text-sm whitespace-nowrap text-orange-501">
                  Employee Count
                </HStack>
                <HStack className="h-0.5 w-full  border-b border-dashed"></HStack>
              </HStack>
              <span className="text-gray-400 pl-14">
                Enter the number of employees in each level
              </span>
              <HStack className="gap-4 px-20 pt-4">
                {data.levelsData.map((level, index) =>
                  Object.entries(level).map(([levelName, value]) => (
                    <InputBoxWithIncrement
                      levelName={levelName}
                      value={value.count}
                      index={index}
                      key={index}
                      includePercentage={false}
                      onChange={(val) => {
                        setData((prev) => {
                          const updatedEmployeeCount = data.levelsData;
                          updatedEmployeeCount[index] = {
                            [levelName]: {
                              ...updatedEmployeeCount[index][levelName],
                              count: val,
                            },
                          };
                          return {
                            ...prev,
                            levelsData: updatedEmployeeCount,
                          };
                        });
                      }}
                    />
                  ))
                )}
              </HStack>
            </>
          )}
        </VStack>
        <HStack className="justify-end gap-3">
          <ButtonSecondary
            onClick={() => onClose()}
            className="p-10 text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

function InputBoxWithIncrement(props: {
  levelName: string;
  value: number;
  index: number;
  onChange: (value: number) => void;
  includePercentage: boolean;
}) {
  const { levelName, value, index, onChange, includePercentage } = props;
  const [focused, setFocused] = useState(false);
  return (
    <VStack key={index}>
      <VStack key={index}>
        <HStack
          className="border border-[#E85936] border-opacity-40 rounded-lg"
          onMouseLeave={() => setFocused(false)}
          onMouseEnter={() => setFocused(true)}
        >
          {focused && (
            <VStack className="justify-around items-center w-8 h-full  bg-[#E85936] bg-opacity-40 rounded-l-lg">
              <Icon
                onClick={() => {
                  onChange(value + 1);
                }}
                icon="pepicons-pop:plus"
                color="#E85936"
                className="cursor-pointer active:bg-opacity-25"
                height={16}
                width={16}
              />
              <Icon
                onClick={() => {
                  onChange(value - 1);
                }}
                icon="pepicons-pop:minus"
                color="#E85936"
                className="cursor-pointer"
                height={16}
                width={16}
              />
            </VStack>
          )}
          <Input
            className={`w-12 p-0 pl-3 rounded-l-lg ${
              includePercentage ? "rounded-r-none" : ""
            }`}
            placeholder="Enter value"
            value={value || 0}
            onChange={(e) => {
              onChange(Number(e.target.value));
            }}
          />
          {includePercentage && (
            <HStack className="items-center pr-2 rounded-r-lg cursor-default bg-gray-901">
              %
            </HStack>
          )}
        </HStack>
        <HStack className="flex items-center justify-center pt-1 font-semibold text-gray-dark">
          {levelName}
        </HStack>
      </VStack>
    </VStack>
  );
}

export default AddGeneralAllocationDialog;
