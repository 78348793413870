import { LinearProgress, LinearProgressProps } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import AvatarEditor from "react-avatar-editor";
import Dropzone, { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import { cloneDeep } from "lodash";
import { bulkUploadGrantLetters, getGrantTemplate } from "../../api/Esop";
import FileInput from "../../components/shared/FileInput";
import { Label } from "../../components/shared/InputField";
import Tooltip from "../../components/shared/Tooltip";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import { useAddGrantExcel, useGrants } from "../../queries";
import { useCompanyStore } from "../../store";
import { ValidationObj } from "../../types/Grant";

import SearchDropDown from "../../components/shared/SearchDropdown";
import convertToBase64 from "../../utils/convertToBase64";

function UploadGrantLetters() {
  const [statusIndicatorData, setStatusIndicatorData] =
    useState<LinearProgressProps>({ variant: "determinate", value: 0 });
  const { isPlaceholderData, isFetching, data: grants } = useGrants();
  const navigate = useNavigate();
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    maxFiles: 50,
  });
  const [grantFilesMap, setGrantFilesMap] = useState<
    Map<
      string,
      {
        file: File | undefined;
        status: string;
        grantIdentifier: string;
        error?: string;
      }
    >
  >(new Map());
  const grantIdentifiers = useMemo(
    () => grants?.map((grant) => grant.grantIdentifier) || [],
    [grants]
  );
  function handleFileUpload() {
    if (grantFilesMap.size === 0) {
      const allowedFileExtensions = ["pdf"];
      const grantFilesMapCopy = cloneDeep(grantFilesMap);
      acceptedFiles.forEach((file: any) => {
        grantFilesMapCopy?.set(file.name, {
          file,
          status: "Pending",
          grantIdentifier: "",
        });
        if (
          allowedFileExtensions.some((extension) =>
            file.name.endsWith(extension)
          )
        ) {
          const grantIdentifier = grantIdentifiers.find((grantIdentifier) =>
            file.name.includes(grantIdentifier)
          );
          if (grantIdentifier) {
            grantFilesMapCopy?.set(file.name, {
              file,
              status: "Pending",
              grantIdentifier,
            });
          }
        }
      });
      setGrantFilesMap(grantFilesMapCopy);
      toast("Files have been matched with respective grants wherever found", {
        autoClose: 2000,
        type: "info",
      });
    } else {
      const isValid = !validateImports();
      if (!isValid) {
        toast("Found errors in mapping, please correct to continue", {
          type: "error",
          autoClose: 2000,
        });
      } else {
        makeChunksAndUpload();
      }
    }
  }
  async function makeChunksAndUpload() {
    const chunkSize = 10;
    const entriesArray = Array.from(grantFilesMap.entries());
    const grantFilesMapCopy = cloneDeep(grantFilesMap);
    grantFilesMapCopy.forEach((grant) => {
      grant.status = "uploading";
    });
    let error = "";
    setGrantFilesMap(grantFilesMapCopy);
    for (let i = 0; i < entriesArray.length; i += chunkSize) {
      const chunk = entriesArray.slice(i, i + chunkSize);
      /* eslint-disable no-await-in-loop */
      const uploadStatus = await uploadGrantLetters(chunk);
      if (uploadStatus !== "") error = uploadStatus;
    }
    if (!error) {
      toast("Grant letters have been uploaded successfully", {
        autoClose: 2000,
        type: "success",
      });
      setTimeout(() => {
        navigate("/options/allPlans");
      }, 1000);
    }
  }
  async function convertFileToBase64(grantId: string, file: File) {
    const base64File: string = (await convertToBase64(file)) as string;
    return { grantId, base64File };
  }
  async function uploadGrantLetters(
    chunk: [
      string,
      {
        file: File | undefined;
        status: string;
        grantIdentifier: string;
        error?: string;
      }
    ][]
  ) {
    const chunkedArray = Array.from(chunk);
    const convertedList = await Promise.all(
      chunkedArray.map((chunkedData) => {
        if (chunkedData[1].file)
          return convertFileToBase64(
            chunkedData[1].grantIdentifier,
            chunkedData[1].file
          );
        return { grantId: chunkedData[1].grantIdentifier, base64File: "" };
      })
    );
    let error = "";
    try {
      const uploaded = await bulkUploadGrantLetters(convertedList);
    } catch (e) {
      toast("Error while uploading some files", {
        autoClose: 2000,
        type: "error",
      });
      error = "Batch upload Failed";
    }
    const grantFilesMapCopy = cloneDeep(grantFilesMap);
    const chunkedGrant = chunk.map(
      (chunkedData) => chunkedData[1].grantIdentifier
    );
    grantFilesMapCopy.forEach((grant) => {
      if (chunkedGrant.includes(grant.grantIdentifier)) {
        grant.status = "success";
        if (error) {
          grant.status = "error";
          grant.error = error;
        }
      }
    });
    setGrantFilesMap(grantFilesMapCopy);
    return error;
  }
  function validateImports() {
    const grantFilesMapCopy = cloneDeep(grantFilesMap);
    let errorsExist = false;
    grantFilesMapCopy.forEach((grantFile) => {
      let error = "";
      if (!grantFile.grantIdentifier) error = "No identifier found";
      else if (
        Array.from(grantFilesMapCopy.values()).filter(
          (value) => value.grantIdentifier === grantFile.grantIdentifier
        ).length > 1
      )
        error = "Duplicate identifier found";
      if (error) {
        grantFile.error = error;
        errorsExist = true;
      }
    });
    setGrantFilesMap(grantFilesMapCopy);
    return errorsExist;
  }
  function handleGrantMapChange(fileKey: string, value: string) {
    const grantFilesMapCopy = cloneDeep(grantFilesMap);
    const existingConfig = grantFilesMap.get(fileKey);
    grantFilesMapCopy.set(fileKey, {
      file: existingConfig?.file,
      grantIdentifier: value,
      status: existingConfig?.status || "Pending",
    });
    setGrantFilesMap(grantFilesMapCopy);
  }
  return (
    <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[600px]">
      <VStack className="overflow-auto">
        <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
          <h6 className="flex-1">Upload Grant Letters</h6>
        </HStack>
        {grantFilesMap?.size === 0 && (
          <Box className="mx-auto h-96 w-3/4 bg-gray-50 pt-8 mt-6 cursor-copy">
            <div className="flex w-full h-full justify-center">
              <div
                {...getRootProps({
                  className: "dropzone  align-middle flex flex-col gap-4",
                })}
              >
                <HStack>
                  <Icon
                    width={64}
                    height={64}
                    className="text-red-500 text-center flex-row w-full"
                    icon={"mdi:folder-upload"}
                  ></Icon>
                </HStack>
                <HStack className="text-center justify-center pb-4">
                  <input {...getInputProps()} />
                  <p>Drag drop grant letters here, or click to select them.</p>
                </HStack>
                <VStack className="text-xxs justify-start text-start">
                  <li>
                    If the grant letters are named as per their grant number,
                    the system will automatically match the grant numbers to the
                    grants in the system.
                  </li>
                  <li>
                    If the grant letters names do not match the grant numbers,
                    you will be prompted to match the grant numbers to the
                    grants in the system.
                  </li>
                  <li>A maximum of 50 files can be uploaded at once.</li>
                  {acceptedFiles.length > 0 && (
                    <p className="mt-4">
                      <span className="text-xl">{acceptedFiles.length}</span>{" "}
                      files uploaded, click on continue to begin the import
                      process
                    </p>
                  )}
                </VStack>
              </div>
            </div>
          </Box>
        )}
        {grantFilesMap?.size > 0 && (
          <Box className="mx-auto h-96 w-3/4">
            <VStack className="w-full h-96 justify-center">
              <HStack className="min-h-16 justify-center align-middle">
                <table className="w-full flex-1">
                  <thead>
                    <tr>
                      <td>File name</td>
                      <td>Grant Identifier</td>
                    </tr>
                  </thead>
                  <tbody className="">
                    {Array.from(grantFilesMap).map((filename, idex) => (
                      <tr
                        key={idex}
                        className="min-h-24 justify-center align-middle border-b-2 border-dotted"
                      >
                        <td className="h-20">{filename[0]}</td>
                        <td className="">
                          <SearchDropDown
                            onChange={(e, value) => {
                              handleGrantMapChange(filename[0], value);
                            }}
                            key={`grantInfo-${idex}`}
                            placeholder="-- Select Field --"
                            options={grantIdentifiers}
                            value={filename[1].grantIdentifier}
                            className={""}
                            getOptionLabel={(option) => `${option}`}
                            isOptionEqualToValue={(option, value) =>
                              (option || "") === (value || "")
                            }
                          />
                          {filename[1]?.error && (
                            <span className="text-red-400 text-xxs">
                              {filename[1]?.error}
                            </span>
                          )}
                          {filename[1]?.status === "success" && (
                            <span className="text-green-400 text-xxs">
                              File uploaded
                            </span>
                          )}
                          {filename[1]?.status === "uploading" && (
                            <span className="w-full rounded-lg align-middle justify-center mx-auto inline-flex">
                              <LinearProgress
                                variant="indeterminate"
                                className="w-full pt-2 rounded-lg"
                                color="info"
                              />
                            </span>
                          )}
                          {uploadInProgress &&
                            filename[1]?.status === "Pending" && (
                              <span className="text-blue-400">
                                Queued for upload
                              </span>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </HStack>
            </VStack>
          </Box>
        )}
      </VStack>
      <HStack className="p-4 justify-end gap-4">
        <ButtonSecondary>Clear</ButtonSecondary>
        <ButtonPrimary
          disabled={acceptedFiles.length === 0}
          onClick={() => handleFileUpload()}
        >
          Continue
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}

export default UploadGrantLetters;
