import { useMutation, useQuery } from "react-query";
import {
  approveCompensationTemplate,
  createOrgCompensationTemplate,
  createOrganisationStructure,
  createOrganisationStructureV2,
  deleteOrgCompTemplate,
  deleteOrganisationStructure,
  editOrgCompensationTemplate,
  editOrganisationStructure,
  getAllOrgCompensationTemplates,
  getAllOrganisationStructure,
  getOrgTemplatebyId,
  getOrganisationStructure,
  getOrganisationStructureV2,
  sendForApproval,
} from "../api/orgStructureApi";
import { queryClient } from "./client";

/**
    Queries for organisation structure
 */

export function useCreateOrgStructure() {
  return useMutation({
    mutationKey: "createOrgansiationStructure",
    mutationFn: createOrganisationStructure,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useCreateOrgStructureV2() {
  return useMutation({
    mutationKey: "createOrgansiationStructureV2",
    mutationFn: createOrganisationStructureV2,
    onSuccess: () => {
      queryClient.invalidateQueries("getOrgStructureV2");
      queryClient.refetchQueries("getOrgStructureV2");
    },
    onError: () => {},
  });
}

export function useGetAllOrgStructure() {
  return useQuery({
    queryKey: ["getOrgStructure"],
    queryFn: getAllOrganisationStructure,
  });
}

export function useGetOrgStructureV2() {
  return useQuery({
    queryKey: "getOrgStructureV2",
    queryFn: getOrganisationStructureV2,
  });
}

export function useGetOrgStructure(orgId: number) {
  return useQuery({
    queryKey: ["getOrgStructure", orgId],
    queryFn: getOrganisationStructure,
  });
}

export function useEditOrgStructure() {
  return useMutation({
    mutationKey: "editOrgansiationStructure",
    mutationFn: editOrganisationStructure,
    onSuccess: (data) => {},
    onError: () => {},
  });
}

export function useDeleteOrgStructure() {
  return useMutation({
    mutationFn: deleteOrganisationStructure,
    mutationKey: "deleteOrganisationStructure",
    onSuccess: () => {},
    onError: () => {},
  });
}

/** queries for compnesation templates */

export function useCreateAllocationTemplate() {
  return useMutation({
    mutationKey: "createCompensationTemplates",
    mutationFn: createOrgCompensationTemplate,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useEditAllocationTemplate() {
  return useMutation({
    mutationKey: "editAllocationTemplate",
    mutationFn: editOrgCompensationTemplate,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useGetAllCompTemlates() {
  return useQuery({
    queryKey: "getAllOrgCompensationTemplates",
    queryFn: getAllOrgCompensationTemplates,
  });
}

export function useGetOrgTemplateById(compTempId: number) {
  return useQuery({
    queryKey: ["getOrgCompensationTemplateById", compTempId],
    queryFn: getOrgTemplatebyId,
    enabled: !!compTempId,
  });
}

export function useDeleteOrgCompTemplate() {
  return useMutation({
    mutationKey: "deleteCompensationTemplate",
    mutationFn: deleteOrgCompTemplate,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useSendForApproval() {
  return useMutation({
    mutationKey: "sendForApproval",
    mutationFn: sendForApproval,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useApproveCompensationTemplate() {
  return useMutation({
    mutationKey: "approveCompensationTemplate",
    mutationFn: approveCompensationTemplate,
  });
}
