import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";
import { Dialog } from "@mui/material";
import _ from "lodash";
import SearchInput from "../../../components/shared/SearchInput";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../../components/utils";
import { CompensationDepartment } from "../../../types/modelling";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import {
  CompanyData,
  DepartmentData,
} from "../companyDetails/utils/OrgStructureJSONDataProc";
import { useGetOrgStructureV2 } from "../../../queries/organisationStructure";
import AddGeneralAllocationDialog from "./dialogs/AddGeneralAllocationDialog";
import { Action, CTADropdown } from "../../../components/shared/Dropdown";

const GeneralCompModelling = () => {
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  const {
    compensationModellingData,
    clearGeneralDataFromModelling,
    selectedCompanyData,
  } = useOrgTemplateStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Edit" | "Add";
    department?: CompensationDepartment;
  }>({
    open: false,
    mode: "Add",
  });
  const [departments, setDepartments] =
    useState<Array<CompensationDepartment>>();
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      width: 200,
      autoHeight: true,
      wrapText: true,
      field: "department",
      filter: "agSetColumnFilter",
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Percentage",
      autoHeight: true,
      field: "percentageOfSalary",
      filter: "agSetColumnFilter",
      width: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: CTADropdown,
    },
  ];
  const convertDepartmentDataToDepartment = (departments: {
    [departmentName: string]: DepartmentData;
  }) => {
    const data: CompensationDepartment[] = [];
    data.push(
      ...Object.entries(departments).map(([name, data]) => {
        const returnValue = {
          department: name,
          percentageOfSalary: data.percentageOfSalary || 0,
          levelsData: Object.entries(data.levels).map(
            ([levelName, levelData]) => ({
              [levelName]: {
                percentage: levelData.percentageOfSalary || 0,
                count: levelData.totalEmployeeCount || 0,
              },
            })
          ),
        };
        return returnValue;
      })
    );
    return data.filter((level) => level.percentageOfSalary > 0);
  };
  useEffect(() => {
    if (
      compensationModellingData &&
      compensationModellingData.data &&
      compensationModellingData.data.departments
    ) {
      setDepartments(
        convertDepartmentDataToDepartment(
          compensationModellingData.data.departments
        )
      );
    }
  }, [compensationModellingData]);

  function handleAction(department: CompensationDepartment, action: Action) {
    if (action.name === "Edit") {
      setDialog({
        open: true,
        mode: "Edit",
        department,
      });
    } else if (action.name === "Delete") {
      clearGeneralDataFromModelling(department);
    }
  }

  const rowData = useMemo(() => {
    if (!departments) return [];
    return departments.map((department) => ({
      ...department,
      actions: (
        <CTADropdown
          dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
          actions={[
            {
              name: "Edit",
            },
            {
              name: "Delete",
            },
          ]}
          onAction={(action) => {
            handleAction(department, action);
          }}
        />
      ),
    }));
  }, [departments]);
  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Allocation to General
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Add department wise % of salary payable as stock options to employees
          other than key roles and key personnel of the company. To make
          deletions, check the box adjacent to department
        </HStack>
      </VStack>
      <HStack className="justify-end gap-8 mx-12 mt-4">
        <div className="flex gap-2 text-xs text-center justify-evenly">
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
          <ButtonPrimary
            onClick={() => {
              setDialog({ open: !dialog.open });
            }}
          >
            Add
          </ButtonPrimary>
        </div>
      </HStack>
      <Dialog
        maxWidth="xl"
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
      >
        <div
          className={`w-[800px] h-[${
            selectedCompanyData?.employeeListUploaded ? "450px" : "600px"
          }] min-h-min mx-auto bg-white rounded-lg`}
        >
          <AddGeneralAllocationDialog
            open={dialog.open}
            mode={dialog.mode}
            department={dialog.department}
            onClose={() => setDialog({ open: false })}
          />
        </div>
      </Dialog>
      <HStack className="justify-between w-full pt-6 pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
};

export default GeneralCompModelling;
