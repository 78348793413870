import { useFormikContext } from "formik";
import { useContext } from "react";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
  isEsopViewer,
} from "../../components/utils";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { SiteSettingsRes } from "../../types/SiteSettings";
import { SiteSettingsContext } from "./SiteSettings";
import { usePermissionStore } from "../../store";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Resource,
  Action as DefaultAction,
} from "../../utils/interfaces/Companies";

function SecuritySettings() {
  const formik = useFormikContext<SiteSettingsRes>();
  const isUserEsopViewer = isEsopViewer();
  const isError = useContext(SiteSettingsContext);
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  const securitySettingsErrors = errors.isEnabledTwoFactorAuthentication;
  function doPartialTouch() {
    formik.setFieldTouched("isEnabledTwoFactorAuthentication");
  }
  const { permission } = usePermissionStore();

  return (
    <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
      <VStack className="justify-between p-4 gap-9">
        <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
          Security Settings
        </Box>
        <HStack className="gap-8">
          <p className={` form-label text-sm font-medium`}>
            Activate two-factor Authentication
          </p>
          <Box className="flex items-start ml-2">
            <SwitchButton
              onClick={() => {
                formik.setFieldValue(
                  "isEnabledTwoFactorAuthentication",
                  !formik.values.isEnabledTwoFactorAuthentication
                );
              }}
              className=""
              value={formik.values.isEnabledTwoFactorAuthentication}
              label=""
            />
          </Box>
        </HStack>
      </VStack>
      {!isUserEsopViewer && (
        <HStack className="justify-between mt-12">
          <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary
            disabled={
              isError ||
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.CompanySettings,
                DefaultAction.All
              )
            }
            className={
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.CompanySettings,
                DefaultAction.All
              )
                ? "opacity-50 cursor-not-allowed"
                : ""
            }
            type="submit"
          >
            Save
          </ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}

export default SecuritySettings;
