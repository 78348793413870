import { QueryFunctionContext } from "react-query";
import optionsApi from "./optionsApi";
import { CompensationTemplate } from "../store/useCompensationTemplateStore";
import { CompensationTemplate as AllocationTemplate } from "../store/useAllocationStore";
import { CompanyData } from "../pages/esopModeling/companyDetails/utils/OrgStructureJSONDataProc";

export async function createOrganisationStructure(
  data: any
): Promise<CompensationTemplate> {
  return optionsApi
    .post("v1/organisationStructure/create", data)
    .then((res) => res.data.data);
}

export async function getAllOrganisationStructure(): Promise<
  CompensationTemplate[]
> {
  return optionsApi
    .get("v1/organisationStructure/getOrgStructure")
    .then((res) => res.data.data);
}

export async function getOrganisationStructure(
  context: QueryFunctionContext
): Promise<CompensationTemplate> {
  return optionsApi
    .get(`v1/organisationStructure/${context.queryKey[0]}/getOrgStructure`)
    .then((res) => res.data.data);
}

export async function editOrganisationStructure(editBody: {
  orgId: string;
  editOrgStrucBody: any;
}): Promise<CompensationTemplate> {
  const orgId = editBody.orgId;
  const OrgStructure = editBody.editOrgStrucBody;
  return optionsApi
    .put(`v1/organisationStructure/${orgId}/edit`, OrgStructure)
    .then((res) => res.data.data);
}

export async function deleteOrganisationStructure(orgId: string): Promise<any> {
  return optionsApi
    .delete(`v1/organisationStructure/${orgId}/delete`)
    .then((res) => res.data.data);
}

/** compensation templates api */

export async function createOrgCompensationTemplate(
  templateSummary: any
): Promise<any> {
  return optionsApi
    .post("v1/allocation/createAllocationTemplate", templateSummary)
    .then((res) => res.data.data);
}

export function editOrgCompensationTemplate(editOrgTemplateSummary: {
  orgId: string;
  orgTemplate: any;
}): Promise<any> {
  return optionsApi
    .put(
      `v1/allocation/${editOrgTemplateSummary.orgId}/editAllocationTemplate`,
      editOrgTemplateSummary
    )
    .then((res) => res.data.data);
}

export function getAllOrgCompensationTemplates(): Promise<
  AllocationTemplate[]
> {
  return optionsApi
    .get(`v1/allocation/getAllocationTemplates`)
    .then((res) => res.data.data);
}

export function getOrgTemplatebyId(
  context: QueryFunctionContext
): Promise<AllocationTemplate> {
  return optionsApi
    .get(`v1/allocation/${context.queryKey[1]}/getAllocationTemplate`)
    .then((res) => res.data.data);
}

export function deleteOrgCompTemplate(compTempId: string): Promise<any> {
  return optionsApi
    .delete(`v1/allocation/${compTempId}/deleteAllocationTemplate`)
    .then((res) => res.data.data);
}

export function sendForApproval(compTempId: string): Promise<any> {
  return optionsApi
    .put(`v1/allocation/${compTempId}/sendForApprovalAllocationTemplate`)
    .then((res) => res.data.data);
}

export function approveCompensationTemplate(compTempId: string): Promise<any> {
  return optionsApi
    .put(`v1/allocation/${compTempId}/approveAllocationTemplate`)
    .then((res) => res.data.data);
}

export async function createOrganisationStructureV2(
  data: CompanyData
): Promise<string> {
  return optionsApi
    .post("v1/organisationStructure/createV2", data)
    .then((res) => res.data.data);
}

export async function getOrganisationStructureV2(): Promise<CompanyData> {
  return optionsApi
    .get("v1/organisationStructure/getOrgStructureV2")
    .then((res) => res.data.data);
}
