import nodeApi from "./nodeApi";
import optionsApi from "./optionsApi";

export async function getExsitingGreytipConfigs() {
  const res = await optionsApi.get("/v1/imports/getExistingConfig/greytip");
  return res.data.data;
}
export async function testHrmsConfig(testBody: any) {
  const res = await optionsApi.post("/v1/imports/testHrmsConfig", testBody);
  return res.data.data;
}
export async function syncHrms(syncConfig: any) {
  const res = await optionsApi.post("/v1/imports/syncHrmsData", syncConfig);
  return res.data.data;
}
