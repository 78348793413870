import TextField from "@mui/material/TextField";
import React from "react";
import { InputType } from "../utils/interfaces/inputTypes";

const Input = (props: InputType) => {
  const { className, type, placeholder, value, onClick, name } = props;

  return (
    <TextField
      placeholder={placeholder}
      type={type}
      className={className}
      value={value}
      name={name}
      onChange={onClick}
    />
  );
};

export default Input;
