import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { format } from "date-fns";
import { CheckBoxWithLabel, Label } from "../../components/shared/InputField";
import { Box, VStack } from "../../components/utils";
import { useEmployeeTableFilterStore } from "../../store/employeeTableFilters";

const EmployeeTableFilters = () => {
  const filter = useEmployeeTableFilterStore();

  return (
    <VStack className="gap-4 p-4 my-auto rounded-lg">
      <Box>
        <Label className="block text-sm font-medium">Actionable Insights</Label>
        {filter.actionableInsightFilters.map((insightFilter) => (
          <Box
            key={insightFilter.label}
            className="flex flex-row justify-between"
          >
            <CheckBoxWithLabel
              label={insightFilter.label}
              labelclassname="ml-2 text-xs1 text-gray-500 p-2"
              onChange={(e) =>
                filter.toggleActionableInsightFilter(
                  insightFilter,
                  e.target.checked
                )
              }
              checked={insightFilter.enabled}
            />
            <span className="text-gray-500"></span>
          </Box>
        ))}
      </Box>
    </VStack>
  );
};

export default EmployeeTableFilters;
