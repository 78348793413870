import { ColDef, GridApi, SideBarDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef } from "react";
import { ApprovedExerciseDetails } from "../../types/OptionBuyback";
import { ConversionMethodology } from "../../types/Exercise";

function ApprovedExercisesAgGrid({
  approvedExercises,
}: {
  approvedExercises: ApprovedExerciseDetails[];
}) {
  const gridApi = useRef<GridApi | null>(null);

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "EMPLOYEE NAME",
      width: 200,
      field: "employeeName",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("employeeName"),
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      autoHeight: true,
      wrapText: true,
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
    },
    {
      headerName: "EMPLOYEE ID",
      field: "hrId",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) => params.getValue("hrId"),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "GRANT ID",
      field: "identifier",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) => params.getValue("identifier"),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "GRANTED OPTIONS",
      field: "grantedOptions",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) => params.getValue("grantedOptions"),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "VESTED OPTIONS",
      field: "vestedOptions",
      sortable: true,
      filterValueGetter: (params) => params.getValue("vestedOptions"),
      filterParams: filterparams,
      filter: "agMultiColumnFilter",
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "OPTIONS EXERCISED",
      field: "exercisedOptions",
      sortable: true,
      filterValueGetter: (params) => params.getValue("exercisedOptions"),
      filterParams: filterparams,
      filter: "agMultiColumnFilter",
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "SHARES RECEIVED",
      field: "sharesReceived",
      sortable: true,
      filterValueGetter: (params) => params.getValue("sharesReceived"),
      filter: "agMultiColumnFilter",
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
      valueGetter: (p) => p.data.sharesReceived,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: "agSetColumnFilter",
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
    },
  ];

  const rowData = useMemo(
    () =>
      approvedExercises?.map((exercise) => ({
        employeeName: exercise.employeeName,
        hrId: exercise.hrId,
        identifier: exercise.identifier,
        grantedOptions: exercise.grantedOptions,
        vestedOptions: exercise.vestedOptions,
        exercisedOptions: exercise.exercisedOptions,
        sharesReceived:
          exercise.conversionMethodology === ConversionMethodology.ROUND_DOWN
            ? Math.floor(
                exercise.exercisedOptions * (exercise.conversionRatio || 1)
              )
            : Math.ceil(
                exercise.exercisedOptions * (exercise.conversionRatio || 1)
              ),
      })),
    [approvedExercises]
  );
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  const topGrid = useRef(null);
  const bottomGrid = useRef(null);
  return (
    <AgGridReact
      ref={topGrid}
      alignedGrids={bottomGrid.current ? [bottomGrid.current] : undefined}
      onGridReady={onGridReady}
      alwaysShowHorizontalScroll
      suppressRowTransform={true}
      alwaysMultiSort
      sideBar={sideBar}
      animateRows={true}
      defaultColDef={defaultColDef}
      rowData={rowData}
      columnDefs={columnDefs}
      suppressCopyRowsToClipboard={true}
      suppressCopySingleCellRanges={true}
      suppressCellFocus={true}
      suppressMenuHide={true}
      rowClass={"border-t border-dashed cursor-pointer hover:bg-slate-50 "}
      pagination={true}
      overlayNoRowsTemplate={
        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
      }
    ></AgGridReact>
  );
}

export default ApprovedExercisesAgGrid;
