import { useState } from "react";
import { VStack } from "../../../components/utils";
import BCHeader from "../../../shared/BCHeader";
import CreateCompensationModelling from "./CreateCompensationModelling";

function CompensationModelling() {
  return (
    <VStack className="gap-6 ">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          className="items-baseline"
          bcTitle="Modelling"
          bcSubTitle="Compensation Modelling"
        />
      </div>
      <div>
        <CreateCompensationModelling />
      </div>
    </VStack>
  );
}

export default CompensationModelling;
