import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Box, VStack } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import {
  LiquidityState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import LiquidationDetails from "./LiquidationDetails";

function LiquidationView() {
  const { id } = useParams();
  const _id = id ?? "";
  const navigate = useNavigate();
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview ?? [];
  const [buyback, setBuyback] = useState<OptionBuyBackDetails>();
  useEffect(() => {
    refetch();
    if (!isFetching) {
      const isValidBuyBack = optionBuybackOverview.find(
        (optionBuyBack) => optionBuyBack.id === _id
      );
      if (!isValidBuyBack) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      } else {
        setBuyback(isValidBuyBack);
      }
    }
  }, [optionBuybackOverview, _id]);
  return (
    <VStack className="gap-4 py-3">
      <BCHeader
        bcTitle={"Liquidity Event Planning and Administration"}
        bcSubTitle={buyback?.eventName}
      />
      {buyback?.liquidityState !== LiquidityState.DRAFT && (
        <Box
          className={`w-3/5 px-5 py-5 border-dashed border ${
            buyback?.liquidityState !== LiquidityState.CLOSED
              ? "border-[#FFC700] bg-yellow-50"
              : "border-green-500 bg-green-100"
          } `}
        >
          <p className="text-sm font-medium text-black">
            {`Liquidation of ${buyback?.eventName} event ${
              buyback?.liquidityState === LiquidityState.ACTIVE
                ? "Started"
                : "Closed"
            } successfully`}
          </p>
        </Box>
      )}
      <div className=" p-4 bg-[#fefefe] gap-4">
        {buyback && <LiquidationDetails buybackDetail={buyback} />}
        {buyback && <BuybackEmployeeDetails buybackDetail={buyback} />}
        {/* {buyback?.transactionType === "exercise and liquidate" &&
          buyback?.elState === "ACTIVE" && buyback?.state === 'COMPLETED' ? (
          <UploadExcel />) :
          ( */}
        {buyback && <BuybackEmployeeList buyback={buyback} />}
        {/* )} */}
      </div>
    </VStack>
  );
}

export default LiquidationView;
