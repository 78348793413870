import StatusLabelColors from "../../constants/StatusLabelColors";
import { stringFormat } from "../../utils/string";

function StatusLabel({
  state,
  _className,
}: {
  state: string;
  _className?: string;
}) {
  state = state.toUpperCase();
  return (
    <span
      className={`${
        _className ? `${_className}` : "h-4"
      } items-center capitalize min-w-10  rounded-sm font-medium text-center text-[9px]  leading-4 px-2 `}
      style={{
        color: StatusLabelColors?.[state]?.color,
        backgroundColor: StatusLabelColors?.[state]?.backgroundColor,
      }}
    >
      {stringFormat(state.toLowerCase())}
    </span>
  );
}

export default StatusLabel;
