import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "./client";
import {
  completeOptionSplit,
  createOptionSplit,
  getOptionSplits,
} from "../api/optionSplit";

export function useGetOptionSplits() {
  return useQuery({
    queryKey: ["optionSplits"],
    queryFn: getOptionSplits,
  });
}

export function useCreateOptionSplit() {
  return useMutation({
    mutationKey: "createOptionSplit",
    mutationFn: createOptionSplit,
    onSuccess: () => {
      queryClient.invalidateQueries("optionSplits");
      queryClient.refetchQueries("optionSplits");
    },
    onError: () => {},
  });
}

export function useCompleteOptionSplit() {
  return useMutation({
    mutationKey: "completeOptionSplit",
    mutationFn: completeOptionSplit,
    onSuccess: () => {
      queryClient.invalidateQueries("optionSplits");
      queryClient.refetchQueries("optionSplits");
      toast("Split Completed", { type: "success" });
    },
    onError: () => {
      toast("Something Went Wrong", { type: "error" });
    },
  });
}
