import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { Action } from "../../components/shared/Dropdown";
import DropdownButton from "../../components/shared/DropdownButton";
import SummaryCard, { SummaryProps } from "../../components/shared/SummaryCard";
import { Box, HStack, VStack, isEsopViewer } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import { usePermissionStore } from "../../store/permissionStore";
import {
  MonetaryEventState,
  MonetaryTransactionType,
} from "../../types/OptionBuyback";
import { determineUserAccessToResource } from "../../utils/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import BuybackTable from "./OptionBuybackTable";

function TransactionSummary() {
  const navigate = useNavigate();
  const isUserEsopViewer = isEsopViewer();
  const { data: _buybackOverview, isPlaceholderData } = useGetOptionBuyback();
  const buybackOverview = _buybackOverview || [];

  function handleAction(action: Action) {
    if (action.name === "Buy Back" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-option-buyback?mode=create");
    }
    if (action.name === "Exercise" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-exercise-event?mode=create");
    }
    if (
      action.name === "Exercise and Liquidate" &&
      !action.disabled &&
      !isUserEsopViewer
    ) {
      navigate("/options/create-exercise-liquidation-flow?mode=create");
    }
    if (action.name === "Liquidate" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-liquidation-flow?mode=create");
    }
  }
  const { isMobile } = useIsMobile();
  const { permission } = usePermissionStore();

  const isCreateDisable = buybackOverview.some(
    (option) =>
      option.eventState !== MonetaryEventState.COMPLETED &&
      option.eventState !== MonetaryEventState.PAUSED
  );
  const summary: SummaryProps[] = [
    {
      name: "Buy Back",
      transactionEvent: buybackOverview.filter(
        (overview) =>
          overview.transactionType === MonetaryTransactionType.BUYBACK &&
          overview.eventState === MonetaryEventState.COMPLETED
      ),
    },
    {
      name: "Exercise and Liquidation",
      transactionEvent: buybackOverview.filter(
        (overview) =>
          overview.transactionType ===
            MonetaryTransactionType.EXERCISE_LIQUIDITY &&
          overview.eventState === MonetaryEventState.COMPLETED
      ),
    },
    {
      name: "Exercise",
      transactionEvent: buybackOverview.filter(
        (overview) =>
          overview.transactionType === MonetaryTransactionType.EXERCISE &&
          overview.eventState === MonetaryEventState.COMPLETED
      ),
    },
  ];
  return (
    <VStack className="p-2 bg-white rounded">
      {/* header */}
      <HStack className="justify-between mx-2">
        <VStack>
          <p className="text-sm font-medium text-gray-dark">
            Transaction Summary
          </p>
        </VStack>
        {!isUserEsopViewer && (
          <HStack className="items-center justify-between rounded ">
            <AddTransaction
              actions={[
                {
                  name: "Buy Back",
                  disabled:
                    isCreateDisable &&
                    determineUserAccessToResource(
                      permission?.aclList || [],
                      Resource.All,
                      DefaultAction.All
                    ),
                },
                {
                  name: "Exercise and Liquidate",
                  disabled:
                    isCreateDisable &&
                    determineUserAccessToResource(
                      permission?.aclList || [],
                      Resource.All,
                      DefaultAction.All
                    ),
                },
                {
                  name: "Exercise",
                  disabled:
                    isCreateDisable &&
                    determineUserAccessToResource(
                      permission?.aclList || [],
                      Resource.All,
                      DefaultAction.All
                    ),
                },
              ]}
              onAction={(action) => handleAction(action)}
            />
          </HStack>
        )}
      </HStack>
      {/* summary cards */}
      <HStack
        className={`${
          isPlaceholderData ? "loading" : ""
        } grid gap-4 md:grid-cols-${
          summary.length
        } sm:grid-cols-1 lg:grid-cols-${summary.length}`}
      >
        {summary.map((card, index) => (
          <SummaryCard key={index} {...card} />
        ))}
      </HStack>
      {/* summary table */}
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : ""} `}>
        <BuybackTable />
      </Box>
    </VStack>
  );
}

export default TransactionSummary;

export function AddTransaction({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  const { isMobile } = useIsMobile();
  return (
    <DropdownButton
      className="items-center"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div
          className={`${
            isMobile ? "text-xs whitespace-nowrap" : ""
          } flex items-center rounded`}
        >
          <p>Add ESOP Transaction</p>
          <Icon height={24} icon="ri:arrow-drop-down-line" className="inline" />
        </div>
      )}
    />
  );
}
