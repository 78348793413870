import React, { useMemo } from "react";
import { Icon } from "@iconify/react";
import { Box, HStack, VStack } from "../../../components/utils";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";

type Step = {
  stepNo: number;
  title: string;
  description: string;
  children?: Step[];
};
type StepProps = {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};
export default function CompensationModellingStepper(props: StepProps) {
  const { selectedCompanyData } = useOrgTemplateStore();
  const currentStep = useMemo(() => props.currentStep, [props.currentStep]);
  const getKeyResourceSteps = () => {
    const keyResourceStep = [
      {
        stepNo: 2.1,
        title: "General",
        description: "All non key employees",
      },
      {
        stepNo: 2.2,
        title: "Key Roles",
        description: "Pivotal Roles in the company",
      },
    ];
    if (selectedCompanyData?.employeeListUploaded) {
      keyResourceStep.push({
        stepNo: 2.3,
        title: "Key Personnel",
        description: "Essential Employees",
      });
    }
    return keyResourceStep;
  };

  const steps = useMemo(
    () => [
      {
        stepNo: 1,
        title: "Choose Allocation Method",
        description: "Automatic or Manual",
      },
      {
        stepNo: 2,
        title: "Allocation to Resources",
        description: "Key positions at Company Level",
        children: [...getKeyResourceSteps()],
      },
      {
        stepNo: 3,
        title: "Valuation Details",
        description: "Current Valuation of the Company",
      },
      {
        stepNo: 4,
        title: "Summary",
        description: "Compensation Model Overview",
      },
    ],
    []
  ) as Step[];
  return (
    <Box className="h-full">
      {steps.map((step) => (
        <StepContainer
          key={step.stepNo}
          step={step}
          completed={
            parseInt(`${currentStep}`, 10) > parseInt(`${step.stepNo}`, 10)
          }
          current={
            parseInt(`${currentStep}`, 10) === parseInt(`${step.stepNo}`, 10)
          }
          currentStep={currentStep}
          setCurrentStep={props.setCurrentStep}
        />
      ))}
    </Box>
  );
}

const StepContainer = (props: {
  step: Step;
  current?: boolean;
  completed?: boolean;
  nextStepsAvailable?: boolean;
  currentStep?: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  _className?: string;
}) => {
  const {
    step,
    current,
    completed,
    nextStepsAvailable,
    currentStep,
    setCurrentStep,
    _className,
  } = props;
  function handleStepClick(
    step: Step,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    event.stopPropagation();
    if (step.stepNo === 2) setCurrentStep(2.1);
    else setCurrentStep(step.stepNo);
  }
  return (
    <HStack
      className={`w-full pt-7 cursor-pointer ${
        !_className ? "pl-6" : _className
      }`}
    >
      <VStack
        className="justify-between w-1/5"
        onClick={(_) => handleStepClick(step, _)}
      >
        <div
          className={`rounded-md shadow-md ${
            current
              ? " text-white bg-orange-501  "
              : "bg-opacity-50 text-orange-501"
          }  w-10 h-10 inline-flex justify-center items-center font-medium`}
        >
          {completed ? (
            <Icon icon="akar-icons:check" className="text-orange-501" />
          ) : (
            step.stepNo
          )}
        </div>
      </VStack>
      <VStack
        className="w-4/5 ml-4 justify-evenly"
        onClick={(_) => handleStepClick(step, _)}
      >
        <div
          className={`text-sm2 font-medium text-black-501 ${
            current && "text-orange-501"
          } `}
        >
          {step.title}
        </div>
        <div
          className={`text-xs0 text-gray-401 font-medium`}
          onClick={(_) => handleStepClick(step, _)}
        >
          {step.description}
        </div>
        {step.children && (
          <div className="">
            {step.children.map((child) => (
              <StepContainer
                nextStepsAvailable={nextStepsAvailable}
                key={child.stepNo}
                step={child}
                current={currentStep === child.stepNo}
                completed={(currentStep || 0) > child.stepNo}
                setCurrentStep={setCurrentStep}
                _className="pl-0"
              />
            ))}
          </div>
        )}
      </VStack>
    </HStack>
  );
};
