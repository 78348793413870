import { useMutation, useQuery } from "react-query";
import {
  getAllPermissions,
  getAssociatedUsers,
  getCompanyRoles,
  saveAllUsers,
} from "../api/usersRoles";
import { queryClient } from "./client";

export function useGetAssociatedUsers() {
  return useQuery({
    queryKey: "associatedUsers",
    queryFn: getAssociatedUsers,
  });
}
export function useGetRoles() {
  return useQuery({
    queryKey: "allRoles",
    queryFn: getCompanyRoles,
  });
}
export function useGetAllPermsissions() {
  return useQuery({
    queryKey: "defaultPermissions",
    queryFn: getAllPermissions,
  });
}
export function useSaveUsers() {
  return useMutation({
    mutationKey: "saveUsers",
    mutationFn: saveAllUsers,
    onSuccess: () => {
      queryClient.invalidateQueries("associatedUsers");
      queryClient.invalidateQueries("allRoles");
      queryClient.invalidateQueries("defaultPermissions");
      queryClient.refetchQueries("associatedUsers");
      queryClient.refetchQueries("allRoles");
      queryClient.refetchQueries("defaultPermissions");
    },
  });
}
