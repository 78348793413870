import randomColor from "randomcolor";
import { RandomDepartmentColorGenerator } from "../companyDetails/utils/RandomDepartmentColorGenerator";

function DepartmentLabel({
  name,
  index,
  _className,
}: {
  name: string;
  index: number;
  _className?: string;
}) {
  const seed = name;
  const backgroundColor = RandomDepartmentColorGenerator(index, name);
  return (
    <span
      className={`${
        _className ? `${_className}` : "h-4"
      } items-center capitalize min-w-10  rounded-sm font-medium text-center text-[9px]  leading-4 px-2 border border-solid border-[${backgroundColor}] `}
      style={{
        color: backgroundColor,
        border: `1px solid ${backgroundColor}`,
      }}
    >
      {name}
    </span>
  );
}

export default DepartmentLabel;
