import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import OrganiSationStructureDataProcessor, {
  OrgDataProcessor,
} from "./OrgStructureJSONDataProc";
import jsonData from "../../../../dummyData.json";

/* eslint-disable no-bitwise */
export function RandomDepartmentColorGenerator(
  index: number,
  departmentName: string,
  fromTemplate = false
) {
  const data = useOrgTemplateStore.getState()?.selectedCompanyData;
  const colors = [
    "#83B5FF",
    "#9A4F3C",
    "#FC9FBC",
    "#FFB876",
    "#9B71F3",
    "#E45B7F",
    "#82D8D5",
    "#797B94",
    "#73C3A4",
    "#E7827B",
    "#86567A",
    "#FFD78E",
  ];
  if (data !== null && data && data.departments && departmentName)
    Object.keys(data.departments).forEach((dep, i) => {
      if (dep === departmentName) index = i % colors.length;
    });
  if (fromTemplate) {
    const processedData = new OrganiSationStructureDataProcessor(
      jsonData as unknown as OrgDataProcessor
    );
    const allDepartments = Array.from(
      processedData.getDepartmentsForTemplate()
    );
    allDepartments.forEach((dep, j) => {
      if (dep === departmentName) index = j % colors.length;
    });
  }
  const backgroundColor = colors[index];

  return backgroundColor;
}
