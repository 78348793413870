import { AgGridReact } from "ag-grid-react";
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  CellClickedEvent,
  ColDef,
  ExcelExportParams,
  GridApi,
  GridOptions,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Grant } from "../../types/Grant";

import {
  Box,
  HStack,
  IconCTAButton,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useDeleteGrant } from "../../queries/esopPlan";
import { useAuthorizationStore } from "../../store";
import { TriggerType } from "../../types/VestingTemplate";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  capitalizeFirstLetter,
  getFormattedValue,
  limitString,
  stringFormat,
  stringToNumberConvertor,
} from "../../utils/string";
import Avatar from "./Avatar";
import { isAccelerationAllowed, isExerciseAllowed } from "./GrantsTable";
import GrantStatusLabel from "./StatusLabel";

import SearchInput from "../../components/shared/SearchInput";
import { usePermissionStore } from "../../store/permissionStore";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import MyCustomFilters from "../../components/shared/MyCustomFilters";
import { AvailableTableFilters, SaveTableState } from "../../types/Table";
import { saveTableFilterState } from "../../api/gridUtils";
import { useGetFilterStates } from "../../queries/gridUtils";
import Tooltip from "../../components/shared/Tooltip";

type GrantInfoCellProps = {
  grant: Grant;
  currency: string; // assuming you have a currency prop
};
function AgGridGrantsTable({
  grantTableData,
  handleAction,
  onSelectedRows,
  gridRef,
}: {
  grantTableData: Grant[];
  handleAction: (grant: Grant, action: Action) => void;
  onSelectedRows: (item: Grant[]) => void;
  gridRef: any;
}) {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const userPermission = useAuthorizationStore();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { mutate: deleteGrant } = useDeleteGrant();
  const { permission } = usePermissionStore();
  const { data, refetch } = useGetFilterStates("PLANSANDGRANTS");
  // route params logic
  const isPlaceholderData = false;
  const navigate = useNavigate();
  const tableData: Grant[] = useMemo(() => grantTableData, [grantTableData]);
  const gridOptions: GridOptions = {};
  const gridApi = useRef<GridApi | any>(null);
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const exportParams: ExcelExportParams = {
    processCellCallback: (params) => {
      // Customize cell values during export
      if (typeof params.value === "number") {
        return params.value.toFixed(2); // Format numbers to 2 decimal places
      }
      return params.value;
    },
  };

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = grantTableData.find(
        (row) =>
          row.employeeId ===
          cellParams?.data?.grantInfo?.props?.grant?.employeeId
      );
      if (rowData) {
        navigate(`/options/employeeDetails/${rowData.employeeId}`);
      }
    }
  };

  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
    const grantsSelected = selectedRows.map(
      (row: any) => row.grantInfo.props.grant
    );

    onSelectedRows(grantsSelected);
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "HOLDER'S NAME",
      checkboxSelection: true,
      width: 200,
      autoHeight: true,
      wrapText: false,
      headerCheckboxSelection: true,
      cellStyle: { "padding-top": "15px" },
      field: "holderName",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) =>
        params.getValue("holderName").props.optionHolderName,
      getQuickFilterText: (params) =>
        Object.values(params?.data?.holderName?.props).toString(),
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.props.optionHolderName?.localeCompare(
          valueB.props.optionHolderName
        );
      },
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: HolderInfoRender,
    },
    {
      headerName: "GRANT INFO",
      field: "grantInfo",
      sortable: true,
      autoHeight: true,
      wrapText: true,
      minWidth: 200,
      width: 200,
      filter: "agMultiColumnFilter",
      suppressAutoSize: false,
      getQuickFilterText: (params) => {
        const { optionHolderState, grantDate, grantPrice, grantIdentifier } =
          params?.data?.grantInfo?.props?.grant || {};

        const valuesToPass = [
          optionHolderState,
          grantDate,
          grantPrice,
          grantIdentifier,
        ].filter((value) => value !== undefined && value !== null);

        return valuesToPass.toString();
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.props.grant.grantIdentifier?.localeCompare(
          valueB.props.grant.grantIdentifier
        );
      },
      suppressSizeToFit: true,
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Grant Date",
            display: "subMenu",
            filterParams: {
              maxNumConditions: 5,
              inRangeInclusive: true,
              buttons: ["reset"],
              comparator: (
                filterLocalDateAtMidnight: Date,
                cellValue: { props: GrantInfoCellProps }
              ) => {
                const filterDate = filterLocalDateAtMidnight;
                filterDate.setHours(0, 0, 0, 0);
                const cellDate = new Date(cellValue.props?.grant?.grantDate);
                cellDate.setHours(0, 0, 0, 0);
                const filterDateAsEpoch = filterDate.valueOf();
                const cellEpoch = cellDate.valueOf();
                return cellEpoch - filterDateAsEpoch;
              },
            },
            menuTabs: ["filterMenuTab"],
            filter: "agDateColumnFilter",
          },
          {
            title: "Grant Status",
            filter: "agSetColumnFilter",
            display: "subMenu",

            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) => {
                const status =
                  params?.data?.grantInfo?.props?.grant?.optionHolderState;
                return capitalizeFirstLetter(
                  stringFormat(status.toLowerCase())
                ); // Custom keyCreator logic // Custom keyCreator logic
              },
              valueFormatter: (params: any) => {
                const status = params?.value?.props?.grant?.optionHolderState;
                return capitalizeFirstLetter(
                  stringFormat(status.toLowerCase())
                );
              },
            },
          },
        ],
      },
      cellStyle: { "padding-top": "15px" },
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: GrantInfoCell,
    },

    {
      headerName: "OPTIONS GRANTED",
      field: "optionsGranted",
      autoHeight: true,
      wrapText: true,
      cellStyle: { "padding-top": "15px" },

      getQuickFilterText: (params) => {
        const { optionsGranted, planName } =
          params?.data?.grantInfo?.props?.grant || {};

        const valuesToPass = [optionsGranted, planName].filter(
          (value) => value !== undefined && value !== null
        );

        return valuesToPass.toString();
      },
      filter: "agMultiColumnFilter",
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(
          valueA.props.optionsGranted,
          valueB.props.optionsGranted,
          nodeA,
          nodeB,
          isDescending
        );
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Plan Name",
            filter: "agSetColumnFilter",
            display: "subMenu",
            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) =>
                params?.data?.grantInfo?.props?.grant?.planName,
              valueFormatter: (params: any) => params?.value?.props?.planName,
            },
          },
          {
            title: "Options Granted",
            display: "subMenu",
            buttons: ["reset"],
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params.data.grantInfo.props.grant.optionsGranted,
            },
            filter: "agNumberColumnFilter",
          },
        ],
      },
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: OptionInfoCell,
    },

    {
      headerName: "VESTED",
      field: "vested",
      autoHeight: true,
      wrapText: true,
      sortable: true,
      filter: "agMultiColumnFilter",
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(
          valueA.props.optionsVested,
          valueB.props.optionsVested,
          nodeA,
          nodeB,
          isDescending
        );
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Options Vested",
            display: "subMenu",
            filterValueGetter: (params: any) =>
              stringToNumberConvertor(
                params?.data.grantInfo.props.grant.optionsVested
              ),
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.data.grantInfo.props.grant.optionsVested,
            },
            filter: "agNumberColumnFilter",
          },
          {
            title: "Vesting Template",
            filter: "agSetColumnFilter",
            display: "subMenu",
            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) => {
                const status =
                  params?.data?.grantInfo?.props?.grant?.vestingTemplateName;
                return status; // Custom keyCreator logic
              },
              valueFormatter: (params: any) => {
                const status = params?.value?.props?.vestingTemplateName;
                return status;
              },
            },
          },
        ],
      },
      menuTabs: ["filterMenuTab"],
      cellRenderer: GrantDetailsCell,
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
    },
    {
      headerName: "UNVESTED",
      field: "unvested",
      autoHeight: true,
      wrapText: true,
      valueParser: numberValueParser,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("unvested")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "NET VESTED",
      field: "netVested",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("netVested")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "STOCK EXERCISED",
      field: "stockExercised",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("stockExercised")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "CASHED OUT",
      field: "cashedOut",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("cashedOut")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "TOTAL EXERCISED",
      field: "totalExercised",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("totalExercised")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "LAPSED",
      field: "lapsed",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("lapsed")),
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "FORFEITED",
      field: "forfeited",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("forfeited")),
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      // cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: CTADropdown,
    },
  ];

  const rowData = useMemo(
    () =>
      tableData?.map((grant) => ({
        avatar: (
          <Avatar
            name={grant.optionHolderName}
            status={grant.employmentStatus}
          />
        ),
        holderName: (
          <HolderInfoRender
            employeeIdentifier={grant.employeeIdentifier}
            optionHolderName={grant.optionHolderName}
            statusId={grant.employmentStatus}
          />
        ),
        grantInfo: <GrantInfoCell grant={grant} currency={currency} />,
        optionsGranted: (
          <OptionInfoCell
            optionsGranted={grant.optionsGranted}
            planName={grant.planName}
            currency={currency}
            isFractional={grant?.isFractional}
          />
        ),
        vested: (
          <GrantDetailsCell
            optionsVested={grant.optionsVested}
            vestingTemplateName={grant.vestingTemplateName}
            cliffPeriod={grant.cliffPeriod}
            vestingInterval={grant.vestingInterval}
            vestingPeriod={grant.vestingPeriod}
            vestingTriggerType={grant.vestingTriggerType}
            currency={currency}
            isFractional={true}
          />
        ),
        unvested: getFormattedValue(
          grant?.unVested || 0,
          currency,
          grant.isFractional
        ),
        netVested: getFormattedValue(
          grant?.netVested || 0,
          currency,
          grant?.isFractional
        ),
        stockExercised: getFormattedValue(
          grant?.optionsExercisedWithStock || 0,
          currency,
          grant.isFractional
        ),
        cashedOut: getFormattedValue(
          grant?.optionsExercisedWithCash || 0,
          currency,
          grant.isFractional
        ),
        totalExercised: getFormattedValue(
          grant?.optionsExercised || 0,
          currency,
          grant.isFractional
        ),
        lapsed: getFormattedValue(
          grant.optionsLapsed || 0,
          currency,
          grant.isFractional
        ),
        forfeited: getFormattedValue(
          grant.optionsForfeited || 0,
          currency,
          grant.isFractional
        ),
        actions: (
          <CTADropdown
            dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
            actions={[
              {
                name: "Edit Grant",
                disabled:
                  grant.optionHolderState === "GRANTED" ||
                  grant.optionHolderState === "OFFERED" ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.Grant,
                    DefaultAction.Edit
                  ) ||
                  grant.isWithdrawLapsed,
              },
              {
                name: "Update grant status",
                disabled:
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.Grant,
                    DefaultAction.STATE_CHANGE
                  ) ||
                  !grant.isStateChangeAllowed ||
                  grant.isWithdrawLapsed,
              },
              {
                name: "Clone Grant",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.Grant,
                  DefaultAction.Create
                ),
              },
              {
                name: "Accelerate Vesting",
                disabled:
                  !isAccelerationAllowed(grant) ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.All,
                    DefaultAction.All
                  ),
              },
              {
                name: "Exercise Options",
                disabled:
                  !isExerciseAllowed(grant) ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.All,
                    DefaultAction.All
                  ),
              },
              {
                name: "View Grant Letter",
                disabled:
                  !grant.grantLetterLink ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.Grant,
                    DefaultAction.Read
                  ),
              },
              {
                name: "Delete",
                disabled:
                  grant.optionHolderState === "GRANTED" ||
                  grant.optionHolderState === "OFFERED" ||
                  grant.optionHolderState === "RESIGNED" ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.Grant,
                    DefaultAction.Delete
                  ),
              },
            ]}
            onAction={(action) => handleAction(grant, action)}
          />
        ),
      })),
    [tableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    gridRef.current = params.api;
    params.columnApi.getAllColumns().map((column: any) => column.colId);
    params.columnApi.autoSizeColumns();
    // If you want to resize all columns
    params.columnApi.autoSizeAllColumns();
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const saveNewFilter = (filterName: string) => {
    const filterState = gridApi.current?.getFilterModel();
    const filterStateToSave: SaveTableState = {
      filterName,
      filterState,
      tableName: "PLANSANDGRANTS",
    };
    saveTableFilterState(filterStateToSave).then((_) => {
      toast(`${filterName} saved successfully`, {
        autoClose: 2000,
        type: "success",
      });
      refetch();
    });
  };
  const onFilterSelected = (filterItem: AvailableTableFilters | null) => {
    gridApi?.current?.setFilterModel(filterItem?.filterState);
  };
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.openToolPanel(key);
        else gridApi?.current?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.openToolPanel(key);
        else gridApi?.current?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const isFractional = data.childrenAfterAggFilter.some(
      (d: any) => d.data.grantInfo.props.grant.isFractional
    );

    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data.grantInfo.props.grant
    );

    const optionsGranted = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.optionsGranted),
      0
    );

    const vested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + Number(data.optionsVested),
      0
    );

    const unvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + Number(data.unVested),
      0
    );
    const netVested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + Number(data.netVested),
      0
    );
    const stockExercised = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.optionsExercisedWithStock),
      0
    );
    const cashedOut = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.optionsExercisedWithCash),
      0
    );
    const totalExercised = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.optionsExercised),
      0
    );
    const lapsed = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + Number(data.optionsLapsed),
      0
    );
    const forfeited = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.optionsForfeited),
      0
    );

    api.setPinnedBottomRowData([
      {
        holderName: (
          <HolderInfoRender
            optionHolderName="Total"
            employeeIdentifier={""}
            statusId={""}
          />
        ),
        optionsGranted: (
          <OptionInfoCell
            optionsGranted={optionsGranted}
            planName={""}
            currency={currency}
            isFractional={isFractional}
          />
        ),
        vested: (
          <GrantDetailsCell
            optionsVested={vested}
            vestingTemplateName=""
            cliffPeriod={0}
            vestingInterval={0}
            vestingPeriod={0}
            vestingTriggerType=""
            currency={currency}
            isFractional={isFractional}
          />
        ),
        unvested: getFormattedValue(unvested, currency, isFractional),
        netVested: getFormattedValue(netVested, currency, isFractional),
        stockExercised: getFormattedValue(
          stockExercised,
          currency,
          isFractional
        ),
        cashedOut: getFormattedValue(cashedOut, currency, isFractional),
        totalExercised: getFormattedValue(
          totalExercised,
          currency,
          isFractional
        ),
        lapsed: getFormattedValue(lapsed, currency, isFractional),
        forfeited: getFormattedValue(forfeited, currency, isFractional),
        actions: "",
        isLastPinnedRow: true,
      },
    ]);
  }

  const onAgGridFilterChanged = (grid: any) => {
    setPinnedBottomRowData(grid);
  };
  return (
    <VStack className="w-full">
      <HStack className="items-center justify-end gap-4">
        <HStack>
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
        </HStack>
        {/* <QuickSearch
          className="px-2"
          placeholder="Search"
          onChange={(e) => {
            gridApi.current.setQuickFilter(e.target.value);
          }}
        /> */}
        <IconCTAButton
          value={"Columns"}
          onClick={() => openToolPanel("columns")}
          iconName={"fluent:column-triple-edit-20-regular"}
          className={`px-4 font-medium items-center flex flex-row ${
            isColumnOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isColumnOpen}
        />
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4  font-medium items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
        <MyCustomFilters
          currentFilterState={gridApi.current?.getFilterModel()}
          availableFilters={data || []}
          onFilterSelect={onFilterSelected}
          className={""}
          onNewFilterAddition={(e) => {
            saveNewFilter(e);
          }}
        ></MyCustomFilters>
        {/* <div
            className={`pr-2 ${
              isColumnOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("columns")}>
              Columns
            </button>
          </div>
          <div
            className={`pr-2 ${
              isFilterOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("filters")}>
              Filters
            </button>
          </div> */}
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${Math.max(
              400,
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            )}px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            getRowStyle={(params) => ({
              background: params.data.isLastPinnedRow ? "#f0f0f0" : "",
              fontWeight: params.data.isLastPinnedRow ? "normal" : "normal",
              color: params.data.isLastPinnedRow ? "black" : "",
            })}
            onFilterChanged={onAgGridFilterChanged}
            onRowDataUpdated={setPinnedBottomRowData}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            defaultColDef={defaultColDef}
            suppressRowTransform={true}
            rowData={rowData}
            animateRows={true}
            pagination={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            onCellClicked={handleCellClick}
            suppressMenuHide={true}
            rowClass={"border-t border-dashed cursor-pointer"}
            rowSelection="multiple"
            sideBar={sideBar}
            rowMultiSelectWithClick={true}
            onSelectionChanged={handleSelectionChanged}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            suppressRowClickSelection={true}
            columnDefs={columnDefs}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

function HolderInfoRender(props: {
  optionHolderName: string;
  employeeIdentifier: string;
  statusId: string;
}) {
  const isTotal = props.optionHolderName === "Total";

  return (
    <HStack
      className={`px-2 ${
        isTotal ? "justify-center items-center" : "align-top"
      }`}
    >
      {isTotal ? null : (
        <Avatar name={props.optionHolderName} status={props.statusId} />
      )}
      <Box className="px-2 align-top">
        <p className={`text-xs font-medium text-gray-dark`}>
          {limitString(props.optionHolderName, 40)}
        </p>
        <p className={`text-[9px] text-gray-light`}>
          {props.employeeIdentifier}
        </p>
      </Box>
    </HStack>
  );
}

const OptionInfoCell: React.FC<{
  optionsGranted: number;
  planName: string;
  currency: string;
  isFractional: boolean;
}> = ({ optionsGranted, planName, currency, isFractional }) => (
  <HStack className="align-center">
    <Box className="">
      <p className={`text-xs font-normal text-gray-dark`}>
        {getFormattedValue(optionsGranted, currency, isFractional)}
      </p>
      <p className="leading-5 text-xxs text-gray-light">{planName}</p>
    </Box>
  </HStack>
);

const GrantInfoCell: React.FC<GrantInfoCellProps> = ({ grant, currency }) => {
  // Check if grant is defined before accessing its properties
  if (!grant || typeof grant.grantDate === "undefined") {
    return null; // or some default content, or an error message
  }
  const formattedDate = new Date(grant.grantDate).toUTCString().slice(5, 17);

  return (
    <VStack className="px-2 align-top text-xs font-medium text-gray-dark ">
      <HStack className="">
        <VStack className="text-ellipsis">{grant.grantIdentifier}</VStack>
        <VStack className="break-keep ps-4">
          <GrantStatusLabel
            _className="max-w-20"
            state={grant.optionHolderState}
          />
        </VStack>
      </HStack>
      <HStack></HStack>
      <HStack className="leading-5 text-xxs text-gray-light">
        {formattedDate} | Price: {formatCurrency(grant.grantPrice, currency)}
      </HStack>
    </VStack>
  );
};

const GrantDetailsCell: React.FC<{
  optionsVested: number;
  vestingTemplateName: string;
  cliffPeriod: number;
  vestingInterval: number | undefined;
  vestingPeriod: number | undefined;
  vestingTriggerType: string;
  currency: string;
  isFractional: boolean;
}> = ({
  optionsVested,
  vestingTemplateName,
  cliffPeriod,
  vestingInterval,
  vestingPeriod,
  vestingTriggerType,
  currency,
  isFractional,
}) => (
  <HStack className="px-2 py-4 align-top">
    <Box>
      <p className="text-xs font-normal text-gray-dark">
        {getFormattedValue(optionsVested, currency, isFractional)}
      </p>
      {vestingTemplateName && (
        <p className="leading-5 text-xxs text-gray-light">
          {vestingTemplateName} Cliff: {cliffPeriod}
          {vestingInterval && vestingTriggerType === TriggerType.TIME
            ? `, ${vestingInterval}/${vestingPeriod})`
            : vestingTriggerType === TriggerType.EVENT
            ? `, Event Based Vesting)`
            : `, Custom Vesting Schedule)`}
        </p>
      )}
    </Box>
  </HStack>
);

export default forwardRef(AgGridGrantsTable);
