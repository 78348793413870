import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, GridApi } from "ag-grid-community";
import React, { useMemo, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { Icon } from "@iconify/react";
import SearchInput from "../../../components/shared/SearchInput";
import { Box, HStack, VStack } from "../../../components/utils";
import { CompensationSummary } from "../../../types/modelling";
import { getSalaryRangeForUI } from "../modellingUtils";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import {
  SalaryRange,
  YearlyData,
} from "../companyDetails/utils/OrgStructureJSONDataProc";
import { formatDisplayDate } from "../../../utils/date";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { RandomDepartmentColorGenerator } from "../companyDetails/utils/RandomDepartmentColorGenerator";

export default function CompModellingSummary() {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  let index = 0;
  const { compensationModellingData } = useOrgTemplateStore();
  const [summaryData, setSummaryData] = useState<Array<CompensationSummary>>(
    []
  );
  const [totalOptions, setTotalOptions] = useState<SalaryRange>();
  const [totalSalary, setTotalSalary] = useState<SalaryRange>();
  const getSalary = (
    percentage: number,
    yoyGrowth: number,
    pps: number,
    salary: SalaryRange,
    currentCount: number,
    yearData?: YearlyData
  ) => {
    let totalPps = pps;
    if (yearData) {
      let totalMinSalary = (currentCount * pps * salary.min * percentage) / 100;
      let totalMaxSalary = (currentCount * pps * salary.max * percentage) / 100;
      for (const year of Object.values(yearData)) {
        totalPps += (totalPps * yoyGrowth) / 100;
        totalMinSalary += (percentage * totalPps * salary.min * year) / 100;
        totalMaxSalary += (percentage * totalPps * salary.max * year) / 100;
      }
      return { min: totalMinSalary, max: totalMaxSalary };
    }
    return {
      min: (percentage * totalPps * salary.min) / 100,
      max: (percentage * totalPps * salary.max) / 100,
    };
  };
  const rowData = useMemo(
    () =>
      summaryData.map((summary) => ({
        department: summary.department,
        role: (
          <KeyRoleCellRenderer
            keyRole={summary.role.keyRole}
            level={summary.role.level}
            role={summary.role.role}
          />
        ),
        salaryRange: getSalaryRangeForUI(
          summary.salaryRange,
          currencySymbol,
          currencyType
        ),
        options: (
          <OptionsCellRenderer
            employeeCount={summary.options.employeeCount || 0}
            salary={summary.salaryRange}
            percentage={summary.options.percentage || 0}
            salaryRange={summary.options.salaryRange || { min: 0, max: 0 }}
          />
        ),
      })),
    [summaryData]
  );

  useEffect(() => {
    if (
      compensationModellingData &&
      compensationModellingData.data &&
      compensationModellingData.data.departments
    ) {
      const data: CompensationSummary[] = [];
      let totalMinSalary = 0;
      let totalMaxSalary = 0;
      Object.entries(compensationModellingData.data.departments).forEach(
        ([departmentName, depData]) => {
          Object.entries(depData.levels).forEach(([levelName, levData]) => {
            Object.entries(levData.roles).forEach(([roleName, roleData]) => {
              const returnData = {
                department: departmentName,
                level: levelName,
                role: {
                  role: roleName,
                  keyRole: roleData.keyRole,
                  level: levelName,
                },
                salaryRange: {
                  min: roleData.salaryRange.min,
                  max: roleData.salaryRange.max,
                },
                options: {
                  employeeCount: roleData.employeeCount || 0,
                  percentage: roleData.percentageOfSalary || 0,
                  salaryRange: getSalary(
                    roleData.percentageOfSalary || 0,
                    compensationModellingData.data?.yoyGrowth || 0,
                    compensationModellingData.data?.ppsOfCompany || 1,
                    roleData.salaryRange,
                    levData.currentEmployeeCount,
                    levData.yearlyData
                  ),
                },
              };
              totalMinSalary += returnData.options.salaryRange.min;
              totalMaxSalary += returnData.options.salaryRange.max;
              data.push(returnData);
            });
          });
        }
      );
      const totalMinNoOfOptions = _.sum(
        data.map(
          (s) =>
            s.options.employeeCount * s.options.percentage * s.salaryRange.min
        )
      );
      const totalMaxNoOfOptions = _.sum(
        data.map(
          (s) =>
            s.options.employeeCount * s.options.percentage * s.salaryRange.max
        )
      );
      setTotalOptions({
        min: totalMinNoOfOptions / 100,
        max: totalMaxNoOfOptions / 100,
      });
      setSummaryData(data);
      setTotalSalary({
        min: totalMinSalary,
        max: totalMaxSalary,
      });
    }
  }, [compensationModellingData]);

  function KeyRoleCellRenderer(params: {
    role: string;
    keyRole: boolean;
    level: string;
  }) {
    if (params.keyRole) {
      return (
        <HStack className="items-center gap-2 text-sm">
          <VStack className="gap-2">
            {params.role}
            <span className="text-xs text-gray-400">{params.level}</span>
          </VStack>
          <img
            src="../assets/KeyRole.svg"
            alt="llll"
            height={"20px"}
            width={"20px"}
          />
        </HStack>
      );
    } else {
      return (
        <VStack className="gap-2 text-sm">
          <span>{params.role}</span>
          <span className="text-xs text-gray-400">{params.level}</span>
        </VStack>
      );
    }
  }

  function OptionsCellRenderer(props: {
    employeeCount: number;
    percentage: number;
    salary: SalaryRange;
    salaryRange: SalaryRange;
  }) {
    return (
      <>
        <VStack className="gap-2 text-sm">
          <HStack>
            {getSalaryRangeForUI(
              {
                min: props.salaryRange.min || 0,
                max: props.salaryRange.max || 0,
              },
              currencySymbol,
              currencyType
            )}
          </HStack>
          <HStack className="text-xs text-gray-400">{`${(
            (props.salary.min * props.employeeCount * props.percentage) /
            100
          ).toLocaleString(currencyType)} - ${(
            (props.salary.min * props.employeeCount * props.percentage) /
            100
          ).toLocaleString(currencyType)} | ${props.percentage}%`}</HStack>
        </VStack>
      </>
    );
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  let prevDept = "";
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      autoHeight: true,
      field: "department",
      initialWidth: 150,
      minWidth: 150,
      width: 150,
      flex: 1,
      filter: "agSetColumnFilter",
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellStyle: (params: any) => {
        const existingDept = params.value;
        if (existingDept === prevDept) index -= 1;
        const color = RandomDepartmentColorGenerator(index, existingDept);
        index = index > 12 ? 0 : index + 1;
        prevDept = existingDept;
        return {
          color: "#ffffff",
          backgroundColor: color,
          "text-align": "center",
        };
      },
    },
    {
      headerName: "ROLE",
      autoHeight: true,
      field: "role",
      flex: 1,
      filter: "agSetColumnFilter",
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: KeyRoleCellRenderer,
    },
    {
      headerName: "SALARY RANGE",
      autoHeight: true,
      field: "salaryRange",
      filter: "agSetColumnFilter",
      initialWidth: 200,
      flex: 1,
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "OPTIONS",
      autoHeight: true,
      field: "options",
      flex: 1,
      filter: "agSetColumnFilter",
      initialWidth: 300,
      minWidth: 300,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: OptionsCellRenderer,
    },
  ];

  return (
    <VStack className="w-full gap-6">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Summary
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Company-wide employment and pay scale structure.
        </HStack>
      </VStack>
      <HStack className="justify-start w-full gap-6 px-6 pt-6">
        <VStack className="w-1/2 gap-6 pl-4 shadow-custom">
          <HStack className="flex-1 gap-8 py-6">
            <VStack className="justify-start space-y-4">
              <p className="">PPS:</p>
              <div className="">Date Of Valuation:</div>
            </VStack>
            <VStack className="justify-start space-y-4">
              <p className="">
                {compensationModellingData?.data?.ppsOfCompany || 1}
              </p>
              <div className="">
                {compensationModellingData?.data?.dateOfValuation
                  ? formatDisplayDate(
                      compensationModellingData?.data?.dateOfValuation
                    )
                  : ""}
              </div>
            </VStack>
          </HStack>
        </VStack>
        <VStack className="w-1/2 gap-6 pl-4 shadow-custom">
          <HStack className="flex-1 gap-8 py-6">
            <VStack className="justify-start space-y-4">
              <p>No. of Options:</p>
              <p>Allocation Budget:</p>
            </VStack>
            <VStack className="justify-start space-y-4">
              <p>{`${(totalOptions?.min || 0).toLocaleString(
                currencyType
              )} - ${(totalOptions?.max || 0).toLocaleString(
                currencyType
              )}`}</p>
              <p>
                {getSalaryRangeForUI(
                  totalSalary || { min: 0, max: 0 },
                  currencySymbol,
                  currencyType
                )}
              </p>
            </VStack>
          </HStack>
        </VStack>
      </HStack>

      <HStack className="justify-end gap-8 pt-10 pr-4">
        <div className="flex gap-2 text-xs text-center justify-evenly">
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
        </div>
      </HStack>

      <HStack className="justify-between w-full pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            rowHeight={55}
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            suppressRowTransform={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}
