import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { formatDistance } from "date-fns";
import * as _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  downloadGrantLetters,
  generateTransactionReport,
  getGrantTemplate,
} from "../../api/Esop";
import SortingComponent from "../../components/SortingVariation";
import AlertDialog from "../../components/shared/AlertDialog";
import { ColumnOptions } from "../../components/shared/ColumnChooser";
import { ContextModal } from "../../components/shared/ContextModal";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { EmptyTable } from "../../components/shared/EmptyTable";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import TableDateFilters from "../../components/shared/TableDateFilters";
import TableRangeFilters from "../../components/shared/TableRangeFilters";
import {
  Box,
  ButtonPrimary,
  ButtonSize,
  Center,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";
import { useEmployees } from "../../queries";
import {
  useAddGrantExcel,
  useDeleteGrant,
  useExportToExcel,
  useGenerateGrantLettersBulk,
  useGrants,
} from "../../queries/esopPlan";
import GenericTableHeader from "../../shared/GenericTableHeader";
import { useAuthorizationStore, useCompanyStore } from "../../store";
import { useError } from "../../store/errorStore";
import { useGrantFilterStore } from "../../store/grantsTableFilter";
import { usePermissionStore } from "../../store/permissionStore";
import {
  useAccelerateVestingDialog,
  useExerciseRequestDialog,
  useGrantCreatedDialog,
} from "../../store/useDialogStore";
import { EmployementStatus } from "../../types/Employee";
import {
  grantHeadersObj,
  grantHeaderslist,
  grantHeadingVal,
} from "../../types/ExportExcelGrant";
import { Grant } from "../../types/Grant";
import { TriggerType } from "../../types/VestingTemplate";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { globalFilter, sort } from "../../utils/arrays";
import { determineUserAccessToResource } from "../../utils/auth";
import convertToBase64 from "../../utils/convertToBase64";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { makeRangeData } from "../../utils/makeRangeDate";
import {
  _trimAll,
  getFormattedValue,
  limitString,
  stringFormat,
} from "../../utils/string";
import ExerciseReportDialog from "../allPlans/ExerciseReportDialog";
import { AddOrEditGrant } from "./AddOrEditGrant";
import AgGridGrantsTable from "./AgGridGrantsTable";
import Avatar from "./Avatar";
import BulkOptionStateChange from "./BulkOptionStateChange";
import EsopAccountingDialog from "./EsopAccountingDialog";
import FinancialReportDialog from "./FinancialReportDialog";
import SH6ReportDialog from "./SH6ReportDialog";
import GrantStatusLabel from "./StatusLabel";
import VestRequest from "./VestRequest";

function GrantsTable() {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { setState: setGrantCreatedDialog } = useGrantCreatedDialog();
  const { setState: setAccelerateVestingDialog } = useAccelerateVestingDialog();

  const grantsTableRef = useRef<GridApi | null>(null);
  const userPermission = useAuthorizationStore();
  const { permission } = usePermissionStore();

  useEffect(() => {
    if (grantsTableRef.current) {
      const gridApi = grantsTableRef.current.getModel();
    }
  }, [grantsTableRef]);

  // route params logic
  const { id } = useParams();
  const navigate = useNavigate();
  const _id = id ?? "";
  const { isPlaceholderData, isFetching, data: grants } = useGrants();
  const _grants = grants || [];

  const grantFilter = useGrantFilterStore();
  const isUserAdmin = useMemo(
    () =>
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    [permission]
  );
  const empDetailPath = useMatch({
    path: "/options/employeeDetails/:id",
    end: true,
    caseSensitive: true,
  });

  const planViewPath = useMatch({
    path: "/options/planView/:id",
    end: true,
    caseSensitive: true,
  });
  // filter and sort logic
  const _data = useMemo(() => _grants || [], [isPlaceholderData, isFetching]);
  const rangeData = makeRangeData(_data, _id);
  let data: Grant[] = [];
  if (id !== "" && empDetailPath?.pathname.includes("employeeDetail")) {
    data = useMemo(
      () => _data.filter((grant) => grant.employeeId.toString() === _id),
      [isPlaceholderData, isFetching, id]
    );
  } else if (id !== "" && planViewPath?.pathname.includes("planView")) {
    data = useMemo(
      () => _data?.filter((grant) => grant?.planid?.toString() === _id),
      [isPlaceholderData, isFetching, id]
    );
  } else {
    data = _data;
  }
  const { mutate: deleteGrant } = useDeleteGrant();
  const { mutate: generateGrantLetters } = useGenerateGrantLettersBulk();
  const { data: employees } = useEmployees();
  const _employees = employees || [];
  const employeeData = _employees?.filter((item) => _id === item.id.toString());
  // pop up dialogs
  const [dialog, setDialog] = useState<{
    message?: string;
    open: boolean;
    grant?: Grant;
    mode?: "Edit" | "Add" | "Clone" | "Delete";
  }>({
    open: false,
    grant: undefined,
    mode: "Add",
    message: undefined,
  });
  const [reportDialog, setReportDialog] = useState<{
    open: boolean;
    type?: string;
  }>({
    open: false,
    type: undefined,
  });

  const [documentDialog, setDocumentDialog] = useState<{
    open: boolean;
    grant?: Grant;
  }>({ open: false, grant: undefined });
  const [bulkActionDialog, setBulkActionDialog] = useState<{
    open: boolean;
    grantItem: Grant[];
    mode?: "State Change" | "Delete" | "Generate";
    message?: string;
  }>({ open: false, grantItem: [], mode: "State Change", message: undefined });
  const [vestRequestDialog, setVestRequestDialog] = useState<{
    open: boolean;
    grant?: Grant;
  }>({ open: false, grant: undefined });
  const { state: exerciseRequestDialog, setState: setExerciseRequestDialog } =
    useExerciseRequestDialog();
  // filter and sort logic
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof Grant | "" | undefined;
    ascending: boolean;
  }>({ field: "optionHolderId", ascending: false });
  data = useMemo(() => {
    if (!data) return [];
    const filterResult = globalFilter(data, globalFilterText, [
      "optionHolderName",
      "optionHolderState",
      "planName",
      "vestingTemplateName",
      "grantIdentifier",
    ])
      .filter((gd) => {
        if (grantFilter.inColumnFilter.grantDate.length > 0) {
          for (const grantDate of grantFilter.inColumnFilter.grantDate) {
            if (
              new Date(grantDate).setHours(0, 0, 0, 0) ===
              new Date(gd.grantDate).setHours(0, 0, 0, 0)
            ) {
              return true;
            }
          }
          return false;
        }
        return gd;
      })
      .filter((og) => {
        if (grantFilter.inColumnFilter.optionsGranted.length > 0) {
          for (const range of grantFilter.inColumnFilter.optionsGranted) {
            const [min, max] = range.split(" - ").map(Number);
            if (og.optionsGranted >= min && og.optionsGranted <= max) {
              return true;
            }
          }
          return false;
        }
        return og;
      })
      .filter((ov) => {
        if (grantFilter.inColumnFilter.optionsVested.length > 0) {
          for (const range of grantFilter.inColumnFilter.optionsVested) {
            const [min, max] = range.split(" - ").map(Number);
            if (ov.optionsVested >= min && ov.optionsVested <= max) {
              return true;
            }
          }
          return false;
        }
        return ov;
      })

      .filter((og) => {
        if (grantFilter.inColumnFilter.optionsExercisedWithCash.length > 0) {
          for (const range of grantFilter.inColumnFilter
            .optionsExercisedWithCash) {
            const [min, max] = range.split(" - ").map(Number);
            if (
              og.optionsExercisedWithCash >= min &&
              og.optionsExercisedWithCash <= max
            ) {
              return true;
            }
          }
          return false;
        }
        return og;
      })
      .filter((ov) => {
        if (grantFilter.inColumnFilter.optionsExercisedWithStock.length > 0) {
          for (const range of grantFilter.inColumnFilter
            .optionsExercisedWithStock) {
            const [min, max] = range.split(" - ").map(Number);
            if (
              ov.optionsExercisedWithStock >= min &&
              ov.optionsExercisedWithStock <= max
            ) {
              return true;
            }
          }
          return false;
        }
        return ov;
      })
      .filter((ov) => {
        if (grantFilter.inColumnFilter.optionsExercised.length > 0) {
          for (const range of grantFilter.inColumnFilter.optionsExercised) {
            const [min, max] = range.split(" - ").map(Number);
            if (ov.optionsExercised >= min && ov.optionsExercised <= max) {
              return true;
            }
          }
          return false;
        }
        return ov;
      })
      .filter(
        (grant) =>
          !grantFilter.grantFilter[0].value ||
          !grantFilter.grantFilter[1].value ||
          (new Date(grant.grantDate) >=
            new Date(grantFilter.grantFilter[0].value) &&
            new Date(grant.grantDate) <=
              new Date(grantFilter.grantFilter[1].value))
      )
      .filter(
        (grant) =>
          !grantFilter.grantFilter[2].value ||
          _trimAll(grantFilter.grantFilter[2].value.toLowerCase()) ===
            _trimAll(grant.planName.toLowerCase())
      )
      .filter(
        (grant) =>
          !grantFilter.grantFilter[3].value ||
          _trimAll(grantFilter.grantFilter[3].value.toLowerCase()) ===
            _trimAll(grant.vestingTemplateName.toLowerCase())
      )
      .filter(
        (grant) =>
          !grantFilter.grantFilter[4].value ||
          _trimAll(grantFilter.grantFilter[4].value.toLowerCase()) ===
            _trimAll(stringFormat(grant.optionHolderState).toLowerCase())
      )
      .filter(
        (grant) =>
          !grantFilter.grantFilter[5].value ||
          parseInt(formatDistance(new Date(grant.grantDate), new Date()), 10) <=
            7
      )
      .filter(
        (grant) =>
          grant.optionsVested >= grantFilter.minOptionsVested &&
          grant.optionsVested <= grantFilter.maxOptionsVested
      )
      .filter(
        (grant) =>
          grant.optionsGranted >= grantFilter.minOptionsGranted &&
          grant.optionsGranted <= grantFilter.maxOptionsGranted
      );

    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [data, globalFilterText, sortField, grantFilter.applyFilter, grantFilter]);
  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  // selection logic
  const [selectedItems, setSelectedItems] = useState<Grant[]>([]);
  const onSelectionChangetemp2 = (item: Grant | Grant[]) => {
    const grantItem: Grant[] = [];
    if (_.isArray(item)) {
      const selectedGrantList = item.map((grant) => grant.grantIdentifier);
      const selectedGrant = data.filter((grant) =>
        selectedGrantList.includes(grant.grantIdentifier)
      );
      grantItem.push(...selectedGrant);
    } else {
      const selectedGrant = data.filter(
        (dataItem) => dataItem.grantIdentifier === item.grantIdentifier
      );
      grantItem.push(...selectedGrant);
    }
    setSelectedItems((items) => [...grantItem]);
  };
  const onSelectionChange = (item: Grant | Grant[]) => {
    const grantItem: Grant[] = [];

    if (_.isArray(item)) {
      const selectedGrantList = item.map((grant) => grant.grantIdentifier);

      const filteredRowIds: string[] = [];
      grantsTableRef.current?.forEachNodeAfterFilter((node: any) => {
        filteredRowIds.push(
          node?.data?.grantInfo?.props?.grant?.optionHolderId
        );
      });
      const filteredRows = data.filter((dataItem) =>
        filteredRowIds.includes(dataItem.optionHolderId)
      );
      const selectedGrants = filteredRows.filter((grant) =>
        selectedGrantList.includes(grant.grantIdentifier)
      );

      grantItem.push(...selectedGrants);
    } else {
      const selectedGrantIdentifier = item.grantIdentifier;

      const filteredRowIds: string[] = [];
      grantsTableRef.current?.forEachNodeAfterFilter((node: any) => {
        filteredRowIds.push(
          node?.data?.grantInfo?.props?.grant?.optionHolderId
        );
      });
      const filteredRows = data.filter((dataItem) =>
        filteredRowIds.includes(dataItem.optionHolderId)
      );

      const selectedGrant = filteredRows.find(
        (dataItem) => dataItem.grantIdentifier === selectedGrantIdentifier
      );

      if (selectedGrant) {
        grantItem.push(selectedGrant);
      }
    }

    setSelectedItems((items) => [...grantItem]);
  };
  const onSelectionChangetemp = (item: Grant) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [data, selectAllChecked]);

  const errorMessage = useError();

  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  let sumOptionsGranted = 0;
  let sumOptionsVested = 0;
  let sumOptionsUnvested = 0;
  let sumOptionsNetvested = 0;
  let sumOptionsStockExercised = 0;
  let sumOptionsCashedOut = 0;
  let sumOptionsExercised = 0;
  let sumOptionsLapsed = 0;
  let sumOptionsForfeited = 0;
  let sumTotal = 0;
  const isFractional = data?.some((grant) => grant.isFractional);

  data?.forEach((grant) => {
    sumOptionsGranted += grant.optionsGranted;
    sumOptionsVested += grant.optionsVested;
    sumOptionsUnvested += grant.optionsGranted - grant.optionsVested;
    sumOptionsNetvested +=
      grant.optionsVested -
      grant.optionsExercisedWithCash -
      grant.optionsExercisedWithStock -
      grant.optionsForfeited;
    sumOptionsStockExercised += grant.optionsExercisedWithStock;
    sumOptionsCashedOut += grant.optionsExercisedWithCash;
    sumOptionsExercised += grant.optionsExercised;
    sumOptionsLapsed += grant.optionsLapsed;
    sumOptionsForfeited += grant.optionsForfeited;
    sumTotal =
      sumOptionsVested +
      sumOptionsUnvested +
      sumOptionsNetvested +
      sumOptionsStockExercised +
      sumOptionsCashedOut +
      sumOptionsExercised +
      sumOptionsLapsed +
      sumOptionsForfeited;
  });

  const getWidth = (currentValue: number) => {
    const screen = window.screen;
    const width = screen.width;
    if (sumTotal > 0) return `${(width / sumTotal) * currentValue + 10}%`;
    else return `100%`;
  };

  function handleExportAction(action: Action) {
    if (action.disabled) return;
    if (action.name === "Export All To Excel") {
      exportClickHandler();
    } else if (action.name === "Export Selected") {
      const selectedRows = grantsTableRef.current
        ?.getSelectedRows()
        .map((row) => row.grantInfo.props.grant.optionHolderId);
      const rowsTobeExported = data.filter((dataItem) =>
        selectedRows?.includes(dataItem.optionHolderId)
      );
      exportClickHandler(rowsTobeExported);
    } else if (action.name === "Export Filtered") {
      if (grantsTableRef.current?.isAnyFilterPresent()) {
        const filteredRowIds: string[] = [];
        grantsTableRef.current?.forEachNodeAfterFilter((node: any) => {
          filteredRowIds.push(
            node?.data?.grantInfo?.props?.grant?.optionHolderId
          );
        });
        const filteredRows = data.filter((dataItem) =>
          filteredRowIds.includes(dataItem.optionHolderId)
        );
        exportClickHandler(filteredRows);
      }
    } else if (action.name === "Import From Excel") {
      navigate("/options/onboarding/grants");
    } else if (action.name === "Import Grant letters") {
      navigate("/options/onboarding/uploadGrantLetters");
    } else if (action.name === "Download Template") {
      getGrantTemplate().then((uploadTemplate) => {
        const template = uploadTemplate.data.uploadTemplate;
        convertBase64ToBlob(
          template,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ).then((blobObject) => {
          downloadBlobObject(
            blobObject,
            `${companyData?.name} Grant Upload Template.xlsx`
          );
        });
      });
    } else if (action.name === "Download Financial Report") {
      setReportDialog({ open: true, type: "Financial Report" });
    } else if (action.name === "Download Transaction Report") {
      generateTransactionReport().then((data) => {
        const reportDto = data.data;
        convertBase64ToBlob(reportDto.base64File, reportDto.fileType).then(
          (blobObject) => {
            downloadBlobObject(
              blobObject,
              `${companyData?.name} Transaction Report.xlsx`
            );
          }
        );
      });
    } else if (action.name === "Download SH-6 Report") {
      setReportDialog({ open: true, type: "SH-6 Report" });
    } else if (action.name === "Historic Onboarding") {
      navigate("/options/onboarding/historic");
    } else if (action.name === "Download ESOP-Accounting") {
      setReportDialog({ open: true, type: "Esop-Accounting" });
    } else if (action.name === "Download Exercise Report") {
      setReportDialog({ open: true, type: "Exercise Report" });
    }
  }

  const { mutate: exportExcel } = useExportToExcel();
  const { companyData } = useCompanyStore();
  const fileName = `Grant_details_${companyData?.name}`;
  const esopAccountingFileName = `ESOP_Accounting_${getCompanyName()}`;
  const exportClickHandler = (dataToBeExported = data) => {
    const reqData = dataToBeExported?.map((item: any) => {
      item.grantDate = formatDisplayDate(
        new Date(item.grantDate).toUTCString()
      );
      return item;
    });

    const reqObj = {
      heading: grantHeadingVal,
      companyName: companyData?.name,
      data: reqData,
      headers: getHeaderObj(grantHeaderslist),
    };

    exportExcel(reqObj, {
      onSuccess: async (data) => {
        const reportDto = data.data;
        const blob = await convertBase64ToBlob(
          reportDto.base64File ?? "",
          reportDto.fileType
            ? reportDto.fileType
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        downloadBlobObject(blob, fileName);
      },
      onError: () => {
        toast("Something Went Wrong", { type: "error", autoClose: 2000 });
      },
    });
  };
  const getHeaderObj = (keys: string[]) =>
    keys.map((ele: string) => ({
      key: ele,
      value:
        ele === "grantPrice"
          ? `${grantHeadersObj[ele]} (${currencySymbol})`
          : grantHeadersObj[ele],
    }));

  const { mutate: addGrantExcel } = useAddGrantExcel();

  function handleChange(e: any) {
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      convertToBase64(file).then((data) => {
        const base64 = {
          file: data as string,
        };
        addGrantExcel(base64, {
          onSuccess: (data) => {
            toast(`${data.length} Grants uploaded successfully`, {
              type: "success",
            });
          },
        });
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
    fileRef.current.value = "";
  }
  const validateUserPermissionForGrant = (
    grant: Grant,
    action: "EDIT" | "STATE_CHANGE"
  ) => true;
  function handleAction(grant: Grant, action: Action) {
    if (action.name === "Edit Grant" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        grant,
        message: "Do you want to edit this grant?",
      });
    } else if (action.name === "Clone Grant" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Clone",
        grant,
        message: "Do you want to clone this grant?",
      });
    } else if (action.name === "View Grant Letter" && !action.disabled) {
      setDocumentDialog({ open: true, grant });
    } else if (action.name === "Update grant status" && !action.disabled) {
      setGrantCreatedDialog({
        open: true,
        grantId: grant.optionHolderId,
        variant: "state change",
      });
    } else if (action.name === "Accelerate Vesting" && !action.disabled) {
      setAccelerateVestingDialog({ open: true, grant });
    } else if (action.name === "Exercise Options" && !action.disabled) {
      setExerciseRequestDialog({ open: true, grant });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        grant,
        message: "Do you want to delete this grant?",
      });
    }
  }

  const nonDeletableGrantState = ["GRANTED", "OFFERED", "RESIGNED"];
  const validBulkActions = [
    {
      name: "Delete",
      disabled:
        isBulkDeleteAllowed(selectedItems) ||
        !determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Grant,
          DefaultAction.Delete
        ),
    },
    {
      name: "State Change",
      disabled:
        !isBulkStateChangeAllowed(selectedItems) ||
        !determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Grant,
          DefaultAction.STATE_CHANGE
        ),
    },
    {
      name: "Generate Grant Letter",
      disabled: !(
        selectedItems.length > 0 &&
        determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Grant,
          DefaultAction.Edit
        )
      ),
    },
    {
      name: "Download Grant Letters",
      disabled: !(
        selectedItems.length > 0 &&
        determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Grant,
          DefaultAction.Read
        )
      ),
    },
  ];

  function isBulkDeleteAllowed(grants: Grant[]) {
    return grants?.length > 0
      ? grants?.some((grant) =>
          nonDeletableGrantState.includes(grant?.optionHolderState)
        )
      : true;
  }

  function isBulkStateChangeAllowed(grants: Grant[]) {
    const uniqueGrantsByState = _.uniqBy(
      grants,
      (grant) => grant?.optionHolderState
    );
    return (
      uniqueGrantsByState.length === 1 &&
      grants[0].optionHolderState !== "RESIGNED" &&
      grants[0].isStateChangeAllowed &&
      !grants.some((g) => g.isWithdrawLapsed)
    );
  }
  function handleBulkAction(action: Action, grantItem: Grant[]): void {
    if (action.disabled) {
      return;
    }
    if (action.name === "State Change") {
      setBulkActionDialog({ open: true, grantItem, mode: "State Change" });
    } else if (action.name === "Delete") {
      setBulkActionDialog({
        open: true,
        grantItem,
        mode: "Delete",
        message: "Do you want to delete these grants?",
      });
    } else if (action.name === "Generate Grant Letter") {
      setBulkActionDialog({
        open: true,
        grantItem,
        mode: "Generate",
        message:
          "Do you want to generate grant letters for these grants? This will replace current grant letters unless uploaded manually.",
      });
    } else if (action.name === "Download Grant Letters") {
      if (grantItem.length > 50) {
        toast("Selected data range is too large", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }
      downloadGrantLetters(grantItem.map((grant) => grant.optionHolderId)).then(
        (data) => {
          convertBase64ToBlob(data, "application/zip").then((blobObject) => {
            downloadBlobObject(blobObject, `Grant letters.zip`);
          });
        }
      );
    }
  }
  function getEmployeeDesignation({ grant }: { grant: Grant }) {
    return (employees || []).find(
      (employee) => employee.id.toString() === grant.employeeId.toString()
    )?.employeeDesignation;
  }

  function bulkDeleteOptionGrant(grants: Grant[]) {
    const ids = grants.map((grant) => grant.optionHolderId);
    deleteGrant(ids, {
      onSuccess: () => {
        toast("Grants deleted successfully!", {
          type: "success",
          autoClose: 2000,
        });
        setBulkActionDialog({
          open: false,
          grantItem: bulkActionDialog.grantItem,
        });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        setBulkActionDialog({
          open: false,
          grantItem: bulkActionDialog.grantItem,
        });
      },
    });
  }

  function bulkGenerateGrantLetters(grants: Grant[]) {
    const ids = grants.map((grant) => grant.optionHolderId);
    generateGrantLetters(ids, {
      onSuccess: () => {
        toast(
          "Generation of grant letters started ! Please check after some time",
          {
            type: "success",
            autoClose: 2000,
          }
        );
        setBulkActionDialog({
          open: false,
          grantItem: bulkActionDialog.grantItem,
        });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        setBulkActionDialog({
          open: false,
          grantItem: bulkActionDialog.grantItem,
        });
      },
    });
  }

  function deleteOptionGrant() {
    const optionHolderId = dialog.grant?.optionHolderId || "";
    deleteGrant([optionHolderId], {
      onSuccess: () => {
        toast("Grant deleted successfully!", {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        setDialog({ open: false });
      },
    });
  }
  function handleActions() {
    grantFilter.setApplyFilter(!grantFilter.applyFilter);
  }
  function reset(
    minOptionsVested: number,
    maxOptionsVested: number,
    minOptionsGranted: number,
    maxOptionsGranted: number
  ) {
    grantFilter.reset(
      minOptionsVested,
      maxOptionsVested,
      minOptionsGranted,
      maxOptionsGranted
    );
  }
  function viewEmployee(grant: Grant) {
    navigate(`/options/employeeDetails/${grant.employeeId}`);
  }

  const initialColumnState = [
    {
      name: "holders-name",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "grant-info",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "options-granted",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "vested",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "unvested",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "net-vested",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "stock-exercised",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "cashed-out",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "total-exercised",
      isVisible: true,
      isDisabled: false,
    },
    {
      name: "forfeited",
      isVisible: true,
      isDisabled: false,
    },
  ];
  const [columnOptions, setColumnOptions] =
    useState<ColumnOptions[]>(initialColumnState);
  const { isMobile } = useIsMobile();
  function handleClose(): void {
    throw new Error("Function not implemented.");
  }

  function handleSelect(): void {
    throw new Error("Function not implemented.");
  }

  return (
    <Box
      // class=" overflow-x-auto p-4 bg-white rounded-lg "
      className={`${
        isMobile ? "min-w-[1040px] overflow-x-auto" : "overflow-x-auto"
      }  p-4 bg-white rounded-lg border border-borderColor drop-shadow-box  `}
    >
      <HStack aria-label="toolbar" className="justify-between mb-4">
        <GenericTableHeader
          heading={"Grant Details"}
          subHeading={""}
          additionalInfo={
            <>
              {data.length} Grants,
              {new Set(data.map((dataItem) => dataItem.employeeId)).size}{" "}
              Employees
            </>
          }
        ></GenericTableHeader>
        {/* <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">Grant Details</p>
          <p className="text-xs font-medium text-gray-light">
            {data.length} Grants,
            {new Set(data.map((dataItem) => dataItem.employeeId)).size}{" "}
            Employees
          </p>
        </VStack> */}
        <HStack className="h-8 min-w-min">
          <Center>
            <BulkActions
              actions={validBulkActions}
              onAction={(action) => handleBulkAction(action, selectedItems)}
            />
          </Center>
          {!empDetailPath?.pathname.includes("employeeDetails") ? (
            <>
              <Center
                className={`p-2 px-3 mx-2 text-white rounded hidden ${
                  grantFilter.filterButtonFocused
                    ? "bg-orange-501"
                    : "bg-slate-100"
                } `}
              >
                <AdvanceFilter
                  primaryAction={handleActions}
                  secondaryAction={(
                    minOptionsVested,
                    maxOptionsVested,
                    minOptionsGranted,
                    maxOptionsGranted
                  ) => {
                    reset(
                      minOptionsVested,
                      maxOptionsVested,
                      minOptionsGranted,
                      maxOptionsGranted
                    );
                  }}
                  data={_grants}
                />
              </Center>
              <HStack className="hidden min-w-72 bg-slate-light text-slate-dark">
                <Icon icon="fe:search" width="24" className="mr-2 " />
                <input
                  type="text"
                  className="w-full text-xs font-normal border-0 outline-none bg-inherit"
                  placeholder="Search by Name, Plan, Status"
                  value={globalFilterText}
                  onChange={(e) => {
                    setGlobalFilterText(e.target.value);
                  }}
                ></input>
              </HStack>
              <HStack className="items-center justify-between mx-2 rounded bg-slate-light">
                <input
                  ref={fileRef}
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  accept=".xlsx, .xls, .ods"
                  hidden
                />
                <ExportImport
                  actions={[
                    {
                      name: "Export All To Excel",
                    },
                    {
                      name: "Export Selected",
                      disabled:
                        grantsTableRef.current?.getSelectedRows()?.length === 0,
                    },
                    {
                      name: "Export Filtered",
                      disabled: !grantsTableRef.current?.isAnyFilterPresent(),
                    },
                    {
                      name: "Import From Excel",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Grant,
                        DefaultAction.Create
                      ),
                    },
                    {
                      name: "Import Grant letters",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Grant,
                        DefaultAction.Create
                      ),
                    },
                    {
                      name: "Download Template",
                    },
                    {
                      name: "Download Financial Report",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.All,
                        DefaultAction.All
                      ),
                    },
                    {
                      name: "Download Transaction Report",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.All,
                        DefaultAction.All
                      ),
                    },
                    {
                      name: "Download SH-6 Report",
                      disabled: !isUserAdmin,
                    },
                    {
                      name: "Historic Onboarding",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.All,
                        DefaultAction.All
                      ),
                    },
                    {
                      name: "Download ESOP-Accounting",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.All,
                        DefaultAction.All
                      ),
                    },
                    {
                      name: "Download Exercise Report",
                      disabled: !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.All,
                        DefaultAction.All
                      ),
                    },
                  ]}
                  onAction={(action) => handleExportAction(action)}
                />
              </HStack>
              {reportDialog.type === "Financial Report" && (
                <Dialog
                  open={reportDialog.open}
                  onClose={() => setReportDialog({ open: false })}
                  maxWidth="lg"
                >
                  <div className="w-[400px] min-h-min mx-auto bg-white rounded-lg">
                    <FinancialReportDialog
                      onClose={() => setReportDialog({ open: false })}
                    />
                  </div>
                </Dialog>
              )}
              {reportDialog.type === "Exercise Report" && (
                <Dialog
                  open={reportDialog.open}
                  onClose={() => setReportDialog({ open: false })}
                  maxWidth="lg"
                >
                  <div className="w-[500px] min-h-min mx-auto bg-white rounded-lg">
                    <ExerciseReportDialog
                      onClose={() => setReportDialog({ open: false })}
                    />
                  </div>
                </Dialog>
              )}
              {reportDialog.type === "SH-6 Report" && (
                <Dialog
                  open={reportDialog.open}
                  onClose={() => setReportDialog({ open: false })}
                  maxWidth="lg"
                >
                  <div className="mx-auto bg-white rounded-lg min-w-min min-h-min">
                    <SH6ReportDialog
                      onClose={() => setReportDialog({ open: false })}
                    />
                  </div>
                </Dialog>
              )}
              {reportDialog.type === "Esop-Accounting" && (
                <Dialog
                  open={reportDialog.open}
                  onClose={() => setReportDialog({ open: false })}
                  maxWidth="lg"
                >
                  <div className="w-[500px] min-h-min mx-auto bg-white rounded-lg">
                    <EsopAccountingDialog
                      onClose={() => setReportDialog({ open: false })}
                      open={reportDialog.open}
                    />
                  </div>
                </Dialog>
              )}
            </>
          ) : (
            ""
          )}
          <ButtonPrimary
            buttonSize={ButtonSize.SMALL}
            className={
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.Grant,
                DefaultAction.Create
              )
                ? "opacity-50 cursor-not-allowed min-w-max"
                : "min-w-max"
            }
            disabled={
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.Grant,
                DefaultAction.Create
              ) ||
              (empDetailPath?.pathname.includes("employeeDetails")
                ? employeeData[0]?.employmentStatus !==
                  EmployementStatus.EMPLOYED
                : false)
            }
            onClick={() => setDialog({ open: true, mode: "Add" })}
          >
            Create a Grant
          </ButtonPrimary>
          <Dialog
            open={dialog.open}
            onClose={() => setDialog({ open: false })}
            maxWidth="md"
          >
            {dialog.mode === "Delete" ? (
              <AlertDialog
                onClose={() => setDialog({ open: false })}
                open={dialog.open}
                message={dialog.message}
                onPrimaryAction={deleteOptionGrant}
                onSecondaryAction={() => setDialog({ open: false })}
              />
            ) : (
              <AddOrEditGrant
                onClose={() => setDialog({ open: false })}
                grant={dialog.grant || undefined}
                mode={dialog.mode}
              />
            )}
          </Dialog>
          <Dialog
            open={documentDialog.open}
            onClose={() => setDocumentDialog({ open: false })}
            maxWidth="lg"
          >
            <LazyPdfDocument
              url={documentDialog?.grant?.grantLetterLink ?? ""}
              name={`${documentDialog?.grant?.employeeName}-
              ${documentDialog?.grant?.grantIdentifier}.pdf`}
            />
          </Dialog>
          <Dialog
            open={vestRequestDialog.open}
            onClose={() => setVestRequestDialog({ open: false })}
            maxWidth="md"
          >
            {vestRequestDialog.grant && (
              <div className="w-[900px]">
                <VestRequest
                  grant={vestRequestDialog.grant}
                  onClose={() => setVestRequestDialog({ open: false })}
                />
              </div>
            )}
          </Dialog>
          <Dialog
            open={bulkActionDialog.open}
            onClose={() =>
              setBulkActionDialog({
                open: false,
                grantItem: bulkActionDialog.grantItem,
              })
            }
          >
            {bulkActionDialog.mode === "State Change" ? (
              <BulkOptionStateChange
                grants={bulkActionDialog.grantItem || []}
                onClose={() =>
                  setBulkActionDialog({
                    open: false,
                    grantItem: bulkActionDialog.grantItem,
                  })
                }
              />
            ) : bulkActionDialog.mode === "Delete" ? (
              <AlertDialog
                onClose={() =>
                  setBulkActionDialog({
                    open: false,
                    grantItem: bulkActionDialog.grantItem,
                  })
                }
                open={bulkActionDialog.open}
                message={bulkActionDialog.message}
                onPrimaryAction={() =>
                  bulkDeleteOptionGrant(bulkActionDialog.grantItem)
                }
                onSecondaryAction={() =>
                  setBulkActionDialog({
                    open: false,
                    grantItem: bulkActionDialog.grantItem,
                  })
                }
              />
            ) : (
              <AlertDialog
                onClose={() =>
                  setBulkActionDialog({
                    open: false,
                    grantItem: bulkActionDialog.grantItem,
                  })
                }
                open={bulkActionDialog.open}
                message={bulkActionDialog.message}
                onPrimaryAction={() =>
                  bulkGenerateGrantLetters(bulkActionDialog.grantItem)
                }
                onSecondaryAction={() =>
                  setBulkActionDialog({
                    open: false,
                    grantItem: bulkActionDialog.grantItem,
                  })
                }
              />
            )}
          </Dialog>
        </HStack>
      </HStack>
      <HStack
        className={`hidden ${
          isPlaceholderData
            ? "loading"
            : "border-t border-dashed items-center gap-4 py-4 text-xs font-medium text-gray-dark"
        }`}
      >
        <HStack className="justify-around w-2/5">
          <VStack className="">
            <p className="text-sm">Grand Total</p>
          </VStack>
          <VStack className="">
            <p className="text-center ">
              {getFormattedValue(
                sumOptionsGranted || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="">Granted</p>
          </VStack>
        </HStack>
        <HStack className="justify-end w-3/5">
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsVested)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-800 border rounded">
              {getFormattedValue(sumOptionsVested || 0, currency, isFractional)}
            </p>
            <p className="px-1">Vested</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsUnvested)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-700 border rounded">
              {getFormattedValue(
                sumOptionsUnvested || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="px-1">Unvested</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsNetvested)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-600 border rounded">
              {getFormattedValue(
                sumOptionsNetvested || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="px-1">Net Vested</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsStockExercised)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-500 border rounded">
              {getFormattedValue(
                sumOptionsStockExercised || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="px-1">Stock Exercised</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsCashedOut)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-400 border rounded">
              {getFormattedValue(
                sumOptionsCashedOut || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="px-1">Cashed Out</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsExercised)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-300 border rounded">
              {getFormattedValue(
                sumOptionsExercised || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="px-1">Total Exercised</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsLapsed)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-200 border rounded">
              {getFormattedValue(sumOptionsLapsed || 0, currency, isFractional)}
            </p>
            <p className="px-1">Lapsed</p>
          </VStack>
          <VStack
            className="max-w-md"
            style={{ width: `${getWidth(sumOptionsForfeited)}` }}
          >
            <p className="p-2 text-center text-white bg-indigo-200 border rounded">
              {getFormattedValue(
                sumOptionsForfeited || 0,
                currency,
                isFractional
              )}
            </p>
            <p className="px-1">Forfeited</p>
          </VStack>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full ">
        <AgGridGrantsTable
          key={"GrantsTable"}
          grantTableData={data}
          handleAction={handleAction}
          onSelectedRows={onSelectionChange}
          gridRef={grantsTableRef}
        />
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          // style={{ height: `${pageSize * 64 + 128}px` }}
          className="hidden w-full max-h-full overflow-x-auto"
        >
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="border-t border-dashed">
                <td className="py-3">
                  <input
                    type="checkbox"
                    className="accent-orange-501 outline-hidden"
                    // checked={selectedItems.l(grant) !== -1}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>
                {!empDetailPath?.pathname.includes("employeeDetails") ? (
                  <>
                    <td className="py-3"></td>
                    {columnOptions.find(
                      (element) => element.name === "holders-name"
                    )?.isVisible && (
                      <td>
                        <HStack className="items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                          <div>HOLDERS NAME</div>
                          <SortingComponent
                            fieldName="optionHolderName"
                            selectedFieldName={sortField?.field ?? ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "optionHolderName",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                    )}
                  </>
                ) : (
                  ""
                )}
                {columnOptions.find((element) => element.name === "grant-info")
                  ?.isVisible && (
                  <td>
                    <HStack className="items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>GRANT INFO</div>
                      <SortingComponent
                        fieldName="grantDate"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "grantDate",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find(
                  (element) => element.name === "options-granted"
                )?.isVisible && (
                  <td>
                    <HStack className="relative items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>OPTIONS GRANTED</div>
                      <SortingComponent
                        fieldName="optionsGranted"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "optionsGranted",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <InColumnRangeFilters
                        primaryAction={handleSelect}
                        secondaryAction={handleClose}
                        name={"optionsGranted"}
                        type={""}
                        data={rangeData && rangeData}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find((element) => element.name === "vested")
                  ?.isVisible && (
                  <td>
                    <HStack className="relative items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>VESTED</div>
                      <SortingComponent
                        fieldName="optionsVested"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "optionsVested",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <InColumnRangeFilters
                        primaryAction={handleSelect}
                        secondaryAction={handleClose}
                        name={"optionsVested"}
                        type={""}
                        data={rangeData && rangeData}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find((element) => element.name === "unvested")
                  ?.isVisible && (
                  <td>
                    <HStack className="items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>UNVESTED</div>
                      <SortingComponent
                        fieldName="unVested"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "unVested",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find((element) => element.name === "net-vested")
                  ?.isVisible && (
                  <td>
                    <HStack className="items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>NET VESTED</div>
                      <SortingComponent
                        fieldName="netVested"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "netVested",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find(
                  (element) => element.name === "stock-exercised"
                )?.isVisible && (
                  <td>
                    <HStack className="relative items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>STOCK EXERCISED</div>
                      <SortingComponent
                        fieldName="optionsExercisedWithStock"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "optionsExercisedWithStock",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <InColumnRangeFilters
                        primaryAction={handleSelect}
                        secondaryAction={handleClose}
                        name={"optionsExercisedWithStock"}
                        type={""}
                        data={rangeData && rangeData}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find((element) => element.name === "cashed-out")
                  ?.isVisible && (
                  <td>
                    <HStack className="relative items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>CASHED OUT</div>
                      <SortingComponent
                        fieldName="optionsExercisedWithCash"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "optionsExercisedWithCash",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <InColumnRangeFilters
                        primaryAction={handleSelect}
                        secondaryAction={handleClose}
                        name={"optionsExercisedWithCash"}
                        type={""}
                        data={rangeData && rangeData}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find(
                  (element) => element.name === "total-exercised"
                )?.isVisible && (
                  <td>
                    <HStack className="relative items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>TOTAL EXERCISED</div>
                      <SortingComponent
                        fieldName="optionsExercised"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "optionsExercised",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <InColumnRangeFilters
                        primaryAction={handleSelect}
                        secondaryAction={handleClose}
                        name={"optionsExercised"}
                        type={""}
                        data={rangeData && rangeData}
                      />
                    </HStack>
                  </td>
                )}
                {columnOptions.find((element) => element.name === "forfeited")
                  ?.isVisible && (
                  <td>
                    <HStack className="items-center justify-start p-3 whitespace-nowrap hover:cursor-pointer">
                      <div>FORFEITED</div>
                      <SortingComponent
                        fieldName="optionsLapsed"
                        selectedFieldName={sortField?.field ?? ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "optionsLapsed",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                )}
              </tr>
            </thead>
            <tbody className={`${isPlaceholderData ? "loading" : ""} `}>
              {currentTableData &&
                currentTableData?.map((grant) => (
                  <tr
                    key={grant.optionHolderId}
                    className="border-t border-dashed cursor-pointer hover:bg-slate-50"
                    onClick={() => viewEmployee(grant)}
                  >
                    <td
                      className="py-6 align-top"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Box>
                        <input
                          type="checkbox"
                          className="items-baseline accent-orange-501 outline-hidden"
                          checked={selectedItems.indexOf(grant) !== -1}
                          onChange={(e) => onSelectionChange(grant)}
                        ></input>
                      </Box>
                    </td>
                    {!empDetailPath?.pathname.includes("employeeDetails") ? (
                      <>
                        <td className="px-2 py-4 align-top ">
                          {!isPlaceholderData ? (
                            <Avatar
                              name={grant.optionHolderName}
                              status={grant.employmentStatus}
                            />
                          ) : (
                            <div className="w-8 h-8 loading-opacity"></div>
                          )}
                        </td>
                        {columnOptions.find(
                          (element) => element.name === "holders-name"
                        )?.isVisible && (
                          <td className="py-4 align-top ">
                            <HStack className="w-32 ">
                              <Box>
                                <p
                                  className={` text-xs font-medium text-gray-dark `}
                                >
                                  {limitString(grant.optionHolderName, 40)}
                                </p>
                                <p className={` text-[9px] text-gray-light`}>
                                  {grant.employeeIdentifier}
                                </p>
                              </Box>
                            </HStack>
                          </td>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {columnOptions.find(
                      (element) => element.name === "grant-info"
                    )?.isVisible && (
                      <td className="px-2 py-4 align-top">
                        <Box className="">
                          <HStack>
                            <p
                              className={`w-10 text-xs font-medium text-gray-dark`}
                            >
                              {grant.grantIdentifier}
                            </p>
                            <GrantStatusLabel
                              state={grant.optionHolderState}
                            ></GrantStatusLabel>
                          </HStack>
                          <p className={` leading-5 text-xxs text-gray-light`}>
                            {new Date(grant.grantDate)
                              .toUTCString()
                              .slice(5, 17)}{" "}
                            | Price:
                            {formatCurrency(grant.grantPrice, currency)}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "options-granted"
                    )?.isVisible && (
                      <td className="p-4 align-top">
                        <Box className="">
                          <p className={` text-xs font-normal text-gray-dark`}>
                            {grant?.optionsGranted.toLocaleString(currency)}
                          </p>
                          <p className="leading-5 text-xxs text-gray-light">
                            {grant.planName}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find((element) => element.name === "vested")
                      ?.isVisible && (
                      <td className="p-4 align-top">
                        <Box className="w-24">
                          <p className={` text-xs font-normal text-gray-dark`}>
                            {grant?.optionsVested.toLocaleString(currency)}
                          </p>
                          <p className={` leading-5 text-xxs text-gray-light`}>
                            {grant.vestingTemplateName} (Cliff:{" "}
                            {grant.cliffPeriod}
                            {grant.vestingInterval &&
                            grant.vestingTriggerType ===
                              TriggerType.TIME.toString()
                              ? `  , ${grant.vestingInterval}/${grant.vestingPeriod})`
                              : grant.vestingTriggerType ===
                                TriggerType.EVENT.toString()
                              ? ` , Event Based Vesting)`
                              : ` , Custom Vesting Schedule)`}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "unvested"
                    )?.isVisible && (
                      <td
                        className={` p-4 text-xs font-normal align-top text-gray-dark`}
                      >
                        <Box>
                          <p className="">
                            {grant.unVested?.toLocaleString(currency)}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "net-vested"
                    )?.isVisible && (
                      <td
                        className={` p-4 text-xs font-normal align-top text-gray-dark`}
                      >
                        <Box>
                          <p className="">
                            {grant.netVested?.toLocaleString(currency)}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "stock-exercised"
                    )?.isVisible && (
                      <td
                        className={` p-4 text-xs font-normal align-top text-gray-dark`}
                      >
                        <Box>
                          <p>
                            {parseInt(
                              grant.optionsExercisedWithStock.toString(),
                              10
                            ).toLocaleString(currency)}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "cashed-out"
                    )?.isVisible && (
                      <td
                        className={` p-4 text-xs font-normal align-top text-gray-dark px-2 `}
                      >
                        <Box>
                          <p className="">
                            {parseInt(
                              grant.optionsExercisedWithCash.toString(),
                              10
                            ).toLocaleString(currency)}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "total-exercised"
                    )?.isVisible && (
                      <td
                        className={` p-4 text-xs font-normal align-top text-gray-dark`}
                      >
                        <Box>
                          <p>
                            {parseInt(
                              grant.optionsExercised.toString(),
                              10
                            ).toLocaleString(currency)}
                          </p>
                        </Box>
                      </td>
                    )}
                    {columnOptions.find(
                      (element) => element.name === "forfeited"
                    )?.isVisible && (
                      <td
                        className={` p-4 text-xs font-normal align-top text-gray-dark`}
                      >
                        <Box>
                          <p>
                            {(
                              grant.optionsLapsed + grant.optionsForfeited
                            )?.toLocaleString(currency) || 0}
                          </p>
                        </Box>
                      </td>
                    )}
                    <td
                      className="px-2 py-4 align-top z-999"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <CTADropdown
                        dropdownClassName="2xl:right-40  xl:right-10 lg:right-8 md:right-2"
                        actions={[
                          {
                            name: "Edit Grant",
                            disabled:
                              !validateUserPermissionForGrant(grant, "EDIT") ||
                              determineUserAccessToResource(
                                permission?.aclList || [],
                                Resource.Grant,
                                DefaultAction.Edit
                              ),
                          },
                          {
                            name: "Update grant status",
                            disabled:
                              !validateUserPermissionForGrant(
                                grant,
                                "STATE_CHANGE"
                              ) ||
                              determineUserAccessToResource(
                                permission?.aclList || [],
                                Resource.GrantTransaction,
                                DefaultAction.Create
                              ),
                          },
                          {
                            name: "Clone Grant",
                            disabled: !determineUserAccessToResource(
                              permission?.aclList || [],
                              Resource.Grant,
                              DefaultAction.Create
                            ),
                          },
                          {
                            name: "Vest Options",
                            disabled: !isVestAllowed(grant) || !isUserAdmin,
                          },
                          {
                            name: "Exercise Options",
                            disabled: !isExerciseAllowed(grant) || !isUserAdmin,
                          },
                          {
                            name: "View Grant Letter",
                            disabled: !grant.grantLetterLink,
                          },
                          {
                            name: "Delete",

                            disabled:
                              grant.optionHolderState === "GRANTED" ||
                              grant.optionHolderState === "OFFERED" ||
                              grant.optionHolderState === "RESIGNED" ||
                              determineUserAccessToResource(
                                permission?.aclList || [],
                                Resource.Grant,
                                DefaultAction.Delete
                              ),
                          },
                        ]}
                        onAction={(action) => handleAction(grant, action)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {currentTableData.length === 0 && (
            <EmptyTable
              header="No Grants"
              subHeader="Click on Create a Grant button to add a grant."
            />
          )}

          <Box className="flex justify-between mt-8">
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setPageSize(value);
                setCurrentPage(1);
              }}
            />
            <Pagination
              className=""
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </Box>
        </Box>
      </HStack>
    </Box>
  );
}

export default GrantsTable;

export function isVestAllowed(grant: Grant) {
  return (
    grant.optionHolderState === "GRANTED" &&
    getVestableOptions(grant) > 0 &&
    grant.vestingTriggerType !== TriggerType.TIME &&
    !grant.isVestRequested
  );
}

export function isExerciseAllowed(grant: Grant) {
  return grant.isExerciseAllowed;
}

export function isAccelerationAllowed(grant: Grant) {
  return (
    grant.optionHolderState === "GRANTED" &&
    grant.availableToAccelerate > 0 &&
    !grant.isPausedVesting
  );
}

export function getVestableOptions(grant: Grant) {
  return grant.optionsGranted - grant.optionsVested - grant.optionsLapsed;
}

export function BulkActions({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  return (
    <CTADropdown
      className="items-center"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div className="flex items-center h-8 p-2 px-2 mx-2 rounded text-slate-dark bg-slate-light">
          <span>Bulk Actions</span>
          <Icon height={24} icon="ri:arrow-drop-down-line" className="inline" />
        </div>
      )}
    />
  );
}
export function ExportImport({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  return (
    <CTADropdown
      className="items-center"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div className="flex items-center h-8 p-2 px-2 mx-2 rounded text-slate-dark bg-slate-light">
          <span>Export/Import</span>
          <Icon height={24} icon="ri:arrow-drop-down-line" className="inline" />
        </div>
      )}
    />
  );
}

export function AdvanceFilter({
  primaryAction,
  secondaryAction,
  data,
}: {
  primaryAction: () => void;
  secondaryAction: (
    minOptionsVested: number,
    maxOptionsVested: number,
    minOptionsGranted: number,
    maxOptionsGranted: number
  ) => void;
  data: Grant[];
}) {
  const grantFilter = useGrantFilterStore();
  return (
    <ContextModal
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      data={data}
      className="items-end"
      render={() => (
        <Icon
          icon="el:filter"
          className={`${
            !grantFilter.filterButtonFocused ? "text-orange-501" : "text-white"
          }  rounded`}
          height={20}
        />
      )}
    />
  );
}

export function InColumnRangeFilters({
  primaryAction,
  secondaryAction,
  name,
  type,
  data,
}: {
  primaryAction: () => void;
  secondaryAction: () => void;
  name: any;
  type: string;
  data: Grant[];
}) {
  const grantFilter = useGrantFilterStore();
  const lowest = 0;
  const _highest = data.map((item: any) => parseInt(item[name], 10));
  const highest: number = _.max(_highest) || 0;
  return (
    <TableRangeFilters
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      lowest={lowest}
      highest={highest}
      className="items-end"
      name={name}
      render={() => (
        <Icon
          icon="material-symbols:filter-list-rounded"
          className={`${
            !grantFilter.filterButtonFocused ? "text-orange-501" : "text-white"
          }  rounded`}
          height={20}
        />
      )}
    />
  );
}

export function InColumnDateFilters({
  primaryAction,
  secondaryAction,
  name,
  type,
  data,
}: {
  primaryAction: () => void;
  secondaryAction: () => void;
  name: any;
  type: string;
  data: Grant[];
}) {
  const grantFilter = useGrantFilterStore();
  return (
    <TableDateFilters
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      data={data}
      className="items-end"
      name={name}
      render={() => (
        <Icon
          icon="material-symbols:filter-list-rounded"
          className={`${
            !grantFilter.filterButtonFocused ? "text-orange-501" : "text-white"
          }  rounded`}
          height={20}
        />
      )}
    />
  );
}
