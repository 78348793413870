/* eslint-disable */
import createStore, { create } from "zustand";
import { configurePersist } from "zustand-persist";
import { Permission } from "../utils/interfaces/Companies";
import {
  CompanyUsersDataModel,
  UserCompanyData,
} from "../types/UserDetailModel";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

interface CompanyStore {
  companyData: UserCompanyData | null;
  setCompanyData: (company: UserCompanyData) => void;
  clearCompanyData: () => void;
}

export const useCompanyStore = create<CompanyStore>(
  persist(
    {
      key: "userCompanyData",
      name: "userCompanyData",
      storage: localStorage,
    },
    (set: any) => ({
      companyData: null,
      setCompanyData: (company: UserCompanyData) => {
        set({ companyData: company });
        // aclList: [],
        // isCompanySpecific: false,
        // role: "",
        // // setPermission: (perm: Permission) => {
        // //   set({ permission: perm });
        // // },
        // setPermission: (
        //   auth: Permission | ((auth: Permission) => Permission)
        // ) => {
        //   if (typeof auth === "function") {
        //     set((_state: Permission) => auth(_state));
        //   } else {
        //     set((_state: Permission) => auth);
        //   }
      },
      clearCompanyData: () => {
        set({ companyData: {} });
      },
    })
  )
);
