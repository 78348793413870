import { CustomResponse } from "../types/Grant";
import { CompanyProfileDetails } from "../types/SiteSettings";
import optionsApi from "./optionsApi";

export async function getCompanyProfileDetails(): Promise<
  CustomResponse<CompanyProfileDetails>
> {
  return optionsApi.get(`v1/company/details`).then((res) => res.data);
}

export async function updateCompanyProfile(
  companyProfileDetails: CompanyProfileDetails
): Promise<any> {
  return optionsApi
    .post("v1/company/updateProfile", companyProfileDetails)
    .then((res) => res.data);
}

export async function createEsopCompany(createJson: any): Promise<string> {
  return optionsApi
    .post(`v1/company/create`, createJson)
    .then((res) => res.data);
}
