import { Dialog } from "@mui/material";
import { AddOrEditTrustWrapper } from "../pages/allPlans/AddOrEditTrust";
import { AccelerateVesting } from "../pages/esopOverview/AccelerateVesting";
import EditEventDetails from "../pages/esopOverview/EditEventDetails";
import ExerciseApproval from "../pages/esopOverview/ExerciseApproval";
import ExerciseRequest from "../pages/esopOverview/ExerciseRequest";
import { GrantCreated } from "../pages/esopOverview/GrantCreated";
import VestApproval from "../pages/esopOverview/VestApproval";
import EditPaymentLink from "../pages/optionBuyback/editPaymentLink";
import FilterDetails from "../pages/optionBuyback/FilterDetails";
import AddEditOptionSplit from "../pages/optionSplit/AddEditOptionSplit";
import { AddVestingTemplateWrapper } from "../pages/vestingSchedules/AddVestingTemplate";
import SignatureGateway from "../shared/signaturePad";
import {
  useAccelerateVestingDialog,
  useAdvanceFilterDialog,
  useEditEventDetailsDialog,
  useEditPaymentLinkDialog,
  useExerciseApproveDialog,
  useExerciseRequestDialog,
  useGrantCreatedDialog,
  useOptionSplitDialog,
  useSignaturePadDialog,
  useTrustDialog,
  useVestingApproveDialog,
  useVestingScheduleDialog,
} from "../store/useDialogStore";

function DialogContainer() {
  const { state: exerciseRequestDialog, setState: setExerciseRequestDialog } =
    useExerciseRequestDialog();
  const { state: exerciseApproveDialog, setState: setExerciseApproveDialog } =
    useExerciseApproveDialog();
  const { state: vestingApproveDialog, setState: setVestingApproveDialog } =
    useVestingApproveDialog();
  const { state: grantCreatedDialog, setState: setGrantCreatedDialog } =
    useGrantCreatedDialog();
  const { state: editEventDetailsDialog, setState: setEditEventDetailsDialog } =
    useEditEventDetailsDialog();
  const { state: vestingScheduleDialog, setState: setVestingScheduleDialog } =
    useVestingScheduleDialog();
  const { state: trustDialog, setState: setTrustDialog } = useTrustDialog();
  const { state: accelerateDialog, setState: setAccelerateDialog } =
    useAccelerateVestingDialog();
  const { state: optionSplitDialog, setState: setOptionSplitDialog } =
    useOptionSplitDialog();
  const { state: showAdvanceFilterDialog, setState: setShowAdvanceFilters } =
    useAdvanceFilterDialog();
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const { state: showPaymentLinkDialog, setState: setShowPaymentLinkDialog } =
    useEditPaymentLinkDialog();
  return (
    <>
      <Dialog open={exerciseRequestDialog.open} maxWidth="md">
        {exerciseRequestDialog.grant && (
          <div className="w-[900px]">
            <ExerciseRequest
              grant={exerciseRequestDialog.grant}
              onClose={() => setExerciseRequestDialog({ open: false })}
            />
          </div>
        )}
      </Dialog>
      {/* payment link edit */}
      <Dialog open={showPaymentLinkDialog.open} maxWidth="md">
        <div className="w-[900px]">
          <EditPaymentLink
            onClose={() => setShowPaymentLinkDialog({ open: false })}
            link={showPaymentLinkDialog.link}
            eventId={showPaymentLinkDialog.eventId}
          />
        </div>
      </Dialog>
      {/* payment link edit */}
      <Dialog open={exerciseApproveDialog.open} maxWidth="md">
        <div className="max-w-[900px]">
          {exerciseApproveDialog.id && (
            <ExerciseApproval
              id={exerciseApproveDialog.id || ""}
              onClose={() => setExerciseApproveDialog({ open: false })}
              isEmployeeRaised={
                exerciseApproveDialog.isEmployeeRaised === undefined
                  ? false
                  : exerciseApproveDialog.isEmployeeRaised
              }
            />
          )}
        </div>
      </Dialog>
      <Dialog open={vestingApproveDialog.open} maxWidth="md">
        <div className="max-w-[900px]">
          {vestingApproveDialog.grant && (
            <VestApproval
              grant={vestingApproveDialog.grant}
              onClose={() => setVestingApproveDialog({ open: false })}
            />
          )}
        </div>
      </Dialog>
      <Dialog open={vestingScheduleDialog.open} maxWidth="lg">
        <div className=" w-[900px]">
          <AddVestingTemplateWrapper
            onClose={() => setVestingScheduleDialog({ open: false })}
            template={vestingScheduleDialog.template}
            mode={vestingScheduleDialog.mode}
            onCreate={() => {}}
          />
        </div>
      </Dialog>
      <Dialog open={trustDialog.open} maxWidth="lg">
        <div className=" w-[900px]">
          <AddOrEditTrustWrapper
            onClose={() => setTrustDialog({ open: false })}
            mode={trustDialog.mode}
            trust={trustDialog.trust}
            onCreate={() => {}}
          />
        </div>
      </Dialog>
      <Dialog open={accelerateDialog.open} maxWidth="lg">
        <div className=" w-[900px]">
          <AccelerateVesting
            onClose={() => setAccelerateDialog({ open: false })}
            grant={accelerateDialog.grant}
          />
        </div>
      </Dialog>
      <Dialog
        open={grantCreatedDialog.open && !!grantCreatedDialog.grantId}
        onClose={() => setGrantCreatedDialog({ open: false })}
        maxWidth="md"
      >
        {grantCreatedDialog.grantId && <GrantCreated />}
      </Dialog>
      <Dialog open={editEventDetailsDialog.open} maxWidth="md">
        {editEventDetailsDialog.event && (
          <EditEventDetails
            event={editEventDetailsDialog.event}
            onClose={() => setEditEventDetailsDialog({ open: false })}
          />
        )}
      </Dialog>
      <Dialog open={optionSplitDialog.open} maxWidth="md">
        <AddEditOptionSplit
          split={optionSplitDialog.split}
          onClose={() => setOptionSplitDialog({ open: false })}
        />
      </Dialog>
      <Dialog
        open={showAdvanceFilterDialog.open}
        maxWidth="md"
        onClose={() => setShowAdvanceFilters({ open: false })}
      >
        <FilterDetails
          onClose={() => setShowAdvanceFilters({ open: false })}
          id={showAdvanceFilterDialog.id}
        />
      </Dialog>
      <Dialog
        open={showSignaturePad.open}
        maxWidth="md"
        onClose={() => setShowSignaturePad({ open: false })}
      >
        <SignatureGateway
          onClose={() => setShowSignaturePad({ open: false })}
        />
      </Dialog>
    </>
  );
}
export default DialogContainer;
