import { QueryFunctionContext } from "react-query";
import {
  AvailableTableFilters,
  SaveTableState,
  TableName,
} from "../types/Table";
import optionsApi from "./optionsApi";

export async function saveTableFilterState(
  filterState: SaveTableState
): Promise<void> {
  return optionsApi
    .post("/v1/filterState/save", filterState)
    .then((res) => res.data.data);
}
export async function getTableFilters(
  context: QueryFunctionContext
): Promise<AvailableTableFilters[]> {
  const tableName = context.queryKey[1];
  return optionsApi
    .get(`/v1/filterState/getAll?tableName=${tableName}`)
    .then((res) => res.data.data);
}
export async function deleteTableFilter(
  id: string
): Promise<AvailableTableFilters[]> {
  return optionsApi
    .delete(`/v1/filterState/delete?id=${id}`)
    .then((res) => res.data.data);
}
