import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { doesCaptableCompanyExists } from "../api/Esop";
import { HStack } from "../components/utils";
import { useSetDefaultProduct } from "../queries/login";
import { useCompanyStore } from "../store";
import esopLogoGrayed from "../utils/assets/esop-grayed.svg";
import esopLogo from "../utils/assets/esop.svg";

export interface CaptableUserAccess {
  captableExists: boolean;
  canUserAccess: boolean;
}

function SwitchProduct({ setDialogOpen }: any) {
  const { companyData } = useCompanyStore();
  const { mutate: setDefaultCaptable } = useSetDefaultProduct();

  const [isCaptableExists, setCaptableExists] = useState(false);
  const canUserAccess = isCaptableExists;
  const esopRole = "";
  useEffect(() => {
    if (
      companyData?.captableCompanyId === undefined ||
      companyData?.captableCompanyId == null
    ) {
      setCaptableExists(false);
    } else {
      doesCaptableCompanyExists(companyData?.captableCompanyId).then(
        (res: { canUserAccess: boolean; captableExists: boolean }) => {
          if (res.canUserAccess && res.captableExists) {
            setCaptableExists(true);
          }
        },
        (err) => {
          setCaptableExists(false);
        }
      );
    }
  }, [companyData]);

  const dialog = useRef<HTMLDivElement>(null);

  const checkIfClickOutside = (e: any) => {
    if (dialog?.current && !dialog?.current?.contains(e.target)) {
      setDialogOpen(false);
    }
  };
  document.addEventListener("mousedown", checkIfClickOutside);

  const redirectToCaptable = async () => {
    if (
      companyData?.captableCompanyId !== undefined &&
      companyData?.captableCompanyId &&
      isCaptableExists
    ) {
      const accessToken = localStorage.getItem("accessToken") ?? "";
      await setDefaultCaptable();
      window.location.href = `${
        process.env.REACT_APP_OPTIONS_URL ?? ""
      }/landing/dashboard?token=${accessToken}&companyId=${
        companyData?.captableCompanyId
      }`;
    }
  };

  return (
    <div>
      <div
        id="dropdown"
        className="absolute z-40 mt-12 text-black bg-white border divide-y divide-gray-500 rounded shadow w-72"
        ref={dialog}
      >
        <ul
          className="w-full py-1 text-xs font-normal text-dark"
          aria-labelledby="dropdownDefault"
        >
          <HStack>
            <p className="p-3 text-base font-medium">Switch Platform</p>
          </HStack>

          <HStack
            className={`justify-between m-1 p-1 hover:bg-gray-100 ${
              isCaptableExists && canUserAccess
                ? "cursor-pointer"
                : "bg-gray-100 cursor-not-allowed"
            } items-center`}
            onClick={redirectToCaptable}
          >
            <HStack className="items-center">
              <img
                className="h-8"
                src={
                  isCaptableExists && canUserAccess ? esopLogo : esopLogoGrayed
                }
                alt="esop"
                color="#E85936"
              />
              <p
                className={`font-semibold text-base p-2 ${
                  isCaptableExists && canUserAccess
                    ? "text-orange-400"
                    : "text-gray-400"
                }`}
              >
                CapTable
              </p>
            </HStack>
            <div className="p-1">
              {isCaptableExists && canUserAccess ? (
                <div>
                  {esopRole && (
                    <p
                      className={`w-fit mr-4 h-fit px-1 py-0.5 text-xs rounded-md font-semibold bg-inherit border text-blue-600 border-blue-600 px-2`}
                    >
                      {esopRole}
                    </p>
                  )}
                </div>
              ) : (
                <Icon
                  icon="il:lock"
                  width="18"
                  height="18"
                  className="mr-10"
                  color="gray"
                />
              )}
            </div>
          </HStack>
          <HStack className="items-center justify-between p-1 m-1 hover:bg-gray-100">
            <HStack className="items-center">
              <Icon
                icon="raphael:piechart"
                color="#E85936"
                width="30"
                height="30"
              ></Icon>
              <p className="p-1 text-base font-semibold text-orange-600">
                ESOP
              </p>
            </HStack>
          </HStack>
        </ul>
      </div>
    </div>
  );
}

export default SwitchProduct;
