/* eslint-disable max-len */
import { Chart as ChartJS } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";
import { _sum } from "../../../../utils/arrays";
import { SummaryChartData } from "../OrgStructureSummary";
import { HStack } from "../../../../components/utils";

interface Props {
  data: SummaryChartData | undefined;
  total: number;
}

export function OrgSummaryDoughnutChart(props: Props) {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: 74,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (tooltipItems: any) => {
            const label = `${tooltipItems.label || ""}: ${
              tooltipItems.raw as string
            }`;
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Doughnut Chart",
        color: "blue",
        font: {
          size: 24,
        },
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
    elements: {
      arc: {
        spacing: -5,
        borderWidth: 8,
        borderRadius: 10,
      },
    },
  };
  const plugins = [
    {
      id: "0",
      beforeDraw(chart: ChartJS) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 80).toFixed(2);
        ctx.font = `${fontSize}em Inter`;
        ctx.textBaseline = "top";
        ctx.save();
      },
      afterDraw(chart: ChartJS) {
        const { ctx, chartArea } = chart;
        ctx.restore();
        const fontSize = 1;
        ctx.font = `${fontSize}em Inter`;
        ctx.textBaseline = "top";
        const text1 = "Total";
        const text2 = props.data?.centerText || "";
        const text3 = `${props.total}`;
        const dataPosition = text2.includes("Role") ? 2.5 : 2.8;
        ctx.fillText(
          text1,
          (chartArea.left + chartArea.right) / 2.2,
          (chartArea.bottom + chartArea.top) / 3
        );
        ctx.fillText(
          text2,
          (chartArea.left + chartArea.right) / dataPosition,
          (chartArea.bottom + chartArea.top) / 2.3
        );
        ctx.font = `${2}em Inter`;
        ctx.fillText(
          text3,
          (chartArea.left + chartArea.right) / 2.12,
          (chartArea.bottom + chartArea.top) / 1.8
        );
        ctx.save();
      },
    },
  ];
  if (props.data)
    return <Doughnut options={options} data={props.data} plugins={plugins} />;
  else return <HStack></HStack>;
}
