import { format } from "date-fns";
import React, { useState } from "react";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";

import { generateReport } from "../../api/Esop";
import { Input, Label } from "../../components/shared/InputField";
import { OnboardingReportDto } from "../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";

function OnboardingReportDialog({
  file,
  onClose = () => {},
}: {
  file: string;
  onClose: () => void;
}) {
  const [date, setDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  function handleDateUpdate(e: React.ChangeEvent<HTMLInputElement>) {
    const dateValue = format(
      new Date(e.target.value) || new Date(),
      "yyyy-MM-dd"
    );
    setDate(dateValue);
  }
  const fileName = `Onboarding Report_${getCompanyName()}`;
  async function handleGenerateReport() {
    const onboardingReportDto: OnboardingReportDto = {
      file,
      dateTillVestingToBeDone: date,
    };
    generateReport(onboardingReportDto).then((data) => {
      const reportDto = data.data;
      convertBase64ToBlob(reportDto.base64File, reportDto.fileType).then(
        (blobObject) => {
          downloadBlobObject(blobObject, fileName);
        }
      );
    });
    onClose();
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6>Download Grant Onboarding Report</h6>
      </div>
      <VStack>
        <HStack className="items-center gap-4 mt-6 ml-6 ">
          <Label className="text-sm font-normal">Vesting Date</Label>
          <Box>
            <Input
              type="date"
              value={date}
              onChange={(e) => handleDateUpdate(e)}
            />
          </Box>
        </HStack>
        <HStack className="justify-between px-4 py-6 mt-7">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={handleGenerateReport}>Generate</ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}
export default OnboardingReportDialog;
