import { QueryFunctionContext } from "react-query";
import { ChangePasswordDetails, UserProfile } from "../types/UserDetailModel";
import optionsApi from "./optionsApi";

export async function saveUserProfile(data: UserProfile): Promise<void> {
  return optionsApi.put("/v1/auth/userDetails", data);
}

export async function changePassword(data: ChangePasswordDetails) {
  return optionsApi
    .post("/v1/auth/changePassword", data)
    .then((res) => res.data);
}
