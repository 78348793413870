import { useMutation, useQuery } from "react-query";
import {
  approveEvent,
  approveVesting,
  doVestRequest,
  eventApproval,
  getEventDetails,
  getEventDetailsForApproval,
  getVestRequestDetails,
  getVestableOptionsDetails,
  pauseVestingForEmployee,
} from "../api/vest";
import { queryClient } from "./client";

export function useVestableOptionsDetails(optionHolderId: string) {
  return useQuery({
    queryKey: ["vestableOptions", optionHolderId],
    queryFn: getVestableOptionsDetails,
  });
}

export function useDoVestRequest() {
  return useMutation({
    mutationKey: "doVestRequest",
    mutationFn: doVestRequest,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useVestRequestDetails(optionHolderId: string) {
  return useQuery({
    queryKey: ["vestRequestDetails", optionHolderId],
    queryFn: getVestRequestDetails,
    staleTime: 0,
  });
}

export function useEventDetails(eventName: string) {
  return useQuery({
    queryKey: "eventDetails",
    queryFn: () => getEventDetails(eventName),
    staleTime: 0,
  });
}

export function useEventDetailsForApproval(eventName: string) {
  return useQuery({
    queryKey: "eventDetails",
    queryFn: () => getEventDetailsForApproval(eventName),
    staleTime: 0,
  });
}

export function useApproveVesting() {
  return useMutation({
    mutationKey: "approveVesting",
    mutationFn: approveVesting,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: (error) => {},
  });
}

export function useDoUpdateEventDetails() {
  return useMutation({
    mutationKey: "updateEvent",
    mutationFn: eventApproval,
    onSuccess: () => {
      queryClient.invalidateQueries("vestingEvents");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function useDoApproveEvent() {
  return useMutation({
    mutationKey: "updateEvent",
    mutationFn: approveEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("vestingEvents");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function usePauseVesting() {
  return useMutation({
    mutationKey: "pauseVesting",
    mutationFn: pauseVestingForEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
      queryClient.invalidateQueries("allGrants");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}
