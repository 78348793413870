import { DragEvent, useContext, useEffect, useMemo, useState } from "react";
import { Box, Dialog } from "@mui/material";
import CryptoJS from "crypto-js";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import {
  useGetEmailTemplateContent,
  useGetEmailTemplateNames,
  useSaveEmailTemplate,
} from "../../queries/siteSettings";
import {
  VStack,
  HStack,
  ButtonPrimary,
  ButtonSecondary,
  isEsopViewer,
  ButtonPrimary1,
} from "../../components/utils";
import { Select } from "../../components/shared/Select";
import EmailTemplateEditorDialog from "./EmailTemplateEditorDialog";
import { SiteSettingsContext } from "./SiteSettings";
import { EmailTemplateSaveObject } from "../../types/SiteSettings";
import { Input } from "../../components/shared/InputField";
import { usePermissionStore } from "../../store/permissionStore";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Resource,
  Action as DefaultAction,
} from "../../utils/interfaces/Companies";

interface EditTemplateDialog {
  templateName?: string;
  templateContent?: string;
  editMode?: boolean;
  templateId?: string;
  isEdited?: boolean;
  isCompanySpecific?: boolean;
}
function EmailTemplateSettings() {
  const { permission } = usePermissionStore();
  const { data: _emailTemplateNames, isFetched: fetchedEmailTemplateList } =
    useGetEmailTemplateNames();
  const [selectedTemplate, setSelectedTemplate] = useState<EditTemplateDialog>(
    {}
  );
  const { mutate: saveTemplate } = useSaveEmailTemplate();
  function saveEmailTemplate() {
    if (!selectedTemplate.isEdited) {
      toast("No Content to Save", { type: "error", autoClose: 3000 });
    } else if (
      !selectedTemplate.templateContent ||
      selectedTemplate.templateContent === ""
    ) {
      toast("No Content to Save", { type: "error", autoClose: 3000 });
    } else if (
      emailtemplateContent?.content === selectedTemplate.templateContent ||
      CryptoJS.SHA1(emailtemplateContent?.content || "") ===
        CryptoJS.SHA1(selectedTemplate.templateContent)
    ) {
      toast("No Content Changes to Save", { type: "error", autoClose: 3000 });
    } else {
      const templateObj: EmailTemplateSaveObject = {
        templateId: selectedTemplate?.templateId || "",
        emailTemplateBody: selectedTemplate.templateContent,
      };
      saveTemplate(templateObj, {
        onSuccess: (_) => {
          toast("Template Saved Successfully", {
            type: "success",
            autoClose: 3000,
          });
          refetchEmailTemplateContent();
        },
      });
    }
  }
  const {
    data: emailtemplateContent,
    isFetched: emailContentFetched,
    refetch: refetchEmailTemplateContent,
  } = useGetEmailTemplateContent(selectedTemplate.templateId || "");
  const emailTemplateList = useMemo(() => {
    if (_emailTemplateNames) return _emailTemplateNames;
    return [];
  }, [fetchedEmailTemplateList, _emailTemplateNames]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setSelectedTemplate((prevState) => ({
      ...prevState,
      templateContent: emailtemplateContent?.content,
    }));
  }, [emailContentFetched, emailtemplateContent]);
  useEffect(() => {
    if (selectedTemplate.templateId) refetchEmailTemplateContent();
  }, [selectedTemplate.templateId]);

  async function onEmailTemplateSelect(templateId: string) {
    const selectedTemplate = emailTemplateList.find(
      (template) => template.id === templateId
    );
    setSelectedTemplate((prev) => ({
      ...prev,
      templateId,
      templateName: selectedTemplate?.templateName,
      isCompanySpecific: Boolean(selectedTemplate?.companyId),
    }));
  }

  return (
    <Box className="md:w-[425px] lg:w-[850px] p-4 bg-white rounded-lg ">
      <VStack className="justify-between p-4 gap-9">
        <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
          Email Template Settings
        </Box>
        <HStack className="gap-2">
          <p className="flex items-center text-sm font-medium form-label">
            Select an Email Template
          </p>
          <div className="flex-row">
            {fetchedEmailTemplateList && (
              <Select
                className="cursor-pointer w-128"
                onChange={(e) => onEmailTemplateSelect(e.target.value)}
                valueGetter={(e) => e.id}
                textGetter={(e) => e.templateType}
                options={emailTemplateList || []}
              />
            )}
          </div>
          <button
            className=""
            disabled={!selectedTemplate.templateId}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTemplate((prevState) => ({
                ...prevState,
                editMode: true,
              }));
            }}
          >
            <Icon
              icon={"akar-icons:pencil"}
              width="27"
              className={`h-4 transition duration-100 ease-in-out border-white border-solid rounded-full  ring-2 ring-white  ${
                !selectedTemplate.templateId
                  ? "cursor-not-allowed text-gray-500"
                  : "cursor-pointer hover:h-6 text-orange-501 hover:-translate-y-1 hover:scale-100 hover:ring-blue-800"
              }`}
            />
          </button>
          <button
            className=""
            disabled={!selectedTemplate.isCompanySpecific}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTemplate((prevState) => ({
                ...prevState,
                editMode: true,
              }));
            }}
          >
            <Icon
              icon="mi:delete"
              className={`h-4 transition duration-100 ease-in-out border-white border-solid rounded-full  ring-2 ring-white  ${
                !selectedTemplate.isCompanySpecific
                  ? "cursor-not-allowed text-gray-500"
                  : "cursor-pointer hover:h-6 text-orange-501 hover:-translate-y-1 hover:scale-100 hover:ring-blue-800"
              }`}
              width="27"
            />
          </button>
        </HStack>
        <HStack className="justify-between">
          {emailContentFetched && (
            <div
              key={selectedTemplate.templateId}
              className="overflow-y-scroll max-h-96"
              dangerouslySetInnerHTML={{
                __html:
                  selectedTemplate?.templateContent ||
                  `<div> No Content Exists </div>`,
              }}
            ></div>
          )}
        </HStack>
        <HStack className="justify-between">
          <Dialog open={selectedTemplate.editMode || false} maxWidth="md">
            <div className="w-[900px]">
              <EmailTemplateEditorDialog
                templateName={selectedTemplate?.templateName || ""}
                templateContent={selectedTemplate?.templateContent || ""}
                onClose={(editedData) => {
                  if (editedData)
                    setSelectedTemplate((prevState) => ({
                      ...prevState,
                      editMode: false,
                      isEdited: true,
                      templateContent: editedData,
                    }));
                  else {
                    setSelectedTemplate((prevState) => ({
                      ...prevState,
                      editMode: false,
                    }));
                  }
                }}
              />
            </div>
          </Dialog>
        </HStack>
      </VStack>
      <HStack className="justify-between mt-12">
        <ButtonPrimary1
          type="reset"
          onClick={() => refetchEmailTemplateContent()}
          className="mr-8 text-red-500"
        >
          Cancel
        </ButtonPrimary1>
        <ButtonPrimary
          type="submit"
          onClick={() => saveEmailTemplate()}
          className={
            !determineUserAccessToResource(
              permission?.aclList || [],
              Resource.CompanySettings,
              DefaultAction.All
            )
              ? "opacity-50 cursor-not-allowed"
              : ""
          }
          disabled={
            !determineUserAccessToResource(
              permission?.aclList || [],
              Resource.CompanySettings,
              DefaultAction.All
            )
          }
        >
          Save
        </ButtonPrimary>
      </HStack>
    </Box>
  );
}

const EmailEditor = () => {
  const [column1Content, setColumn1Content] = useState(
    "Type in English here..."
  );
  const [column2Items] = useState(["Item 1", "Item 2", "Item 3"]); // Add more items as needed
  const [isHtmlMode, setIsHtmlMode] = useState(false);

  const allowDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const drag = (event: DragEvent<HTMLDivElement>, item: string) => {
    event.dataTransfer.setData("text/plain", item);
  };

  const drop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text/plain");
    const cursorPosition = getCursorPosition(event.target);

    const textBefore = column1Content.substring(0, cursorPosition);
    const textAfter = column1Content.substring(cursorPosition);

    setColumn1Content(textBefore + data + textAfter);
  };

  const getCursorPosition = (element: any) => {
    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        return preCaretRange.toString().length;
      }
    }
    return 0;
  };

  const toggleMode = () => {
    setIsHtmlMode(!isHtmlMode);
  };

  return <div></div>;
};

export default EmailTemplateSettings;
