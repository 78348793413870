import { CircularProgress, Dialog, LinearProgress } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";
import FileInput from "../../components/shared/FileInput";
import { Label } from "../../components/shared/InputField";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import {
  useDownloadTemplate,
  useImportExcel,
  useValidateExcel,
} from "../../queries";
import { Errors, ImportRes, ValidateRes } from "../../types/Grant";
import { downloadS3File } from "../../utils/DownloadFile";
import { sort } from "../../utils/arrays";
import convertToBase64 from "../../utils/convertToBase64";
import OnboardingReportDialog from "./OnboardingReportDialog";

function GrantDetails() {
  const initalValidateResponse = {
    basicValidationErrors: [],
    employeeErrors: [],
    exerciseErrors: [],
    grantErrors: [],
    vestingErrors: [],
  };
  const [response, setResponse] = useState<ValidateRes>(initalValidateResponse);
  const [base64File, setBase64File] = useState("");
  const { mutate: validateExcel, isLoading: isValidating } = useValidateExcel();
  const { refetch: refetchForDownloadTemplate } = useDownloadTemplate();
  const { mutate: importAll, isLoading: isImporting } = useImportExcel();
  const [progress, setProgress] = useState(0);
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const [isUploaded, setIsUploaded] = useState(false);
  const [summaryDetails, setSummaryDetails] = useState<ImportRes[]>([]);
  const [expandedEmployee, setExpandedEmployee] = useState(true);
  const [expandedBasic, setExpandedBasic] = useState(true);
  const [graphTextBasic, setGraphTextBasic] = useState("-");
  const [graphTextEmployee, setGraphTextEmployee] = useState("-");
  const [graphTextGrant, setGraphTextGrant] = useState("-");
  const [graphTextExercise, setGraphTextExercise] = useState("-");
  const [graphTextVesting, setGraphTextVesting] = useState("-");
  const [expandedGrant, setExpandedGrant] = useState(true);
  const [expandedExercise, setExpandedExercise] = useState(true);
  const [expandedVesting, setExpandedVesting] = useState(true);
  const [reportDialog, setReportDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const [sortFieldEmployee, setSortFieldEmployee] = useState<{
    field: keyof Errors | "" | undefined;
    ascending: boolean;
  }>();
  const [sortFieldGrant, setSortFieldGrant] = useState<{
    field: keyof Errors | "" | undefined;
    ascending: boolean;
  }>();
  const [sortFieldExercise, setSortFieldExercise] = useState<{
    field: keyof Errors | "" | undefined;
    ascending: boolean;
  }>();
  const [sortFieldVesting, setSortFieldVesting] = useState<{
    field: keyof Errors | "" | undefined;
    ascending: boolean;
  }>();
  const [sortFieldBasic, setSortFieldBasic] = useState<{
    field: keyof Errors | "" | undefined;
    ascending: boolean;
  }>();

  async function handleExcelUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files?.[0];
    const base64File = (await convertToBase64(file)) as string;
    setProgress(50);
    setBase64File(base64File);
  }
  let employeeErrors = response.employeeErrors;
  employeeErrors = useMemo(() => {
    if (!employeeErrors) return [];
    const sortResult = sort(
      employeeErrors,
      sortFieldEmployee?.field,
      sortFieldEmployee?.ascending
    );
    return sortResult;
  }, [employeeErrors, sortFieldEmployee]);
  let grantErrors = response.grantErrors;
  grantErrors = useMemo(() => {
    if (!grantErrors) return [];
    const sortResult = sort(
      grantErrors,
      sortFieldGrant?.field,
      sortFieldGrant?.ascending
    );
    return sortResult;
  }, [grantErrors, sortFieldGrant]);
  let exerciseErrors = response.exerciseErrors;
  exerciseErrors = useMemo(() => {
    if (!exerciseErrors) return [];
    const sortResult = sort(
      exerciseErrors,
      sortFieldExercise?.field,
      sortFieldExercise?.ascending
    );
    return sortResult;
  }, [exerciseErrors, sortFieldExercise]);
  let vestingErrors = response.vestingErrors;
  vestingErrors = useMemo(() => {
    if (!vestingErrors) return [];
    const sortResult = sort(
      vestingErrors,
      sortFieldVesting?.field,
      sortFieldVesting?.ascending
    );
    return sortResult;
  }, [vestingErrors, sortFieldVesting]);
  let basicValidationErrors = response.basicValidationErrors;
  basicValidationErrors = useMemo(() => {
    if (!basicValidationErrors) return [];
    const sortResult = sort(
      basicValidationErrors,
      sortFieldBasic?.field,
      sortFieldBasic?.ascending
    );
    return sortResult;
  }, [basicValidationErrors, sortFieldBasic]);
  const errors =
    employeeErrors.length > 0 ||
    grantErrors.length > 0 ||
    exerciseErrors.length > 0 ||
    vestingErrors.length > 0 ||
    basicValidationErrors.length > 0;
  const navigate = useNavigate();
  function clearForm(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    e.currentTarget.value = "";
    setBase64File("");
    setResponse(initalValidateResponse);
    setProgress(0);
  }
  const handleValidate = () => {
    validateExcel(base64File ?? "", {
      onSuccess: (data) => {
        setResponse(data.data);
        setProgress(100);
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };

  async function downloadTemplate() {
    const template = await refetchForDownloadTemplate();
    downloadS3File(template.data?.data.s3link ?? "");
  }

  async function handleImport() {
    importAll(base64File ?? "", {
      onSuccess: (data) => {
        setIsUploaded(true);
        setSummaryDetails(data.data);
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
    setDialog({ open: false });
  }

  useEffect(() => {
    if (base64File) handleValidate();
  }, [base64File]);

  const message =
    base64File && !errors
      ? "Seems like there are no errors. Please proceed on importing ,also you can download the report to verify."
      : "Please proceed on uploading excel file to validate.";

  return (
    <>
      {!isUploaded ? (
        <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[600px]">
          <VStack>
            <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
              <h6 className="flex-1">Historic Onboarding</h6>
              <ButtonPrimary onClick={() => downloadTemplate()}>
                Download Template
              </ButtonPrimary>
            </HStack>
            <HStack className="items-center gap-4 pt-4 ">
              <Label className="text-sm font-normal">Upload Excel File</Label>
              <FileInput
                accept=".xlsx, .xls, .ods"
                onClick={clearForm}
                onChange={handleExcelUpload}
                disabled={isValidating}
                multiple={false}
                type="file"
                file={"Choose File"}
              />
              <LinearProgress
                className="w-1/2 p-1"
                variant="determinate"
                value={progress}
                color="success"
              />
              <p>{progress}%</p>
            </HStack>
            <VStack className="w-full h-full gap-6 px-2 py-4 bg-white">
              {!errors && isValidating && (
                <Box className="items-center text-lg font-medium text-center pt-44 text-gray-dark">
                  <div>{<CircularProgress size={100} />}</div>
                  <p>Validating please wait...</p>
                </Box>
              )}
              {!errors && isImporting && (
                <Box className="items-center text-lg font-medium text-center pt-44 text-gray-dark">
                  <div>{<CircularProgress size={100} />}</div>
                  <p>Importing please wait...</p>
                </Box>
              )}
              {basicValidationErrors && basicValidationErrors.length > 0 && (
                <Box className="w-full py-4 bg-white rounded-lg min-w-min">
                  <HStack
                    className={`items-center justify-between p-4 ${
                      expandedBasic ? "border-b" : ""
                    }`}
                  >
                    <Box className="w-1/3 text-lg font-medium text-gray-dark">
                      Basic Validation Errors
                    </Box>
                    <ButtonPrimary
                      className="p-4"
                      onClick={() => {
                        setExpandedBasic((state) => !state);
                        setGraphTextBasic((state) => {
                          if (state === "+") {
                            return "-";
                          } else {
                            return "+";
                          }
                        });
                      }}
                    >
                      {graphTextBasic}
                    </ButtonPrimary>
                  </HStack>
                  {expandedBasic && (
                    <Box>
                      <table className="w-full ">
                        <thead className="text-sm font-medium text-gray-light">
                          <tr className="">
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldBasic({
                                  field: "id",
                                  ascending: !sortFieldBasic?.ascending,
                                })
                              }
                            >
                              ID
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldBasic({
                                  field: "name",
                                  ascending: !sortFieldBasic?.ascending,
                                })
                              }
                            >
                              NAME
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldBasic({
                                  field: "errors",
                                  ascending: !sortFieldBasic?.ascending,
                                })
                              }
                            >
                              ISSUE AT
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {basicValidationErrors?.map((error) => (
                            <tr key={error.id}>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.id}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.name}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <HStack>
                                  <p
                                    className={`text-sm font-medium text-gray-dark`}
                                  >
                                    {error.errors}
                                  </p>
                                </HStack>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </Box>
              )}
              {employeeErrors && employeeErrors.length > 0 && (
                <Box className="w-full py-4 bg-white rounded-lg min-w-min">
                  <HStack
                    className={`items-center justify-between p-4 ${
                      expandedEmployee ? "border-b" : ""
                    }`}
                  >
                    <Box className="w-1/3 text-lg font-medium text-gray-dark">
                      Employee Sheet Errors
                    </Box>
                    <ButtonPrimary
                      className="p-4"
                      onClick={() => {
                        setExpandedEmployee((state) => !state);
                        setGraphTextEmployee((state) => {
                          if (state === "+") {
                            return "-";
                          } else {
                            return "+";
                          }
                        });
                      }}
                    >
                      {graphTextEmployee}
                    </ButtonPrimary>
                  </HStack>
                  {expandedEmployee && (
                    <Box>
                      <table className="w-full ">
                        <thead className="text-sm font-medium text-gray-light">
                          <tr className="">
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldEmployee({
                                  field: "id",
                                  ascending: !sortFieldEmployee?.ascending,
                                })
                              }
                            >
                              EMPLOYEE ID
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldEmployee({
                                  field: "name",
                                  ascending: !sortFieldEmployee?.ascending,
                                })
                              }
                            >
                              EMPLOYEE NAME
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldEmployee({
                                  field: "errors",
                                  ascending: !sortFieldEmployee?.ascending,
                                })
                              }
                            >
                              ISSUE AT
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {employeeErrors?.map((error) => (
                            <tr key={error.id}>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.id}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.name}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <HStack>
                                  <p
                                    className={`text-sm font-medium text-gray-dark`}
                                  >
                                    {error.errors}
                                  </p>
                                </HStack>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </Box>
              )}
              {grantErrors && grantErrors.length > 0 && (
                <Box className="w-full py-4 bg-white rounded-lg min-w-min">
                  <HStack
                    className={`items-center justify-between p-4 ${
                      expandedGrant ? "border-b" : ""
                    }`}
                  >
                    <Box className="w-1/3 text-lg font-medium text-gray-dark">
                      Grant Sheet Errors
                    </Box>
                    <ButtonPrimary
                      className="p-4"
                      onClick={() => {
                        setExpandedGrant((state) => !state);
                        setGraphTextGrant((state) => {
                          if (state === "+") {
                            return "-";
                          } else {
                            return "+";
                          }
                        });
                      }}
                    >
                      {graphTextGrant}
                    </ButtonPrimary>
                  </HStack>
                  {expandedGrant && (
                    <Box>
                      <table className="w-full ">
                        <thead className="text-sm font-medium text-gray-light">
                          <tr className="">
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldGrant({
                                  field: "id",
                                  ascending: !sortFieldGrant?.ascending,
                                })
                              }
                            >
                              GRANT ID
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldGrant({
                                  field: "name",
                                  ascending: !sortFieldGrant?.ascending,
                                })
                              }
                            >
                              EMPLOYEE NAME
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldGrant({
                                  field: "errors",
                                  ascending: !sortFieldGrant?.ascending,
                                })
                              }
                            >
                              ISSUE AT
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {grantErrors?.map((error) => (
                            <tr key={error.id}>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.id}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.name}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <HStack>
                                  <p
                                    className={`text-sm font-medium text-gray-dark`}
                                  >
                                    {error.errors}
                                  </p>
                                </HStack>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </Box>
              )}
              {exerciseErrors && exerciseErrors.length > 0 && (
                <Box className="w-full py-4 bg-white rounded-lg min-w-min">
                  <HStack
                    className={`items-center justify-between p-4 ${
                      expandedExercise ? "border-b" : ""
                    }`}
                  >
                    <Box className="w-1/3 text-lg font-medium text-gray-dark">
                      Exercise Sheet Errors
                    </Box>
                    <ButtonPrimary
                      className="p-4"
                      onClick={() => {
                        setExpandedExercise((state) => !state);
                        setGraphTextExercise((state) => {
                          if (state === "+") {
                            return "-";
                          } else {
                            return "+";
                          }
                        });
                      }}
                    >
                      {graphTextExercise}
                    </ButtonPrimary>
                  </HStack>
                  {expandedExercise && (
                    <Box>
                      <table className="w-full ">
                        <thead className="text-sm font-medium text-gray-light">
                          <tr className="">
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldExercise({
                                  field: "id",
                                  ascending: !sortFieldExercise?.ascending,
                                })
                              }
                            >
                              GRANT ID
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldExercise({
                                  field: "name",
                                  ascending: !sortFieldExercise?.ascending,
                                })
                              }
                            >
                              EMPLOYEE NAME
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldExercise({
                                  field: "errors",
                                  ascending: !sortFieldExercise?.ascending,
                                })
                              }
                            >
                              ISSUE AT
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {exerciseErrors?.map((error) => (
                            <tr key={error.id}>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.id}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.name}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <HStack>
                                  <p
                                    className={`text-sm font-medium text-gray-dark`}
                                  >
                                    {error.errors}
                                  </p>
                                </HStack>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </Box>
              )}
              {vestingErrors && vestingErrors.length > 0 && (
                <Box className="w-full py-4 bg-white rounded-lg min-w-min">
                  <HStack
                    className={`items-center justify-between p-4 ${
                      expandedVesting ? "border-b" : ""
                    }`}
                  >
                    <Box className="w-1/3 text-lg font-medium text-gray-dark">
                      Vesting Sheet Errors
                    </Box>
                    <ButtonPrimary
                      className="p-4"
                      onClick={() => {
                        setExpandedVesting((state) => !state);
                        setGraphTextVesting((state) => {
                          if (state === "+") {
                            return "-";
                          } else {
                            return "+";
                          }
                        });
                      }}
                    >
                      {graphTextVesting}
                    </ButtonPrimary>
                  </HStack>
                  {expandedVesting && (
                    <Box>
                      <table className="w-full ">
                        <thead className="text-sm font-medium text-gray-light">
                          <tr className="">
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldVesting({
                                  field: "id",
                                  ascending: !sortFieldVesting?.ascending,
                                })
                              }
                            >
                              GRANT ID
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldVesting({
                                  field: "name",
                                  ascending: !sortFieldVesting?.ascending,
                                })
                              }
                            >
                              EMPLOYEE NAME
                            </td>
                            <td
                              className="py-2 align-middle hover:cursor-pointer"
                              onClick={() =>
                                setSortFieldVesting({
                                  field: "errors",
                                  ascending: !sortFieldVesting?.ascending,
                                })
                              }
                            >
                              ISSUE AT
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {vestingErrors?.map((error) => (
                            <tr key={error.id}>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.id}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <Box className="">
                                  <HStack>
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {error.name}
                                    </p>
                                  </HStack>
                                </Box>
                              </td>
                              <td className="py-2 align-middle">
                                <HStack>
                                  <p
                                    className={`text-sm font-medium text-gray-dark`}
                                  >
                                    {error.errors}
                                  </p>
                                </HStack>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </Box>
              )}
              {!errors && !isImporting && !isValidating && (
                <Box className="pt-44">
                  <p className="items-center text-lg font-medium text-center text-gray-dark">
                    {message}
                  </p>
                </Box>
              )}
            </VStack>
          </VStack>
          <Dialog
            open={reportDialog.open}
            onClose={() => setReportDialog({ open: false })}
            maxWidth="lg"
          >
            <div className="w-[400px] min-h-min mx-auto bg-white rounded-lg">
              <OnboardingReportDialog
                onClose={() => setReportDialog({ open: false })}
                file={base64File ?? ""}
              />
            </div>
          </Dialog>
          <HStack className="justify-end gap-4 mb-4 bg-white ">
            <ButtonPrimary
              onClick={() => setReportDialog({ open: true })}
              disabled={errors || !base64File || isValidating || isImporting}
            >
              Generate Report
            </ButtonPrimary>
            <ButtonPrimary
              onClick={() =>
                setDialog({
                  open: true,
                  message: "Do you want to import the records?",
                })
              }
              disabled={errors || !base64File || isValidating || isImporting}
            >
              Upload
            </ButtonPrimary>
            <Dialog
              open={dialog.open}
              maxWidth="md"
              onClose={() => setDialog({ open: false })}
            >
              <AlertDialog
                onClose={() => setDialog({ open: false })}
                open={dialog.open}
                message={dialog.message}
                onPrimaryAction={handleImport}
                onSecondaryAction={() => setDialog({ open: false })}
              />
            </Dialog>
          </HStack>
        </VStack>
      ) : (
        <VStack className="w-full h-full px-4 bg-white border-2 rounded-md min-h-[400px]">
          <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
            <h6 className="flex-1">Historic Onboarding</h6>
          </HStack>
          <Box className="pt-6">
            <p className="text-lg font-medium text-center justify-items-start text-gray-dark">
              Onboarding is complete. Below is the summary:
            </p>
          </Box>
          <Box className="pt-6">
            <table className="w-full table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="">
                  <td className="py-2 align-middle"></td>
                  <td className="py-2 align-middle">EXISTING NUMBER</td>
                  <td className="py-2 align-middle">ADDED NUMBER</td>
                  <td className="py-2 align-middle">TOTAL NUMBER</td>
                </tr>
              </thead>
              <tbody>
                {summaryDetails?.map((summary) => (
                  <tr key={summary.name} className="border-t border-dashed ">
                    <td className="py-2 align-middle">
                      <HStack className="">
                        <Box>
                          <p className={`text-xs font-medium text-gray-dark `}>
                            {summary.name}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-2 align-middle">
                      <Box className="">
                        <HStack>
                          <p className={`text-xs font-medium text-gray-dark`}>
                            {summary.existingNumber}
                          </p>
                        </HStack>
                      </Box>
                    </td>
                    <td className="py-2 align-middle">
                      <HStack>
                        <p className={`text-xs font-medium text-gray-dark`}>
                          {summary.addedNumber}
                        </p>
                      </HStack>
                    </td>
                    <td className="py-2 align-middle">
                      <HStack>
                        <p className={`text-xs font-medium text-gray-dark `}>
                          {summary.afterNumber}
                        </p>
                      </HStack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <HStack className="justify-end gap-4 pt-6 mb-4 bg-white">
            <ButtonPrimary onClick={() => navigate("/options/allPlans")}>
              Continue
            </ButtonPrimary>
          </HStack>
        </VStack>
      )}
    </>
  );
}

export default GrantDetails;
