import { CustomResponse, ReportDto } from "../types/Grant";
import optionsApi from "./optionsApi";

export async function exportExcel(
  reqExcelBody: any
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post("v1/exports/exportExcel", reqExcelBody)
    .then((res) => res.data);
}
