import React, { useEffect, useState, Fragment } from "react";
import { Icon } from "@iconify/react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { useMatch, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AssociatedCompany, Permission } from "../utils/interfaces/Companies";
import {
  useAuthStore,
  useAuthorizationStore,
  useCompanyStore,
  usePermissionStore,
} from "../store";

import {
  queryClient,
  useGetUserCompanies,
  useGetUserCompanyDetails,
  useGetUserRoleAndPermission,
  useSwitchCompany,
  useUserDetails,
} from "../queries";
import { HStack } from "../components/utils";
import { Input } from "../components/shared/InputField";
import { globalFilter } from "../utils/arrays";
import Avatar from "../pages/esopOverview/Avatar";

export function FlyoutMobileProfile() {
  const [show, setShown] = useState(false);
  const { permission, clearPermission } = usePermissionStore();
  const { clearCompanyData } = useCompanyStore();
  const { setAuth, clear } = useAuthStore();
  const [showCompanies, setShowCompanies] = useState(false);
  const { data: _userData } = useUserDetails();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const contextRef = React.useRef<HTMLDivElement>(null);
  const { data: currentCompanyDetails, refetch: refetchUserCompanyDetails } =
    useGetUserCompanyDetails();
  const { data, refetch: getPermission } = useGetUserRoleAndPermission();
  const { setPermission } = usePermissionStore();
  const { companyData, setCompanyData } = useCompanyStore();
  const { data: userCompanies, isLoading: companiesLoading } =
    useGetUserCompanies();
  if (
    companiesLoading === false &&
    userCompanies &&
    userCompanies?.associatedCompanies?.length === 0
  )
    navigate("/company/createCompany");
  const companies = React.useMemo(() => {
    if (!companyData || !userCompanies) return [];

    const filterResult = globalFilter(
      userCompanies.associatedCompanies,
      searchInput,
      ["name"]
    );
    return filterResult;
  }, [searchInput, companyData, userCompanies]);

  const profileMatch = useMatch(`/${"/user/profile".split("/")[1]}/*`);
  const userData = React.useMemo(() => _userData, [_userData]);
  const { mutate: switchUserCompany } = useSwitchCompany();
  const [successMessage, setSuccessMessage] = useState("");

  async function switchCompany(company: AssociatedCompany) {
    if (company.id === userCompanies?.defaultCompany?.id) return;

    const switchCompanyObject = { companyId: company.id };
    switchUserCompany(switchCompanyObject, {
      onSuccess: (data) => {
        getPermission().then((permission) => {
          setPermission(permission.data as Permission);
        });
        refetchUserCompanyDetails();
        setSuccessMessage("Company switched successfully!");
        toast.success("Company switched successfully!", {
          autoClose: 2000,
        });

        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      },
      onError: (error) => {
        setSuccessMessage("Failed to switch company. Please try again.");
        toast.error("Failed to switch company. Please try again.", {
          autoClose: 2000,
        });
      },
    });
    queryClient.invalidateQueries();
    queryClient.refetchQueries();
    navigate("/options/esopOverview");
  }

  const routes = [
    {
      name: "Profile",
      onClick: () => {
        navigate("/user/profile");
        setShown(false);
        setShowCompanies(false);
      },
      icon: UserCircleIcon,
      conponent: <div></div>,
    },
    {
      name: "Switch Company",
      onClick: () => {},
      icon: ArrowsRightLeftIcon,
      component: (
        <div className="w-screen pr-3 max-w-xs flex-auto overflow-hidden text-sm leading-6">
          <div className="group relative flex gap-x-2 w-full rounded-lg">
            {showCompanies && (
              <>
                <ul className="bg-white border my-2 divide-y w-full divide-gray-100 rounded dropdown-menu ">
                  <HStack className="items-center rounded justify-start bg-slate-light text-slate-dark w-full">
                    <Icon icon="fe:search" width="24" />
                    <Input
                      type="text"
                      value={searchInput}
                      className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                      placeholder="Search Company"
                      onChange={(e: any) => setSearchInput(e.target.value)}
                    />
                  </HStack>
                  {companies.map((company, index) => (
                    <li
                      onMouseDown={(e) => {
                        e.preventDefault();
                        switchCompany(company);
                      }}
                      key={company.id}
                      className="block px-4 py-2 hover:bg-gray-100 w-full"
                    >
                      <HStack className="items-center justify-between w-full">
                        <button
                          type="button"
                          className={`${
                            company?.id === userCompanies?.defaultCompany?.id &&
                            "text-gray-300 cursor-not-allowed "
                          } `}
                        >
                          {company.name}
                        </button>
                      </HStack>
                    </li>
                  ))}
                  {companies.length === 0 ? (
                    <ul className="bg-white rounded shadow ">
                      <li className="block px-4 py-2 hover:bg-gray-100">
                        No companies availables
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      ),
    },
    {
      name: "Log Out",
      onClick: () => {
        logout();
        setShown(false);
        setShowCompanies(false);
      },
      icon: ArrowLeftOnRectangleIcon,
      component: <div></div>,
    },
  ];
  const { clearAuthorization } = useAuthorizationStore();
  function logout() {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("companyId");
    localStorage.removeItem("hissaUrl");
    queryClient.clear();
    clearAuthorization();
    clearPermission();
    clear();
    clearCompanyData();
  }
  return (
    <div>
      {loading ? (
        <div className="items-center px-6 py-2">
          <p className="text-sm animate-pulse font-medium">Switching...</p>
        </div>
      ) : (
        <Popover className="relative">
          <div>
            <Popover.Button
              onClick={() => setShown(!show)}
              onMouseEnter={() => {
                setShown(true);
              }}
              onMouseLeave={() => {
                setShown(false);
                setShowCompanies(false);
              }}
              className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 "
            >
              <span className="sr-only">Open user menu</span>
              <Avatar name={userData?.full_name || ""} />
              <span className="hidden lg:flex lg:items-center">
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Popover.Button>
          </div>
          <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              onMouseEnter={() => {
                setShown(true);
              }}
              onMouseLeave={() => {
                setShown(false);
                setShowCompanies(false);
              }}
              ref={contextRef}
              className="absolute z-10 mt-5 flex w-screen max-w-max  right-0 "
            >
              <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
                <div className=" divide-x divide-gray-900/5 bg-gray-50">
                  <div className="group relative flex gap-x-2 rounded-lg p-4 hover:bg-gray-50">
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <Avatar name={userData?.full_name || ""} />
                    </div>
                    <div>
                      <a className="font-semibold text-gray-900 whitespace-nowrap">
                        {userData?.full_name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="text-gray-600 whitespace-nowrap">
                        {userData?.email_id}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-2  cursor-pointer">
                  {routes.map((item) => (
                    <>
                      <div
                        key={item.name}
                        onClick={() =>
                          item.name === "Switch Company"
                            ? setShowCompanies(!showCompanies)
                            : item.onClick()
                        }
                        className="group relative flex gap-x-4 rounded-lg p-2 hover:bg-gray-50 items-center"
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className={`h-6 w-6 text-gray-600 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <div>
                          <a
                            className={`font-semibold text-gray-900 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                        </div>
                        {item.name === "Switch Company" && (
                          <div>
                            {showCompanies ? (
                              <ChevronUpIcon
                                className="ml-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="ml-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        )}
                      </div>
                      {item.component}
                    </>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  );
}
