import { getNames } from "country-list";
import { useFormikContext } from "formik";
import { Icon } from "@iconify/react";

import React, { useEffect, useMemo, useState } from "react";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { Box, Center, Error, HStack, VStack } from "../../components/utils";
import { designationForUserDropDownValues } from "../../constants/DropDownValues";
import { UserProfile } from "../../types/UserDetailModel";
import convertToBase64 from "../../utils/convertToBase64";

export default function UserProfileForm({
  setDialog,
}: {
  setDialog: Function;
}) {
  const formik = useFormikContext<UserProfile>();
  const { errors, touched, getFieldProps } = formik;
  const profileImage = useMemo(() => formik.values.profilePic, [formik.values]);

  async function handleEditImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64: string = (await convertToBase64(file, true)) as string;
    formik.setFieldValue("profilePic", base64);
  }

  return (
    <>
      <Box className="sm:px-10 px-4 py-4 text-lg font-medium text-left border-b">
        <h6>User Details</h6>
      </Box>
      <VStack className="w-full  sm:px-10 px-4 py-4 gap-6">
        <Box className={"flex-1 flex-row md:justify-start md:flex"}>
          <VStack className="gap-4">
            <Box className="relative w-32">
              <div className="flex-1">
                <Label className="text-sm font-normal">Avatar</Label>
              </div>
              <Label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  className="hidden"
                  onChange={handleEditImage}
                />
                <Icon
                  icon={"eva:edit-outline"}
                  className={`absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 hover:text-black`}
                />
              </Label>
              {!profileImage ? (
                <Center className="relative w-32 h-32 bg-gray-100 ">
                  <Center className="flex w-32 h-32 rounded-full ">
                    <p className="text-base font-bold ">
                      {formik.values.fullName.charAt(0)}
                    </p>
                  </Center>
                </Center>
              ) : (
                <img
                  className="block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                  src={profileImage}
                  alt="avatar"
                />
              )}

              <Icon
                icon={"eva:close-fill"}
                onClick={() => {
                  formik.setFieldValue("profilePic", null);
                }}
                className={`absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 -bottom-3 hover:text-black`}
              />
            </Box>
            <Box className="text-gray-400">
              Allowed file types: png, jpg, jpeg.
            </Box>
          </VStack>
        </Box>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              User Name
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter User Name"
              className=""
              {...getFieldProps("fullName")}
            />
            {touched.fullName && errors.fullName && (
              <Error text={errors.fullName} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Email ID
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="email"
              placeholder="Enter Email Address"
              className="cursor-not-allowed"
              {...getFieldProps("emailAddress")}
              disabled={true}
            />
            {touched.emailAddress && errors.emailAddress && (
              <Error text={errors.emailAddress} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Contact Number
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="phone"
              placeholder="Enter Phone Number"
              {...getFieldProps("phoneNumber")}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <Error text={errors.phoneNumber} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Designation
              <span className="text-rose-400">*</span>
            </Label>
            <Select
              options={designationForUserDropDownValues}
              {...getFieldProps("designation")}
            />
            {touched.designation && errors.designation && (
              <Error text={errors.designation} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Country Of Residence
              <span className="text-rose-400">*</span>
            </Label>
            <Select
              options={getNames().sort()}
              {...getFieldProps("countryOfResidence")}
            />
            {touched.countryOfResidence && errors.countryOfResidence && (
              <Error text={errors.countryOfResidence} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">Nationality</Label>
            <Select
              options={getNames().sort()}
              {...getFieldProps("nationality")}
            />
            {touched.nationality && errors.nationality && (
              <Error text={errors.nationality} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col w-1/2">
          <div className="flex-1">
            <Label className="text-sm font-normal">Gender</Label>
            <Select
              options={["Male", "Female", "Others"]}
              {...getFieldProps("gender")}
            />
            {touched.gender && errors.gender && <Error text={errors.gender} />}
          </div>
        </HStack>
        <HStack className="mb-2 mt-5">
          <div
            className="text-blue-600 text-lg font-semibold cursor-pointer"
            onClick={() => setDialog({ open: true })}
          >
            Change Password
          </div>
        </HStack>
      </VStack>
    </>
  );
}
