import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useChangeOptionStates,
  useCreateESignRequest,
  useSignAndApproveGrant,
} from "../../queries";
import { useCompanyStore } from "../../store";
import { useError } from "../../store/errorStore";
import { useSignaturePadDialog } from "../../store/useDialogStore";
import { Grant, SignatureAttachment } from "../../types/Grant";
import { ErrorInterface } from "../../types/OptionBuyback";
import { stringFormat } from "../../utils/string";

function BulkOptionStateChange({
  grants,
  onClose = () => {},
}: Readonly<{
  grants: Grant[];
  onClose: () => void;
}>) {
  const [nextState, setNextState] = useState(grants[0]?.availableStates[0]);
  const { mutate: changeOptionStates, status: isStateChanged } =
    useChangeOptionStates();
  const {
    mutate: attachSignature,
    isLoading: signatureBeingAttached,
    status: isSignatureAttached,
  } = useSignAndApproveGrant();
  const errorMessage = useError();
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const { companyData } = useCompanyStore();
  const [lapseWithdrawed, setLapseWithdrawed] = useState(false);
  const availableOptions =
    grants[0]?.availableStates.map((option) => stringFormat(option)) || [];
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const { mutate: createEsignRequest, isLoading: eSignSuccess } =
    useCreateESignRequest();
  useEffect(() => {
    if (showSignaturePad.signatureType === "regular") {
      handleSignaturePad(showSignaturePad.signature ?? "");
    }
    if (showSignaturePad.signatureType === "esign") {
      createDigiESignRequest();
    }
  }, [showSignaturePad]);

  function createDigiESignRequest() {
    const eSignRequestObject = {
      entityIds: grants.map((grant) => grant.optionHolderId),
      type: "grant",
    };
    setShowSignaturePad({ open: false, isLoading: !eSignSuccess });
    createEsignRequest(eSignRequestObject, {
      onSuccess: (data) => {
        setShowSignaturePad({ open: false, isLoading: eSignSuccess });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(`${error.errors}`, { autoClose: 2000, type: "error" });
        setShowSignaturePad({ open: false, isLoading: eSignSuccess });
      },
    });
  }
  function handleClick() {
    if (!grants[0].optionHolderId || !nextState) return;
    const ids = grants.map((grant) => grant.optionHolderId);
    if (note === "" && nextState === "WITHDRAW") {
      setError("Note required");
      return;
    }
    if (
      companyData?.companySettings.grantLetterSettings
        ?.requestApproverSignature &&
      nextState === "APPROVED"
    ) {
      toast("Please Sign In order to approve the grant", {
        type: "info",
        autoClose: 3000,
      });
      setShowSignaturePad({ open: true, entityType: "grant" });
      return;
    }
    changeOptionStates(
      {
        optionHolderIds: ids,
        optionHolderState: nextState.replace(" ", "_"),
        note,
        mailToBeSent: false,
        lapseWithdrawed,
      },
      {
        onSuccess: () => {
          toast("State Changed Successfully!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          onClose();
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  function handleSignaturePad(signature: string) {
    if (signature.includes("data:image/png;base64,")) {
      signature = signature.replace("data:image/png;base64,", "");
    }
    const ids = grants.map((grant) => grant.optionHolderId);
    const signatureAttachmentObj: SignatureAttachment = {
      signature,
      entityIds: ids,
      origin: "grant",
    };
    setShowSignaturePad({ open: false, isLoading: !signatureBeingAttached });
    attachSignature(signatureAttachmentObj, {
      onSuccess: () => {
        toast("State Changed Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
        setShowSignaturePad({ open: false, isLoading: signatureBeingAttached });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        onClose();
        toast(`${error.errors}`, { autoClose: 2000, type: "error" });
      },
    });
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b ">
        <h6>Bulk Option State Change</h6>
      </div>
      <VStack className="justify-between gap-4 p-10 min-w-[500px] min-h-[300px]">
        <HStack className="text-center text-md item-center">
          Selected {grants.length}
          {grants.length === 1 ? ` grant ` : ` grants `} in{" "}
          {stringFormat(grants[0].optionHolderState)} state.
        </HStack>
        <Box>
          <Label className="text-sm font-normal">Select Grant Status</Label>
          <Select
            options={availableOptions}
            isPlaceHolderDisabled={true}
            onChange={(e) => setNextState(e.target.value)}
          />
        </Box>

        {nextState === "WITHDRAW" && (
          <VStack>
            <Label>Note</Label>
            <Input
              type="text"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <Error text={error} />
            <HStack className="gap-8 mt-6 text-sm font-normal">
              <SwitchButton
                value={lapseWithdrawed}
                label="Lapsed Withdrawed options?"
                onClick={() => {
                  setLapseWithdrawed(!lapseWithdrawed);
                }}
              />
            </HStack>
          </VStack>
        )}
        <ButtonPrimary
          className={`flex items-center self-end justify-center ${
            isStateChanged === "success" || isSignatureAttached === "success"
              ? "bg-green-500"
              : ""
          }`}
          disabled={
            !nextState ||
            isStateChanged !== "idle" ||
            isSignatureAttached !== "idle"
          }
          loading={
            isStateChanged === "loading" || isSignatureAttached === "loading"
          }
          onClick={() => handleClick()}
        >
          {(isStateChanged === "idle" || isSignatureAttached === "idle") &&
            "Confirm"}
          {(isStateChanged === "success" ||
            isSignatureAttached === "success") && (
            <Icon icon="clarity:success-standard-line" width={36} />
          )}
        </ButtonPrimary>
      </VStack>
    </>
  );
}
export default BulkOptionStateChange;
