import { useFormik } from "formik";
import { Input, Label } from "../../components/shared/InputField";
import { Box, HStack, VStack } from "../../components/utils";
import { OptionBuyBackDetails } from "../../types/OptionBuyback";
import { formatDisplayDate } from "../../utils/date";
import StatusLabel from "../esopOverview/StatusLabel";

function LiquidationDetails({
  buybackDetail,
}: {
  buybackDetail: OptionBuyBackDetails;
}) {
  const initialValues = {
    name: buybackDetail?.eventName,
    startDate: formatDisplayDate(buybackDetail?.startDate || "1979-01-01"),
    percentage: ((buybackDetail?.eventPercentage || 0) * 100).toPrecision(3),
    price: buybackDetail?.monetaryDetails.price.toLocaleString(),
    paymentLink: buybackDetail?.monetaryDetails.paymentLink,
    vestingCutOffDate: formatDisplayDate(
      buybackDetail?.vestingCutOffDate || "1979-01-01"
    ),
    endDate: formatDisplayDate(buybackDetail?.endDate || "1979-01-01"),
    description: buybackDetail?.description,
    willingNess: buybackDetail && buybackDetail?.askWillingness,
    liquidationEndDate: formatDisplayDate(
      buybackDetail?.endDate || "1979-01-01"
    ),
    sellingPrice: buybackDetail && buybackDetail?.monetaryDetails.sellingPrice,
    buyerName: buybackDetail && buybackDetail?.buyerName,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: {},
    onSubmit: () => {},
  });

  return (
    <VStack className="gap-4 p-6">
      <>
        <Box className="flex items-center gap-12">
          <p className="text-lg font-medium text-stone-700">
            {buybackDetail?.eventName}
          </p>
          <StatusLabel state={buybackDetail?.liquidityState ?? ""} />
        </Box>
        <VStack className="gap-12 bg-[#fefefe] rounded drop-shadow-md mx-4 p-6">
          <VStack className="w-4/5 gap-4 mt-4">
            <HStack className="justify-start w-full gap-12">
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Liquidation Program Name</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("name")}
                  />
                </VStack>
              </Box>
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Exercise Start Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("startDate")}
                  />
                </VStack>
              </Box>
            </HStack>
            <HStack className="justify-start w-full gap-12">
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Exercise End Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("endDate")}
                  />
                </VStack>
              </Box>
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Description</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("description")}
                  />
                </VStack>
              </Box>
            </HStack>
            <HStack className="justify-start gap-12 text-sm ">
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Vesting End Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("vestingCutOffDate")}
                  />
                </VStack>
              </Box>
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Liquidation End Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("liquidationEndDate")}
                  />
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </VStack>
      </>
    </VStack>
  );
}

export default LiquidationDetails;
