import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  approveExercise,
  getAllExerciseRequests,
  getExercisableOptionsDetails,
  getExerciseRequestDetails,
  rejectExerciseRequest,
  requestToExercise,
} from "../api/exercise";
import { Result } from "../types/Exercise";
import { queryClient } from "./client";

export function useExercisableOptionsDetails(
  optionHolderId: string,
  date: string
) {
  return useQuery({
    queryKey: ["exercisableOptions"],
    queryFn: async () => {
      const response = await getExercisableOptionsDetails(optionHolderId, date);
      return response.data;
    },
    enabled: false,
  });
}

export function useRequestToExercise() {
  return useMutation({
    mutationKey: "doExerciseRequest",
    mutationFn: requestToExercise,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useExerciseRequestDetails(optionHolderId: string) {
  return useQuery({
    queryKey: ["exerciseRequestDetails"],
    queryFn: async () => (await getExerciseRequestDetails(optionHolderId)).data,
    staleTime: 0,
    retry: false,
    onError: (error: AxiosError<Result>) =>
      toast(`Cannot Approve Exercise Because ${error.response?.data.reason}`, {
        type: "error",
        autoClose: 2000,
      }),
  });
}

export function useApproveExercise() {
  return useMutation({
    mutationKey: "approveExercise",
    mutationFn: approveExercise,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("getAllExerciseRequests");
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("getAllExerciseRequests");
    },
    onError: () => {},
  });
}

export function useRejectExerciseRequest() {
  return useMutation({
    mutationKey: "rejectExerciseRequest",
    mutationFn: rejectExerciseRequest,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllExerciseRequests");
      queryClient.refetchQueries("getAllExerciseRequests");
    },
  });
}

export function useGetAllExerciseRequests() {
  return useQuery({
    queryKey: ["getAllExerciseRequests"],
    queryFn: getAllExerciseRequests,
  });
}
