import { LinearProgress, LinearProgressProps } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getGrantTemplate } from "../../api/Esop";
import FileInput from "../../components/shared/FileInput";
import { Label } from "../../components/shared/InputField";
import Tooltip from "../../components/shared/Tooltip";
import { ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useAddGrantExcel } from "../../queries";
import { useCompanyStore } from "../../store";
import { ValidationObj } from "../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import convertToBase64 from "../../utils/convertToBase64";

interface GrantValidation {
  hasErrors: boolean;
  fileUploaded: boolean;
  fileValidated: boolean;
  data: ValidationObj[][];
}

function GrantsImport() {
  const { companyData } = useCompanyStore();
  const { mutate: addGrantExcel, variables } = useAddGrantExcel();
  const [grantValidation, setGrantValidation] = useState<GrantValidation>({
    fileUploaded: false,
    fileValidated: false,
    hasErrors: false,
    data: [],
  });
  const [response, setResponse] = useState<[]>();
  const [statusIndicatorData, setStatusIndicatorData] =
    useState<LinearProgressProps>({ variant: "determinate", value: 0 });
  const navigate = useNavigate();
  function clearForm(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    e.currentTarget.value = "";
    setResponse(undefined);
    setGrantValidation({
      data: [],
      fileUploaded: false,
      fileValidated: false,
      hasErrors: false,
    });
  }
  function handleChange(e: any) {
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setStatusIndicatorData({
        ...statusIndicatorData,
        variant: "indeterminate",
      });
      convertToBase64(file).then((data) => {
        const base64 = {
          file: data as string,
        };
        addGrantExcel(base64, {
          onSuccess: (data) => {
            setStatusIndicatorData({
              ...statusIndicatorData,
              variant: "determinate",
            });
            toast(data, {
              type: "success",
            });
            navigate("/options/allPlans");
          },
          onError: (error) => {
            setStatusIndicatorData({
              ...statusIndicatorData,
              variant: "determinate",
            });
            if (
              typeof error.response.data.response === "string" &&
              error.response.data.response === "No grants to upload"
            )
              toast("No grants to upload", { type: "error" });
            if (typeof error.response.data.response === "object") {
              toast("Please correct the errors before proceeding", {
                type: "error",
              });
              const { response } = error.response.data;
              setGrantValidation({
                hasErrors: true,
                fileUploaded: true,
                fileValidated: true,
                data: response,
              });
            }
          },
        });
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }
  const downloadTemplate = () => {
    getGrantTemplate().then((uploadTemplate) => {
      const template = uploadTemplate.data.uploadTemplate;
      convertBase64ToBlob(
        template,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ).then((blobObject) => {
        downloadBlobObject(
          blobObject,
          `${companyData?.name} Grant Upload Template.xlsx`
        );
      });
    });
  };

  return (
    <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[600px]">
      <VStack>
        <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
          <h6 className="flex-1">Upload Grants</h6>
          <ButtonPrimary onClick={downloadTemplate}>
            Download Template
          </ButtonPrimary>
        </HStack>
        <HStack className="items-center gap-4 pt-4 ">
          <Label className="text-sm font-normal">Upload Excel File</Label>
          <FileInput
            accept=".xlsx, .xls, .ods"
            onClick={clearForm}
            onChange={handleChange}
            multiple={false}
            type="file"
            file={"Choose File"}
          />
          <LinearProgress
            className="w-1/2 p-1"
            {...statusIndicatorData}
            color="success"
          />
        </HStack>
        <VStack className="w-full h-full gap-6 px-2 py-4 bg-white">
          {grantValidation.fileUploaded && grantValidation.hasErrors && (
            <table className="w-full table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="border-b border-dashed ">
                  {grantValidation.data[0].map((data) => (
                    <th key={data.name}>{data.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {grantValidation.data.map((data, index) => (
                  <tr className="border-b border-dashed " key={index}>
                    {data.map((item) => (
                      <td key={item.name} className="px-2 py-1 text-center">
                        {item.error ? (
                          <Tooltip text={item.error}>
                            <p className="text-xs font-medium text-red-400 cursor-default">
                              {item.value || `{ ${item.name} }`}
                            </p>
                          </Tooltip>
                        ) : (
                          <p className="text-xs font-medium cursor-default text-gray-dark">
                            {item.value}
                          </p>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
}

export default GrantsImport;
