/* eslint-disable no-loop-func */
import create from "zustand";
import { configurePersist } from "zustand-persist";
import _ from "lodash";
import {
  CompanyData,
  CompensationModellingData,
  DepartmentData,
  HiringPlanData,
} from "../pages/esopModeling/companyDetails/utils/OrgStructureJSONDataProc";
import {
  CompensationDepartment,
  Department,
  KeyRoleCompModelling,
  Levels,
} from "../types/modelling";
import { AddEmployeeReq } from "../types/Employee";

const { persist: _persist } = configurePersist({
  storage: localStorage,
});

const persist = _persist as any;

const getLevelMinAndMaxSalary = (
  department: string,
  level: string,
  employeesList: AddEmployeeReq[]
) => {
  const min =
    _.minBy(
      employeesList
        .filter((employee) => employee.department === department)
        .filter((emp) => emp.grade === level),
      (e) => e.salary
    )?.salary || 0;
  const max =
    _.maxBy(
      employeesList
        .filter((employee) => employee.department === department)
        .filter((emp) => emp.grade === level),
      (e) => e.salary
    )?.salary || 0;
  return { min, max };
};

const getLevelEmpCount = (
  department: string,
  level: string,
  employeesList: AddEmployeeReq[]
) => {
  const employees = employeesList
    .filter((employee) => employee.department === department)
    .filter((emp) => emp.grade === level);

  return employees.length;
};

const getRoleMinAndMaxSalary = (
  department: string,
  level: string,
  role: string,
  employeesList: AddEmployeeReq[]
) => {
  const min =
    _.minBy(
      employeesList
        .filter((employee) => employee.department === department)
        .filter((emp) => emp.grade === level)
        .filter((empl) => empl.employeeDesignation === role),
      (e) => e.salary
    )?.salary || 0;
  const max =
    _.maxBy(
      employeesList
        .filter((employee) => employee.department === department)
        .filter((emp) => emp.grade === level)
        .filter((empl) => empl.employeeDesignation === role),
      (e) => e.salary
    )?.salary || 0;
  return { min, max };
};

const getRoleEmpCount = (
  department: string,
  level: string,
  role: string,
  employeesList: AddEmployeeReq[]
) => {
  const employees = employeesList
    .filter((employee) => employee.department === department)
    .filter((emp) => emp.grade === level)
    .filter((empl) => empl.employeeDesignation === role);
  return employees.length;
};

const initialState = {
  selectedCompany: undefined,
  selectedIndustry: undefined,
  selectedCompanyData: undefined,
};

type OrgTemplateState = {
  selectedCompany: string | undefined;
  selectedIndustry: string | undefined;
  selectedCompanyData: CompanyData | undefined;
  employeeListUploaded: boolean | undefined;
  setEmployeeListUploaded: (employeeList: boolean) => void;
  setSelectedCompany: (selectedCompany: string | undefined) => void;
  hiringPlanData: HiringPlanData | undefined;
  setHiringPlanData: (hiringPlanData: HiringPlanData | undefined) => void;
  compensationModellingData: CompensationModellingData | undefined;
  setCompensationModellingData: (
    compensationModellingData: CompensationModellingData | undefined
  ) => void;
  setSelectedIndustry: (selectedIndustry: string | undefined) => void;
  setSelectedCompanyData: (
    selectedCompanyData: CompanyData | undefined
  ) => void;
  addDepartmentToCompanyData: (addDepartment: Department) => void;
  deleteDepartmentFromCompanyData: (name: string) => void;
  addLevelDataToCompanyData: (addLevel: Levels) => void;
  deleteLevelDatafromCompanyData: (
    levelName: string,
    departmentName: string
  ) => void;
  addorDeleteKeyRoleToCompanyData: (
    department: string,
    level: string,
    role: string,
    keyRole: boolean
  ) => void;
  addorDeleteKeyPersonnelToCompanyData: (
    department: string,
    level: string,
    role: string,
    employeeId: string,
    keyPersonnel: boolean
  ) => void;
  addorDeleteKeyPersonnelToCompensationModelling: (
    department: string,
    level: string,
    role: string,
    employeeId: string,
    keyPersonnel: boolean,
    percentage: number
  ) => void;
  addorDeleteKeyRoleEmployeeCountToCompensationModelling: (
    keyRoleData: KeyRoleCompModelling
  ) => void;
  clear: () => void;
  addCompensationGeneralDataToModelling: (
    addData: CompensationDepartment
  ) => void;
  ppsOfCompany: number;
  dateOfValuation: Date;
  setPpsOfCompany: (pps: number) => void;
  setYoyGrowthOfCompany: (percentage: number) => void;
  setPpsDateOfCompany: (date: Date) => void;
  clearGeneralDataFromModelling: (department: CompensationDepartment) => void;
  addEmployeesDataToCompanyData: (employeesList: AddEmployeeReq[]) => void;
};

export const useOrgTemplateStore = create<OrgTemplateState>(
  persist(
    { key: "orgData", name: "orgData", storage: localStorage },
    (set: any) => ({
      ...initialState,
      setSelectedCompany: (selectedCompany: string | undefined) => {
        set({ selectedCompany });
      },
      setSelectedIndustry: (selectedIndustry: string | undefined) => {
        set({ selectedIndustry });
      },
      setSelectedCompanyData: (
        selectedCompanyData: CompanyData | undefined
      ) => {
        set({ selectedCompanyData });
      },
      setEmployeeListUploaded: (employeeListUploaded: boolean | undefined) => {
        set((state: OrgTemplateState) => ({
          ...state,
          selectedCompanyData: {
            ...state.selectedCompanyData,
            employeeListUploaded,
          },
        }));
      },
      setHiringPlanData: (hiringPlanData: HiringPlanData | undefined) => {
        set({ hiringPlanData });
      },
      setCompensationModellingData: (
        compensationModellingData: CompensationModellingData | undefined
      ) => {
        set({ compensationModellingData });
      },
      setPpsOfCompany: (pps: number) => {
        set((state: OrgTemplateState) => ({
          ...state,
          compensationModellingData: {
            ...state.compensationModellingData,
            data: {
              ...state.compensationModellingData?.data,
              ppsOfCompany: pps,
            },
          },
        }));
      },
      setYoyGrowthOfCompany: (percentage: number) => {
        set((state: OrgTemplateState) => ({
          ...state,
          compensationModellingData: {
            ...state.compensationModellingData,
            data: {
              ...state.compensationModellingData?.data,
              yoyGrowth: percentage,
            },
          },
        }));
      },
      setPpsDateOfCompany: (date: Date) => {
        set((state: OrgTemplateState) => ({
          ...state,
          compensationModellingData: {
            ...state.compensationModellingData,
            data: {
              ...state.compensationModellingData?.data,
              dateOfValuation: date,
            },
          },
        }));
      },

      addDepartmentToCompanyData: (addDepartment: Department) => {
        set((state: OrgTemplateState) => ({
          ...state,
          selectedCompanyData: {
            ...state.selectedCompanyData,
            departments: {
              ...(state.selectedCompanyData?.departments || {}),
              [addDepartment.name]: {
                identifier: addDepartment.identifier,
                levels: {},
              },
            },
          },
        }));
      },

      deleteDepartmentFromCompanyData: (name: string) => {
        set((state: OrgTemplateState) => {
          if (state.selectedCompanyData) {
            const updatedDepartments = {
              ...state.selectedCompanyData.departments,
            };

            delete updatedDepartments[name];

            return {
              ...state,
              selectedCompanyData: {
                ...state.selectedCompanyData,
                departments: updatedDepartments,
              },
            };
          }

          return state;
        });
      },

      addLevelDataToCompanyData: (level: Levels) => {
        const roles = level.role.split(",");
        let rolesData = {};
        roles.forEach((role) => {
          rolesData = {
            ...rolesData,
            [role]: {
              keyRole: false,
              salaryRange: {
                min: level.salaryRange.min,
                max: level.salaryRange.max,
              },
            },
          };
        });
        set((state: OrgTemplateState) => {
          if (state.selectedCompanyData) {
            const departmentName = level.department;

            if (
              Object.prototype.hasOwnProperty.call(
                state.selectedCompanyData.departments,
                level.department
              )
            ) {
              const updatedDepartments = {
                ...state.selectedCompanyData.departments,
                [departmentName]: {
                  ...state.selectedCompanyData.departments[departmentName],
                  levels: {
                    ...state.selectedCompanyData.departments[departmentName]
                      .levels,
                    [level.level]: {
                      salaryRange: level.salaryRange,
                      roles: rolesData,
                    },
                  },
                },
              };

              return {
                ...state,
                selectedCompanyData: {
                  ...state.selectedCompanyData,
                  departments: updatedDepartments,
                },
              };
            }
          }
          return state;
        });
      },

      deleteLevelDatafromCompanyData: (
        levelName: string,
        departmentName: string
      ) => {
        set((state: OrgTemplateState) => {
          if (state.selectedCompanyData) {
            const updatedDepartments = {
              ...state.selectedCompanyData.departments,
            };

            delete updatedDepartments[departmentName].levels[levelName];

            return {
              ...state,
              selectedCompanyData: {
                ...state.selectedCompanyData,
                departments: updatedDepartments,
              },
            };
          }

          return state;
        });
      },

      addorDeleteKeyRoleToCompanyData: (
        department: string,
        level: string,
        role: string,
        keyRole: boolean
      ) => {
        set((state: OrgTemplateState) => {
          if (state.selectedCompanyData) {
            if (
              Object.prototype.hasOwnProperty.call(
                state.selectedCompanyData.departments,
                department
              )
            ) {
              const updatedDepartments = {
                ...state.selectedCompanyData.departments,
                [department]: {
                  ...state.selectedCompanyData.departments[department],
                  levels: {
                    ...state.selectedCompanyData.departments[department].levels,
                    [level]: {
                      ...state.selectedCompanyData.departments[department]
                        .levels[level],
                      roles: {
                        ...state.selectedCompanyData.departments[department]
                          .levels[level].roles,
                        [role]: {
                          ...state.selectedCompanyData.departments[department]
                            .levels[level].roles[role],
                          keyRole,
                        },
                      },
                    },
                  },
                },
              };

              return {
                ...state,
                selectedCompanyData: {
                  ...state.selectedCompanyData,
                  departments: updatedDepartments,
                },
              };
            }
          }
          return state;
        });
      },

      addorDeleteKeyPersonnelToCompanyData: (
        department: string,
        level: string,
        role: string,
        id: string,
        keyPersonnel: boolean
      ) => {
        set((state: OrgTemplateState) => {
          if (state.selectedCompanyData) {
            if (
              Object.prototype.hasOwnProperty.call(
                state.selectedCompanyData.departments,
                department
              )
            ) {
              const updatedDepartments = {
                ...state.selectedCompanyData.departments,
                [department]: {
                  ...state.selectedCompanyData.departments[department],
                  levels: {
                    ...state.selectedCompanyData.departments[department].levels,
                    [level]: {
                      ...state.selectedCompanyData.departments[department]
                        .levels[level],
                      roles: {
                        ...state.selectedCompanyData.departments[department]
                          .levels[level].roles,
                        [role]: {
                          ...state.selectedCompanyData.departments[department]
                            .levels[level].roles[role],
                          employees: {
                            ...(state.selectedCompanyData.departments[
                              department
                            ].levels[level].roles[role]?.employees ?? {}),
                            [id]: {
                              ...(state.selectedCompanyData.departments[
                                department
                              ].levels[level].roles[role]?.employees?.[id] ??
                                {}),
                              keyPersonnel,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              };

              return {
                ...state,
                selectedCompanyData: {
                  ...state.selectedCompanyData,
                  departments: updatedDepartments,
                },
              };
            }
          }
          return state;
        });
      },

      addorDeleteKeyPersonnelToCompensationModelling: (
        department: string,
        level: string,
        role: string,
        id: string,
        keyPersonnel: boolean,
        percentage: number
      ) => {
        set((state: OrgTemplateState) => {
          if (
            state.compensationModellingData &&
            state.compensationModellingData.data
          ) {
            if (
              Object.prototype.hasOwnProperty.call(
                state.compensationModellingData.data.departments,
                department
              )
            ) {
              const updatedDepartments = {
                ...state.compensationModellingData.data.departments,
                [department]: {
                  ...state.compensationModellingData.data.departments[
                    department
                  ],
                  levels: {
                    ...state.compensationModellingData.data.departments[
                      department
                    ].levels,
                    [level]: {
                      ...state.compensationModellingData.data.departments[
                        department
                      ].levels[level],
                      roles: {
                        ...state.compensationModellingData.data.departments[
                          department
                        ].levels[level].roles,
                        [role]: {
                          ...state.compensationModellingData.data.departments[
                            department
                          ].levels[level].roles[role],
                          employees: {
                            ...(state.compensationModellingData.data
                              .departments[department].levels[level].roles[role]
                              ?.employees ?? {}),
                            [id]: {
                              ...(state.compensationModellingData.data
                                .departments[department].levels[level].roles[
                                role
                              ]?.employees?.[id] ?? {}),
                              keyPersonnel,
                              percentageOfSalary:
                                percentage === 0
                                  ? state.compensationModellingData.data
                                      .departments[department].levels[level]
                                      .percentageOfSalary
                                  : percentage,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              };

              return {
                ...state,
                compensationModellingData: {
                  ...state.compensationModellingData,
                  data: {
                    ...state.compensationModellingData.data,
                    departments: updatedDepartments,
                  },
                },
              };
            }
          }
          return state;
        });
      },

      addCompensationGeneralDataToModelling(addData: CompensationDepartment) {
        set((state: OrgTemplateState) => {
          if (state.compensationModellingData) {
            if (
              state.compensationModellingData.data &&
              Object.prototype.hasOwnProperty.call(
                state.compensationModellingData.data.departments,
                addData.department
              )
            ) {
              const { department, percentageOfSalary, levelsData } = addData;
              let updatedDepartments = _.cloneDeep(
                state.compensationModellingData.data.departments
              );
              for (const levelObj of levelsData) {
                const level = Object.keys(levelObj)[0];
                const { percentage, count } = levelObj[level];
                updatedDepartments = {
                  ...updatedDepartments,
                  [department]: {
                    ...updatedDepartments[department],
                    percentageOfSalary,
                    levels: {
                      ...updatedDepartments[department].levels,
                      [level]: {
                        ...updatedDepartments[department].levels[level],
                        percentageOfSalary: percentage,
                        totalEmployeeCount: count,
                        roles: Object.keys(
                          updatedDepartments[department].levels[level].roles
                        ).reduce((roles: any, roleName: any) => {
                          roles[roleName] = {
                            ...updatedDepartments[department].levels[level]
                              .roles[roleName],
                            percentageOfSalary: percentage,
                            employeeCount: count,
                            ...(state.compensationModellingData?.data
                              ?.employeeListUploaded && {
                              employees: Object.entries(
                                state.compensationModellingData.data
                                  .departments[department].levels[level].roles[
                                  roleName
                                ].employees
                              ).reduce((acc: any, [empId, empData]) => {
                                const employee = empData;
                                acc[empId] = {
                                  ...employee,
                                  percentageOfSalary,
                                };
                                return acc;
                              }, {}),
                            }),
                          };
                          return roles;
                        }, {}),
                      },
                    },
                  },
                };
              }
              return {
                ...state,
                compensationModellingData: {
                  ...state.compensationModellingData,
                  data: {
                    ...state.compensationModellingData.data,
                    departments: updatedDepartments,
                  },
                },
              };
            }
          }
          return state;
        });
      },

      clearGeneralDataFromModelling(addData: CompensationDepartment) {
        set((state: OrgTemplateState) => {
          if (state.compensationModellingData) {
            if (
              state.compensationModellingData.data &&
              Object.prototype.hasOwnProperty.call(
                state.compensationModellingData.data.departments,
                addData.department
              )
            ) {
              const { department, levelsData } = addData;
              let updatedDepartments = _.cloneDeep(
                state.compensationModellingData.data.departments
              );
              for (const levelObj of levelsData) {
                const level = Object.keys(levelObj)[0];
                updatedDepartments = {
                  ...updatedDepartments,
                  [department]: {
                    ...updatedDepartments[department],
                    percentageOfSalary: 0,
                    levels: {
                      ...updatedDepartments[department].levels,
                      [level]: {
                        ...updatedDepartments[department].levels[level],
                        percentageOfSalary: 0,
                        totalEmployeeCount: 0,
                        roles: Object.keys(
                          updatedDepartments[department].levels[level].roles
                        ).reduce((roles: any, roleName: any) => {
                          roles[roleName] = {
                            ...updatedDepartments[department].levels[level]
                              .roles[roleName],
                            percentageOfSalary: 0,
                            employeeCount: 0,
                          };
                          return roles;
                        }, {}),
                      },
                    },
                  },
                };
              }
              return {
                ...state,
                compensationModellingData: {
                  ...state.compensationModellingData,
                  data: {
                    ...state.compensationModellingData.data,
                    departments: updatedDepartments,
                  },
                },
              };
            }
          }
          return state;
        });
      },

      addorDeleteKeyRoleEmployeeCountToCompensationModelling: (
        keyRole: KeyRoleCompModelling
      ) => {
        const {
          department,
          level,
          role,
          employeeCount: count,
          percentageOfSalary,
          key,
        } = keyRole;

        set((state: OrgTemplateState) => {
          if (
            state.compensationModellingData &&
            state.compensationModellingData.data
          ) {
            if (
              Object.prototype.hasOwnProperty.call(
                state.compensationModellingData.data.departments,
                department
              )
            ) {
              const updatedDepartments = {
                ...state.compensationModellingData.data.departments,
                [department]: {
                  ...state.compensationModellingData.data.departments[
                    department
                  ],
                  levels: {
                    ...state.compensationModellingData.data.departments[
                      department
                    ].levels,
                    [level]: {
                      ...state.compensationModellingData.data.departments[
                        department
                      ].levels[level],
                      roles: {
                        ...state.compensationModellingData.data.departments[
                          department
                        ].levels[level].roles,
                        [role]: {
                          ...state.compensationModellingData.data.departments[
                            department
                          ].levels[level].roles[role],
                          employeeCount: count,
                          percentageOfSalary:
                            percentageOfSalary === 0
                              ? state.compensationModellingData.data
                                  .departments[department].levels[level]
                                  .percentageOfSalary
                              : percentageOfSalary,
                          keyRole: key || false,
                          ...(state.compensationModellingData?.data
                            ?.employeeListUploaded && {
                            employees: Object.entries(
                              state.compensationModellingData.data.departments[
                                department
                              ].levels[level].roles[role].employees
                            ).reduce((acc: any, [empId, empData]) => {
                              const employee = empData;
                              acc[empId] = {
                                ...employee,
                                percentageOfSalary,
                              };
                              return acc;
                            }, {}),
                          }),
                        },
                      },
                    },
                  },
                },
              };

              return {
                ...state,
                compensationModellingData: {
                  ...state.compensationModellingData,
                  data: {
                    ...state.compensationModellingData.data,
                    departments: updatedDepartments,
                  },
                },
              };
            }
          }
          return state;
        });
      },

      addEmployeesDataToCompanyData: (employeesList: AddEmployeeReq[]) => {
        set((state: OrgTemplateState) => {
          const departments: { [departmentName: string]: DepartmentData } = {};

          employeesList.forEach((employee) => {
            const {
              department,
              grade,
              employeeIdentification,
              employeeDesignation,
            } = employee;

            if (!departments[department]) {
              departments[department] = {
                identifier: department.charAt(0),
                percentageOfSalary: 0,
                levels: {},
              };
            }

            if (!departments[department].levels[grade]) {
              departments[department].levels[grade] = {
                salaryRange: getLevelMinAndMaxSalary(
                  department,
                  grade,
                  employeesList
                ),
                currentEmployeeCount: getLevelEmpCount(
                  department,
                  grade,
                  employeesList
                ),
                totalEmployeeCount: 1,
                percentageOfSalary: 0,
                yearlyData: {},
                roles: {},
              };
            }

            if (
              !departments[department].levels[grade].roles[employeeDesignation]
            ) {
              departments[department].levels[grade].roles[employeeDesignation] =
                {
                  keyRole: false,
                  percentageOfSalary: 0,
                  employeeCount: getRoleEmpCount(
                    department,
                    grade,
                    employeeDesignation,
                    employeesList
                  ),
                  salaryRange: getRoleMinAndMaxSalary(
                    department,
                    grade,
                    employeeDesignation,
                    employeesList
                  ),
                  employees: {},
                };
            }

            if (
              !departments[department].levels[grade].roles[employeeDesignation]
                ?.employees[employeeIdentification]
            ) {
              departments[department].levels[grade].roles[
                employeeDesignation
              ].employees[employeeIdentification] = {
                employeeData: employee,
                keyPersonnel: false,
                salary: employee.salary || 0,
                percentageOfSalary: 0,
              };
            }
          });

          const newCompanyData: CompanyData = {
            teamSize: employeesList.length,
            companyName: employeesList[0].company,
            departments,
            employeeListUploaded: true,
          };

          return {
            selectedCompanyData: newCompanyData,
          };
        });
      },

      clear() {
        set((state: OrgTemplateState) => initialState);
      },
    })
  )
);
