export interface ListofCompanies {
  defaultCompany: DefaultCompany;
  associatedCompanies: AssociatedCompany[];
}

export interface DefaultCompany {
  name: string;
  id: string;
  captableCompanyId: any;
  parentCompanyId: any;
  onboardingStatus: string;
  companyStatus: string;
}

export interface AssociatedCompany {
  name: string;
  id: string;
  captableCompanyId: any;
  parentCompanyId: any;
  onboardingStatus: string;
  companyStatus: string;
}
export interface Permission {
  aclList: AclList[];
  role: string;
  isCompanySpecific: boolean;
}

export interface AclList {
  action: Action;
  resource: Resource;
}

export enum Resource {
  AuditLog = "audit_log",
  Company = "company",
  CompanyMetaData = "company_meta_data",
  CompanySettings = "company_settings",
  Document = "document",
  DocumentTemplate = "document_template",
  EmailTemplate = "email_template",
  Employee = "employee",
  EmployeeMetaData = "employee_meta_data",
  Grant = "grant",
  GrantTransaction = "grant_transaction",
  MonetaryOffer = "monetary_offer",
  MonetaryTransactionDetails = "monetary_transaction_details",
  NomineeDetails = "nominee_details",
  OptionsPlan = "options_plan",
  OptionsPool = "options_pool",
  Permission = "permission",
  PlanTransaction = "plan_transaction",
  PoolTransaction = "pool_transaction",
  ProjectedVesting = "projected_vesting",
  Role = "role",
  StockTransactionDetails = "stock_transaction_details",
  UserCompanyRole = "user_company_role",
  Valuation = "valuation",
  Vesting = "vesting",
  VestingSchedule = "vesting_schedule",
  All = "*",
}
export enum Action {
  Read = "read",
  Create = "create",
  Edit = "edit",
  Delete = "delete",
  All = "*",
  STATE_CHANGE = "state_change",
}

export interface AssociatedUsers {
  id?: string;
  email: string;
  role: UserRole;
  name: string;
  isCompanySpecificRole: boolean;
  userSince: string;
  newlyAdded?: boolean;
  edited?: boolean;
  userId?: bigint;
  toBeDeleted?: boolean;
}

export interface UserRole {
  name?: string;
  id?: string;
}
export interface Roles {
  id: string;
  name: string;
  companyId?: string;
  permissions: DefaultPermission[];
}
export interface DefaultPermission {
  id: string;
  name: string;
  description: string;
  acl: AclList;
}
