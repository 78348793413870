/*eslint arrow-body-style: [0]*/
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import {
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  CheckBoxWithLabel,
  Input,
  Label,
  TextArea,
} from "../../components/shared/InputField";
import {
  useEsopPlans,
  usesyncHrms,
  useTestHrmsConfig,
  useVestingTemplates,
} from "../../queries";
import { useValuationDetails } from "../../queries/Valuation";
import { Select } from "../../components/shared/Select";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { useCompanyStore } from "../../store";

type DarwinBoxDatasetConfig = {
  username: string;
  password: string;
  apiKey: string;
  datasetKey: string;
  domain: string;
  esopPlanId?: string;
  vestingScheduleId?: string;
  valuationId?: string;
};
function DarwinBoxDatasetImport(props: { resetData: () => void }) {
  const { resetData } = props;
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const [consentGiven, setConsentGiven] = useState(false);
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const { data: planData } = useEsopPlans();
  const { data: vestingSchedules } = useVestingTemplates();
  const { data: valuations } = useValuationDetails();
  const { mutate: testDarwinBoxConfig } = useTestHrmsConfig();
  const { mutate: syncDarwinBox } = usesyncHrms();
  const { companyData } = useCompanyStore();
  const [hasGrantInformation, setHasGrantInformation] = useState(false);
  const [darwinboxData, setDarwinboxData] = useState<{
    type: string;
    credentialData: DarwinBoxDatasetConfig;
  }>({
    type: "DarwinboxDataset",
    credentialData: {
      apiKey: "",
      datasetKey: "",
      domain: "",
      password: "",
      username: "",
      esopPlanId: undefined,
      vestingScheduleId: undefined,
      valuationId: undefined,
    },
  });
  const testCredentials = () => {
    setOperationInProgress(true);
    const credentialData = darwinboxData.credentialData;
    if (
      !credentialData.apiKey ||
      !credentialData.datasetKey ||
      !credentialData.password ||
      !credentialData.username
    ) {
      toast("Please fill all the fields", { autoClose: 2000, type: "error" });
      setOperationInProgress(false);
      return;
    }
    testDarwinBoxConfig(darwinboxData, {
      onSuccess: (data: { hasGrantInformation: boolean }) => {
        if (data.hasGrantInformation) {
          toast(
            "Data has Grant related information, please select a Plan, Vesting Schedule and a Valuation",
            {
              type: "info",
              autoClose: 3000,
            }
          );
          setHasGrantInformation(true);
        }
        setCredentialsValid(true);
        toast("Credentials are valid, proceed to import", {
          type: "success",
          autoClose: 3000,
        });
        setOperationInProgress(false);
      },
      onError: () => {
        setCredentialsValid(false);
        toast("Credentials are Invalid, please check again", {
          type: "error",
          autoClose: 3000,
        });
        setOperationInProgress(false);
      },
    });
  };
  const importData = () => {
    setOperationInProgress(true);
    syncDarwinBox(darwinboxData, {
      onSuccess: () => {
        toast("Successfully imported records, you'll now be redirected", {
          type: "success",
          autoClose: 3000,
        });
        setTimeout(() => {
          navigate("/options/allEmployees");
        }, 2000);
        setOperationInProgress(false);
      },
      onError: () => {
        toast("Encountered error while importing", {
          type: "error",
          autoClose: 3000,
        });
        setOperationInProgress(false);
      },
    });
  };
  const handleConfigChange = (
    field: keyof DarwinBoxDatasetConfig,
    value: string
  ) => {
    setDarwinboxData((prevConfig) => ({
      ...prevConfig,
      credentialData: {
        ...prevConfig.credentialData,
        [field]: value,
      },
    }));
  };
  return (
    <HStack key="darwinbox-import">
      <VStack className="justify-between w-full">
        <Box className="justify-start w-full ">
          <h6 className="flex justify-start row text-lg font-medium pb-1">
            Enter Your Darwinbox Credentials
          </h6>
          <p className="text-xxs">
            Enter your Dataset details to pull data from darwinbox
          </p>
        </Box>
        <HStack className="gap-8 pt-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Host Name</Label>
            <Input
              autoComplete="false"
              type="text"
              required
              disabled={credentialsValid}
              name=""
              value={darwinboxData.credentialData?.domain}
              placeholder="eg : *.darwinbox.in"
              onChange={(e) => handleConfigChange("domain", e.target.value)}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">API Username</Label>
            <Input
              type="text"
              name="username"
              required
              value={darwinboxData.credentialData?.username}
              disabled={credentialsValid}
              onChange={(e) => handleConfigChange("username", e.target.value)}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">API Password</Label>
            <Input
              type="password"
              name="api_password"
              required
              disabled={credentialsValid}
              value={darwinboxData.credentialData?.password}
              onChange={(e) => handleConfigChange("password", e.target.value)}
            />
          </div>
        </HStack>
        <HStack className="gap-8 pt-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">API Key</Label>
            <TextArea
              name="api_key"
              required
              disabled={credentialsValid}
              value={darwinboxData.credentialData?.apiKey}
              onChange={(e) => handleConfigChange("apiKey", e.target.value)}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Dataset Key</Label>
            <TextArea
              name="datasetKey"
              required
              disabled={credentialsValid}
              value={darwinboxData.credentialData?.datasetKey}
              onChange={(e) => handleConfigChange("datasetKey", e.target.value)}
            />
          </div>
        </HStack>
        {hasGrantInformation && (
          <HStack className="gap-8 pt-8 border-t-2 mt-6">
            <div className="flex-1">
              <Label className="text-sm font-normal">Plan Name</Label>
              <Select
                name="esopPlanId"
                required
                value={darwinboxData.credentialData?.esopPlanId}
                onChange={(e) =>
                  handleConfigChange("esopPlanId", e.target.value)
                }
                options={
                  planData?.map((plan) => {
                    return { id: plan.esopPlanId, name: plan.planName };
                  }) || []
                }
                textGetter={(e) => e.name}
                valueGetter={(e) => e.id}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Vesting Schedule</Label>
              <Select
                name="vestingScheduleId"
                required
                value={darwinboxData.credentialData?.vestingScheduleId}
                onChange={(e) =>
                  handleConfigChange("vestingScheduleId", e.target.value)
                }
                options={
                  vestingSchedules?.map((schedule) => {
                    return {
                      id: schedule.id,
                      name: schedule.vestingTemplateName,
                    };
                  }) || []
                }
                textGetter={(e) => e.name}
                valueGetter={(e) => e.id}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Valuation</Label>
              <Select
                name="valuationId"
                required
                value={darwinboxData.credentialData?.valuationId}
                onChange={(e) =>
                  handleConfigChange("valuationId", e.target.value)
                }
                options={
                  valuations?.map((valuation) => {
                    return {
                      id: valuation.id,
                      name: `${valuation.name} -  PPS: ${formatCurrency(
                        valuation.pps,
                        currency
                      )}`,
                    };
                  }) || []
                }
                textGetter={(e) => e.name}
                valueGetter={(e) => e.id}
              />
            </div>
          </HStack>
        )}
        <HStack className="mt-8">
          <CheckBoxWithLabel
            label="By checking this box, I authorize HISSA to access my Employee data from Darwinbox."
            labelclassname={"ml-2"}
            onChange={(e) => setConsentGiven(e.target.checked)}
          />
        </HStack>
        <HStack className="justify-end gap-4 p-3">
          <ButtonPrimary1
            type="reset"
            className="text-red-500"
            onClick={() => {
              resetData();
            }}
          >
            Cancel
          </ButtonPrimary1>
          {operationInProgress ? (
            <Icon
              className="animate-spin text-orange-501"
              icon="lucide:loader-2"
              width={36}
            />
          ) : (
            <>
              {!credentialsValid ? (
                <ButtonPrimary
                  type="reset"
                  disabled={!consentGiven}
                  className="text-red-500"
                  onClick={() => {
                    testCredentials();
                  }}
                >
                  Test
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  type="reset"
                  disabled={!consentGiven}
                  className="text-red-500"
                  onClick={() => {
                    importData();
                  }}
                >
                  Import
                </ButtonPrimary>
              )}
            </>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
}

export default DarwinBoxDatasetImport;
