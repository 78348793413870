import React from "react";
import { VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";

import TransactionSummary from "./TransactionSummary";
import { MonetaryTransactionType } from "../../types/OptionBuyback";

export type BuybackType = {
  name: string;
  value: MonetaryTransactionType | null;
  disabled: boolean;
  type?: string;
  tooltipText?: string;
};

function BuybackIndex() {
  return (
    <VStack className="gap-4">
      <BCHeader
        bcTitle="Liquidity Event Planning and Administration"
        className="items-baseline"
        bcDescription="Plan and execute liquidity events with detailed insights into buyback opportunities, sales, and employee participation options."
      />
      <TransactionSummary />
    </VStack>
  );
}

export default BuybackIndex;
