import { useEffect, useRef, useState } from "react";
import { Editor as TinyEditor } from "@tinymce/tinymce-react";
import Editor, { useMonaco, loader, EditorProps } from "@monaco-editor/react";

import {
  VStack,
  HStack,
  ButtonPrimary,
  ButtonSecondary,
  isEsopViewer,
} from "../../components/utils";
import { Label, Radio } from "../../components/shared/InputField";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Resource,
  Action as DefaultAction,
} from "../../utils/interfaces/Companies";
import { usePermissionStore } from "../../store";

interface DialogProps {
  templateName: string;
  templateContent: string;
  onClose: (editedData?: string) => void;
}
interface TemplateContent {
  templateHeader?: string;
  templateBody?: string;
  templateFooter?: string;
}
function EmailTemplateEditorDialog(props: DialogProps) {
  const { templateName, templateContent, onClose } = props;
  const { permission } = usePermissionStore();
  const insertMode: "insert" | "replace" = "insert";
  const suggestMode: "prefix" | "subword" | "subwordSmart" | undefined =
    "prefix";
  const autoClosingBrackets: "always" = "always";
  const language: "html" = "html";
  const suggestSelection: "first" = "first";
  const monaco = useMonaco();
  const isUserEsopViewer = isEsopViewer();
  const editorRef = useRef<TinyEditor["editor"]>();
  const monacoEditorOptions = {
    autoClosingBrackets,
    inlineSuggest: {
      enabled: true,
      mode: suggestMode,
    },
    language,
    suggest: {
      insertMode,
    },
    suggestSelection,
  };
  const [editMode, setEditMode] = useState("Classic");
  const [separatedTemplateContent, setSeparatedTemplateContent] =
    useState<TemplateContent>({});
  const tearDownEditorAndClose = () => {
    if (editMode === "Classic") {
      const body = editorRef.current?.getContent();
      const emailTemplateEditedContent = separatedTemplateContent.templateHeader
        ?.concat(body as string)
        .concat(separatedTemplateContent.templateFooter as string);
      onClose(emailTemplateEditedContent);
    } else if (monaco) {
      const body = monaco?.editor?.getEditors()[0].getValue();
      const emailTemplateEditedContent = separatedTemplateContent.templateHeader
        ?.concat(body as string)
        .concat(separatedTemplateContent.templateFooter as string);
      onClose(emailTemplateEditedContent);
    }
  };
  useEffect(() => {
    const emailTemplateData = templateContent;
    const headerIndex = emailTemplateData.indexOf("<!-- body start -->");
    const header = emailTemplateData.substring(0, headerIndex);
    const footerIndex = emailTemplateData.indexOf("<!-- body end -->");
    const footer = emailTemplateData.substring(footerIndex);
    const body = emailTemplateData.substring(headerIndex, footerIndex);
    setSeparatedTemplateContent({
      templateBody: body,
      templateFooter: footer,
      templateHeader: header,
    });
  }, [templateContent]);
  function handleEditorDidMount(editor: any, monaco: any) {
    if (monaco)
      monaco.languages.registerCompletionItemProvider("html", {
        triggerCharacters: ["?"],
        // eslint-disable-next-line consistent-return
        provideCompletionItems: async (model: any, position: any) => {
          const wordBeforePosition = model.getWordUntilPosition({
            lineNumber: position.lineNumber,
            column: position.column - 1,
          });
          const lineContent = model.getLineContent(position.lineNumber);
          const prefix = lineContent.slice(0, position.column - 1);
          const lastItemIsVariable = lineContent?.trim();
          const wordUntilPosition = model.getWordUntilPosition(position);
          if (
            wordBeforePosition.word.trim() === "" ||
            wordUntilPosition.word.trim() === "#"
          ) {
            const keywords = completionTriggerKeywords;

            const suggestions = keywords.map((id) => ({
              label: id.label,
              kind: id.kind,
              description: id.description,
              documentation: id.description,
              insertText: id.insertText,
              detail: id.description,
              insertTextRules: id.insertTextRules,
              range: {
                startLineNumber: position.lineNumber,
                startColumn: wordUntilPosition.startColumn,
                endLineNumber: position.lineNumber,
                endColumn: wordUntilPosition.endColumn - 1,
              },
            }));
            return { suggestions };
          }
        },
      });

    const completionTriggerKeywords = [
      {
        label: "Username",
        kind: monaco.languages.CompletionItemKind.Text,
        insertText: "#user.name#",
        description: "Name of the user",
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      },
      {
        label: "Employee name",
        kind: monaco.languages.CompletionItemKind.Text,
        insertText: "#employee.name#",
        description: "Name of the Employee",
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      },
      {
        label: "Test3",
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: "Test3",
        description: "3.1, 3.2, 3.3",
      },
    ];
  }

  return (
    <div>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium border-b stone-700 ">
        <h6 className="flex justify-between">
          Edit {templateName}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-2 p-2 px-10 ">
        Editor Mode
        <HStack className="items-center">
          <VStack className="justify-between gap-2 p-2 px-10 ">
            <span>
              <input
                type="radio"
                className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer `}
                checked={editMode === "Classic"}
                onChange={(e) => setEditMode("Classic")}
              />
              <Label className="mt-2">Classic</Label>
            </span>
          </VStack>
          <VStack className="justify-between gap-2 p-2 px-10 ">
            <span>
              <input
                type="radio"
                className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer `}
                checked={editMode === "HTML"}
                onChange={(e) => setEditMode("HTML")}
              />
              <Label className="mt-2">Advanced</Label>
            </span>
          </VStack>
        </HStack>
        <HStack>
          {editMode === "Classic" && (
            <TinyEditor
              apiKey="l8zomkw48zb8qhvwpahb2g5nodmnma6qjyz1acf2y14t53z6"
              onInit={(_evt, editor) => {
                editorRef.current = editor;
              }}
              initialValue={separatedTemplateContent.templateBody}
              init={{
                height: 500,
                menubar: false,
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          )}
          {editMode === "HTML" && (
            <Editor
              onMount={handleEditorDidMount}
              options={monacoEditorOptions}
              language="html"
              height="50vh"
              defaultLanguage="html"
              theme=""
              value={separatedTemplateContent.templateBody}
            />
          )}
        </HStack>
        {!isUserEsopViewer && (
          <HStack className="flex flex-row-reverse gap-4">
            <ButtonPrimary
              className={`${
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              } flex items-center self-end justify-center`}
              onClick={() => {
                tearDownEditorAndClose();
              }}
              disabled={
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
              }
            >
              Save
            </ButtonPrimary>
            <ButtonSecondary
              onClick={() => onClose()}
              className="text-gray-400 bg-slate-50"
            >
              Cancel
            </ButtonSecondary>
          </HStack>
        )}
      </VStack>
    </div>
  );
}

export default EmailTemplateEditorDialog;
