export enum DocumentType {
  ESOP_PLAN_DOCUMENT = "ESOP_PLAN_DOCUMENT",
  ESOP_BOARD_DOCUMENT = "ESOP_BOARD_DOCUMENT",
  OTHERS = "OTHERS",
}

export interface UploadDocument {
  b64File: string;
  contentType: string;
  documentName: string;
  entityType: DocumentType;
  otherDocumentType?: string;
}

export interface AvailableDocument {
  documentName: string;
  entityType: DocumentType;
  otherDocumentType?: string;
  entityId: string;
  createdAt: string;
  updatedAt: string;
  documentPath: string;
}

export interface DocumentInterface {
  entityId?: string;
  documentPath?: string;
  b64File?: string;
  contentType?: string;
  documentName?: string;
  documentType: DocumentType;
  toBeDeleted?: boolean;
  toBeAdded?: boolean;
  isEdited?: boolean;
  id?: string;
  version?: number;
  isUserUploaded: boolean;
}

export interface DocumentVault {
  id: string;
  name: string;
  isFolder: boolean;
  children: DocumentVault[];
  url?: string;
}
