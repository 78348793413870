import { useParams } from "react-router";
import { VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import AddOrEditPlan from "./AddPlan";

function CreateViewOrEditPlan() {
  const { mode } = useParams();
  return (
    <VStack className="gap-6">
      <BCHeader
        bcTitle={"Overview and Insights"}
        className="py-1"
        bcSubTitle={
          mode === "add"
            ? "Create Plan"
            : mode === "edit"
            ? "Edit Plan"
            : "View Plan"
        }
      />
      <VStack>
        <AddOrEditPlan />
      </VStack>
    </VStack>
  );
}

export default CreateViewOrEditPlan;
