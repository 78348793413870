import { useMutation, useQuery } from "react-query";
import {
  addValuation,
  deleteValuation,
  editValuation,
  getValuationDetails,
} from "../api/Valuation";
import { queryClient } from "./client";

export function useValuationDetails() {
  return useQuery({
    queryKey: "allValuationDetails",
    queryFn: getValuationDetails,
  });
}

export function useAddValuation() {
  return useMutation({
    mutationKey: "addValuation",
    mutationFn: addValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("allValuationDetails");
      queryClient.refetchQueries("allValuationDetails");
    },
    onError: () => {},
  });
}

export function useEditValuation() {
  return useMutation({
    mutationKey: "editValuation",
    mutationFn: editValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("allValuationDetails");
      queryClient.refetchQueries("allValuationDetails");
    },
  });
}

export function useDeleteValuation() {
  return useMutation({
    mutationKey: "deleteValuation",
    mutationFn: deleteValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("allValuationDetails");
      queryClient.refetchQueries("allValuationDetails");
    },
  });
}
