import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Box, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useQuery } from "../../utils/useQuery";
import StatusLabel from "../esopOverview/StatusLabel";
import CreateEmployeesList from "./CreateOffersList";
import useIsMobile from "../../utils/detectDevice";

function CreateExerciseFlow() {
  const query = useQuery().get("mode");
  const [bcTitle, setBCTitle] = useState<string>();
  const { isMobile } = useIsMobile();

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes("create-exercise-liquidation-flow")) {
      if (query === "create") {
        setBCTitle("Create Liquidity Program");
      } else {
        setBCTitle("Edit Liquidity Program");
      }
    } else if (pathname.includes("create-exercise-flow")) {
      if (query === "create") {
        setBCTitle("Create Exercise Program");
      } else {
        setBCTitle("Edit Exercise Program");
      }
    }
  }, [pathname]);
  return (
    <VStack className="gap-6">
      <BCHeader
        bcTitle={"Liquidity Event Planning and Administration"}
        className="py-3"
        bcSubTitle={bcTitle}
      />
      <VStack className={` gap-4 `}>
        <CreateEmployeesList />
      </VStack>
    </VStack>
  );
}

export default CreateExerciseFlow;
