import { useFormik, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import produce from "immer";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Error,
  isEsopViewer,
} from "../../components/utils";

import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  ESignSetting,
  SignerDetails,
  SiteSettingsRes,
} from "../../types/SiteSettings";
import { Input, Label } from "../../components/shared/InputField";

import { Select } from "../../components/shared/Select";
import {
  useESginSettings,
  useGetAllCompanyUsers,
  useSetESignSettings,
} from "../../queries/siteSettings";
import { SiteSettingsContext } from "./SiteSettings";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { usePermissionStore } from "../../store/permissionStore";

function ESignSettings() {
  const {
    data: ESginSettingDetails,
    refetch,
    isError,
    isFetched,
  } = useESginSettings();
  const initialValues: ESignSetting = {
    isEsignEnabled: (ESginSettingDetails?.isEsignEnabled as boolean) || false,
    isDocuSignEnabled:
      (ESginSettingDetails?.isDocuSignEnabled as boolean) || false,
    isDigioEnabled: (ESginSettingDetails?.isDigioEnabled as boolean) || false,
    signerDetails: ESginSettingDetails?.signerDetails || [],
    signerDetailsDtos: ESginSettingDetails?.signerDetailsDtos || [],
  };
  const validationSchema = Yup.object({
    isEsignEnabled: Yup.boolean(),
    isDocuSignEnabled: Yup.boolean(),
  });
  const { permission } = usePermissionStore();

  const { mutate: setESignSettings, isLoading: isSettingsSaved } =
    useSetESignSettings();
  const handleSubmit = (values: ESignSetting) => {
    setESignSettings(values, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };

  function handleOnCancel(values: ESignSetting) {
    values = initialValues;
  }
  const formik = useFormik<ESignSetting>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      handleSubmit(values);
    },
    onReset: (values) => {
      handleOnCancel(values);
    },
  });

  useEffect(() => {
    if (ESginSettingDetails) {
      formik.setValues(ESginSettingDetails);
    }
  }, [ESginSettingDetails]);

  const isUserEsopViewer = isEsopViewer();
  const disableDocuSign = false;
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  const eSignSettingsErrors =
    errors.isEsignEnabled || errors.isDocuSignEnabled || errors.signerDetails;
  function doPartialTouch() {
    formik.setFieldTouched("isEsignEnabled");
    formik.setFieldTouched("isDocuSignEnabled");
    formik.setFieldTouched("signerDetails");
  }

  const { data: _userData } = useGetAllCompanyUsers();
  const userNames = _userData;
  const [availableSigners, setAvailableSigners] = useState<
    Array<SignerDetails>
  >(formik.values.signerDetails);
  const baseSigner: SignerDetails = {
    name: "",
    email: "",
    signerType: "OPTIONS",
    toBeAdded: true,
  };
  function handleAdd() {
    const signers = [...values.signerDetails];
    const newSigner = baseSigner;
    signers.push(newSigner);
    setFieldValue("signerDetails", signers);
  }

  function handleEdit(
    index: number,
    changedField: keyof SignerDetails,
    changeEvent: any
  ) {
    setAvailableSigners(
      produce((availableSigners) => {
        const signer = availableSigners[index];
        signer.toBeUpdated = true;
        if (changedField === "email" || changedField === "name")
          signer[changedField] = changeEvent.target.value;
      })
    );
    formik.setFieldValue(`signerDetails[${index}].toBeUpdated`, true);
  }

  function handleDelete(deleteIndex: number) {
    const signerDetails = [...values.signerDetails];
    signerDetails.forEach((signer, index) => {
      if (deleteIndex === index) {
        if (signer.toBeAdded) signerDetails.splice(deleteIndex, 1);
        signer.toBeDeleted = true;
      }
    });
    setFieldValue("signerDetails", signerDetails);
  }
  function userSelected(selectedUserId: number, signerIndex: number) {
    const selectedUser = _userData?.find(
      (user) => user.userId === selectedUserId
    );
    formik.setFieldValue(
      `signerDetails[${signerIndex}].name`,
      selectedUser?.fullName
    );
    formik.setFieldValue(
      `signerDetails[${signerIndex}].email`,
      selectedUser?.emailId
    );
    formik.setFieldValue(
      `signerDetails[${signerIndex}].userId`,
      selectedUser?.userId
    );
  }
  return (
    <form key="GrantLetterSettings" onSubmit={formik.handleSubmit}>
      <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
        {disableDocuSign && (
          <VStack className="justify-between p-4 gap-9">
            <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
              DocuSign Settings
            </Box>
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Employees should be allowed to E-sign
              </p>
              <Box className="flex items-start w-1/3 ml-2">
                <SwitchButton
                  className=""
                  value={formik.values.isDocuSignEnabled}
                  label=""
                  onClick={() => {
                    formik.setFieldValue(
                      "isDocuSignEnabled",
                      !formik.values.isDocuSignEnabled
                    );
                  }}
                />
              </Box>
            </HStack>
            {formik.values.isDocuSignEnabled && (
              <>
                <HStack>
                  <p className={` form-label text-sm font-medium w-2/3 `}>
                    Signers for Options
                  </p>
                </HStack>
                <VStack aria-label="header" className="px-4 gap-y-2">
                  {values.signerDetails.length > 0 && (
                    <HStack className="mt-4 gap-x-8">
                      <div className="flex-1">
                        <Label className="text-sm font-normal">User</Label>
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm font-normal">Name</Label>
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm font-normal">E-Mail</Label>
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm font-normal">
                          Signature Type
                        </Label>
                      </div>
                      <div className="flex-1"></div>
                    </HStack>
                  )}
                  <VStack className="justify-between gap-4 overflow-y-auto max-h-72">
                    {values.signerDetails?.map(
                      (signer, signerIndex: number) => {
                        const newSigner = values?.signerDetails[signerIndex];
                        const fieldTouched =
                          touched?.signerDetails?.[signerIndex];
                        const err = errors?.signerDetails?.[signerIndex] as any;
                        return (
                          <HStack
                            className="gap-4"
                            key={`${signer.name}${signerIndex}`}
                          >
                            <div className="flex-1">
                              <Select
                                options={userNames || []}
                                textGetter={(e) => e.fullName}
                                valueGetter={(e) => e.userId}
                                value={newSigner.userId}
                                onChange={(e) => {
                                  setFieldValue(
                                    `signerDetail[${signerIndex}].userId`,
                                    e.target.value
                                  );
                                  userSelected(
                                    Number(e.target.value),
                                    signerIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <Input
                                type="text"
                                className={`${
                                  formik.values.signerDetails[signerIndex]
                                    ?.userId != null
                                    ? "cursor-not-allowed"
                                    : null
                                }`}
                                disabled={
                                  formik.values.signerDetails[signerIndex]
                                    ?.userId != null
                                }
                                value={newSigner.name}
                                onChange={(e) => {
                                  setFieldValue(
                                    `signerDetails[${signerIndex}].name`,
                                    e.target.value
                                  );
                                }}
                              />
                              {fieldTouched?.name && err?.name && (
                                <Error text={err?.name} />
                              )}
                            </div>
                            <div className="flex-1">
                              <Input
                                type="email"
                                className={`${
                                  formik.values.signerDetails[signerIndex]
                                    ?.userId != null
                                    ? "cursor-not-allowed"
                                    : null
                                }`}
                                value={newSigner.email}
                                onChange={(e) => {
                                  setFieldValue(
                                    `signerDetails[${signerIndex}].email`,
                                    e.target.value
                                  );
                                }}
                                disabled={
                                  formik.values.signerDetails[signerIndex]
                                    ?.userId != null
                                }
                              />
                              {fieldTouched?.email && err?.email && (
                                <Error text={err?.email} />
                              )}
                            </div>
                            <div className="flex-1">
                              <Select
                                disabled
                                options={["OPTIONS"]}
                                value={newSigner.signerType}
                              />
                            </div>
                            <div className="flex items-center flex-1 gap-4">
                              {signer.toBeDeleted ? (
                                <div className="text-xs text-red-600">
                                  Will be deleted
                                </div>
                              ) : (
                                <Icon
                                  width={20}
                                  className="text-gray-400 cursor-pointer hover:text-orange-501"
                                  onClick={() => handleDelete(signerIndex)}
                                  icon={"ic:baseline-delete"}
                                />
                              )}
                            </div>
                          </HStack>
                        );
                      }
                    )}
                  </VStack>
                  <HStack className="justify-center mt-8">
                    <ButtonSecondary
                      className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2 flex flex-col items-center"
                      onClick={() => handleAdd()}
                    >
                      Add a Signer
                    </ButtonSecondary>
                  </HStack>
                </VStack>
                <HStack></HStack>
              </>
            )}
          </VStack>
        )}
        {true && (
          <VStack className="justify-between p-4 gap-9">
            <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
              Digio Settings
            </Box>
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Employees should be allowed to E-sign
              </p>
              <Box className="flex items-start w-1/3 ml-2">
                <SwitchButton
                  className=""
                  value={formik.values.isDigioEnabled}
                  label=""
                  onClick={() => {
                    if (!formik.values.isDigioEnabled) {
                      toast(
                        "Digio is enabled only for Employee e-signing,Please save to reflect the changes",
                        {
                          type: "info",
                          autoClose: 5000,
                        }
                      );
                    }
                    formik.setFieldValue(
                      "isDigioEnabled",
                      !formik.values.isDigioEnabled
                    );
                  }}
                />
              </Box>
            </HStack>
            {formik.values.isDigioEnabled && (
              <>
                <HStack>
                  <p className={` form-label text-sm font-medium w-2/3 `}>
                    Please Note this enables only Employee grant acceptance
                  </p>
                </HStack>
              </>
            )}
          </VStack>
        )}

        {!isUserEsopViewer && (
          <HStack className="justify-between mt-12">
            <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              type="submit"
              className={
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }
              disabled={
                isError ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
              }
            >
              {!isSettingsSaved ? (
                "Save Settings"
              ) : (
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={36}
                />
              )}
            </ButtonPrimary>
          </HStack>
        )}
      </Box>
    </form>
  );
}

export default ESignSettings;
