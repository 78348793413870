/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  ButtonSize,
  HStack,
  IconButtonType,
  IconPlacement,
  IconSize,
  isEsopViewer,
} from "../utils";
import { BuybackType } from "../../pages/optionBuyback";
import Tooltip from "./Tooltip";
import { useAuthorizationStore } from "../../store";
import { Input } from "./InputField";
import { AvailableTableFilters } from "../../types/Table";
import AlertDialog from "./AlertDialog";
import { useDeleteTableFilter } from "../../queries/gridUtils";

interface CustomFilterProps {
  availableFilters: AvailableTableFilters[];
  className?: string;
  onFilterSelect: (action: AvailableTableFilters | null) => void;
  onNewFilterAddition: (filterName: string) => void;
  selected?: boolean;
  iconName?: string;
  buttonType?: IconButtonType;
  iconClassName?: string;
  buttonSize?: ButtonSize;
  iconSize?: IconSize;
  iconPlacement?: IconPlacement;
  currentFilterState: object;
}

function MyCustomFilters(props: CustomFilterProps) {
  const {
    selected = false,
    className,
    buttonType = IconButtonType.OUTLINED,
    buttonSize = ButtonSize.MEDIUM,
    iconClassName,
    iconSize = IconSize.M,
    iconPlacement = IconPlacement.LEFT,
    availableFilters = [],
    currentFilterState = {},
    onNewFilterAddition,
    onFilterSelect,
    ...rest
  } = props;
  const placementClass =
    iconPlacement === IconPlacement.RIGHT ? "flex-row-reverse" : "";
  const selectedStyle = selected
    ? "text-white bg-secondary"
    : "text-secondary  bg-transparent";
  const buttonClassName = `${buttonType} inline-flex border border-borderColor items-center gap-x-1 rounded-sm ${buttonSize} font-medium ${className} ${placementClass} ${
    buttonType === IconButtonType.OUTLINED ? selectedStyle : "border-none"
  }`;
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [newFilterName, setNewFilterName] = React.useState("");
  const [deleteDialogParams, setDeleteDialogParams] = useState<{
    open: boolean;
    filter?: AvailableTableFilters;
  }>({ open: false });
  const [selectedFilter, setSelectedFilter] =
    React.useState<AvailableTableFilters>();
  const { mutate: deleteTableFilter } = useDeleteTableFilter();
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);
  const onFilterSelected = (filter: AvailableTableFilters) => {
    setSelectedFilter(() => filter);
    onFilterSelect(filter);
  };
  const handleReset = () => {
    setSelectedFilter(undefined);
    onFilterSelect(null);
  };
  const saveNewFilter = () => {
    if (Object.keys(currentFilterState).length === 0) {
      toast("No Filter exists to save", { type: "error", autoClose: 2000 });
      return;
    }
    if (
      availableFilters.some((filter) =>
        _.isEqual(filter.filterState, currentFilterState)
      )
    ) {
      toast("Same filter already exists", { type: "error", autoClose: 2000 });
      return;
    }
    if (
      availableFilters.some(
        (availableFilters) =>
          availableFilters.name?.toLowerCase() === newFilterName?.toLowerCase()
      )
    ) {
      toast("Filter with same name already exists", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    if (newFilterName) {
      onNewFilterAddition(newFilterName);
      setNewFilterName("");
      setDropDownOpen(!dropdownOpen);
    } else {
      toast("Please enter Filter's name", { type: "error", autoClose: 2000 });
    }
  };
  const filterNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFilterName(e.target.value);
  };
  const deleteFilter = (filter?: AvailableTableFilters) => {
    setDeleteDialogParams({ open: false, filter: undefined });
    if (filter && filter.id) {
      deleteTableFilter(filter?.id, {
        onSuccess: () => {
          toast(`${filter?.name} deleted successfully`, {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: () => {
          toast(`Error while deleting ${filter?.name}`, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  };

  return (
    <>
      <div className={`flex flex-col ${className} `} ref={contextRef}>
        <button
          {...rest}
          onClick={() => setDropDownOpen(!dropdownOpen)}
          className={buttonClassName}
          type={"button"}
        >
          <Icon
            aria-hidden="true"
            icon={"material-symbols:filter-alt"}
            className={iconClassName}
            height={iconSize}
            width={iconSize}
          />
          My Custom Filters{" "}
          {selectedFilter && (
            <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white">
              <span className="flex w-2 h-2 bg-red-600 rounded-full -mt-4 -ms-1 flex-shrink-0"></span>
            </span>
          )}
        </button>
        {dropdownOpen && (
          <div
            id="dropdown"
            className="absolute z-10 bg-white border divide-y divide-gray-100 rounded shadow mt-11 w-1/6"
          >
            <p className="px-4 py-1 text-stone-300 w-full text-center">
              Your Saved Filters
            </p>
            <ul
              className="w-full py-1 text-xs font-normal divide-y text-dark"
              aria-labelledby="dropdownDefault"
            >
              {availableFilters.length === 0 && (
                <li key={"NONE"}>
                  <a
                    href=""
                    className={` block px-4 py-2 bg-gray-100 cursor-not-allowed `}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    -- No Filters --
                  </a>
                </li>
              )}
              {availableFilters.map((filter, index) => (
                <li key={index} className="flex flex-row justify-between">
                  <a
                    href=""
                    className={` block px-4 py-2 w-3/4 ${
                      filter.id === selectedFilter?.id
                        ? "text-orange-500 cursor-not-allowed"
                        : " hover:bg-gray-100 "
                    }`}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      onFilterSelected(filter);
                      setDropDownOpen((state) => !state);
                    }}
                  >
                    {filter.name}
                  </a>
                  {filter.id !== selectedFilter?.id && (
                    <span>
                      <Icon
                        className="cursor-pointer mr-4 mt-1"
                        icon="fluent:delete-28-regular"
                        onClick={() => {
                          setDeleteDialogParams({ open: true, filter });
                        }}
                        width="18"
                        height="18"
                      />
                    </span>
                  )}
                </li>
              ))}
              <li
                key={"Save Filter State"}
                className="py-4 divide-y text-dark px-2"
              >
                <Input
                  type="text"
                  value={newFilterName}
                  className="w-full h-5 min-h-3"
                  placeholder="Filter Name"
                  required
                  onChange={filterNameChanged}
                />
                <HStack className="gap-2">
                  <ButtonPrimary
                    className={`w-1/2  mt-4 block px-4 hover:bg-gray-100 justify-center mx-auto`}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      saveNewFilter();
                    }}
                  >
                    Save
                  </ButtonPrimary>
                  <ButtonSecondary
                    className={`w-1/2  mt-4 block px-4 hover:bg-gray-100 justify-center mx-auto`}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      handleReset();
                      setDropDownOpen((state) => !state);
                    }}
                  >
                    Reset
                  </ButtonSecondary>
                </HStack>
              </li>
            </ul>
          </div>
        )}
      </div>
      <Dialog
        open={deleteDialogParams.open}
        onClose={() => setDeleteDialogParams({ open: false })}
        maxWidth="md"
      >
        {deleteDialogParams.open && (
          <AlertDialog
            message={`Are you sure you want to delete '${deleteDialogParams.filter?.name}' ?`}
            primaryActionText="Delete"
            onClose={() => setDeleteDialogParams({ open: false })}
            secondaryActionText="Cancel"
            onPrimaryAction={() => {
              deleteFilter(deleteDialogParams.filter);
            }}
            onSecondaryAction={() => {
              setDeleteDialogParams({ open: false });
            }}
            open={deleteDialogParams.open}
          />
        )}
      </Dialog>
    </>
  );
}

export default MyCustomFilters;
