import { currencyTypes } from "../constants/CompanyCurrencyType";
import { useCompanyStore } from "../store";

export function formatCurrency(num: number, format: string) {
  let returnFormat = "INR";
  for (const [key, value] of Object.entries(currencyTypes)) {
    if (value.toString() === format) {
      returnFormat = key.toString();
    }
  }
  if (returnFormat === "SGD") {
    return `${"S$"}${num?.toLocaleString(format)}`;
  } else {
    return num?.toLocaleString(format, {
      style: "currency",
      currency: returnFormat,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}

export function getCurrencyType() {
  const { companyData } = useCompanyStore();
  const res = companyData?.currency?.split("-")[0].trim() || "INR";
  const key = res as keyof typeof currencyTypes;
  const currencyType =
    key != null ? currencyTypes[key] : window.navigator.language;
  return currencyType;
}

export function getCurrencySymbol() {
  const { companyData } = useCompanyStore();
  const res = (companyData?.currency?.split("-")[1].trim() ?? "") || "₹";
  return res;
}
