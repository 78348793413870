/* eslint-disable no-undef */

export type TooltipProps = {
  text: string;
  children: JSX.Element;
  _className?: string;
  disabled?: boolean;
};
function Tooltip(props: TooltipProps) {
  const { text, children, _className, disabled = false } = props;
  return (
    <div className="relative group ">
      {children}
      <div
        className={`absolute bottom-0 right-0 flex-col items-center hidden mb-6 rounded-md left-0 ${
          disabled ? "" : "group-hover:flex"
        }`}
      >
        <div
          className={`relative z-20 p-2 text-sm text-black shadow-lg bg-[#f6f9fb] ${_className}`}
        >
          {text}
        </div>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-[#f6f9fb]"></div>
      </div>
    </div>
  );
}

export default Tooltip;
