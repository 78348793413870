/* eslint-disable no-unused-expressions */
import randomColor from "randomcolor";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Center, HStack, VStack } from "../components/utils";
import { useTransactionsCard } from "../queries/esopPlan";
import {
  EmployeeCard,
  EsopPlanCard,
  GrantCard,
  OptionBuyBackCard,
} from "../types/Card";

type Props = {
  transactionData:
    | EmployeeCard[]
    | EsopPlanCard[]
    | OptionBuyBackCard[]
    | GrantCard[];
};
function CardData({ transactionData }: Props): React.ReactElement {
  const navigate = useNavigate();
  const handleAction = (
    trans: EmployeeCard | EsopPlanCard | OptionBuyBackCard | GrantCard
  ) => {
    const { tType, tData } = trans;
    switch (tType) {
      case "Employee": {
        const empData = tData;
        empData.length > 1
          ? navigate("/options/allEmployees")
          : navigate(`/options/employeeDetails/${empData[0]}`);
        break;
      }
      case "EsopPlan": {
        const planData = tData;
        planData.length > 1
          ? navigate("/options/esopOverview")
          : navigate(`/options/planView/${planData[0]}`);
        break;
      }
      case "BuyBack": {
        const buyBackData = tData;
        buyBackData.length > 1
          ? navigate("/options/esopTransactions")
          : navigate(`/options/view-option-buy-back/${buyBackData[0]}`);
        break;
      }
      case "Grant": {
        const empData = tData;
        empData.length > 1
          ? navigate("/options/allPlans")
          : navigate(`/options/employeeDetails/${empData[0]}`);
        break;
      }
      default:
    }
  };
  return (
    <>
      {transactionData?.map((transaction, index) => {
        const backgroundColor = randomColor({
          luminosity: "dark",
          format: "rgba",
          alpha: 0.2,
          seed: transaction.data,
        });
        return (
          <HStack
            key={index}
            onClick={() => handleAction(transaction)}
            className="justify-between p-2 cursor-pointer"
          >
            <HStack>
              <Box
                style={{ backgroundColor }}
                className="w-4 h-4 mt-2 rounded"
              ></Box>
              <VStack className="pl-4">
                <p className="font-medium text-xs2 text-gray-dark">
                  {transaction.data}
                </p>
                <p className="text-xs1 font-semi-medium text-gray-light">
                  {transaction.subData}
                </p>
              </VStack>
            </HStack>
          </HStack>
        );
      })}
    </>
  );
}

function CardTransaction() {
  const header = "Transactions";
  const { data: _transactions, isFetched } = useTransactionsCard();
  const [transactions, setTransactions] = useState(_transactions);
  useEffect(() => {
    setTransactions(_transactions);
  }, [isFetched]);
  return (
    <VStack className="p-6 overflow-auto bg-white rounded-lg max-h-96 border border-borderColor drop-shadow-box ">
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
      </VStack>
      <VStack>
        {transactions && (
          <>
            <CardData transactionData={transactions.employeeCard} />
            <CardData transactionData={transactions.grantCard} />
            <CardData transactionData={transactions.esopPlanCard} />
            <CardData transactionData={transactions.buyBackEvent} />
          </>
        )}
        {!transactions && (
          <Center className="h-48">
            <p className="p-4 font-medium text-teal-600 rounded">
              No Transactions
            </p>
          </Center>
        )}
      </VStack>
    </VStack>
  );
}

export default CardTransaction;
