export interface GrantExportExcelReq {
  heading: string;
  data: any;
  headers: any;
}

export interface Header {
  key: string;
  value: string;
}

export const grantHeadingVal: string = "Esop";
export const grantHeadersObj: any = {
  grantIdentifier: "Grant Id",
  optionHolderName: "Name of Holder",
  employeeIdentifier: "Employee Id",
  optionHolderState: "Grant Status",
  optionsGranted: "No of Options",
  grantPrice: "Grant Price",
  grantDate: "Grant Date",
  optionsVested: "Vested",
  optionsExercisedWithStock: "Stock Exercise",
  optionsExercisedWithCash: "Cashed Out",
  optionsLapsed: "Lapsed",
  optionsForfeited: "Forfeited",
  planName: "Plan Name",
  employmentStatus: "Status",
  unVested: "Unvested",
  netVested: "Netvested",
};
export const grantHeaderslist: string[] = [
  "grantIdentifier",
  "optionHolderName",
  "employeeIdentifier",
  "optionHolderState",
  "optionsGranted",
  "grantPrice",
  "grantDate",
  "optionsVested",
  "optionsExercisedWithStock",
  "optionsExercisedWithCash",
  "optionsLapsed",
  "optionsForfeited",
  "planName",
  "employmentStatus",
  "unVested",
  "netVested",
];
