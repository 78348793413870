import { useMutation, useQuery } from "react-query";
import { deleteTableFilter, getTableFilters } from "../api/gridUtils";
import { TableName } from "../types/Table";
import { queryClient } from "./client";

export function useGetFilterStates(tableName: TableName) {
  return useQuery({
    queryKey: ["getAllFilters", tableName],
    queryFn: getTableFilters,
  });
}

export function useDeleteTableFilter() {
  return useMutation({
    mutationFn: deleteTableFilter,
    mutationKey: "deleteTableFilter",
    onSuccess: () => {
      queryClient.invalidateQueries("getAllFilters");
    },
  });
}
