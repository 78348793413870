import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  HStack,
  VStack,
  Error,
  ButtonPrimary,
} from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import { LiquidationDetails } from "../../types/OptionBuyback";
import { useQuery } from "../../utils/useQuery";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

function CreateLiquidationDetails() {
  const currencySymbol = getCurrencySymbol();
  const currency = getCurrencyType();
  const { id } = useParams();
  const _id = id ?? "";

  const query = useQuery();
  const searchParam = query.get("mode");
  const navigate = useNavigate();

  function getCreateInitialValues(): LiquidationDetails {
    return {
      name: "",
      startDate: formatDate(new Date().toISOString()),
      endDate: "",
      sellingPrice: undefined,
      buyerName: "",
      totalAmount: undefined,
    };
  }

  function getEditInitialValues(): LiquidationDetails {
    return {
      name: "",
      startDate: "",
      endDate: "",
      sellingPrice: undefined,
      buyerName: "",
      totalAmount: undefined,
    };
  }
  function getInitialValues() {
    if (searchParam === "edit") return getEditInitialValues();
    return getCreateInitialValues();
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name required")
      .min(3, "Name should be atleast 3 characters"),
    endDate: Yup.string().required("Liquidation end date required"),
    sellingPrice: Yup.string().required("Selling price required"),
    buyerName: Yup.string().required("Buyer name required"),
  });

  const formik = useFormik<LiquidationDetails>({
    initialValues: getInitialValues(),
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const liquidationDto = { ...values };
      liquidationDto.startDate = formatWithTimeZone(liquidationDto.startDate);
      liquidationDto.endDate = formatWithTimeZone(liquidationDto.endDate);
      navigate(`/options/view-option-buy-back/12345`);
    },
  });

  return (
    <>
      <VStack className="w-4/5 gap-4 mt-4">
        <HStack className="justify-start w-full gap-12">
          <Box className="flex items-center justify-between w-1/2 gap-4 ">
            <Label>Exercise Window</Label>
            <VStack>
              <Input
                type="text"
                placeholder="Enter Exercise Window Name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name && (
                <Error text={formik.errors.name} />
              )}
            </VStack>
          </Box>
          <Box className="flex items-center justify-between w-1/2 gap-4 ">
            <Label>Liquidation Start Date</Label>
            <VStack>
              <Input
                type="date"
                disabled
                {...formik.getFieldProps("startDate")}
              />
            </VStack>
          </Box>
        </HStack>
        <HStack className="justify-start w-full gap-12">
          <Box className="flex items-center justify-between w-1/2 gap-4 ">
            <Label>Liquidation End Date</Label>
            <VStack>
              <Input type="date" {...formik.getFieldProps("endDate")} />
              {formik.touched.endDate && formik.errors.endDate && (
                <Error text={formik.errors.endDate} />
              )}
            </VStack>
          </Box>
          <Box className="flex items-center justify-between w-1/2 gap-4 ">
            <Label>Selling Price ({currencySymbol})</Label>
            <VStack>
              <Input
                type="number"
                placeholder="Enter selling price"
                {...formik.getFieldProps("sellingPrice")}
              />
              {formik.touched.sellingPrice && formik.errors.sellingPrice && (
                <Error text={formik.errors.sellingPrice} />
              )}
            </VStack>
          </Box>
        </HStack>
        <HStack className="justify-start gap-12 text-sm ">
          <Box className="flex items-center justify-between w-1/2 gap-4 ">
            <Label>Buyer</Label>
            <VStack>
              <Input
                type="text"
                {...formik.getFieldProps("buyerName")}
                placeholder="Enter Buyer name"
              />
              {formik.touched.buyerName && formik.errors.buyerName && (
                <Error text={formik.errors.buyerName} />
              )}
            </VStack>
          </Box>
          <Box className="flex items-center justify-between w-1/2 gap-4 ">
            <Label>Total Amount ({currencySymbol}) </Label>
            <VStack>
              <Input
                type="number"
                placeholder="total amount"
                {...formik.getFieldProps("totalAmount")}
              />
              {formik.touched.totalAmount && formik.errors.totalAmount && (
                <Error text={formik.errors.totalAmount} />
              )}
            </VStack>
          </Box>
        </HStack>
      </VStack>
      <HStack className="justify-end">
        <ButtonPrimary
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          Generate List
        </ButtonPrimary>
      </HStack>
    </>
  );
}

export default CreateLiquidationDetails;
