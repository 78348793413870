import React, { useState } from "react";
import { HStack, VStack } from "../../../components/utils";
import CompensationModellingStepper from "./CompensationModellingStepper";
import CompensationModellingNavigation from "./CompensationModellingNavigation";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";

const CompensationModellingCreation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { selectedCompanyData } = useOrgTemplateStore();
  function onStepChange(step: number) {
    if (step >= 2 && step <= 2.3) {
      if (step === 2) setCurrentStep(2.1);
      if (step === 2.1) setCurrentStep(2.2);
      if (step === 2.2 && !selectedCompanyData?.employeeListUploaded)
        setCurrentStep(3);
      if (step === 2.2 && selectedCompanyData?.employeeListUploaded)
        setCurrentStep(2.3);
      if (step === 2.3) setCurrentStep(3);
    } else if (step === 1) {
      setCurrentStep(2.1);
    } else {
      setCurrentStep(step + 1);
    }
  }
  function onBackClick() {
    if (currentStep !== 1 && currentStep !== 5) {
      if (currentStep <= 3 && currentStep >= 2.1) {
        if (currentStep === 3) {
          if (selectedCompanyData?.employeeListUploaded) setCurrentStep(2.3);
          else setCurrentStep(2.2);
        }
        if (currentStep === 2.2) setCurrentStep(2.1);
        if (currentStep === 2.1) setCurrentStep(1);
        if (currentStep === 2.3) setCurrentStep(2.2);
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  }
  return (
    <div>
      <HStack className="flex h-full min-h-full gap-8 ">
        <VStack className="flex w-[30%] bg-white rounded-md min-w-72 ">
          <CompensationModellingStepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </VStack>
        <VStack className="flex w-[70%] bg-white rounded-md min-w-96">
          <CompensationModellingNavigation
            currentStep={currentStep}
            onStepChange={() => onStepChange(currentStep)}
            onBackClick={() => onBackClick()}
          />
        </VStack>
      </HStack>
    </div>
  );
};

export default CompensationModellingCreation;
