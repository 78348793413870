import { useMemo } from "react";
import { useVestingTemplates } from "../../queries";
import VestingTemplatesTable from "./VestingTemplatesTable";
import { Box, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import useIsMobile from "../../utils/detectDevice";

export function VestingTemplates() {
  const { isMobile } = useIsMobile();
  return (
    <VStack className="gap-4 rounded-lg">
      <BCHeader
        className="items-baseline"
        bcTitle="Vesting Schedule Management"
        bcDescription="Set up and adjust vesting schedules, including custom timelines for performance-based vesting and standard time-based vesting."
      />
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
        <VestingTemplatesTable />
      </Box>
    </VStack>
  );
}
