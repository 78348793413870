import React from "react";
import { ButtonPrimary, HStack } from "../utils";

export type ExpandButtonProps = {
  expanded: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function ExpandButton(props: ExpandButtonProps) {
  const { expanded, ...rest } = props;

  return (
    <ButtonPrimary
      className="flex items-center justify-center w-1 h-4 px-0 py-0"
      {...rest}
    >
      {expanded ? "-" : "+"}
    </ButtonPrimary>
  );
}
