import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ChartData } from "../pages/allEmployees/DepartmentChart";

export function DoughnutChart({ data }: { data: ChartData }) {
  const total = data.datasets[0].data.length;
  const optionsPlan = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: 68,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (tooltipItems: any) => {
            const label = `${tooltipItems.label || ""}: ${
              tooltipItems.raw as string
            }`;
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Doughnut Chart",
        color: "blue",
        font: {
          size: 24,
        },
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
    elements: {
      arc: {
        spacing: -5,
        borderWidth: 5,
        borderRadius: 10,
      },
    },
  };
  const plugins = [
    {
      id: "0",
      beforeDraw(chart: ChartJS) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 80).toFixed(2);
        ctx.font = `${fontSize}em Inter`;
        ctx.textBaseline = "top";
        ctx.save();
      },
    },
  ];
  return <Doughnut options={optionsPlan} data={data} plugins={plugins} />;
}
