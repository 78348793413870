import { useMutation, useQuery } from "react-query";
import {
  createCompensationModelling,
  getAllCompensationModelling,
} from "../api/compensationModellingApi";

export function useGetAllCompensationModelling() {
  return useQuery({
    queryKey: ["getAllCompensationModelling"],
    queryFn: getAllCompensationModelling,
  });
}

export function useCreateCompensationModelling() {
  return useMutation({
    mutationKey: "createCompensationModelling",
    mutationFn: createCompensationModelling,
  });
}
