import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
} from "../../../components/utils";
import KeyRoles from "./KeyRoles";
import GradeGuide from "./GradeGuide";
import LevelGuide from "./LevelGuide";
import OrganisationStructure from "./OrgStructure";
import KeyPersonnel from "./KeyPersonnel";
import OrgStructureSummary from "./OrgStructureSummary";
import { CompanyData } from "./utils/OrgStructureJSONDataProc";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import {
  useCreateOrgStructureV2,
  useGetOrgStructureV2,
} from "../../../queries/organisationStructure";
import OrgStructureSummaryWithEmployee from "./OrgStructureSummaryWithEmployee";

type CompanyDetailsNavigationProps = {
  currentStep: number;
  onStepChange: () => void;
  onBackClick: () => void;
};
export default function CompanyDetailsNavigation(
  props: Readonly<CompanyDetailsNavigationProps>
) {
  const navigate = useNavigate();
  const [prevData, setPrevData] = useState<CompanyData>();
  const { selectedCompanyData, setSelectedCompanyData } = useOrgTemplateStore();
  const { mutate: createOrgStructure, isLoading } = useCreateOrgStructureV2();
  const { data: orgData, isFetching } = useGetOrgStructureV2();
  useEffect(() => {
    if (orgData && JSON.stringify(prevData) !== JSON.stringify(orgData)) {
      setPrevData(orgData);
      setSelectedCompanyData(orgData as CompanyData);
    }
  }, [isFetching]);
  function handleSave() {
    if (
      selectedCompanyData &&
      JSON.stringify(prevData) !== JSON.stringify(selectedCompanyData)
    ) {
      createOrgStructure(selectedCompanyData, {
        onSuccess: () => {
          toast("Successfully Created Organisation Structure");
          navigate(`/modelling/home`);
        },
        onError: () => {
          toast("Error while creating Organisation Structure");
        },
      });
    }
  }
  const { currentStep, onStepChange, onBackClick } = props;
  return (
    <Box className="w-full h-full pb-4 mx-auto align-middle">
      <HStack>
        {currentStep === 1 && (
          <OrganisationStructure onStepChange={onStepChange} />
        )}
        {currentStep === 2 && <GradeGuide />}
        {currentStep === 3 && <LevelGuide />}
        {(currentStep === 4.1 || currentStep === 4) && <KeyRoles />}
        {currentStep === 4.2 && <KeyPersonnel />}
        {currentStep === 5 &&
          (!selectedCompanyData?.employeeListUploaded ? (
            <OrgStructureSummary />
          ) : (
            <OrgStructureSummaryWithEmployee />
          ))}
      </HStack>
      {currentStep === 5 ? (
        <HStack className="justify-between px-4 pl-8">
          <ButtonPrimary1 onClick={onBackClick}>Cancel</ButtonPrimary1>
          <ButtonPrimary onClick={() => handleSave()}>
            {!isLoading ? (
              "Save"
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
          </ButtonPrimary>
        </HStack>
      ) : (
        <HStack className="justify-between px-4 pl-8">
          <ButtonPrimary1 onClick={onBackClick}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={onStepChange}>Next</ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}
