import { Valuation } from "../types/ValuationType";
import optionsApi from "./optionsApi";

export async function getValuationDetails(): Promise<Valuation[]> {
  return optionsApi.get("v1/valuation/details").then((res) => res.data.data);
}

export async function addValuation(valuation: Valuation): Promise<string> {
  return optionsApi
    .post("v1/valuation/add", valuation)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function editValuation(valuation: Valuation): Promise<string> {
  return optionsApi
    .put(`v1/valuation/edit`, valuation)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function deleteValuation(valuationId: string): Promise<string> {
  const deleteBody = {
    id: valuationId,
  };
  return optionsApi
    .delete(`v1/valuation/delete`, { data: deleteBody })
    .then((res) => res.data)
    .catch((err) => err);
}
