import { QueryFunctionContext } from "react-query";
import { CustomResponse } from "../types/Grant";
import {
  AddVestingMilestoneReq,
  VestingMilestone,
  VestingMilestoneCompleteDetails,
  VestingMilestoneCompleteDetailsDto,
  VestingMilestoneTableDetails,
} from "../types/milestone";
import optionsApi from "./optionsApi";

export async function getAllMilestones(): Promise<
  CustomResponse<VestingMilestone[]>
> {
  return optionsApi.get("v1/milestone/getMilestones").then((res) => res.data);
}

export async function createMilestones(
  data: AddVestingMilestoneReq[]
): Promise<CustomResponse<VestingMilestone[]>> {
  return optionsApi
    .post("v1/milestone/createMilestone", data)
    .then((res) => res.data);
}

export async function updateMilestone(
  data: AddVestingMilestoneReq
): Promise<CustomResponse<VestingMilestone>> {
  return optionsApi
    .put("v1/milestone/updateMilestone", data)
    .then((res) => res.data);
}

export async function getMilestoneTableDetails(): Promise<
  CustomResponse<VestingMilestoneTableDetails[]>
> {
  return optionsApi
    .get("v1/milestone/getMilestoneTableDetails")
    .then((res) => res.data);
}

export async function getMilestoneCompleteDetails(
  context: QueryFunctionContext
): Promise<CustomResponse<VestingMilestoneCompleteDetailsDto[]>> {
  const milestoneId = context.queryKey[1];
  return optionsApi
    .get(`v1/milestone/getMilestoneCompleteDetails/${milestoneId}`)
    .then((res) => res.data);
}

export async function completeMilestone(
  data: VestingMilestoneCompleteDetails[]
): Promise<CustomResponse<String>> {
  return optionsApi
    .post("v1/milestone/completeMilestone", data)
    .then((res) => res.data);
}

export async function deleteMilestone(id: string): Promise<String> {
  return optionsApi
    .delete(`v1/milestone/deleteMilestone/${id}`)
    .then((res) => res.data);
}
