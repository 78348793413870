/* eslint-disable default-case */
import { useFormik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import { KeyboardEventHandler, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  Error,
} from "../../../../components/utils";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import { Select } from "../../../../components/shared/Select";
import OrganiSationStructureDataProcessor from "../utils/OrgStructureJSONDataProc";
import { Levels } from "../../../../types/modelling";
import { Action } from "../../../../components/shared/Dropdown";
import { getCurrencySymbol } from "../../../../utils/currencyFormatter";

interface Option {
  readonly label: string;
  readonly value: string;
}

const AddLevelDialog = ({
  open,
  mode,
  levelData,
  onClose = () => {},
}: {
  open: boolean;
  mode?: "Edit" | "Add";
  levelData?: Levels;
  onClose: () => void;
}) => {
  const currencySymbol = getCurrencySymbol();
  const initialValues: Levels = {
    department: "",
    level: "",
    role: "",
    salaryRange: { min: 0, max: 0 },
  };
  const createOption = (label: string) => ({
    label,
    value: label,
  });
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<readonly Option[]>([]);
  const { selectedCompanyData, addLevelDataToCompanyData } =
    useOrgTemplateStore();

  const validationSchema = Yup.object().shape({
    department: Yup.string().required("Department is required"),
    level: Yup.string().required("Level is required"),
  });
  const getInitialValues = () => {
    if (mode === "Edit") {
      return levelData;
    }
    return initialValues;
  };

  useEffect(() => {
    if (levelData) {
      const roles =
        levelData.role.split(",").map((r) => ({ label: r, value: r })) || [];
      setValue(roles);
    }
  }, [levelData]);

  const formik = useFormik({
    initialValues: getInitialValues() || initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (value.length === 0) {
        toast("Please enter atleast one Role", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }
      if (values.salaryRange.min <= 0) {
        toast("Please enter salary", { type: "error", autoClose: 2000 });
        return;
      }
      addLevelDataToCompanyData({
        department: values.department,
        level: values.level,
        role: value.map((val) => val.value).join(","),
        salaryRange: {
          min: values.salaryRange?.min,
          max: values.salaryRange?.max,
        },
      });
      onClose();
    },
  });

  const getDepartments = () => {
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      selectedCompanyData
    );
    return processedData.getDepartmentNames();
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (value.some((val) => val.value === inputValue)) return;
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <>
      <div className="font-semibold border-b p-7">
        <div className="flex items-center justify-between">
          <VStack className="gap-2 text-lg2 text-black-501">
            <p> Level Guide</p>
            <p className="text-sm4 text-gray-401">
              Provide the salary range for employment levels and roles in this
              department.
            </p>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </div>
      <VStack className="w-full gap-6 p-7">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Department</Label>
            <Select
              options={getDepartments()}
              disabled={mode !== "Add"}
              {...formik.getFieldProps("department")}
            />
            {formik.touched.department && formik.errors.department && (
              <Error text={formik.errors.department} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Level</Label>
            <Input
              className=""
              disabled={mode !== "Add"}
              type="text"
              {...formik.getFieldProps("level")}
            />
            {formik.touched.level && formik.errors.level && (
              <Error text={formik.errors.level} />
            )}
          </div>
        </HStack>
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Role</Label>
            <CreatableSelect
              components={{ DropdownIndicator: null }}
              inputValue={inputValue}
              isMulti
              isClearable
              noOptionsMessage={() => null}
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder="Type something and press enter..."
              value={value}
            />
          </div>
          {selectedCompanyData && selectedCompanyData.employeeListUploaded ? (
            <>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Salary ({currencySymbol})
                </Label>
                <HStack className="items-center gap-2">
                  <Input
                    type="number"
                    {...formik.getFieldProps("salaryRange.min")}
                  />
                </HStack>
              </div>
            </>
          ) : (
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Salary Range ({currencySymbol})
              </Label>
              <HStack className="items-center gap-2">
                <Label className="text-sm font-normal">min</Label>
                <Input
                  type="number"
                  {...formik.getFieldProps("salaryRange.min")}
                />
                <Label className="text-sm font-normal">max</Label>
                <Input
                  type="number"
                  {...formik.getFieldProps("salaryRange.max")}
                />
              </HStack>
            </div>
          )}
        </HStack>
        <HStack className="justify-end gap-3 pt-3">
          <ButtonSecondary
            onClick={() => onClose()}
            className="p-10 text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddLevelDialog;
