/* eslint-disable default-case */
import { useFormik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import { KeyboardEventHandler, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { toast } from "react-toastify";
import { Input, Label } from "../../../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  Error,
} from "../../../../components/utils";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import { Select as LocalSelect } from "../../../../components/shared/Select";
import OrganiSationStructureDataProcessor from "../utils/OrgStructureJSONDataProc";

interface AddKeyRole {
  department: string;
  level: string;
  role: { label: string; value: string }[];
}

const AddKeyRoleDialog = ({
  open,
  onClose = () => {},
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const initialValues: AddKeyRole = {
    department: "",
    level: "",
    role: [],
  };
  const { selectedCompanyData, addorDeleteKeyRoleToCompanyData } =
    useOrgTemplateStore();

  const validationSchema = Yup.object().shape({
    department: Yup.string().required("Department is required"),
    level: Yup.string().required("Level is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (values.role.length <= 0) {
        toast("Please enter atleast one Role", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }
      for (const role of values.role) {
        addorDeleteKeyRoleToCompanyData(
          values.department,
          values.level,
          role.value,
          true
        );
      }
      onClose();
    },
  });

  const getDepartments = () => {
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      selectedCompanyData
    );
    return processedData.getDepartmentNames();
  };

  const getLevels = () => {
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      selectedCompanyData
    );
    return processedData.getLevels(formik.values.department);
  };

  const getRoles = () => {
    if (!formik.values.department) return [];
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      selectedCompanyData
    );
    return processedData.getRolesByLevel(
      formik.values.department,
      formik.values.level
    );
  };

  return (
    <>
      <div className="font-semibold border-b p-7">
        <div className="flex items-center justify-between">
          <VStack className="gap-2 text-lg2 text-black-501">
            <p> Key Roles</p>
            <p className="text-sm4 text-gray-401">
              Provide the salary range for different roles within a department
              in the organisation.
            </p>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </div>
      <VStack className="w-full gap-6 p-7">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Department</Label>
            <LocalSelect
              options={getDepartments()}
              {...formik.getFieldProps("department")}
            />
            {formik.touched.department && formik.errors.department && (
              <Error text={formik.errors.department} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Level</Label>
            <LocalSelect
              options={getLevels()}
              {...formik.getFieldProps("level")}
            />
            {formik.touched.level && formik.errors.level && (
              <Error text={formik.errors.level} />
            )}
          </div>
        </HStack>
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Role</Label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              isClearable
              options={getRoles()}
              onChange={(newValue) => formik.setFieldValue("role", newValue)}
            />
            {/* TODO: Formik validation */}
          </div>
          <div className="flex-1"></div>
        </HStack>
        <HStack className="justify-end gap-3 pt-6">
          <ButtonSecondary
            onClick={() => onClose()}
            className="p-10 text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddKeyRoleDialog;
