/* eslint-disable etc/no-commented-out-code */
import { Icon } from "@iconify/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  CheckBoxWithLabel,
  Input,
  Label,
} from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useGetGreyTipConfigs,
  usesyncHrms,
  useTestHrmsConfig,
} from "../../queries";

type GreytipCredentials = {
  greytip_host: string;
  api_user_name: string;
  api_user_password: string;
};
function GreyTipImport(props: { resetData: () => void }) {
  const [greyTipConfig, setGreyTipConfig] = useState<{
    type: string;
    credentialData: GreytipCredentials;
  }>({
    type: "GREYTIP",
    credentialData: {
      greytip_host: "",
      api_user_name: "",
      api_user_password: "",
    },
  });
  const navigate = useNavigate();
  const [consentGiven, setConsentGiven] = useState(false);
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const { data: existingConfig } = useGetGreyTipConfigs();
  const { mutate: testGreyTipConfig } = useTestHrmsConfig();
  const { mutate: syncGreyTip } = usesyncHrms();
  const handleConfigChange = (
    field: keyof GreytipCredentials,
    value: string
  ) => {
    setGreyTipConfig((prevConfig) => ({
      ...prevConfig,
      credentialData: {
        ...prevConfig.credentialData,
        [field]: value,
      },
    }));
  };
  function testCredentials() {
    if (
      !greyTipConfig.credentialData.greytip_host ||
      !greyTipConfig.credentialData.api_user_name ||
      !greyTipConfig.credentialData.api_user_password
    ) {
      toast("Please fill all the fields", { type: "error", autoClose: 2000 });
      return;
    }
    setOperationInProgress(true);
    testGreyTipConfig(greyTipConfig, {
      onSuccess: (data) => {
        setOperationInProgress(false);
        setCredentialsValid(true);
        toast("Credentials are valid, proceed to Import", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err) => {
        setOperationInProgress(false);
        toast("Invalid Credentials", { type: "error", autoClose: 2000 });
      },
    });
  }
  function startImport() {
    setOperationInProgress(true);
    syncGreyTip(greyTipConfig, {
      onSuccess: (data) => {
        setOperationInProgress(false);
        setCredentialsValid(true);
        toast("Data Imported Successfully", {
          type: "success",
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/options/allEmployees");
        }, 2000);
      },
      onError: (err) => {
        setOperationInProgress(false);
        toast("Error in Importing Data", { type: "error", autoClose: 2000 });
      },
    });
  }

  return (
    <HStack key="greytip-import">
      <VStack className="justify-between w-full">
        <Box className="flex justify-center w-full px-10 text-lg font-medium">
          <h6 className="flex justify-between">
            Enter Your Greytip Credentials
          </h6>
        </Box>
        <HStack className="gap-8 pt-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">GreyTip Host</Label>
            <Input
              autoComplete="false"
              type="text"
              required
              disabled={credentialsValid}
              name="greytip_host"
              placeholder="eg : *.greythr.com"
              onChange={(e) =>
                handleConfigChange("greytip_host", e.target.value)
              }
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">GreyTip API Username</Label>
            <Input
              type="text"
              name="greytip_api_username"
              required
              disabled={credentialsValid}
              onChange={(e) =>
                handleConfigChange("api_user_name", e.target.value)
              }
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">GreyTip API Password</Label>
            <Input
              type="password"
              name="greytip_api_password"
              required
              disabled={credentialsValid}
              onChange={(e) =>
                handleConfigChange("api_user_password", e.target.value)
              }
            />
          </div>
        </HStack>
        <HStack className="mt-8">
          <CheckBoxWithLabel
            label="By checking this box, I authorize HISSA to access my Employee data from Greytip."
            labelclassname={"ml-2"}
            onChange={(e) => setConsentGiven(e.target.checked)}
          />
        </HStack>
        <HStack className="justify-end gap-4 p-3">
          <ButtonPrimary1
            type="reset"
            className="text-red-500"
            onClick={() => {
              props.resetData();
            }}
          >
            Cancel
          </ButtonPrimary1>
          {operationInProgress ? (
            <Icon
              className="animate-spin text-orange-501"
              icon="lucide:loader-2"
              width={36}
            />
          ) : (
            <>
              {!credentialsValid ? (
                <ButtonPrimary
                  type="reset"
                  disabled={!consentGiven}
                  className="text-red-500"
                  onClick={() => {
                    testCredentials();
                  }}
                >
                  Test
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  type="reset"
                  disabled={!consentGiven}
                  className="text-red-500"
                  onClick={() => {
                    startImport();
                  }}
                >
                  Import
                </ButtonPrimary>
              )}
            </>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
}

export default GreyTipImport;
