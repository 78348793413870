/* eslint-disable default-case */
import { useFormik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import { KeyboardEventHandler, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { Input, Label } from "../../../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  Error,
} from "../../../../components/utils";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import { KeyPersonnelType } from "../../../../types/modelling";
import OrganiSationStructureDataProcessor from "../../companyDetails/utils/OrgStructureJSONDataProc";

interface AddKeyPersonnel {
  department: string;
  level: string;
  role: string;
}

interface LabelData {
  label: string;
  value: string;
}

const AddKeyPersonnelCompModellingDialog = ({
  open,
  onClose = () => {},
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    compensationModellingData,
    addorDeleteKeyPersonnelToCompensationModelling,
  } = useOrgTemplateStore();

  const [employeesList, setEmployeesList] = useState<
    { label: string; value: string }[]
  >([]);

  const [employeesData, setEmployeesData] = useState<KeyPersonnelType[]>([]);

  const formik = useFormik({
    initialValues: { employees: [], percentage: 0 },
    onSubmit: async (values) => {
      for (const employee of values.employees) {
        const emp = employee as LabelData;
        const data = employeesData.find((e) => e.employeeId === emp.value);
        if (data?.department && data.role && data.employeeId && data.level) {
          addorDeleteKeyPersonnelToCompensationModelling(
            data.department,
            data.level,
            data.role,
            emp.value,
            true,
            values.percentage
          );
        }
      }
      onClose();
    },
  });

  useEffect(() => {
    if (compensationModellingData?.data) {
      const processedData = new OrganiSationStructureDataProcessor(
        undefined,
        compensationModellingData.data
      );
      const data = processedData.getEmployeesWithAllDetails();
      setEmployeesData(data);
      setEmployeesList(
        data.map((emp) => ({
          label: `${emp.name} (${emp.employeeId})`,
          value: emp.employeeId,
        }))
      );
    }
  }, [compensationModellingData]);

  return (
    <>
      <div className="font-semibold border-b p-7">
        <div className="flex items-center justify-between">
          <VStack className="gap-2 text-lg2 text-black-501">
            <p> Key Personnel</p>
            <p className="text-sm4 text-gray-401">
              Select employees to be categorised as key personnel.
            </p>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </div>
      <VStack className="w-full gap-6 p-7">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Employee List</Label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              isClearable
              options={employeesList}
              onChange={(newValue) =>
                formik.setFieldValue("employees", newValue)
              }
            />
            <div className="flex-1"></div>
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Percentage Of Salary</Label>
            <Input type="number" {...formik.getFieldProps("percentage")} />
          </div>
        </HStack>
        <HStack className="justify-end gap-3 pt-6">
          <ButtonSecondary
            onClick={() => onClose()}
            className="p-10 text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddKeyPersonnelCompModellingDialog;
