const StatusLabelColors = {
  GRANTED: {
    color: "rgba(122, 208, 150, 1)",
    backgroundColor: "rgba(232, 255, 243, 1)",
  },
  OFFER_REJECTED: {
    color: "rgba(232, 89, 54, 1)",
    backgroundColor: "rgba(251, 227, 221, 1)",
  },
  OFFERED: {
    color: "rgba(230, 18, 151, 1)",
    backgroundColor: "rgba(230, 18, 151, 0.1)",
  },
  FOR_APPROVAL: {
    color: "rgba(30, 18, 151, 1)",
    backgroundColor: "rgba(30, 18, 151, 0.1)",
  },
  REQUESTED: {
    color: "rgba(30, 18, 151, 1)",
    backgroundColor: "rgba(30, 18, 151, 0.1)",
  },
  FORAPPROVAL: {
    color: "rgba(30, 18, 151, 1)",
    backgroundColor: "rgba(30, 18, 151, 0.1)",
  },
  APPROVED: {
    color: "rgba(77, 123, 255, 1)",
    backgroundColor: "rgba(77, 123, 255, 0.1)",
  },
  NOT_APPROVED: {
    color: "rgba(232, 89, 54, 1)",
    backgroundColor: "rgba(251, 227, 221, 1)",
  },
  CREATED: {
    color: "rgba(54, 153, 255, 1)",
    backgroundColor: "rgba(229, 242, 255, 1)",
  },
  RESIGNED: {
    color: "rgba(165, 160, 5, 1)",
    backgroundColor: "rgba(165, 160, 5, 0.2)",
  },
  ACTIVE: {
    color: "rgba(122, 208, 150, 1)",
    backgroundColor: "rgba(232, 255, 243, 1)",
  },
  DRAFT: {
    color: "rgba(255, 168, 0, 1)",
    backgroundColor: "rgba(255, 238, 204, 1)",
  },
  COMPLETE: {
    color: " rgb(255,193,64)",
    backgroundColor: "rgb(255,248,221)",
  },
  COMPLETED: {
    color: "rgba(122, 208, 150, 1)",
    backgroundColor: "rgba(232, 255, 243, 1)",
  },
  STARTED: {
    color: "rgba(30, 18, 151, 1)",
    backgroundColor: "rgba(30, 18, 151, 0.1)",
  },
  WITHDRAW: {
    color: " rgb(255,193,64)",
    backgroundColor: "rgb(255,248,221)",
  },
  CONSTRUCTED: {
    color: " rgb(255,193,64)",
    backgroundColor: "rgb(255,248,221)",
  },
  AMENDMENT: {
    color: " rgb(255,193,64)",
    backgroundColor: "rgb(255,248,221)",
  },
  REJECTED: {
    color: "rgba(232, 89, 54, 1)",
    backgroundColor: "rgba(251, 227, 221, 1)",
  },
  EXPIRED: {
    color: "rgba(232, 89, 54, 1)",
    backgroundColor: "rgba(251, 227, 221, 1)",
  },
  ROUND: {
    color: "rgba(232, 89, 54, 1)",
    backgroundColor: "rgba(251, 227, 221, 1)",
  },
  CLOSED: {
    backgroundColor: "rgba(129, 117, 117, 0.2)",
    color: "rgba(129, 117, 117, 1)",
  },
  OPEN: {
    color: "rgba(30, 18, 151, 1)",
    backgroundColor: "rgba(30, 18, 151, 0.1)",
  },
  EXTENDED: {
    color: "rgba(232, 89, 54, 1)",
    backgroundColor: "rgba(251, 227, 221, 1)",
  },
  EMPLOYED: {
    color: "rgba(122, 208, 150, 1)",
    backgroundColor: "rgba(232, 255, 243, 1)",
  },
  PENDING: {
    color: "rgba(77, 123, 255, 1)",
    backgroundColor: "rgba(77, 123, 255, 0.1)",
  },
};

export default StatusLabelColors as any;
