/* eslint-disable etc/no-commented-out-code */
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { ButtonPrimary, HStack, VStack } from "../../../components/utils";
import CompanyStructureTemplateDialog from "./dialogs/CompanyStructureTemplateDialog";
import ExcelImport from "../../allEmployees/ExcelImport";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";

type OrganisationStructureProps = {
  onStepChange: () => void;
};

export default function OrganisationStructure(
  props: Readonly<OrganisationStructureProps>
) {
  const [templateDialog, setTemplateDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const [employeeDialog, setEmployeeDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  function resetData() {
    setEmployeeDialog({ open: false });
  }
  function saveData() {}
  function onClose() {
    setEmployeeDialog({ open: false });
  }
  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Create Organisation Structure
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Provide details of your organisation’s hierarchy, employees, and key
          personnel
        </HStack>
      </VStack>
      <HStack className="justify-center gap-8 mx-12 mt-8">
        <div className="justify-center font-medium text-center text-sm3 text-gray-401">
          Click on the below buttons to select an organization structure
          <br /> from the options provided
        </div>
      </HStack>
      <HStack className="gap-8 mx-12 mt-8">
        <HStack className="items-center justify-around w-full px-12">
          <div className="">
            <ButtonPrimary
              onClick={() => {
                setTemplateDialog({ open: !templateDialog.open });
              }}
            >
              Select Template
            </ButtonPrimary>
          </div>
          <VStack className="py-4">
            <HStack>
              <HStack className=" h-0.5 w-14  bg-gray-201 rounded flex-col items-center mt-3 "></HStack>
              <HStack className="px-2 text-base text-gray-950">Or</HStack>
              <HStack className=" h-0.5 w-14  bg-gray-201 rounded flex-col items-center mt-3"></HStack>
            </HStack>
          </VStack>
          <div className="">
            <ButtonPrimary
              onClick={() => {
                setEmployeeDialog({ open: !employeeDialog.open });
              }}
            >
              I have Employee List
            </ButtonPrimary>
          </div>
        </HStack>
      </HStack>
      <HStack className="gap-8 mx-12 mt-8">
        <div className="mx-auto">
          <img
            src="../assets/rafiki@2x.svg"
            alt="llll"
            height={"250px"}
            width={"250px"}
          />
        </div>
      </HStack>
      <Dialog
        maxWidth="xl"
        open={templateDialog.open}
        onClose={() => setTemplateDialog({ open: false })}
      >
        <div className="w-[1360px] h-[700px] bg-white rounded-lg">
          <CompanyStructureTemplateDialog
            open={templateDialog.open}
            onClose={() => setTemplateDialog({ open: false })}
            onStepChange={props.onStepChange}
          />
        </div>
      </Dialog>
      <Dialog
        maxWidth="xl"
        open={employeeDialog.open}
        onClose={() => setEmployeeDialog({ open: false })}
      >
        <div className="px-4">
          <div>
            <HStack className="justify-between p-2 py-4 text-lg font-medium text-left bg-white border-b">
              <h6 className="flex-1">Import Employees</h6>
              <span onClick={() => onClose()} className="cursor-pointer">
                X
              </span>{" "}
            </HStack>
          </div>
          <div className="w-[1360px] h-[700px] bg-white rounded-lg pt-4">
            <ExcelImport
              resetData={resetData}
              usingForModelling={true}
              onClose={onClose}
              onStepChange={props.onStepChange}
            />
          </div>
        </div>
      </Dialog>
    </VStack>
  );
}
