import { useMutation, useQuery } from "react-query";
import {
  updateCompanyProfile,
  createEsopCompany,
  getCompanyProfileDetails,
} from "../api/onBoarding";
import { queryClient } from "./client";

export function useCompanyProfileDetails() {
  return useQuery({
    queryKey: "getCompanyProfileDetails",
    queryFn: async () => (await getCompanyProfileDetails()).data,
  });
}

export function useUpdateCompanyProfile() {
  return useMutation({
    mutationKey: "updateCompanyProfile",
    mutationFn: updateCompanyProfile,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useCreateESOPCompany() {
  return useMutation({
    mutationKey: "createCompany",
    mutationFn: createEsopCompany,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
}
