import { QueryFunctionContext } from "react-query";
import optionsApi from "./optionsApi";
import { HiringPlanData } from "../pages/esopModeling/companyDetails/utils/OrgStructureJSONDataProc";

export async function getAllHiringPlans(): Promise<HiringPlanData[]> {
  return optionsApi
    .get("v1/hiringPlan/getAllHiringPlans")
    .then((res) => res.data.data);
}

export async function createHiringPlan(
  data: HiringPlanData
): Promise<HiringPlanData> {
  return optionsApi
    .post("v1/hiringPlan/createHiringPlan", data)
    .then((res) => res.data.data);
}
