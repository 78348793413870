import { useEffect } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { format } from "date-fns/esm";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { Switch } from "@mui/material";
import { Grant } from "../../types/Grant";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import {} from "../../queries/esopPlan";
import { Input, Label } from "../../components/shared/InputField";
import {
  useDoVestRequest,
  useVestableOptionsDetails,
} from "../../queries/vest";
import { VestReq } from "../../types/Vest";
import { useError } from "../../store/errorStore";
import { formatDisplayDate } from "../../utils/date";

function VestRequest({
  grant,
  onClose = () => {},
}: {
  grant: Grant;
  onClose: () => void;
}) {
  const { data, error } = useVestableOptionsDetails(
    grant.optionHolderId.toString() ?? ""
  );
  const _error = error as AxiosError;
  const errorType = _error?.response?.data as {
    reason: string;
    reasonCode: number;
  };
  const { mutate: doVestRequest, status } = useDoVestRequest();
  const errorMessage = useError();
  const initialValues: Partial<VestReq> = {
    ...data,
    id: data?.id,
    percentageOfSharesToVest: 0,
    isResetUnvestedShares: false,
  };
  useEffect(() => {
    if (error) {
      onClose();
      toast(errorType.reason, { type: "error", autoClose: 2000 });
    }
  }, [data, error]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const vestReq: Partial<VestReq> = {
        ...data,
        percentageOfSharesToVest: (values?.percentageOfSharesToVest || 0) / 100,
      };
      doVestRequest(vestReq as VestReq, {
        onSuccess: () => {
          toast("Vest Request Submitted!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data, { type: "error", autoClose: 2000 });
        },
      });
    },
  });

  return (
    <>
      <div className="stone-700 p-4 px-10 mt-4 ml-3 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Request to Vest Options
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack className=" justify-between gap-8 p-4 px-10">
        <HStack aria-label="section body" className="grow flex-wrap">
          {[
            { field: "Employee Name", value: data?.employeeName },
            {
              field: "Plan Name",
              value: data?.planName,
            },
            {
              field: "Event Completion Date",
              value:
                data?.eventDate &&
                formatDisplayDate(new Date(data?.eventDate).toUTCString()),
            },
            {
              field: "Options Available To Vest",
              value: data?.numberOfSharesToVest,
            },
          ].map(({ field, value }, i) => (
            <HStack key={i} className="w-1/2 gap-8 p-3">
              <span className="text-stone-700 text-sm font-normal">
                {field}
              </span>
              <span className="text-sm font-medium text-gray-600">
                {value?.toString()}
              </span>
            </HStack>
          ))}
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1 ml-3">
            <Label className="text-sm font-normal">
              Percentage To Vest {"(%)"}
            </Label>
            <Input
              type="number"
              min={0}
              max={100}
              {...formik.getFieldProps("percentageOfSharesToVest")}
            />
            <Label className="self-end mt-1 text-sm font-normal">
              {`Total ${Math.floor(
                ((data?.numberOfSharesToVest || 0) *
                  (formik.values.percentageOfSharesToVest || 0)) /
                  100
              )} options will be vested`}
            </Label>
          </div>
          <HStack className="items-center flex-1 gap-8 p-8 mr-4">
            <Label className=" text-sm font-normal">
              Return Unvested Options To Pool
            </Label>
            <Switch
              checked={formik.values.isResetUnvestedShares}
              onChange={(e) =>
                formik.setFieldValue(
                  "isResetUnvestedShares",
                  !formik.values.isResetUnvestedShares
                )
              }
              value={formik.values.isResetUnvestedShares}
            />
          </HStack>
        </HStack>
        <HStack className="flex flex-row-reverse gap-4">
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => {
              if (status === "success") {
                onClose();
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {(status === "idle" || status === "error") && "Send Request"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => onClose()}
            className="bg-slate-50 text-gray-400"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      </VStack>
    </>
  );
}
export default VestRequest;
