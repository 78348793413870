import { Fragment, useEffect, useMemo, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { useLocation, useMatch, useNavigate } from "react-router";
import { Action, Resource } from "../utils/interfaces/Companies";
import { determineUserAccessToResource } from "../utils/auth";
import { usePermissionStore } from "../store/permissionStore";
import { useAuthStore } from "../store";
import { useUserDetails } from "../queries";

export default function FlyoutSettings() {
  const [show, setShown] = useState(false);
  const { permission } = usePermissionStore();
  const navigate = useNavigate();
  const { data: user } = useUserDetails();
  const location = useLocation();
  const isInternalUser = useMemo(
    () => user?.email_id.endsWith("@rulezero.com"),
    [user]
  );
  const [selectedItem, setSelectedItem] = useState("");
  useEffect(() => {
    const currentPath = location.pathname;
    if (
      currentPath.startsWith("/settings") ||
      currentPath.startsWith("/auth")
    ) {
      setSelectedItem(currentPath.replace("/", ""));
    } else {
      setSelectedItem("");
    }
  }, [location]);

  const settingsItems = [
    {
      name: "Company Details",
      description:
        "Provide official details of the company as per your records",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "heroicons:adjustments-horizontal",
      onClick: () => {
        navigate("/settings/companyProfile");
        setShown(true);
        setSelectedItem("Company Profile ");
      },
      enabledForEsopOnlyCompany: true,
      path: "settings/companyProfile",
      useCurrentPortal: true,
    },
    {
      name: "Site Settings",
      description: "Adjust ESOP portal preferences and configurations",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "ooui:page-settings",
      onClick: () => {
        navigate("/settings/siteSettings");
        setShown(true);
        setSelectedItem("Site Settings");
      },
      enabledForEsopOnlyCompany: true,
      path: "settings/siteSettings",
      useCurrentPortal: true,
    },
    {
      name: "FAQ",
      description: "Manage frequently asked questions for user understanding",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "wpf:faq",
      onClick: () => {
        navigate("/settings/faq");
        setShown(true);
        setSelectedItem("FAQ");
      },
      enabledForEsopOnlyCompany: true,
      path: "settings/faq",
      useCurrentPortal: true,
    },
    {
      name: "User & Roles",
      description: "Set up and administer user accounts and roles",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "eos-icons:role-binding-outlined",
      onClick: () => {
        navigate("/auth/usersRoles");
        setShown(true);
        setSelectedItem("User & Roles");
      },
      useCurrentPortal: true,
      isInternal: true,
      path: "auth/usersRoles",
    },
  ];
  const [routes, setRoutes] = useState(settingsItems);
  useMemo(() => {
    if (!permission) return;
    const filteredSettingsItems = settingsItems
      .filter((sideNavItem) =>
        determineUserAccessToResource(
          permission.aclList,
          sideNavItem.permissions.resource,
          sideNavItem.permissions.action
        )
      )
      .filter((sideNavItem) =>
        sideNavItem.isInternal ? isInternalUser : true
      );
    setRoutes(filteredSettingsItems);
  }, [permission]);

  return (
    <Popover className="relative">
      <Popover.Button
        onClick={() => setShown(!show)}
        onMouseEnter={() => {
          setShown(true);
        }}
        onMouseLeave={() => {
          setShown(false);
        }}
        className={`-m-2.5 p-2.5  hover:rotate-90 transition-all transform ${
          selectedItem ? "text-primary" : "text-gray-400 hover:text-gray-500"
        } `}
      >
        <span className="sr-only">Settings</span>
        <Icon icon="material-symbols:settings-rounded" className={`h-6 w-6 `} />
      </Popover.Button>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={() => {
            setShown(true);
          }}
          onMouseLeave={() => {
            setShown(false);
          }}
          className="absolute z-10 mt-5 flex w-screen max-w-max  -right-8 "
        >
          <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
            <div className="bg-gray-50">
              <a
                key={"setting"}
                href={"setting"}
                className="flex items-center justify-center gap-x-1 p-2 font-semibold text-gray-900 hover:bg-gray-100"
              >
                {"Settings"}
              </a>
            </div>
            <div className="p-4  cursor-pointer">
              {routes.map((item) => (
                <div
                  key={item.name}
                  onClick={item.onClick}
                  className="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50 mb-2"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <Icon
                      icon={`${item.icon}`}
                      className={`h-6 w-6  group-hover:text-primary ${
                        item.path === selectedItem
                          ? "text-primary"
                          : "text-gray-600"
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <a
                      className={`font-semibold  ${
                        item.path === selectedItem
                          ? "text-primary"
                          : "text-gray-600"
                      } group-hover:text-primary`}
                    >
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
