import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Box, VStack } from "../../components/utils";
import { useEsopPlans, useGrants } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import CardPend from "../../shared/card-pend";
import CardPlan from "../../shared/card-plan";
import CardPool from "../../shared/card-pool";
import { Dropdown } from "../../utils/interfaces/BCHeader";
import GrantsTable from "../esopOverview/GrantsTable";
import VestingEvents from "./VestingEvents";

import CardTransaction from "../../shared/card-transaction";
import { usePermissionStore } from "../../store/permissionStore";
import { determineUserAccessToResource } from "../../utils/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";

function AllPlans() {
  const navigate = useNavigate();
  const { data: grants } = useGrants();
  const { data: esopTableDtoList } = useEsopPlans();
  const plans = esopTableDtoList || [];
  const [selectedPlan, setSelectedPlan] = useState<Dropdown>({
    name: "",
    id: "",
  });
  const { permission } = usePermissionStore();
  const planDropdown: Dropdown[] = [];
  useEffect(() => {
    setSelectedPlan({ name: "All Plans", id: "Total" });
  }, []);
  plans.forEach((plan, index) => {
    planDropdown.push({ name: plan.planName, id: plan.esopPlanId });
  });
  function handleAction() {
    if (
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.OptionsPlan,
        DefaultAction.Create
      )
    ) {
      navigate("/options/plan/add");
    }
  }

  planDropdown.unshift(selectedPlan);
  function handlePlan(e: React.ChangeEvent<HTMLSelectElement>) {
    const id = e.target.value;
    if (id === "Total") {
      navigate("/options/allPlans");
    } else navigate(`/options/planView/${id}`);
  }
  const { isMobile } = useIsMobile();
  return (
    <VStack className="gap-4">
      <BCHeader
        className="items-baseline "
        bcTitle="Equity Plans & Grants"
        bcSubTitle={
          selectedPlan?.name === "Equity Plans & Grants"
            ? ""
            : selectedPlan?.name ?? ""
        }
        buttonName="Create ESOP Plan"
        onClick={handleAction}
        selectOption={handlePlan}
        selectedValue={selectedPlan}
        dropdown={planDropdown}
        bcDescription="Explore comprehensive details of your equity plans, track grant allocations, and monitor plan utilizations across the organization."
      />
      <Box className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
        <CardPlan />
        <CardPool />
        <CardTransaction />
      </Box>
      <Box
        className={`grid gap-6 overflow-auto  ${
          isMobile ? "grid-cols-1" : "grid-cols-2"
        }`}
      >
        <VestingEvents />
        <CardPend></CardPend>
      </Box>
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} mb-10`}>
        {grants && <GrantsTable />}
      </Box>
    </VStack>
  );
}

export default AllPlans;
