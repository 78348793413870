import { QueryFunctionContext } from "react-query";
import {
  ApprovedExerciseDetails,
  CumulativeOffer,
  ErrorInterface,
  OptionBuyBackDetails,
} from "../types/OptionBuyback";
import hissaApi from "./hissaApi";
import optionsApi from "./optionsApi";

export async function getOptionBuybackDetails(): Promise<
  OptionBuyBackDetails[]
> {
  return optionsApi
    .get("v1/liquidityEvent/getAll")
    .then((res) => res.data.data);
}

export async function createOptionBuybackDetails(
  addOptionBuyback: OptionBuyBackDetails
): Promise<OptionBuyBackDetails> {
  return optionsApi
    .post("v1/liquidityEvent/create", addOptionBuyback)
    .then((res) => res.data.data);
}

export async function getEventOffers(
  context: QueryFunctionContext
): Promise<CumulativeOffer[]> {
  const buybackId = context.queryKey[1];
  return optionsApi
    .get(`v1/liquidityEvent/getEventOffers`, { params: { id: buybackId } })
    .then((res) => res.data.data);
}
export async function discardBuyback(buybackId: string) {
  return optionsApi
    .delete(`v1/liquidityEvent/delete/${buybackId}`, {
      params: { id: buybackId },
    })
    .then((res) => res.data);
}

export async function sendLiquidationIntimation(
  buybackId: string
): Promise<string> {
  return optionsApi
    .put(`v1/liquidityEvent/startLiquidation/${buybackId}`)
    .then((res) => res.data.data);
}

export async function startLiquidityEvent(
  eventId: string
): Promise<OptionBuyBackDetails | ErrorInterface> {
  return optionsApi
    .put(`v1/liquidityEvent/start/${eventId}`)
    .then((res) => res.data.data);
}

export async function completeLiquidityEvent(
  eventId: string
): Promise<OptionBuyBackDetails | ErrorInterface> {
  return optionsApi
    .put(`v1/liquidityEvent/complete/${eventId}`)
    .then((res) => res.data.data);
}

export async function closeLiquidityEvent(
  eventId: string
): Promise<OptionBuyBackDetails | ErrorInterface> {
  return optionsApi
    .put(`v1/liquidityEvent/close/${eventId}`)
    .then((res) => res.data.data);
}

export async function editLiquidityEvent(
  editOptionBuyback: OptionBuyBackDetails
): Promise<OptionBuyBackDetails> {
  return optionsApi
    .put(`v1/liquidityEvent/edit/${editOptionBuyback.id}`, editOptionBuyback)
    .then((res) => res.data.data);
}

export async function editPilotMode(pilotDto: {
  eventId?: string;
  mode: boolean;
}): Promise<any> {
  return optionsApi
    .put(`v1/liquidityEvent/autoPilotMode/${pilotDto.mode}/${pilotDto.eventId}`)
    .then((res) => res.data.data);
}

export async function uploadEmployeeOffersList(file: any): Promise<any> {
  return hissaApi
    .post("/usingexcelfile/createTransactionOffers", file)
    .then((res) => res.data);
}

export async function uploadTDSExcel(body: any): Promise<any> {
  return hissaApi
    .post("/usingexcelfile/uploadTDS", body)
    .then((res) => res.data);
}

export async function createExerciseEvent(
  body: OptionBuyBackDetails
): Promise<any> {
  return optionsApi
    .post("v1/liquidityEvent/createExerciseEvent", body)
    .then((res) => res.data);
}

export async function completeExerciseEvent(eventId: string): Promise<any> {
  return optionsApi
    .post(`v1/liquidityEvent/completeExerciseEvent?eventId=${eventId}`)
    .then((res) => res.data);
}

export async function getAllApprovedExercises(
  context: QueryFunctionContext
): Promise<ApprovedExerciseDetails[]> {
  const eventId = context.queryKey[1];
  return optionsApi
    .get(`v1/liquidityEvent/approvedExercises?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function closeExerciseEvent(eventId: string): Promise<any> {
  return optionsApi
    .post(`v1/liquidityEvent/closeExerciseEvent?eventId=${eventId}`)
    .then((res) => res.data);
}

export async function pauseExerciseEvent(pauseObj: {
  eventId: string;
  actionType: string;
  offerIds: string[];
  sendEmails: boolean;
}): Promise<any> {
  return optionsApi
    .post(`v1/liquidityEvent/pauseExerciseEvent`, pauseObj)
    .then((res) => res.data);
}

export async function editExerciseRequestedNumber(body: any): Promise<void> {
  return optionsApi
    .put(`v1/liquidityEvent/editRequestedOptions`, body)
    .then((res) => res.data);
}

export async function editPaymentLink(body: {
  paymentLink: string | undefined;
  eventId: string | undefined;
}): Promise<void> {
  return optionsApi
    .patch(`v1/liquidityEvent/editPaymentLink/${body.eventId}`, body)
    .then((res) => res.data);
}
