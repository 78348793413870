/* eslint-disable */
import createStore, { create } from "zustand";
import { configurePersist } from "zustand-persist";
import { Permission } from "../utils/interfaces/Companies";

const { persist: _persist } = configurePersist({
  storage: localStorage,
});

const persist = _persist as any;

interface PermissionStore {
  permission: Permission | null;
  setPermission: (perm: Permission) => void;
  // aclList: any[];
  // isCompanySpecific: boolean;
  // role: string;
  // setPermission: (
  //   auth: Permission | ((auth: Permission) => Permission)
  // ) => void;
  clearPermission: () => void;
}

export const usePermissionStore = create<PermissionStore>(
  persist(
    {
      key: "userPermission",
      name: "userPermission",
      storage: localStorage,
    },
    (set: any) => ({
      permission: null,
      setPermission: (perm: Permission) => {
        set({ permission: perm });
        // aclList: [],
        // isCompanySpecific: false,
        // role: "",
        // // setPermission: (perm: Permission) => {
        // //   set({ permission: perm });
        // // },
        // setPermission: (
        //   auth: Permission | ((auth: Permission) => Permission)
        // ) => {
        //   if (typeof auth === "function") {
        //     set((_state: Permission) => auth(_state));
        //   } else {
        //     set((_state: Permission) => auth);
        //   }
      },
      clearPermission: () => {
        set({ permission: {} });
      },
    })
  )
);
