import { ExerciseReq, Result } from "../types/Exercise";
import { CustomResponse } from "../types/Grant";
import { ErrorInterface } from "../types/OptionBuyback";
import optionsApi from "./optionsApi";

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getExercisableOptionsDetails(
  grantId: string,
  date: string
): Promise<CustomResponse<ExerciseReq>> {
  return optionsApi
    .get(`v1/exercise/exercisableDetails`, { params: { id: grantId, date } })
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: requestExercise
 * @returns
 */

export async function requestToExercise(
  exerciseRequests: ExerciseReq
): Promise<string | ErrorInterface> {
  return optionsApi
    .post(`v1/exercise/createExerciseRequest`, exerciseRequests)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getExerciseRequestDetails(
  grantId: string
): Promise<CustomResponse<ExerciseReq>> {
  return optionsApi
    .get(`v1/exercise/approveDetails`, { params: { id: grantId } })
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: approveExercise
 * @returns
 */

export async function approveExercise(
  approveExerciseReq: ExerciseReq
): Promise<Result> {
  return optionsApi
    .post(`v1/exercise/approveExerciseRequest`, approveExerciseReq)
    .then((res) => res.data);
}

export async function rejectExerciseRequest(id: string): Promise<any> {
  return optionsApi.patch(`v1/exercise/rejectExerciseRequest/${id}`);
}

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getAllExerciseRequests(): Promise<
  CustomResponse<ExerciseReq[]>
> {
  return optionsApi.get(`v1/exercise/exerciseRequests`).then((res) => res.data);
}
