import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Icon } from "@iconify/react";
import {
  AddVestingMilestoneReq,
  ClosureType,
  MilestoneType,
} from "../../types/milestone";
import {
  useCreateMilestones,
  useUpdateMilestones,
} from "../../queries/milestone";
import {
  Box,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
  ButtonPrimary,
} from "../../components/utils";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";

type AddOrEditMilestoneProps = {
  mode: "Edit" | "Add" | "Delete";
  data?: AddVestingMilestoneReq;
  onClose: () => void;
};

function AddOrEditMilestone(props: AddOrEditMilestoneProps) {
  const { data, mode, onClose } = props;
  const baseValues: AddVestingMilestoneReq = {
    milestoneName: "",
    closureType: ClosureType.Manual,
    milestoneType: MilestoneType.CompanyWide,
    targetDate: new Date(),
    notes: "",
  };

  function getInitialValuesEditing(data: AddVestingMilestoneReq | undefined) {
    if (!data) return baseValues;
    return {
      id: data.id,
      milestoneName: data.milestoneName,
      closureType: data.closureType,
      milestoneType: data.milestoneType,
      targetDate: data.targetDate,
      notes: data.notes,
    };
  }

  function getInitialValues() {
    if (mode === "Edit") {
      return getInitialValuesEditing(data);
    } else {
      return baseValues;
    }
  }

  const { mutate: addMilestone, isLoading: addMilestoneloading } =
    useCreateMilestones();
  const { mutate: editMilestone, isLoading: editMilestoneloading } =
    useUpdateMilestones();

  const validationSchema = Yup.object().shape({
    milestoneName: Yup.string().required("name is required"),
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: () => {
      const milestoneDto = { ...formik.values };
      milestoneDto.targetDate = new Date(milestoneDto.targetDate || new Date());
      if (mode === "Add") {
        addMilestone([milestoneDto], {
          onSuccess: () => {
            toast("Milestone Added Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        editMilestone(milestoneDto, {
          onSuccess: () => {
            toast("Milestone Edited Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      }
    },
  });

  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit" ? "Edit Milestone" : "Add Milestone"}{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Milestone Name</Label>
            <Input type="text" {...formik.getFieldProps("milestoneName")} />
            {formik.touched.milestoneName && formik.errors.milestoneName && (
              <Error text={formik.errors.milestoneName} />
            )}
          </div>
          <div className="flex-1">
            <Label>Milestone Type</Label>
            <Select
              options={[MilestoneType.CompanyWide, MilestoneType.Individual]}
              {...formik.getFieldProps("milestoneType")}
            />
            {formik.touched.milestoneType && formik.errors.milestoneType && (
              <Error text={formik.errors.milestoneType} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Closure Type</Label>
            <Select
              options={[ClosureType.Manual]}
              {...formik.getFieldProps("closureType")}
            />
            {formik.touched.closureType && formik.errors.closureType && (
              <Error text={formik.errors.closureType} />
            )}
          </div>
          <div className="flex-1">
            <Label>Target Date</Label>
            <Input type="date" {...formik.getFieldProps("targetDate")} />
            {formik.touched.targetDate && formik.errors.targetDate && (
              <Error text={formik.errors.targetDate} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Notes</Label>
            <TextArea {...formik.getFieldProps("notes")} />
          </div>
        </HStack>
        <HStack className="justify-between">
          <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={(e) => {
              formik.handleSubmit();
            }}
            loading={addMilestoneloading || editMilestoneloading}
          >
            {mode === "Add" ? "Create" : "Update"}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}

export default AddOrEditMilestone;
