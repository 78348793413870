import { Icon } from "@iconify/react";
import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { Grant } from "../../types/Grant";

import { Input, Label } from "../../components/shared/InputField";
import {
  useExercisableOptionsDetails,
  useRequestToExercise,
} from "../../queries/exercise";
import { ExerciseReq, RaisedBy } from "../../types/Exercise";
import { ErrorInterface } from "../../types/OptionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { formatDate } from "../../utils/date";

function ExerciseRequest({
  grant,
  onClose = () => {},
}: {
  grant: Grant;
  onClose: () => void;
}) {
  const currency = getCurrencyType();
  const [exerciseDate, setExerciseDate] = useState(new Date().toDateString());
  const [numberOfShares, setNumberOfShares] = useState(0);
  const [isCashExercise, setIsCashExercise] = useState(false);

  const { data, refetch } = useExercisableOptionsDetails(
    grant.optionHolderId || "",
    exerciseDate
  );
  const { mutate: requestToExercise, status } = useRequestToExercise();
  const initialValues: ExerciseReq = {
    esopPlanName: data?.esopPlanName || "",
    planType: data?.planType || "",
    exercisePrice: data?.exercisePrice || 0,
    optionHolderName: data?.optionHolderName || "",
    optionHolderId: data?.optionHolderId || "",
    optionsAvailable: data?.optionsAvailable || 0,
    numberOfShares,
    isCashExercise,
    isFractional: data?.isFractional || false,
    dateOfExercise:
      formatDate(exerciseDate) || formatDate(new Date().toDateString()),
    conversionRatio: data?.conversionRatio || 1,
    note: data?.note || "",
    id: data?.id || "",
    identifier: data?.identifier || "",
    status: data?.status || "",
    raisedBy: RaisedBy.ADMIN,
    isSellToCover: false,
    sharesSoldToCover: 0,
  };
  const conversionRatio = initialValues.conversionRatio;
  const validationSchema = Yup.object({
    numberOfShares: Yup.number()
      .required("required")
      .positive("should not be zero")
      .max(
        data?.optionsAvailable || 0,
        `available options to exercise is ${data?.optionsAvailable}`
      )
      .when("isFractional", {
        is: false,
        then: Yup.number().test(
          "fractionalCheck",
          "Decimals are not allowed",
          (value) => {
            if (value && value === Math.floor(value)) {
              return true;
            }
            return false;
          }
        ),
      })
      .when("isCashExercise", {
        is: false,
        then: Yup.number().test(
          "numberOfShares",
          `Number of shares should be a multiple of ${1 / conversionRatio}`,
          (value) => {
            if (
              (value || 0) * conversionRatio ===
              Math.floor((value || 0) * conversionRatio)
            ) {
              return true;
            }
            return false;
          }
        ),
      }),
    dateOfExercise: Yup.date()
      .required("required")
      .max(formatDate(new Date()), "Exercise date cannot be in future"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      requestToExercise(values, {
        onSuccess: () => {
          resetForm();
          toast("Exercise Request Submitted!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          const error = err.response.data as ErrorInterface;
          toast(error.errorMessage, { type: "error", autoClose: 5000 });
          onClose();
        },
      });
    },
  });
  useEffect(() => {
    refetch();
  }, [exerciseDate, formik.values.isCashExercise]);

  useEffect(() => {
    if (formik.values.dateOfExercise) {
      setExerciseDate(formik.values.dateOfExercise);
    }
  }, [formik.values.dateOfExercise]);
  useEffect(() => {
    if (formik.values.numberOfShares) {
      setNumberOfShares(formik.values.numberOfShares);
    }
  }, [formik.values.numberOfShares]);
  useEffect(() => {
    if (formik.values.isCashExercise) {
      setIsCashExercise(formik.values.isCashExercise);
    }
  }, [formik.values.isCashExercise]);

  useEffect(() => {
    formik.setFieldTouched("numberOfShares", true);
  }, [data?.optionsAvailable]);
  const showMessage =
    formik.touched?.numberOfShares &&
    !formik.values?.isCashExercise &&
    !formik.errors.numberOfShares;
  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium border-b stone-700">
        <h6 className="flex justify-between">
          Request to Exercise Options{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-4 p-4 px-10 ">
        <HStack aria-label="section body" className="flex-wrap grow">
          {[
            { field: "Employee Name", value: data?.optionHolderName },
            {
              field: "Plan Name",
              value: data?.esopPlanName,
            },
            {
              field: "Exercise Price",
              value: formatCurrency(data?.exercisePrice || 0, currency),
            },
            {
              field: "Options Available To Exercise",
              value: data?.optionsAvailable,
            },
          ].map(({ field, value }, i) => (
            <HStack key={i} className="w-1/2 gap-8 p-3">
              <span className="text-sm font-normal text-stone-700">
                {field}
              </span>
              <span className="text-sm font-medium text-gray-600">
                {value?.toString()}
              </span>
            </HStack>
          ))}
        </HStack>
        <HStack className="gap-8 px-3">
          <div className="flex-1">
            <Label className="text-sm font-normal">Options to Exercise</Label>
            <Input
              type="number"
              min={1}
              max={data?.numberOfShares}
              {...formik.getFieldProps("numberOfShares")}
            />
            {formik.touched.numberOfShares && formik.errors.numberOfShares && (
              <Error text={formik.errors.numberOfShares} />
            )}
            {showMessage && (
              <Label className="text-xs font-normal">
                {formik.values.numberOfShares} options will be exercised into{" "}
                {(formik.values.numberOfShares || 1) *
                  (formik.values.conversionRatio || 1)}{" "}
                shares
              </Label>
            )}
          </div>
          <div className="flex-1 gap-8 mr-1">
            <Label className="text-sm font-normal">Date Of Exercise</Label>
            <Input
              type="date"
              max={formatDate(new Date())}
              {...formik.getFieldProps("dateOfExercise")}
            />
            {formik.touched.dateOfExercise && formik.errors.dateOfExercise && (
              <Error text={formik.errors.dateOfExercise} />
            )}
          </div>
        </HStack>
        <HStack className="items-center w-1/2 gap-8 ml-3">
          <Label className="text-sm font-normal ">Cash Out?</Label>
          <Switch
            checked={formik.values.isCashExercise}
            onChange={(e) =>
              formik.setFieldValue(
                "isCashExercise",
                !formik.values.isCashExercise
              )
            }
            value={formik.values.isCashExercise}
          />
        </HStack>
        <HStack className="flex flex-row-reverse gap-4">
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => {
              if (status === "success") {
                onClose();
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {(status === "idle" || status === "error") && "Send Request"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      </VStack>
    </>
  );
}
export default ExerciseRequest;
