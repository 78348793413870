import { Popover, Transition } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";
import { Fragment, useEffect, useState } from "react";
import { doesCaptableCompanyExists } from "../api/Esop";
import { HStack } from "../components/utils";
import { useSetDefaultProduct } from "../queries";
import { useAuthStore, useCompanyStore } from "../store";
import esopLogo from "../utils/assets/esop.svg";

export default function FlyoutSwitch() {
  const [show, setShown] = useState(false);
  const { mutate: setDefaultEsop } = useSetDefaultProduct();

  const { companyData } = useCompanyStore();
  const { mutate: setDefaultCaptable } = useSetDefaultProduct();
  const [isCaptableExists, setCaptableExists] = useState(false);
  const canUserAccess = isCaptableExists;
  const esopRole = "";

  useEffect(() => {
    if (
      companyData?.captableCompanyId === undefined ||
      companyData?.captableCompanyId == null
    ) {
      setCaptableExists(false);
    } else {
      doesCaptableCompanyExists(companyData?.captableCompanyId).then(
        (res: { canUserAccess: boolean; captableExists: boolean }) => {
          if (res.canUserAccess && res.captableExists) {
            setCaptableExists(true);
          }
        },
        (err) => {
          setCaptableExists(false);
        }
      );
    }
  }, [companyData]);
  const redirectToCaptable = async () => {
    if (
      companyData?.captableCompanyId !== undefined &&
      companyData?.captableCompanyId &&
      isCaptableExists
    ) {
      const accessToken =
        localStorage.getItem("accessToken") ??
        useAuthStore.getState().accessToken;
      await setDefaultCaptable();
      window.location.href = `${
        process.env.REACT_APP_OPTIONS_URL ?? ""
      }/landing/dashboard?logintoken=${accessToken}&companyId=${
        companyData?.captableCompanyId
      }`;
    }
  };
  const products = [
    {
      name: "Cap Table",
      enabled: isCaptableExists,
      current: false,
      description: "Equity Ownership of Company",
      onClick: redirectToCaptable,
      icon: (
        <div>
          <Icon
            icon="raphael:piechart"
            className={`${
              isCaptableExists ? "text-[#E85936] " : " text-[#BABABA]"
            }`}
            width="30"
            height="30"
          ></Icon>
        </div>
      ),
    },
    {
      name: "ESOP",
      enabled: true,
      current: true,
      description: "ESOP Management of Company",
      onClick: () => {},
      icon: (
        <div>
          <img className="h-8" src={esopLogo} alt="esop" color="#E85936" />
        </div>
      ),
    },
  ];
  return (
    <Popover className="relative">
      <Popover.Button
        onMouseEnter={() => {
          setShown(true);
        }}
        onMouseLeave={() => {
          setShown(false);
        }}
        onClick={() => setShown(!show)}
        className="text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">Switch</span>
        <Icon id="switch" icon="mdi:dots-grid" className={`h-6 w-6`} />
      </Popover.Button>
      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={() => {
            setShown(true);
          }}
          onMouseLeave={() => {
            setShown(false);
          }}
          className="absolute z-10 flex w-screen mt-5 max-w-max -right-8"
        >
          <div className="flex-auto w-screen max-w-xs overflow-hidden text-sm leading-6 bg-white rounded-lg shadow-lg">
            <div className="bg-gray-50">
              <div
                key={"Switch"}
                className="flex items-center justify-center p-2 font-semibold text-gray-900 gap-x-1 hover:bg-gray-100"
              >
                {"Switch Platform"}
              </div>
            </div>
            <div className="p-4 cursor-pointer">
              {products.map((item) => (
                <div
                  key={item.name}
                  onClick={() => {
                    item.onClick();
                  }}
                  className="relative flex p-2 mb-2 rounded-lg group gap-x-2 hover:bg-gray-50"
                >
                  <div className="flex items-center justify-center flex-none mt-1 rounded-lg h-11 w-11 bg-gray-50 group-hover:bg-white">
                    {item.icon}
                  </div>
                  <div className="flex-1">
                    <HStack className="justify-between">
                      <a
                        className={`font-semibold  ${
                          item.current
                            ? "text-primary"
                            : item.enabled
                            ? "text-gray-600 group-hover:text-primary"
                            : "text-gray-300"
                        }`}
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      {!item.enabled && (
                        <LockClosedIcon
                          className={`h-4 w-4 ${
                            item.current
                              ? "text-primary"
                              : item.enabled
                              ? "text-gray-600 group-hover:text-primary"
                              : "text-gray-300"
                          }`}
                          aria-hidden="true"
                        />
                      )}
                    </HStack>
                    <p
                      className={
                        item.enabled ? "text-gray-600" : "text-gray-300"
                      }
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
