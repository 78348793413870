import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "../../components/utils";
import { useEsopPlans } from "../../queries/esopPlan";
import { getCurrencyType } from "../../utils/currencyFormatter";
import useIsMobile from "../../utils/detectDevice";
import { getFormattedValue, stringFormat } from "../../utils/string";

const ESOPPlan = () => {
  const currency = getCurrencyType();
  const { id } = useParams();
  const _id = id ?? "";
  const { data: _plans } = useEsopPlans();
  const plans = _plans ?? [];
  const plan = plans?.find((plan) => plan?.esopPlanId === _id);
  const { isMobile } = useIsMobile();
  return (
    <>
      {plan && (
        <VStack className="bg-white rounded-lg h-96">
          <Box
            className={`grid grid-cols-2 gap-32 p-6 bg-wave bg-center bg-repeat rounded-lg place-content-between h-1/2 items-center`}
          >
            <VStack className="w-60">
              <Box className="font-medium text-white">{plan.planName}</Box>
            </VStack>
            <Box className="grid h-10 font-semibold text-center text-white bg-opacity-25 rounded bg-green-op place-items-center xl:w-28 md:w-24 md:text-xxs xl:text-xs">
              {stringFormat(plan.esopPlanState ?? "")}
            </Box>
          </Box>
          <HStack className="flex-wrap justify-between w-full px-2 py-2 -mt-12 overflow-auto grow h-1/2">
            {[
              {
                field: "Total Options",
                value: getFormattedValue(
                  plan.poolSize,
                  currency,
                  plan.isFractional
                ),
              },
              {
                field: "Holding %",
                value: (plan.planHoldingPercentage * 100).toFixed(2),
              },
              { field: "Plan Type", value: plan.planType },
              {
                field: "Plan Start Date",
                value: plan.planStartDate
                  ? format(new Date(plan.planStartDate), "dd MMM yyyy")
                  : "-",
              },
            ].map(({ field, value }) => (
              <Box
                key={field}
                className={` text-center border rounded-lg py-6 pb-2 mx-auto my-1 bg-slate-100 ${
                  isMobile ? "flex flex-col w-full" : " md:w-28 xl:w-36 sm:28"
                }`}
              >
                <p
                  className={`text-sm font-semibold ${
                    field === "Total Options"
                      ? "text-blue-500"
                      : field === "Holding %"
                      ? "text-amber-500"
                      : field === "Plan Type"
                      ? "text-teal-400"
                      : "text-rose-500"
                  }`}
                >
                  {value}
                </p>
                <p className="text-gray-500">{field}</p>
              </Box>
            ))}
          </HStack>
        </VStack>
      )}
    </>
  );
};
export default ESOPPlan;
