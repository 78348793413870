import { useState } from "react";
import * as Yup from "yup";
import { useCompanyProfileDetails } from "../../queries/onBoarding";
import { CompanyProfileDetails } from "../../types/SiteSettings";
import { formatDate } from "../../utils/date";
import { initialCompanyDetail } from "../companyProfile/initValues";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}

export function Playground() {
  const initialValues = { name: "name", age: 10 };
  const [selectedOption, setSelectedOption] = useState("");
  const { refetch, data: _companyDetail } = useCompanyProfileDetails();
  const [companyLogoImage, setCompanyLogoImage] = useState<FileTypeModel>({
    fileBlob: "",
  });
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const [toggle, setToggle] = useState(false);
  const toggleClass = " transform translate-x-5";
  const actions = ["filter1", "filter2", "filter3", "filter4", "filter5"];
  const handleActions = (e: any) => {
    e.stopPropagation();
  };
  const companyDetail = _companyDetail;

  const getInitialValue = () =>
    companyDetail
      ? {
          id: parseInt(companyDetail.id, 10),
          name: companyDetail.name ?? "",
          cin: companyDetail.cin ?? "",
          companyCurrency: companyDetail.companyCurrency ?? "",
          dateOfIncorporation: formatDate(companyDetail.dateOfIncorporation),
          address: companyDetail.address ?? "",
          email: companyDetail.email ?? "",
          companyLogoPath: companyLogoImage?.fileBlob ?? "",
          operatingAs: companyDetail.operatingAs ?? "",
          nic: companyDetail.nic ?? "",
        }
      : initialCompanyDetail;
  const handleCompanyDetailsSubmit = (values: CompanyProfileDetails) => {
    const formData = new FormData();

    formData.append("nameOfTheCompany", values.name);
    formData.append("doingBusinessAs", values.operatingAs);
    formData.append("cinNumber", values.cin);
    formData.append("dateOfIncorporation", "6/27/05");
    formData.append("corporateOfficeAddress", values.address);
    formData.append("companyEmailId", values.email);
    formData.append("companyCurrency", values.companyCurrency);
    formData.append("nic", values.nic);
    formData.append("companyLogoPath", values.companyLogoPath);
    //   createCompanyDetails(formData, {
    //     onSuccess: () => {
    //       toast("Company Details Saved Successfully!", {
    //         type: "success",
    //         autoClose: 2000,
    //       });          },
    //     onError: (error: { response: { data: { reason: string } } }) => {
    //       toast(`${error.response.data.reason}`, {
    //         type: "error",
    //         autoClose: 2000,
    //       });
    //     },
    //   });
    // };
    const validationCompanyDetailsSchema = Yup.object().shape({
      nameOfTheCompany: Yup.string().required("Company Name is required"),
      cinNumber: Yup.string().required("CIN Number is required"),
      typeOfTheCompany: Yup.string().required("Company Type is required"),
      dateOfIncorporation: Yup.string().required(
        "Date of Incorporation is required"
      ),
      companyEmailId: Yup.string()
        .email("Enter Valid Company Email is required")
        .required("Email is required"),
      companyCurrency: Yup.string().required("Company Currency is required"),
      nic: Yup.string().required("NIC is required"),
      category: Yup.array().min(1, "Category is Required"),
      founders: Yup.array()
        .of(
          Yup.object().shape({
            fullName: Yup.string().required("Founder Name is required"),
            emailId: Yup.string()
              .email("Enter Valid Company Email is required")
              .required("Valid EmailID is required"),
            designation: Yup.string().required("Designation is required"),
            nationality: Yup.string().required("Nationality is required"),
          })
        )
        .required("Required"),
      directors: Yup.array()
        .of(
          Yup.object().shape({
            fullName: Yup.string().required("Director Name is required"),
            emailId: Yup.string()
              .email("Enter Valid Company Email is required")
              .required("Valid E-mail ID is required"),
            typeOfDirector: Yup.string().required(
              "Type of Director is required"
            ),
            din: Yup.string().required("DIN Number is required"),
            nationality: Yup.string().required("Nationality is required"),
          })
        )
        .required("Required"),
    });
  };

  return <div></div>;
}

export default Playground;
