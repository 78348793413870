/* eslint-disable import/no-extraneous-dependencies */
import CryptoJS from "crypto-js";
import { Action, Permission, Resource } from "./interfaces/Companies";

export const determineUserAccessToResource = (
  permissions: Permission["aclList"],
  resource: Resource,
  action: Action
) => {
  if (
    permissions.some(
      (permission) =>
        permission.action === Action.All && permission.resource === Resource.All
    )
  )
    return true;

  const canAccessResource = permissions.some(
    (role) => role.resource === resource
  );
  const canPerformActionOnResource = permissions.some(
    (role) => role.action === action && role.resource === resource
  );

  if (canAccessResource && !canPerformActionOnResource) {
    return permissions.some(
      (role) => role.resource === resource && role.action === Action.All
    );
  }

  if (canPerformActionOnResource && !canAccessResource) {
    return permissions.some(
      (role) => role.resource === Resource.All && role.action === action
    );
  }

  return canAccessResource && canPerformActionOnResource;
};

export const generateHMAC = (data: any) => {
  const sharedKey = process.env.REACT_APP_SECRET_KEY || "";
  const requestPayload = JSON.stringify(data);
  const calculatedHMAC = CryptoJS.HmacMD5(requestPayload, sharedKey).toString();
  return calculatedHMAC;
};
