import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";

import { generateFinancialReport } from "../../api/Esop";
import { Input, Label } from "../../components/shared/InputField";
import { MultiSelect, OptionTypes } from "../../components/shared/MultiSelect";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useEsopPlans } from "../../queries";
import { FinancialReportReq, PlanState } from "../../types/EsopPlan";
import { ReportDto } from "../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { formatDate } from "../../utils/date";

function FinancialReportDialog({
  onClose = () => {},
}: Readonly<{
  onClose: () => void;
}>) {
  const { data: _plans } = useEsopPlans();
  const plans = _plans || [];
  const planOptions: OptionTypes[] = [];
  const ids: string[] = [];
  plans
    .filter((p) => p.esopPlanState === PlanState.ACTIVE)
    .forEach((plan) => {
      planOptions.push({ value: plan.planName });
      ids.push(plan.esopPlanId);
    });
  const initialValues: FinancialReportReq = {
    showFutureVestings: false,
    date: formatDate(new Date().toUTCString()),
    includeResignedEmployees: false,
    planIds: ids,
  };
  const validationSchema = Yup.object({
    date: Yup.date().required("required"),
    planIds: Yup.array()
      .test("notEmpty", "Select a plan", (value) => {
        if (value && value.length > 0) {
          return true;
        }
        return false;
      })
      .required("plan required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (values.planIds.length > 0) {
        generateFinancialReport(values)
          .then((res) => {
            downloadReport(res.data);
          })
          .catch((err) => {
            toast("Something Went Wrong!", { type: "error", autoClose: 2000 });
          });
      }
    },
  });

  const fileName = `Financial Report_${getCompanyName()}`;

  async function downloadReport(reportDto: ReportDto) {
    onClose();
    const blob = await convertBase64ToBlob(
      reportDto.base64File,
      reportDto.fileType
    );
    downloadBlobObject(blob, fileName);
  }

  return (
    <VStack>
      <div className="p-8 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Download Financial Report
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack>
        <HStack className="items-center justify-between gap-4 p-4 pr-[50px]">
          <Label className="text-sm font-normal">Vesting Date</Label>
          <Box>
            <Input
              type="date"
              value={formik.values.date}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (!e.target.value) return;
                formik.setFieldValue("date", e.target.value);
              }}
            />
          </Box>
        </HStack>
        <HStack className="flex-wrap items-baseline justify-start">
          <Box className="flex flex-row-reverse items-center">
            <SwitchButton
              className="mt-4 ml-4 mr-4"
              value={formik.values.showFutureVestings}
              label="Show future vestings as zero"
              onClick={() => {
                formik.setFieldValue(
                  "showFutureVestings",
                  !formik.values.showFutureVestings
                );
              }}
            />
          </Box>
        </HStack>

        <HStack className="flex-wrap items-baseline justify-start mr-4">
          <Box className="flex flex-row-reverse items-center">
            <SwitchButton
              className="mt-4 ml-4 mr-4"
              value={formik.values.includeResignedEmployees}
              label="Include Resigned Employees"
              onClick={() => {
                formik.setFieldValue(
                  "includeResignedEmployees",
                  !formik.values.includeResignedEmployees
                );
              }}
            />
          </Box>
        </HStack>
        <VStack className="w-full p-4 max-w-xl">
          <Label>Select Plans (Optional)</Label>
          <MultiSelect
            _className=""
            multiple={true}
            placeholder={"--Select plans--"}
            options={planOptions}
            handleSelection={(e) => {
              const planIds = plans
                .filter(
                  (p) =>
                    e.includes(p.planName) &&
                    p.esopPlanState === PlanState.ACTIVE
                )
                .map((p) => p.esopPlanId);
              formik.setFieldValue("planIds", planIds);
            }}
            optionValues={plans
              .filter((p) => formik.values.planIds.includes(p.esopPlanId))
              .map((p) => p.planName)}
          />
          {formik.errors.planIds && (
            <Error text={formik.errors.planIds.toString()} />
          )}
        </VStack>
        <HStack className="justify-between px-4 py-6 mt-7">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Generate
          </ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
}
export default FinancialReportDialog;
