import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Input, Label } from "../../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../../components/utils";
import { Select } from "../../../components/shared/Select";
import {
  CompanyData,
  HiringPlanData,
} from "../companyDetails/utils/OrgStructureJSONDataProc";
import { useGetOrgStructureV2 } from "../../../queries/organisationStructure";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import HiringPlanTable from "./HiringPlanTable";
import { useGetAllHiringPlans } from "../../../queries/hiringPlan";

type ExistingModels = {
  id: string | undefined;
  modelName: string;
};

const CreateHiringPlan: React.FC = () => {
  const navigate = useNavigate();
  const [clickedNext, setClickedNext] = useState(false);
  const [prevData, setPrevData] = useState<CompanyData>();
  const { data: orgData, isFetching } = useGetOrgStructureV2();
  const { data: existingPlans, isFetching: plansFetching } =
    useGetAllHiringPlans();
  const { setSelectedCompanyData, selectedCompanyData } = useOrgTemplateStore();
  const [newModel, setNewModel] = useState<boolean>(true);
  const [selected, setSelected] = useState<boolean>(false);
  const [existingModels, setExistingModels] = useState<ExistingModels[]>([]);
  useEffect(() => {
    if (existingPlans) {
      setExistingModels(
        existingPlans.map((plan) => ({
          id: plan.id,
          modelName: plan.modelName,
        }))
      );
    }
  }, [plansFetching]);
  const initialModel: HiringPlanData = {
    modelName: "",
    noOfYears: 0,
    description: "",
    data: selectedCompanyData,
  };
  const [modelData, setModelData] = useState<HiringPlanData>(initialModel);
  useEffect(() => {
    if (
      orgData &&
      _.isEmpty(prevData) &&
      JSON.stringify(prevData) !== JSON.stringify(orgData)
    ) {
      setPrevData(orgData);
      setSelectedCompanyData(orgData as CompanyData);
    }
  }, [isFetching]);
  function onPageChange() {
    if (!modelData || !modelData.modelName || modelData.modelName === "") {
      toast.error("Please Provide Model Name");
      return;
    }
    if (!modelData || !modelData.noOfYears || modelData.noOfYears <= 0) {
      toast.error("Please Provide Number of Years");
      return;
    }
    setClickedNext(true);
  }
  return (
    <div>
      {!clickedNext ? (
        <VStack className="gap-6">
          <VStack className="px-4 bg-white rounded-lg shadow-custom">
            <Box className="px-4 py-4 ">
              <p className="text-xl font-semibold text-gray-dark">
                Hiring Plan
              </p>
              <Box className="text-xs font-normal text-gray-light">
                Create and refine detailed multiple hiring plans for designated
                years
              </Box>
            </Box>
            <VStack className="space-y-4">
              <VStack className="gap-4 p-4 ">
                <ButtonPrimary
                  onClick={() => {
                    setNewModel(true);
                    setSelected(true);
                    setModelData(initialModel);
                  }}
                  className="justify-center bg-gray-300 cursor-not-allowed w-36 hover:bg-gray-300"
                >
                  Model a Hiring Plan
                </ButtonPrimary>
                <VStack className="py-4">
                  <HStack>
                    <HStack className=" h-0.5 w-14  bg-gray-201 rounded flex-col items-center mt-3 "></HStack>
                    <HStack className="px-2 text-base text-gray-950">Or</HStack>
                    <HStack className=" h-0.5 w-14  bg-gray-201 rounded flex-col items-center mt-3"></HStack>
                  </HStack>
                </VStack>
              </VStack>
              <HStack className="w-56 px-4 pb-4">
                <VStack>
                  <Label className="text-sm font-semibold">
                    Select a Model
                  </Label>
                  <HStack className="justify-center gap-4 align-middle">
                    <Select
                      className=""
                      options={existingModels}
                      valueGetter={(e) => e.id}
                      textGetter={(e) => e.modelName}
                      value={modelData.id || ""}
                      onChange={(e) => {
                        setNewModel(false);
                        setSelected(true);
                        setModelData(
                          (existingPlans?.find(
                            (plan) => plan.id === e.target.value
                          ) as HiringPlanData) || initialModel
                        );
                      }}
                    />
                    <div className="align-center">
                      {" "}
                      <Icon
                        icon="ic:outline-edit"
                        height={24}
                        width={24}
                        className="mt-2"
                      ></Icon>
                    </div>
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
          </VStack>

          {selected && (
            <VStack className="gap-2 px-4 pt-4 bg-white rounded-lg shadow-custom">
              <Box className="px-4 py-4 ">
                <p className="text-lg font-semibold text-gray-dark">
                  {newModel ? "New Hiring Plan Model" : "Hiring Plan Model"}
                </p>
              </Box>
              <HStack className="justify-between w-2/4 px-4 pt-2">
                <Box className="flex items-center ">
                  <Label className="flex items-center text-sm font-medium ">
                    Model Name
                  </Label>
                </Box>
                <Box>
                  <Input
                    type="text"
                    value={modelData.modelName}
                    onChange={(e) => {
                      setModelData((prev) => ({
                        ...prev,
                        modelName: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </HStack>
              <HStack className="justify-between w-2/4 px-4">
                <Box className="flex items-center ">
                  <Label className="flex items-center text-sm font-medium ">
                    Number of Years
                  </Label>
                </Box>
                <Box>
                  <Input
                    type="number"
                    value={modelData.noOfYears}
                    onChange={(e) => {
                      setModelData((prev) => ({
                        ...prev,
                        noOfYears: parseInt(e.target.value, 10),
                      }));
                    }}
                  />
                </Box>
              </HStack>
              <HStack className="justify-between w-2/4 px-4">
                <Box className="flex items-center ">
                  <Label className="flex items-center text-sm font-medium ">
                    Description
                  </Label>
                </Box>
                <Box>
                  <Input
                    type="text"
                    value={modelData.description}
                    onChange={(e) => {
                      setModelData((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </HStack>
              <HStack className="justify-end gap-4 p-4 row-reverse">
                <ButtonPrimary1
                  className="border-orange-501"
                  onClick={() => {
                    navigate(`/modelling/home`);
                  }}
                >
                  Cancel
                </ButtonPrimary1>
                <ButtonPrimary
                  className="min-w-max "
                  onClick={() => onPageChange()}
                >
                  Next
                </ButtonPrimary>
              </HStack>
            </VStack>
          )}
        </VStack>
      ) : (
        <VStack>
          <HiringPlanTable
            onclickedNext={setClickedNext}
            hiringPlanData={modelData}
            setHiringPlanData={setModelData}
          />
        </VStack>
      )}
    </div>
  );
};

export default CreateHiringPlan;
