import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label } from "../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../components/utils";
import { getDefaultResignationProcessObject } from "../pages/employeeDetails/Resignation";
import {
  useGetResignationDetails,
  useResignationProcess,
} from "../queries/employees";
import { useError } from "../store/errorStore";
import { ResignationDto } from "../types/EmployeeResignation";
import { getCurrencyType } from "../utils/currencyFormatter";
import { formatDate } from "../utils/date";

type ExtendExerciseType = {
  onClose: () => void;
  index: number;
  values: ResignationDto;
};

export function ExtendExercise(props: ExtendExerciseType) {
  const { id } = useParams();
  const _id = id ?? "";
  const { onClose, index, values } = props;
  const grantDetails =
    values.extendExerciseDetails.grantDetailsForExercises[index];
  const { mutate: resignationProcess } = useResignationProcess();

  const { data: _resignationDetails, refetch: getResignationDetails } =
    useGetResignationDetails(_id);

  const errorMessage = useError();

  const currency = getCurrencyType();

  const [newExpiryDate, setNewExpiryDate] = useState(
    grantDetails.newExpiryDate || grantDetails.currentExpiryDate
  );

  return (
    <div className="w-full">
      <div className="px-5 py-5 border-b">
        <VStack className="">
          <HStack className="justify-between text-lg font-medium">
            <h6>Extend Exercise </h6>
            <span onClick={() => props.onClose()} className="cursor-pointer">
              X
            </span>
          </HStack>
          <p className="whitespace-nowrap">
            {grantDetails.identifier} |{" "}
            {grantDetails.grantDate
              ? new Date(grantDetails.grantDate).toUTCString().slice(5, 17)
              : "-"}
          </p>
        </VStack>
      </div>
      <VStack className="w-full px-10 py-5 gap-9">
        <VStack className="flex-wrap">
          <HStack className="items-center">
            <Label className="mr-10 text-sm font-normal whitespace-nowrap w-60">
              Available Options to Exercise
            </Label>
            <Label className="items-start">
              {grantDetails.optionsAvailableForExercise.toLocaleString(
                currency
              )}
            </Label>
          </HStack>
          <HStack className="items-center">
            <Label className="mr-10 text-sm font-normal whitespace-nowrap w-60">
              Options pending for Exercise Approval
            </Label>
            <Label className="items-start">
              {grantDetails.noOfOptionsToExercise.toLocaleString(currency)}
            </Label>
          </HStack>
          <HStack className="items-center">
            <Label className="mr-10 text-sm font-normal whitespace-nowrap w-60">
              Current Exercise Expiry Date
            </Label>
            <Label>
              {grantDetails.currentExpiryDate
                ? new Date(grantDetails.currentExpiryDate)
                    .toUTCString()
                    .slice(5, 17)
                : "-"}
            </Label>
          </HStack>
          <HStack className="items-center">
            <Label className="mr-10 text-sm font-normal whitespace-nowrap w-60">
              New Expiry Date
            </Label>
            <Input
              type="date"
              className="w-fit"
              value={newExpiryDate}
              onChange={(e) => {
                setNewExpiryDate(formatDate(e.target.value));
              }}
            />
          </HStack>
        </VStack>
        <HStack className="justify-between mt-4">
          <ButtonPrimary1 onClick={onClose}>Back</ButtonPrimary1>
          <ButtonPrimary
            onClick={() => {
              const defaultResignationDetails =
                getDefaultResignationProcessObject(_id);
              defaultResignationDetails.extendExerciseDetails =
                values.extendExerciseDetails;
              defaultResignationDetails.extendExerciseDetails.grantDetailsForExercises[
                index
              ].newExpiryDate = newExpiryDate;
              defaultResignationDetails.extendExerciseDetails.allowExtendExercise =
                true;
              resignationProcess(defaultResignationDetails, {
                onSuccess: () => {
                  toast("New Expiry Date Updated Successfully", {
                    type: "success",
                    autoClose: 2000,
                  });
                  onClose();
                  getResignationDetails();
                },
                onError: (err: any) => {
                  const errMsg = err.response?.data?.errorsValidation?.errorRaw;
                  if (errMsg) {
                    errorMessage.setMessage(errMsg);
                    toast(errMsg, {
                      type: "error",
                      autoClose: 2000,
                    });
                  }
                  getResignationDetails();
                },
              });
            }}
          >
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </div>
  );
}
