import React, { useState } from "react";
import { toast } from "react-toastify";
import { Label } from "../../components/shared/InputField";
import { ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useEditPaymentLink } from "../../queries/optionBuyback";
import Input from "../../shared/input";
import { ErrorInterface } from "../../types/OptionBuyback";

function EditPaymentLink({
  link,
  eventId,
  onClose = () => {},
}: {
  link: string | undefined;
  eventId: string | undefined;
  onClose: () => void;
}) {
  const urlSearchParams = new URLSearchParams();
  const [editedLink, setEditedLink] = useState(link);
  const { mutate: submitPaymentLink } = useEditPaymentLink();
  function submit() {
    const obj: {
      paymentLink: string | undefined;
      eventId: string | undefined;
    } = {
      paymentLink: editedLink,
      eventId,
    };
    submitPaymentLink(obj, {
      onSuccess: () => {
        toast("Payment Link Edited Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorMessage, { type: "error", autoClose: 5000 });
      },
    });
  }
  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium border-b stone-700">
        <h6 className="flex justify-between">
          Edit Payment Link{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-4 p-4 px-10 ">
        <HStack className="items-center gap-8 px-3 ">
          <Label className="flex text-sm font-normal whitespace-nowrap">
            Payment Link
          </Label>
          <div className="w-full gap-8 mr-1">
            <Input
              type="text"
              className={"w-full"}
              placeholder={""}
              value={editedLink || ""}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditedLink(e.target.value)
              }
              name={""}
            />
          </div>
        </HStack>
        <HStack className="justify-center">
          <ButtonPrimary onClick={() => submit()}>Submit</ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}

export default EditPaymentLink;
