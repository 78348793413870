import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { CellValueChangedEvent, ColDef, GridApi } from "ag-grid-community";
import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import SearchInput from "../../../components/shared/SearchInput";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../../components/utils";
import DepartmentLabel from "./DepartmentLabel";
import OrganiSationStructureDataProcessor, {
  CompanyData,
  HiringPlanData,
  YearlyData,
} from "../companyDetails/utils/OrgStructureJSONDataProc";
import { getSalaryRangeForUI } from "../modellingUtils";
import { useCreateHiringPlan } from "../../../queries/hiringPlan";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import "./hiringPlanTable.css";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";

interface HiringPlanTableProps {
  onclickedNext: Dispatch<SetStateAction<boolean>>;
  hiringPlanData: HiringPlanData;
  setHiringPlanData: Dispatch<SetStateAction<HiringPlanData>>;
}

type LevelInfoDepartmentCell = {
  name: string;
  level: string;
};

type TotalInfoCellProps = {
  currentCount: number;
  totalNewHires: number;
};

export default function HiringPlanTable({
  onclickedNext,
  hiringPlanData,
  setHiringPlanData,
}: Readonly<HiringPlanTableProps>) {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { selectedCompanyData } = useOrgTemplateStore();
  const navigate = useNavigate();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  function onPageChange() {
    const updatedData = updateModelData();
    setHiringPlanData(updatedData);
    onclickedNext(false);
  }
  const { mutate: createHiringPlan } = useCreateHiringPlan();
  let departmentIndex = 0;
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const LevelInfoCell: React.FC<LevelInfoDepartmentCell> = ({
    name,
    level,
  }) => {
    departmentIndex = departmentIndex > 12 ? 0 : departmentIndex + 1;
    return (
      <VStack className="w-full">
        <HStack>
          <span className="px-2 pt-1 pl-4 text-xs font-medium p text-gray-dark">
            {level}
          </span>
        </HStack>
        <HStack className="items-center pt-1 pl-4">
          <DepartmentLabel
            name={name}
            index={departmentIndex}
            _className="leading-0"
          />
        </HStack>
      </VStack>
    );
  };
  const TotalInfoCell: React.FC<TotalInfoCellProps> = ({
    totalNewHires,
    currentCount,
  }) => (
    <VStack className="w-full pt-2">
      <HStack>
        <span className="text-xs font-medium p text-gray-dark">
          <b>New Hires:</b> {totalNewHires}
        </span>
      </HStack>
      <HStack className="items-center pt-1">
        <span className="text-xs text-gray-400">Previous: {currentCount}</span>
      </HStack>
    </VStack>
  );
  const getColumnDef = () => {
    const data: ColDef[] = [
      {
        headerName: "LEVELS",
        autoHeight: true,
        field: "level",
        minWidth: 100,
        flex: 1,
        filter: "agSetColumnFilter",
        sortable: true,
        menuTabs: ["filterMenuTab"],
        cellClass: "removePadding",
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value.props,
        cellRenderer: LevelInfoCell,
      },
      {
        headerName: "ROLES",
        autoHeight: true,
        field: "role",
        filter: "agSetColumnFilter",
        minWidth: 200,
        flex: 1,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        cellClass: "removePadding",
      },
      {
        headerName: "SALARY",
        autoHeight: true,
        field: "salaryRange",
        flex: 1,
        filter: "agSetColumnFilter",
        minWidth: 150,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        cellClass: "removePadding",
      },
      {
        headerName: "CURRENT COUNT",
        autoHeight: true,
        field: "currentCount",
        filter: "agSetColumnFilter",
        flex: 1,
        minWidth: 120,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        editable: !selectedCompanyData?.employeeListUploaded,
        ...(!selectedCompanyData?.employeeListUploaded && {
          cellClass: "hiringPlanEditor",
        }),
      },
      {
        headerName: "Department",
        autoHeight: true,
        field: "department",
        filter: "agSetColumnFilter",
        flex: 1,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        cellClass: "removePadding",
        hide: true,
      },
    ];

    for (let i = 1; i <= hiringPlanData.noOfYears; i++) {
      data.push({
        headerName: `YEAR ${i}`,
        autoHeight: true,
        field: `YEAR ${i}`,
        editable: true,
        cellEditor: "agNumberCellEditor",
        flex: 1,
        filter: "agSetColumnFilter",
        minWidth: 80,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        cellClass: "hiringPlanEditor",
      });
    }
    data.push({
      headerName: "TOTAL",
      autoHeight: true,
      field: "total",
      flex: 1,
      filter: "agSetColumnFilter",
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: TotalInfoCell,
    });
    return data;
  };

  const updateModelData = () => {
    let hiringData = _.cloneDeep(hiringPlanData);
    for (const rd of rowData) {
      let yearlyData = {};
      let totalEmployeeCount = 0;
      let currentCount = 0;
      Object.entries(rd).forEach(([key, value]) => {
        if (key.includes("YEAR")) {
          yearlyData = { ...yearlyData, [key]: value };
          totalEmployeeCount += Number(value);
        }
        if (key.includes("current")) {
          currentCount += Number(value);
          totalEmployeeCount += Number(value);
        }
      });

      hiringData = {
        ...hiringData,
        data: {
          employeeListUploaded: hiringData.data?.employeeListUploaded || false,
          teamSize: 0,
          companyName: hiringData.data?.companyName || "",
          ...hiringData.data,
          departments: {
            ...hiringData.data?.departments,
            [rd.department]: {
              ...hiringData.data?.departments[rd.department],
              levels: {
                ...hiringData.data?.departments[rd.department].levels,
                [rd.level.props.level]: {
                  ...hiringData.data?.departments[rd.department].levels[
                    rd.level.props.level
                  ],
                  yearlyData,
                  currentEmployeeCount: rd.currentCount,
                  totalEmployeeCount,
                  ...(selectedCompanyData?.employeeListUploaded && {
                    futureHires: {
                      employeeName: "Future Hires",
                      employeeDesignation: "",
                      employeeIdentification: "",
                      grade: rd.level.props.level,
                      department: rd.department,
                      salary: parseInt(
                        (
                          (Number(
                            hiringData.data?.departments[rd.department].levels[
                              rd.level.props.level
                            ].salaryRange.max
                          ) +
                            Number(
                              hiringData.data?.departments[rd.department]
                                .levels[rd.level.props.level].salaryRange.min
                            )) /
                          2
                        ).toString(),
                        10
                      ),
                      employeeCount: totalEmployeeCount - currentCount,
                    },
                  }),
                },
              },
            },
          },
        },
      };
    }
    return hiringData;
  };

  const getRowData = () => {
    const data = [];
    if (hiringPlanData.data) {
      const processedData = new OrganiSationStructureDataProcessor(
        undefined,
        hiringPlanData.data
      );
      const levelData = processedData.getDataByLevel();
      for (const level of levelData) {
        const currentCount =
          hiringPlanData.data.departments[level.department]?.levels[level.level]
            ?.currentEmployeeCount || 0;
        const rowData: any = {
          level: <LevelInfoCell name={level.department} level={level.level} />,
          role: level.role,
          department: level.department,
          salaryRange: getSalaryRangeForUI(
            level.salaryRange,
            currencySymbol,
            currencyType
          ),
          currentCount,
        };
        let total = 0;
        for (let j = 1; j <= hiringPlanData.noOfYears; j++) {
          const yearTotal =
            hiringPlanData.data.departments[level.department]?.levels[
              level.level
            ]?.yearlyData &&
            Object.prototype.hasOwnProperty.call(
              hiringPlanData.data.departments[level.department]?.levels[
                level.level
              ]?.yearlyData,
              `YEAR ${j}`
            )
              ? hiringPlanData.data.departments[level.department]?.levels[
                  level.level
                ]?.yearlyData[`YEAR ${j}`]
              : 0;
          total += yearTotal;
          rowData[`YEAR ${j}`] = yearTotal;
        }
        rowData.total = (
          <TotalInfoCell currentCount={currentCount} totalNewHires={total} />
        );
        data.push(rowData);
      }
    }

    return data;
  };

  const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
    const { data, colDef, newValue } = event;
    if (colDef.field === "currentCount") {
      data.total = (
        <TotalInfoCell
          currentCount={parseInt(newValue, 10)}
          totalNewHires={data.total.props.totalNewHires}
        />
      );
    }
    if (colDef.field?.includes("YEAR")) {
      let total = 0;
      const key = colDef.field;
      Object.entries(data).forEach(([name, value]) => {
        if (name.includes("YEAR")) {
          total += parseInt(value as unknown as string, 10);
        }
      });
      data.total = (
        <TotalInfoCell
          totalNewHires={total}
          currentCount={data.total.props.currentCount}
        />
      );
    }
    gridApi?.current?.api?.applyTransaction({ update: [data] });
    gridApi?.current?.refreshCells({ force: true });
  }, []);

  const columnDefs = getColumnDef();

  const rowData = useMemo(() => getRowData(), [hiringPlanData.data]);

  function handleSave() {
    const updatedData = updateModelData();
    setHiringPlanData(updatedData);
    createHiringPlan(updatedData, {
      onSuccess: () => {
        toast("Successfully Created Hiring Plan");
        navigate(`/modelling/home`);
      },
      onError: () => {
        toast("Error while creating Hiring Plan");
      },
    });
  }

  return (
    <VStack className="w-full bg-white">
      <HStack className="justify-between ">
        <VStack>
          <VStack className="justify-between gap-1 pb-3 pl-6 font-medium pt-7">
            <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
              Hiring Plan
            </HStack>
            <h4 className="flex justify-between text-gray-500">
              Summary of hiring plan created
            </h4>
          </VStack>
        </VStack>
        <VStack className="justify-end gap-8 mx-10 mt-4">
          <div className="flex gap-2 text-xs text-center justify-evenly">
            <SearchInput
              className=""
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.setQuickFilter(e.target.value);
              }}
            />
          </div>
        </VStack>
      </HStack>
      <HStack className="justify-between w-full pt-6 pl-10 overflow-x-auto">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            onCellValueChanged={onCellValueChanged}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
      <HStack className="justify-between gap-4 p-4 pl-10 row-reverse">
        <ButtonPrimary1
          className="border-orange-501"
          onClick={() => {
            onPageChange();
          }}
        >
          Back
        </ButtonPrimary1>
        <ButtonPrimary className="min-w-max " onClick={() => handleSave()}>
          Save
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}
