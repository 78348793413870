export const isValidDate = (date: string) =>
  Number.isNaN(date) &&
  new Date(date).toString() !== "Invalid Date" &&
  !Number.isNaN(Date.parse(date));

export const normaliseValue = (value: string | number) => {
  if (typeof value === "number") return value;
  if (typeof value === "string") {
    // check if it can be converted to number first
    // assume if commas are there
    if (isValidDate(value)) return new Date(value).valueOf();
    try {
      value = value.replaceAll(",", "");
    } catch (TypeError) {
      value = value.replace(/,./g, "");
    }
    return parseFloat(value);
  }
  return value;
};

export const sortComparator = (
  valueA: number | string,
  valueB: number | string,
  ..._: any[]
) => normaliseValue(valueA) - normaliseValue(valueB);
