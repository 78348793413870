import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import { toast } from "react-toastify";
import { Slider } from "@mui/material";
import { Box, ButtonPrimary, ButtonPrimary1, HStack, VStack } from "../utils";
import { fetchAndConvertToDataURL } from "../../utils/DownloadFile";
import { Label } from "./InputField";

type ImageResizerProps = {
  onCancel: () => void;
  onSave: (image: string) => void;
  imageUrl?: string;
};

export default function ImageResizer(props: ImageResizerProps) {
  const { onCancel, onSave, imageUrl } = props;
  const editorRef = useRef() as React.MutableRefObject<AvatarEditor>;
  const acceptedTypes = ["image/jpeg", "image/png", "image/jpg"];
  const [openImageEdit, setImageEdit] = useState(false);
  const [imageProperties, setImageProperties] = useState({
    image: "",
    width: 250,
    height: 250,
    scale: 1,
    borderRadius: 0,
    showGrid: false,
    rotate: 0,
  });
  useEffect(() => {
    if (imageUrl) setImageProperties({ ...imageProperties, image: imageUrl });
  }, [imageUrl]);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const fileData = acceptedFiles[0];
      if (!acceptedTypes.includes(fileData.type)) {
        toast("Invalid filetype", { autoClose: 2000, type: "error" });
        return;
      }
      setImageProperties({ ...imageProperties, image: acceptedFiles[0] });
      setImageEdit(true);
    }
  }, []);
  const setEditorRef = (editor: any) => {
    editorRef.current = editor;
  };
  const handleSubmit = () => {
    if (editorRef && editorRef.current !== undefined) {
      const img = editorRef.current?.getImageScaledToCanvas().toDataURL();
      onSave(img);
    }
  };
  function handleImageEdit(value: number | number[], property: string): void {
    setImageProperties({ ...imageProperties, [property]: value });
  }

  return (
    <div className="w-full">
      <div className="justify-between px-10 text-lg font-medium py-7">
        <h6 className="flex justify-between border-b">
          Resize Image
          <button
            type="button"
            onClick={() => onCancel()}
            className="cursor-pointer"
          >
            X
          </button>
        </h6>
        <HStack className="w-full mt-4">
          <VStack className="w-[300px] min-w-[300px] justify-center">
            <Dropzone onDrop={onDrop} noClick noKeyboard>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <AvatarEditor
                    ref={setEditorRef}
                    border={5}
                    {...imageProperties}
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            <span className="text-xxs text-gray-201 mt-4">
              Drag and Drop an image into the box and edit if necessary
            </span>
          </VStack>
          <VStack
            className={`mx-10 w-1/2 ${!openImageEdit && "cursor-not-allowed"}`}
          >
            <HStack className="flex">
              <Box className="relative w-32">
                <Label className="text-xxs">Zoom</Label>
                <Slider
                  value={imageProperties.scale}
                  shiftStep={0.1}
                  disabled={!openImageEdit}
                  step={0.1}
                  onChange={(_, val) => handleImageEdit(val, "scale")}
                  valueLabelDisplay="off"
                  min={0.1}
                  max={2}
                />
              </Box>
            </HStack>
            <HStack className="flex">
              <Box className="relative w-32">
                <Label className="text-xxs">Rotation</Label>
                <Slider
                  value={imageProperties.rotate}
                  shiftStep={0.1}
                  step={0.1}
                  disabled={!openImageEdit}
                  onChange={(_, val) => handleImageEdit(val, "rotate")}
                  valueLabelDisplay="auto"
                  min={-270}
                  max={270}
                />
              </Box>
            </HStack>
            <HStack className="flex">
              <Box className="relative w-32">
                <Label className="text-xxs">Height</Label>
                <Slider
                  value={imageProperties.height}
                  shiftStep={5}
                  step={5}
                  disabled={!openImageEdit}
                  onChange={(_, val) => handleImageEdit(val, "height")}
                  valueLabelDisplay="auto"
                  min={100}
                  max={250}
                />
              </Box>
            </HStack>
            <HStack className="flex">
              <Box className="relative w-32">
                <Label className="text-xxs">Width</Label>
                <Slider
                  value={imageProperties.width}
                  shiftStep={5}
                  step={5}
                  disabled={!openImageEdit}
                  onChange={(_, val) => handleImageEdit(val, "width")}
                  valueLabelDisplay="auto"
                  min={100}
                  max={250}
                />
              </Box>
            </HStack>
          </VStack>
        </HStack>

        <HStack className="justify-end gap-4 mt-8">
          <ButtonPrimary1 onClick={onCancel}>Cancel</ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Save</ButtonPrimary>
        </HStack>
      </div>
    </div>
  );
}
