import { QueryFunctionContext } from "react-query";
import {
  FaqForEmployeeInterface,
  FaqSettingInterface,
  Faqparam,
} from "../types/faqPara";
import hissaApi from "./hissaApi";
import optionsApi from "./optionsApi";

export async function setAllFaq(faqDto: Faqparam[]): Promise<Faqparam[]> {
  return hissaApi.post(`/company/setFaq`, faqDto).then((res) => res.data);
}

export async function getAllFaq(): Promise<Faqparam[]> {
  return hissaApi.get(`/company/getFaq`).then((res) => res.data);
}

export async function getFaqSetting(): Promise<FaqForEmployeeInterface> {
  return hissaApi.get(`/AdminSetting/settings`).then((res) => res.data);
}

export async function setEmployeeFaqSettings(faqForEmployeeParam: {
  faqForEmployee: boolean;
}): Promise<FaqForEmployeeInterface> {
  return hissaApi
    .post(`/AdminSetting/save`, faqForEmployeeParam)
    .then((res) => res.data);
}

export async function getFaqSettings(): Promise<FaqSettingInterface> {
  return optionsApi
    .get("/v1/settings/getFaqSettings")
    .then((res) => res.data.data);
}
export async function saveFaqSettings(
  faqSettings: FaqSettingInterface
): Promise<FaqSettingInterface> {
  return optionsApi
    .post("/v1/settings/saveFaqSettings", faqSettings)
    .then((res) => res.data.data);
}
