import { VStack } from "../../../components/utils";
import BCHeader from "../../../shared/BCHeader";
import CreateHiringPlan from "./CreateHiringPlan";

function HiringPlan() {
  return (
    <VStack className="gap-6 ">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          className="items-baseline"
          bcTitle="Modelling"
          bcSubTitle="Hiring Plan"
        />
      </div>
      <div>
        <CreateHiringPlan />
      </div>
    </VStack>
  );
}

export default HiringPlan;
