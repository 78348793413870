import {
  AssociatedUsers,
  DefaultPermission,
  Roles,
} from "../utils/interfaces/Companies";
import optionsApi from "./optionsApi";

export async function getAssociatedUsers(): Promise<AssociatedUsers[]> {
  return optionsApi
    .get("/v1/company/getAssociatedUsers")
    .then((res) => res?.data?.data);
}
export async function getCompanyRoles(): Promise<Roles[]> {
  return optionsApi.get("/v1/company/getRoles").then((res) => res?.data?.data);
}

export async function getAllPermissions(): Promise<DefaultPermission[]> {
  return optionsApi
    .get("/v1/auth/getAllDefaultPermissions")
    .then((res) => res?.data?.data);
}
export async function saveAllUsers(userList: AssociatedUsers[]): Promise<any> {
  return optionsApi
    .post("/v1/auth/saveUsers", { data: userList })
    .then((res) => res?.data?.data);
}
