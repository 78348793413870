/* eslint-disable default-case */
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Input, Label } from "../../../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  Error,
} from "../../../../components/utils";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import { Select as LocalSelect } from "../../../../components/shared/Select";
import OrganiSationStructureDataProcessor, {
  LevelData,
  SalaryRange,
} from "../../companyDetails/utils/OrgStructureJSONDataProc";
import { KeyRoleCompModelling } from "../../../../types/modelling";

const AddKeyRoleCompensationModellingDialog = ({
  open,
  mode,
  keyRole,
  changeKeyRole,
  onClose = () => {},
}: {
  open: boolean;
  mode?: "Edit" | "Add";
  keyRole?: KeyRoleCompModelling;
  changeKeyRole: boolean;
  onClose: () => void;
}) => {
  const initialValues: KeyRoleCompModelling = {
    department: "",
    level: "",
    role: "",
    employeeCount: 0,
    salaryRange: {
      min: 0,
      max: 0,
    },
    maximumAllowedCount: 0,
    percentageOfSalary: 0,
  };
  const {
    compensationModellingData,
    addorDeleteKeyRoleEmployeeCountToCompensationModelling,
    selectedCompanyData,
  } = useOrgTemplateStore();

  const validationSchema = Yup.object().shape({
    department: Yup.string()
      .required("Department is required")
      .notOneOf([""], "Department is required"),
    level: Yup.string()
      .required("Level is required")
      .notOneOf([""], "Department is required"),
    ...(!compensationModellingData?.data?.employeeListUploaded && {
      employeeCount: Yup.number()
        .required("Employee count is required")
        .min(0, "Employee count cannot be negative")
        .max(
          Yup.ref("maximumAllowedCount"),
          ({ max }) =>
            `Employee count cannot exceed maximum employee count is (${max})`
        ),
    }),
  });

  const formik = useFormik({
    initialValues: mode === "Edit" ? keyRole || initialValues : initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const returnKeyRole: KeyRoleCompModelling = {
        department: values.department,
        level: values.level,
        role: values.role,
        salaryRange: initialValues.salaryRange,
        percentageOfSalary: values.percentageOfSalary,
        maximumAllowedCount: values.maximumAllowedCount ?? 1,
        employeeCount: values.employeeCount ?? 1,
        key: changeKeyRole,
      };
      addorDeleteKeyRoleEmployeeCountToCompensationModelling(returnKeyRole);
      onClose();
    },
  });

  const getDepartments = () => {
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      compensationModellingData?.data
    );
    return processedData.getDepartmentNames();
  };

  const getLevels = () => {
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      compensationModellingData?.data
    );
    return processedData.getLevels(formik.values.department);
  };

  const getRoles = () => {
    if (!formik.values.department) return [];
    const processedData = new OrganiSationStructureDataProcessor(
      undefined,
      compensationModellingData?.data
    );
    const roles = processedData.getRolesByLevel(
      formik.values.department,
      formik.values.level
    );
    return roles.map((role) => role.value);
  };

  function getMaximumAllowedCount(levelData?: LevelData) {
    return levelData
      ? levelData.totalEmployeeCount -
          _.sum(
            Object.entries(levelData.roles)
              .filter(([_, roleData]) => roleData.keyRole)
              .map(([_, roleData]) => roleData.employeeCount)
          )
      : 0;
  }

  function handleLevelChange(value: string) {
    formik.setFieldValue("level", value);
    formik.setFieldValue(
      "maximumAllowedCount",
      getMaximumAllowedCount(
        compensationModellingData?.data?.departments[formik.values.department]
          .levels[value]
      )
    );
  }

  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <div className="flex items-center justify-between">
          <VStack>
            <p> Key Roles</p>
            <p className="text-xs text-gray-400">
              Provide the salary range for different roles within a department
              in the organisation.
            </p>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </div>
      <VStack className="w-full gap-6 p-4 pt-8">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Department</Label>
            <LocalSelect
              options={getDepartments()}
              {...formik.getFieldProps("department")}
            />
            {formik.touched.department && formik.errors.department && (
              <Error text={formik.errors.department} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Level</Label>
            <LocalSelect
              options={getLevels()}
              value={formik.values.level}
              onChange={(e) => handleLevelChange(e.target.value)}
            />
            {formik.touched.level && formik.errors.level && (
              <Error text={formik.errors.level} />
            )}
          </div>
        </HStack>
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Role</Label>
            <LocalSelect
              options={getRoles()}
              value={formik.values.role}
              onChange={(newValue) =>
                formik.setFieldValue("role", newValue.target.value)
              }
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Percentage of salary</Label>
            <Input
              type="number"
              {...formik.getFieldProps("percentageOfSalary")}
            />
            {formik.touched.percentageOfSalary &&
              formik.errors.percentageOfSalary && (
                <Error text={formik.errors.percentageOfSalary} />
              )}
          </div>
        </HStack>
        {!selectedCompanyData?.employeeListUploaded && (
          <HStack className="gap-6">
            <div className="flex-1">
              <Label className="text-sm font-normal">Employee Count</Label>
              <Input type="number" {...formik.getFieldProps("employeeCount")} />
              {formik.touched.employeeCount && formik.errors.employeeCount && (
                <Error text={formik.errors.employeeCount} />
              )}
            </div>
            <div className="flex-1"></div>
          </HStack>
        )}
        <HStack className="justify-end gap-3 pt-6">
          <ButtonSecondary
            onClick={() => onClose()}
            className="p-10 text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddKeyRoleCompensationModellingDialog;
