import { Icon } from "@iconify/react";
import { Dialog, DialogProps } from "@mui/material";
import { useState } from "react";
import { MonetaryOffers } from "../../types/OptionBuyback";
import { ButtonPrimary, ButtonSecondary, HStack, VStack } from "../utils";
import { CheckBoxWithLabel } from "./InputField";

export type GenerateCredentialsTypes = {
  error?: boolean;
  message?: string;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: (
    actionType: string,
    ids: string[],
    sendEmails: boolean
  ) => void;
  onSecondaryAction?: () => void;
  offersList: MonetaryOffers[];
  offersIncluded: MonetaryOffers[];
  offersExcluded: MonetaryOffers[];
  actionType?: string;
} & DialogProps;

function PauseExerciseEvent({
  offersList,
  offersIncluded,
  offersExcluded,
  error = false,
  actionType = "",
  message = "Are you sure you want to Pause event",
  primaryActionText = "Pause",
  secondaryActionText = "Cancel",
  onPrimaryAction = (
    actionType: string,
    ids: string[],
    sendEmails: boolean
  ) => {},
  onSecondaryAction = () => {},
  ...dialogProps
}: GenerateCredentialsTypes) {
  const [sendEmails, setSendEmails] = useState(true);
  function eventAction() {
    const ids = offersExcluded.map((offer) => offer.id);
    if (onPrimaryAction) onPrimaryAction(actionType, ids, sendEmails);
  }
  return (
    <Dialog {...dialogProps}>
      <VStack className="justify-between p-9 bg-white rounded h-[321px] ">
        <VStack className="items-center justify-center gap-6 grow">
          {!error ? (
            <Icon icon="ep:warning" color="#FFE99B" height={72} />
          ) : (
            <Icon icon="codicon:error" color="#ff0000" height={72} />
          )}
        </VStack>
        <p className="items-center justify-center gap-4 font-medium text-dark grow text-xs3">
          {message}
        </p>
        {actionType === "paused" && (
          <VStack className="justify-start gap-4 grow">
            {/* <CheckBoxWithLabel
            label={`Reject ${
              offersExcluded.length > 0 ? offersExcluded.length : ""
            } employee request `}
            disabled={offersExcluded.length === 0}
            labelclassname={`ml-2 text-xs2 ${
              offersExcluded.length === 0 ? "text-slate-300" : "text-dark"
            }`}
            onChange={(e) => setExisting(e.target.checked)}
          /> */}
            <span
              className={`ml-2 text-xs2 ${
                offersExcluded.length === 0 ? "text-slate-300" : "text-dark"
              }`}
              aria-disabled={`${offersExcluded.length > 0 ?? false}`}
            >{`${actionType === "paused" ? "Reject" : "Resume"} ${
              offersExcluded.length > 0 ? offersExcluded.length : 0
            } employees request `}</span>
            <CheckBoxWithLabel
              label={`Send notification email to all employees upon rejection request`}
              labelclassname={"ml-2 text-xs2 text-dark"}
              checked={sendEmails}
              onChange={(e) => setSendEmails(e.target.checked)}
            />
          </VStack>
        )}
        <HStack className="justify-between gap-9">
          <ButtonSecondary
            onClick={() => onSecondaryAction()}
            className="text-gray-400 bg-slate-50"
          >
            {secondaryActionText}
          </ButtonSecondary>
          <ButtonPrimary onClick={() => eventAction()}>
            {primaryActionText}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Dialog>
  );
}

export default PauseExerciseEvent;
