import { CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { useAccelerateForGrant } from "../../queries/esopPlan";
import { AccelerateVestingDto, Grant } from "../../types/Grant";
import { ErrorInterface } from "../../types/OptionBuyback";
import { formatDate } from "../../utils/date";

type AccelerateProps = {
  onClose: () => void;
  grant?: Grant;
};

export function AccelerateVesting({
  onClose = () => {},
  grant,
}: Readonly<AccelerateProps>) {
  const availableToAccelerate = grant?.availableToAccelerate || 0;
  function getInitialValues() {
    const initialValues: AccelerateVestingDto = {
      grantId: grant?.grantIdentifier ?? "",
      accelerateDate: formatDate(new Date()),
      availableToAccelerate,
      currentVested: grant?.optionsVested ?? 0,
      numberOfOptionsToBeAccelerated: 0,
    };
    return initialValues;
  }
  const validationSchema = Yup.object({
    accelerateDate: Yup.date()
      .required("New Vesting Date required")
      .max(new Date(), "Accelerated Vesting Date cannot be in future"),
    numberOfOptionsToBeAccelerated: Yup.number()
      .required("Enter Number of options to be accelerated")
      .moreThan(0, "Enter Number of options to be accelerated")
      .max(
        availableToAccelerate,
        `Options to accelerate cannot be more than available options (${availableToAccelerate} options)`
      )
      .test(
        "is-fractional",
        "Number to accelerate must be an integer",
        (value) => {
          if (grant?.isFractional) {
            return true;
          } else {
            return Number.isInteger(value);
          }
        }
      ),
  });
  const { mutate: accelerate, isLoading } = useAccelerateForGrant();
  return (
    <div>
      <div className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {`Accelerate Vesting of Grant ${grant?.grantIdentifier}`}
          <button onClick={() => onClose()} className="cursor-pointer">
            X
          </button>
        </h6>
      </div>
      <div className="px-10 pt-4">
        <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            accelerate(values, {
              onSuccess: () => {
                resetForm();
                toast("Accelerated For Grant successfully", {
                  type: "success",
                });
                onClose();
              },
              onError: (err: any) => {
                const error = err.response.data as ErrorInterface;
                toast(error.errorMessage, { type: "error", autoClose: 5000 });
              },
            });
          }}
        >
          {({
            touched,
            errors,
            isValid,
            getFieldProps,
            handleSubmit,
            values,
          }) => (
            <VStack className="gap-8">
              <HStack className="w-1/2 gap-12">
                <span className="text-sm font-normal text-stone-700">
                  Current Vested Options
                </span>
                <span className="text-sm font-medium text-gray-600">
                  {values.currentVested}
                </span>
              </HStack>
              <HStack className="w-1/2 gap-8">
                <span className="text-sm font-normal text-stone-700">
                  Available Options to Accelerate
                </span>
                <span className="text-sm font-medium text-gray-600">
                  {values.availableToAccelerate}
                </span>
              </HStack>
              <HStack className="gap-8">
                <div className="flex-1">
                  <Label className="text-sm font-normal">
                    No. of Options for Accelerated Vesting
                  </Label>
                  <Input
                    type="number"
                    {...getFieldProps("numberOfOptionsToBeAccelerated")}
                  />
                  {touched.numberOfOptionsToBeAccelerated &&
                    errors.numberOfOptionsToBeAccelerated && (
                      <Error text={errors.numberOfOptionsToBeAccelerated} />
                    )}
                </div>
                <div className="flex-1">
                  <Label className="text-sm font-normal">
                    New Date of Vesting
                  </Label>
                  <Input type="date" {...getFieldProps("accelerateDate")} />
                  {touched.accelerateDate && errors.accelerateDate && (
                    <Error text={errors.accelerateDate} />
                  )}
                </div>
              </HStack>
              <HStack className="justify-between my-10 justify">
                <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
                <div className="flex items-center">
                  <ButtonPrimary
                    disabled={isLoading}
                    className={`${!isValid && "bg-gray-400"}`}
                    onClick={(e) => {
                      handleSubmit();
                    }}
                    loading={isLoading}
                  >
                    Confirm
                  </ButtonPrimary>
                </div>
              </HStack>
            </VStack>
          )}
        </Formik>
      </div>
    </div>
  );
}
