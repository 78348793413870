/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import { addMonths } from "date-fns";
import {
  Schedule,
  TriggerType,
  VestingType,
} from "../../types/VestingTemplate";

const baseVesting = {
  cliffDate: false,
  date: new Date(2022, 1, 1),
  intervalsPassed: 0,
  vestedPercentage: 0,
  isVestDate: false,
  vestedOptions: 0,
  accumulatedVestingPercentageForGrant: 0,
  accumulatedVestedOptionsForGrant: 0,
};

type VestingTemplateInput = {
  cliffPeriod: number;
  schedules: Schedule[];
  vestingStartDate: Date;
  optionsGranted: number;
  vestingType: VestingType;
  isFractional: boolean;
};

export function generateProjections({
  optionsGranted = 100,
  vestingStartDate = new Date(2022, 1, 1),
  cliffPeriod,
  schedules,
  vestingType,
  isFractional,
}: VestingTemplateInput) {
  if (vestingType === VestingType.CUSTOM) {
    return getProjectionsForCustomTemplate(
      schedules,
      vestingStartDate,
      cliffPeriod,
      optionsGranted,
      isFractional
    );
  } else {
    return getProjectionsForStandardTemplate(
      vestingStartDate,
      TriggerType.TIME,
      schedules[0]?.vestingDuration || 0,
      schedules[0]?.vestingInterval || 0,
      cliffPeriod,
      optionsGranted,
      isFractional
    );
  }
}
function getProjectionsForStandardTemplate(
  vestingStartDate: Date,
  vestingTriggerType: TriggerType,
  vestingPeriod: number | undefined,
  vestingInterval: number | undefined,
  cliffPeriod: number,
  optionsGranted: number,
  isFractional: boolean
) {
  let accumulatedVestingPercentageBeforeCliff = 0;
  let intervalsPassed = 0;
  let vestedOptions = 0;
  let vestingPercentage = 0;
  let accumulatedVestedOptionsForGrant = 0;
  let accumulatedVestingPercentageForGrant = 0;
  let vestedOptionsSoFarForGrant = 0;
  let currentDate = vestingStartDate;
  const vestings = [];
  if (
    vestingTriggerType === TriggerType.TIME &&
    vestingPeriod &&
    vestingInterval
  ) {
    let noOfVestEventsForGrant = 1;
    if (vestingPeriod === 0 || vestingInterval === 0) {
      noOfVestEventsForGrant = 1;
    } else {
      noOfVestEventsForGrant = vestingPeriod / vestingInterval;
    }
    vestings.push({
      date: currentDate,
      vestedPercentage: 0,
      intervalsPassed: 0,
      cliffDate: false,
      vestedOptions: 0,
      accumulatedVestedOptionsForGrant: 0,
      accumulatedVestingPercentageForGrant: 0,
    });
    for (let event = 1; event <= noOfVestEventsForGrant; event++) {
      const vesting = { ...baseVesting };
      intervalsPassed += vestingInterval;
      if (intervalsPassed < cliffPeriod) {
        accumulatedVestingPercentageBeforeCliff =
          intervalsPassed / vestingPeriod;
      } else {
        if (intervalsPassed === cliffPeriod) {
          vesting.cliffDate = true;
        }
        vestingPercentage =
          1.0 / noOfVestEventsForGrant +
          accumulatedVestingPercentageBeforeCliff;
        accumulatedVestingPercentageBeforeCliff = 0;
        accumulatedVestingPercentageForGrant += vestingPercentage;
        if (isFractional) {
          accumulatedVestedOptionsForGrant = parseFloat(
            (accumulatedVestingPercentageForGrant * optionsGranted).toFixed(4)
          );
          vestedOptions = parseFloat(
            (
              accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant
            ).toFixed(4)
          );
        } else {
          accumulatedVestedOptionsForGrant = Math.floor(
            roundOptions(
              roundPercentage(accumulatedVestingPercentageForGrant)
            ) * optionsGranted
          );
          vestedOptions =
            accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant;
        }
        vestedOptionsSoFarForGrant += vestedOptions;
        currentDate = addMonths(vestingStartDate, intervalsPassed);
        vesting.date = currentDate;
        vesting.intervalsPassed = intervalsPassed;
        vesting.vestedOptions = vestedOptions;
        vesting.vestedPercentage = vestingPercentage;
        vesting.accumulatedVestingPercentageForGrant =
          accumulatedVestingPercentageForGrant;
        vesting.accumulatedVestedOptionsForGrant =
          accumulatedVestedOptionsForGrant;
        vesting.isVestDate = true;
        vestings.push(vesting);
      }
    }
  }
  return vestings;
}

function getProjectionsForCustomTemplate(
  schedules: Schedule[],
  vestingStartDate: Date,
  cliffPeriod: number,
  optionsGranted: number,
  isFractional: boolean
) {
  schedules.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  const vestings = [];
  let accumulatedVestingPercentageBeforeCliff = 0;
  let intervalsPassed = 0;
  let vestedOptions = 0;
  let vestingPercentage = 0;
  let accumulatedVestedOptionsForGrant = 0;
  let accumulatedVestingPercentageForGrant = 0;
  let vestedOptionsSoFarForGrant = 0;
  let currentDate = vestingStartDate;
  for (const schedule of schedules) {
    if (schedule.vestingTriggerType === TriggerType.TIME) {
      let noOfEventsInVestingSequence = 1;
      if (schedule.vestingDuration === 0 || schedule.vestingInterval === 0) {
        noOfEventsInVestingSequence = 1;
      } else {
        noOfEventsInVestingSequence =
          schedule.vestingDuration / schedule.vestingInterval;
      }
      for (let event = 1; event <= noOfEventsInVestingSequence; event++) {
        const vesting = { ...baseVesting };
        intervalsPassed += schedule.vestingInterval;
        if (intervalsPassed < cliffPeriod) {
          accumulatedVestingPercentageBeforeCliff +=
            schedule.percentage / noOfEventsInVestingSequence;
        } else {
          if (intervalsPassed === cliffPeriod) {
            vesting.cliffDate = true;
          }
          vestingPercentage =
            schedule.percentage / noOfEventsInVestingSequence +
            accumulatedVestingPercentageBeforeCliff;
          accumulatedVestingPercentageBeforeCliff = 0;
          currentDate = addMonths(vestingStartDate, intervalsPassed);
          vesting.date = currentDate;
          vesting.intervalsPassed = intervalsPassed;
          vesting.vestedPercentage = vestingPercentage;
          vesting.isVestDate = true;
          vestings.push(vesting);
        }
      }
    } else if (schedule.vestingTriggerType === TriggerType.EVENT) {
      const vesting = { ...baseVesting };
      vesting.date = new Date(
        schedule.eventCompletionDate || schedule.eventTargetDate || "1900-01-01"
      );
      vesting.vestedPercentage = schedule.percentage;
      vesting.isVestDate = true;
      vestings.push(vesting);
    }
  }
  vestings.sort((a, b) => a.date.valueOf() - b.date.valueOf());
  for (const vesting of vestings) {
    accumulatedVestingPercentageForGrant += vesting.vestedPercentage;
    if (isFractional) {
      accumulatedVestedOptionsForGrant = parseFloat(
        (accumulatedVestingPercentageForGrant * optionsGranted).toFixed(4)
      );
      vestedOptions = parseFloat(
        (accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant).toFixed(
          4
        )
      );
    } else {
      accumulatedVestedOptionsForGrant = Math.floor(
        roundOptions(roundPercentage(accumulatedVestingPercentageForGrant)) *
          optionsGranted
      );
      vestedOptions =
        accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant;
    }
    vestedOptionsSoFarForGrant += vestedOptions;
    vesting.vestedOptions = vestedOptions;
    vesting.accumulatedVestingPercentageForGrant =
      accumulatedVestingPercentageForGrant;
    vesting.accumulatedVestedOptionsForGrant = accumulatedVestedOptionsForGrant;
  }
  return vestings;
}

function roundPercentage(x: number) {
  return roundToPlaces(x, 10);
}

function roundOptions(x: number) {
  return roundToPlaces(x, 3);
}

function roundToPlaces(x: number, places: number) {
  const scaledNumber = x * 10 ** places;
  return Math.round(scaledNumber) / 10 ** places;
}
