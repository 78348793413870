import { useEffect } from "react";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetUserRoleAndPermission } from "../../queries";
import { usePermissionStore } from "../../store";

function HandleHissaRedirect() {
  const { setAuth } = useAuthStore();
  const { data, refetch: getPermission } = useGetUserRoleAndPermission();
  const { setPermission } = usePermissionStore();
  useEffect(() => {
    const params = window.location.search;
    if (params) {
      const urlSearchParams = new URLSearchParams(params);
      const accesstoken = urlSearchParams.get("logintoken");
      const xUsrToken = urlSearchParams.get("x-usr-token");
      const companyId = urlSearchParams.get("companyId");
      const redirectUri = urlSearchParams.get("redirectURL");
      if (xUsrToken) {
        localStorage.setItem("currentUser", xUsrToken);
      }
      if (accesstoken) {
        setAuth({ isAuthenticated: true, accessToken: accesstoken });
        localStorage.setItem("accesstoken", accesstoken);
      }
      if (redirectUri) {
        localStorage.setItem("hissaUrl", redirectUri);
      }
      if (companyId) {
        localStorage.setItem("companyId", companyId);
      }
      getPermission().then((permission) => {
        if (permission !== undefined && permission.data !== undefined)
          setPermission(permission.data);
      });
      const currentURL = window.location.href;

      const urlObj = new URL(currentURL);
      const domain = urlObj.origin; // Get the domain
      const path = urlObj.pathname; // Get the path

      const newURL = domain + path;

      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, "", newURL);
    }
  }, []);
  return null;
}

export default HandleHissaRedirect;
