export type VestingMilestone = {
  id: string;
  targetDate: Date;
  completionDate: Date;
  milestoneName: string;
  milestoneStatus: MilestoneStatus;
  newlyAdded?: boolean;
};

export type AddVestingMilestoneReq = {
  id?: string;
  milestoneName: string;
  closureType?: ClosureType;
  milestoneType?: MilestoneType;
  targetDate?: Date;
  completionDate?: Date | undefined;
  notes?: string;
  companyId?: string;
};

export type VestingMilestoneTableDetails = {
  id: string;
  milestoneName: string;
  targetDate: Date;
  completionDate?: Date;
  employeesLinked: number;
  employeesCompleted: number;
  employeesPending: number;
  totalNoOfOptions: number;
  totalNoOfOptionsVested: number;
  totalNoOfOptionsLapsed: number;
  milestoneStatus: MilestoneStatus;
  closureType: ClosureType;
  milestoneType: MilestoneType;
  employeeNames: string[];
};

export enum MilestoneType {
  CompanyWide = "CompanyWide",
  Individual = "Individual",
}

export enum ClosureType {
  Manual = "Manual",
}

export enum MilestoneStatus {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  FOR_APPROVAL = "FOR_APPROVAL",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export type VestingMilestoneCompleteDetailsDto = {
  grantId: string;
  milestoneDetails: VestingMilestoneCompleteDetails[];
};

export interface VestingMilestoneCompleteDetails {
  employeeName: string;
  employeeId: string;
  grantId: string;
  grantIdentifier: string;
  optionsGranted: number;
  optionsVested?: number;
  vestedPercentage?: number;
  newExtendedDate?: Date;
  completionDate?: Date;
  notes?: string;
  updateState: VestingMilestoneState;
  milestoneId: string;
  projectedVestingId: string;
  projectedVestingState: VestState;
  isFractional: boolean;
  milestoneName: string;
  targetDate: Date;
  uiVestedPercentage?: number;
}

export enum VestingMilestoneState {
  DONOTHING = "-",
  LAPSE = "Lapse",
  EXTEND = "Extend",
}

export enum VestState {
  ACTIVE = "ACTIVE",
  VESTED = "VESTED",
  LAPSED = "LAPSED",
}
