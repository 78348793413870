import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { toast } from "react-toastify";
import {
  clearCredentials,
  redirectToHissa,
  revalidateAuth,
} from "../components/utils";
import {
  useAuthStore,
  useAuthorizationStore,
  useCompanyStore,
  usePermissionStore,
} from "../store";
import { generateHMAC } from "../utils/auth";

const clearStores = () => {
  useAuthStore.getState().clear();
  useAuthorizationStore.getState().clearAuthorization();
  useCompanyStore.getState().clearCompanyData();
  usePermissionStore.getState().clearPermission();
};
const optionsApi = axios.create({
  baseURL:
    localStorage.getItem("esopNodeApi") ||
    process.env.REACT_APP_OPTIONS_API ||
    "http://localhost:4000",
});
optionsApi.defaults.headers.post["Content-Type"] = "application/json";
optionsApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).Authorization = accesstoken;
  }
  return config;
});
optionsApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      clearCredentials();
      clearStores();
      redirectToHissa("", true);
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearCredentials();
      clearStores();
      redirectToHissa("", true);
    }
    return Promise.reject(error);
  }
);

export default optionsApi;
