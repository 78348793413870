import React, { useEffect, useState } from "react";
import { Box, HStack, VStack } from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import { Select } from "../../../components/shared/Select";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import { useValuationDetails } from "../../../queries/Valuation";
import { formatDate, formatDisplayDate } from "../../../utils/date";
import { Valuation } from "../../../types/ValuationType";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";

const CompModellingValuationDetails = () => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const {
    setPpsOfCompany,
    setYoyGrowthOfCompany,
    setPpsDateOfCompany,
    compensationModellingData,
  } = useOrgTemplateStore();
  const { data: _valuations, isFetched } = useValuationDetails();
  const [valuations, setValuations] = useState<Valuation[]>();
  const [valuationDate, setValuationDate] = useState<string>("");
  const [pps, setPps] = useState<number>();
  const [yoyGrowth, setYoyGrowth] = useState<number>();

  useEffect(() => {
    if (compensationModellingData?.data?.ppsOfCompany) {
      setPps(compensationModellingData?.data?.ppsOfCompany);
    }
    if (compensationModellingData?.data?.dateOfValuation) {
      setValuationDate(
        formatDate(compensationModellingData?.data?.dateOfValuation)
      );
    }
    if (compensationModellingData?.data?.yoyGrowth) {
      setYoyGrowth(compensationModellingData?.data?.yoyGrowth);
    }
  }, [compensationModellingData]);

  useEffect(() => {
    setValuations(_valuations);
  }, [isFetched]);
  return (
    <VStack className="w-full h-[600px]">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Valuation Details
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Enter the valuation amount of the company or choose a valuation report
          to determine the share price as of the given date.
        </HStack>
      </VStack>
      <VStack className="gap-4 px-6 pt-6 pl-10 text-sm">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Reports</Label>
            <Select
              options={valuations || []}
              textGetter={(val) =>
                `${formatDisplayDate(val.dateOfValuation)} (${val.pps})`
              }
              valueGetter={(val) => val.id}
              onChange={(e) => {
                const val = valuations?.find((v) => v.id === e.target.value);
                if (val?.dateOfValuation && val?.pps) {
                  setPpsDateOfCompany(new Date(val.dateOfValuation));
                  setPpsOfCompany(val.pps);
                }
              }}
              placeholder="Select a Valuation Report"
            />
          </div>
          <div className="flex-1"></div>
        </HStack>
        <HStack className="gap-6 pt-10">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Date</Label>
            <Input
              type="date"
              value={valuationDate}
              onChange={(e) => setPpsDateOfCompany(new Date(e.target.value))}
            />
          </div>
          <div className="flex-1"></div>
        </HStack>
      </VStack>
      <VStack className="gap-1 px-6 pt-6 pl-10 text-sm">
        <span className="font-semibold">Price Per Share</span>
        <div className="flex justify-between text-xs text-gray-400">
          Enter the Price Per Share if the valuation is Unknown
        </div>
        <HStack className="gap-6 pt-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              PPS ({currencySymbol})
            </Label>
            <Input
              type="number"
              value={pps}
              onChange={(e) => setPpsOfCompany(Number(e.target.value))}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">YoY Growth%</Label>
            <Input
              type="number"
              value={yoyGrowth}
              onChange={(e) => setYoyGrowthOfCompany(Number(e.target.value))}
            />
          </div>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default CompModellingValuationDetails;
