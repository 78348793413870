import { format } from "date-fns";
import { TrustDetails } from "../../types/Grant";
import {
  AddVestingTemplateReq,
  TriggerType,
  VestingType,
} from "../../types/VestingTemplate";

export enum EventStatus {
  DRAFT = "DRAFT",
  FOR_APPROVAL = "FOR_APPROVAL",
  COMPLETED = "COMPLETED",
}

export const baseEventScheduleDto = {
  eventName: "",
  eventTargetDate: format(new Date(), "yyyy-MM-dd"),
  id: "",
  vestingDuration: 0,
  vestingInterval: 0,
  sequenceNumber: 0,
  percentage: 0,
  vestingScheduleState: EventStatus.DRAFT,
  vestingTriggerType: TriggerType.EVENT,
  milestoneId: "",
  newlyAdded: false,
};
export const baseTimeScheduleDto = {
  eventName: "",
  eventTargetDate: "",
  id: "",
  vestingDuration: 0,
  vestingInterval: 0,
  sequenceNumber: 0,
  percentage: 0,
  vestingScheduleState: "",
  vestingTriggerType: TriggerType.TIME,
};

export const vestingBaseValues: AddVestingTemplateReq = {
  id: "",
  vestingTemplateName: "",
  timeScheduleDtos: [baseTimeScheduleDto],
  eventScheduleDtos: [],
  cliffPeriod: 12,
  vestingType: VestingType.STANDARD,
  includeTimeVesting: false,
  includeEventVesting: false,
  isDefault: false,
};

export const trustBaseValues: TrustDetails = {
  id: "",
  trustName: "",
  trustPoolSize: 0,
  availableShares: 0,
};
