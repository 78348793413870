import axios, { AxiosRequestHeaders } from "axios";
import { revalidateAuth } from "../components/utils";
import { useAuthStore } from "../store";

const captableApi = axios.create({
  baseURL: process.env.REACT_APP_CAPTABLE_API || "http://localhost:4001",
});
captableApi.defaults.headers.post["Content-Type"] = "application/json";
captableApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).companyId =
      localStorage.getItem("companyId") ?? "";
    (config.headers as AxiosRequestHeaders).accessToken = accesstoken;
  }

  return config;
});

export default captableApi;
