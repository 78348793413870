import { VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useQuery } from "../../utils/useQuery";
import CreateBuybackDetails from "./CreateBuybackDetails";

function CreateOptionBuyback() {
  const query = useQuery().get("mode");
  return (
    <VStack className="gap-6">
      <BCHeader
        bcTitle={"Liquidity Event Planning and Administration"}
        className="py-3"
        bcSubTitle={query === "create" ? "Create Buy-Back" : "Edit Buy-Back"}
      />
      <VStack>
        <CreateBuybackDetails />
      </VStack>
    </VStack>
  );
}

export default CreateOptionBuyback;
