import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { string } from "yup";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export interface ChartData {
  labels: (string | number)[];
  datasets: {
    label: string;
    data: (string | number)[];
    backgroundColor: string[];
    borderColor: string[];
  }[];
}

export function DepartmentChart({
  data,
  options,
  length,
}: {
  data: ChartData;
  options: Object;
  length: number;
}) {
  const plugins = [
    {
      id: "0",
      beforeDraw(chart: ChartJS) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 100).toFixed(2);
        ctx.font = `${fontSize}em Inter`;
        ctx.textBaseline = "top";
        const text = length.toLocaleString();
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 1.8;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 250).toFixed(2);
        ctx.font = `${fontSize}em Inter`;
        ctx.textBaseline = "top";
        const text2 = `Total`;
        const textX = Math.round((width - ctx.measureText(text2).width) / 2);
        const textY = height / 2.4;
        ctx.fillText(text2, textX, textY + 20);
        ctx.save();
      },
    },
  ];
  return <Doughnut options={options} data={data} plugins={plugins} />;
}
