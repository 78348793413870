import optionsApi from "./optionsApi";
import { CompensationModellingData } from "../pages/esopModeling/companyDetails/utils/OrgStructureJSONDataProc";

export async function getAllCompensationModelling(): Promise<
  CompensationModellingData[]
> {
  return optionsApi
    .get("v1/compensationModelling/getAllCompensationModelling")
    .then((res) => res.data.data);
}

export async function createCompensationModelling(
  data: CompensationModellingData
): Promise<CompensationModellingData> {
  return optionsApi
    .post("v1/compensationModelling/createCompensationModelling", data)
    .then((res) => res.data.data);
}
