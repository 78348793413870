import { Icon } from "@iconify/react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import SortingComponent from "../../components/SortingVariation";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import DropdownButton from "../../components/shared/DropdownButton";
import { EmptyTable } from "../../components/shared/EmptyTable";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Box, HStack, VStack } from "../../components/utils";
import { useGetAllExerciseRequests } from "../../queries/exercise";
import {
  useEditExerciseOptions,
  useGetOptionBuyback,
} from "../../queries/optionBuyback";
import { usePermissionStore } from "../../store/permissionStore";
import { useExerciseApproveDialog } from "../../store/useDialogStore";
import {
  ConversionMethodology,
  ExerciseReq,
  RaisedBy,
} from "../../types/Exercise";
import {
  LiquidityState,
  MonetaryEventState,
  MonetaryTransactionType,
  OfferState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import { globalFilter, sort } from "../../utils/arrays";
import { determineUserAccessToResource } from "../../utils/auth";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { capitalizeFirstLetter } from "../../utils/string";
import GrantStatusLabel from "../esopOverview/StatusLabel";
import { BuybackType } from "./index";

function BuybackTable() {
  const currency = getCurrencyType();
  const navigate = useNavigate();
  const {
    data: _buybackOverview,
    isPlaceholderData,
    isFetching,
  } = useGetOptionBuyback();
  const pathName = useLocation().pathname;
  const [active, setActive] = useState<BuybackType>(
    pathName.includes("exerciseRequests")
      ? {
          name: "Exercise Requests",
          value: null,
          disabled: false,
        }
      : {
          name: "Buy Back",
          value: MonetaryTransactionType.BUYBACK,
          disabled: false,
        }
  );
  // memoization of result
  let buybackOverview = useMemo(
    () => _buybackOverview || [],
    [isPlaceholderData, isFetching, _buybackOverview]
  );

  const [globalFilterText, setGlobalFilterText] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { permission } = usePermissionStore();

  const { data: exerciseRequest } = useGetAllExerciseRequests();
  const { setState: setExerciseApproveDialog } = useExerciseApproveDialog();

  const buyBackType: BuybackType[] = [
    {
      name: "Buy Back",
      value: MonetaryTransactionType.BUYBACK,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
    {
      name: "Exercise and Liquidate",
      value: MonetaryTransactionType.EXERCISE_LIQUIDITY,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
    {
      name: "Exercise",
      value: MonetaryTransactionType.EXERCISE,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
    {
      name: "Exercise Requests",
      value: null,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
  ];
  const [sortField, setSortField] = useState<{
    field: keyof OptionBuyBackDetails | "" | undefined;
    ascending: boolean;
  }>({ field: "startDate", ascending: false });
  // search filter
  buybackOverview = useMemo(() => {
    if (!buybackOverview) return [];
    const filterResult = globalFilter(buybackOverview, globalFilterText, [
      "eventName",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [buybackOverview, globalFilterText, sortField]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  const [searchText, setSearchText] = useState("");

  //pagination logic, table data manipulation
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    let activeTabData: OptionBuyBackDetails[] | ExerciseReq[] = [];
    if (active.name === "Exercise Requests") {
      activeTabData = exerciseRequest?.data || [];

      activeTabData = globalFilter(activeTabData, searchText, [
        "optionHolderName",
        "identifier",
        "numberOfShares",
      ]);
    } else {
      activeTabData = buybackOverview.filter(
        (item) => item.transactionType === active.value
      );

      activeTabData = globalFilter(activeTabData, searchText, ["eventName"]);
    }
    return activeTabData.slice(firstPageIndex, lastPageIndex);
  }, [
    buybackOverview,
    currentPage,
    pageSize,
    active,
    exerciseRequest,
    searchText,
  ]);
  // routing for event details
  function buybackDetails(item: OptionBuyBackDetails) {
    if (item.transactionType === MonetaryTransactionType.EXERCISE) {
      if (
        [MonetaryEventState.DRAFT, MonetaryEventState.STARTED].includes(
          item.eventState
        )
      ) {
        navigate(`/options/exercise-event/edit/${item.id}`);
      } else {
        navigate(`/options/exercise-event/view/${item.id}`);
      }
    } else if (item.eventState === MonetaryEventState.STARTED) {
      navigate(`/options/start-buy-back/${item.id}`);
    } else if (item.eventState === MonetaryEventState.COMPLETED) {
      if (
        item.transactionType === MonetaryTransactionType.EXERCISE_LIQUIDITY &&
        item.liquidityState !== LiquidityState.DRAFT
      ) {
        navigate(`/options/liquidation-details/${item.id}`);
        return;
      }
      navigate(`/options/complete-buy-back/${item.id}`);
    } else navigate(`/options/view-option-buy-back/${item.id}`);
  }

  // approving the exercise request
  const handleAction = (action: Action, data: ExerciseReq) => {
    const runningExerciseEventId = buybackOverview.find(
      (item) =>
        item.eventState === "STARTED" && item.transactionType === "EXERCISE"
    )?.id;
    if (action.name === "Approve/Reject Request" && !action.disabled) {
      setExerciseApproveDialog({
        open: true,
        id: data.id,
        isEmployeeRaised: data.raisedBy === RaisedBy.EMPLOYEE,
      });
    }
  };
  // responsive hook
  const { isMobile } = useIsMobile();

  // find the event pps
  function getPPSfromOffers(items: OptionBuyBackDetails) {
    const totalAmountReceived =
      items?.monetaryOffers
        ?.filter(
          (offer) =>
            offer.offerState === OfferState.ACCEPTED ||
            offer.offerState === OfferState.COMPLETED
        )
        .reduce(
          (accumulator: any, { amountReceived }: { amountReceived: number }) =>
            accumulator + amountReceived,
          0
        ) || 0;
    const totalGivenWillingness =
      items?.monetaryOffers
        ?.filter(
          (offer) =>
            offer.offerState === OfferState.ACCEPTED ||
            offer.offerState === OfferState.COMPLETED
        )
        .reduce(
          (accumulator: any, { willingness }: { willingness: number }) =>
            accumulator + willingness,
          0
        ) || 0;

    const pps = totalAmountReceived / totalGivenWillingness;
    return pps;
  }

  const [optionsRequested, setOptionsRequested] = useState<number>();
  const [editRow, setEditRow] = useState({
    editable: false,
    id: "0",
    index: 0,
  });
  const [error, setError] = useState(false);
  const {
    mutate: updateRequestedExerciseOptions,
    isLoading: isOptionsUpdated,
  } = useEditExerciseOptions();
  function saveUpdateOptions(
    grantId: string,
    isCashExercise: boolean,
    note: string,
    id: string
  ) {
    if (error) {
      toast("Can not request more than already requested");
    }
    if (optionsRequested && optionsRequested <= 0) {
      toast("Cannot set willingness to 0");
    }
    const obj = {
      grantId,
      isCashExercise,
      note,
      optionsRequested,
      id,
    };

    updateRequestedExerciseOptions(obj, {
      onSuccess: (data) => {
        toast("Requested options Successfully updated", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: () => {},
    });
  }
  return (
    <Box className="p-6 bg-white rounded-lg">
      <Box
        aria-label="toolbar"
        className={`flex ${
          isMobile ? "flex-col mb-2" : "flex-row mb-4"
        } justify-between items-baseline`}
      >
        <HStack className="gap-4">
          <VStack className="min-w-max">
            {active.name !== "Exercise Requests" ? (
              <div>
                <p className="text-lg font-medium text-gray-dark">
                  {active.value === MonetaryTransactionType.EXERCISE
                    ? "Option Exercise Overview"
                    : active.value === MonetaryTransactionType.BUYBACK
                    ? "Option Buy Back Overview"
                    : active.value === MonetaryTransactionType.LIQUIDATION
                    ? "Option Liquidation Overview "
                    : "Option Exercise and Liquidation"}
                </p>
                <p className="text-xs font-medium text-[#B5B5C3]">
                  {
                    (currentTableData as OptionBuyBackDetails[])?.filter(
                      (item) => item.transactionType === active.value
                    ).length
                  }{" "}
                  results
                </p>
              </div>
            ) : (
              <div>
                <p className="text-lg font-medium text-gray-dark">
                  Option Exercise Requests
                </p>
                <p className="text-xs font-medium text-[#B5B5C3]">
                  {currentTableData?.length || 0} results
                </p>
              </div>
            )}
          </VStack>
        </HStack>
        <HStack className="gap-4 overflow-auto ">
          {buyBackType.map((type) => (
            <Box
              key={type.name}
              onClick={() => setActive(type)}
              className={`text-xs3 font-semibold cursor-pointer py-4 ${
                type.value === active?.value
                  ? "border-b-2 border-solid border-red-500 text-red-500"
                  : "text-gray-201 "
              }`}
            >
              {type.name}
            </Box>
          ))}
        </HStack>
      </Box>
      <HStack className="justify-end">
        <HStack className="items-center p-2 mr-4 rounded-md w-60 bg-slate-light text-slate-dark">
          <Icon icon="fe:search" width="24" className="mr-2 " />
          <input
            type="text"
            className="w-full text-xs font-normal border-0 outline-none bg-inherit"
            placeholder="Search..."
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          ></input>
        </HStack>
      </HStack>
      <Box className="max-h-full overflow-auto">
        {active.name !== "Exercise Requests" ? (
          <table
            className={` table-space ${
              isMobile ? "min-w-[1040px]" : "w-full"
            } `}
          >
            <thead className="text-xs font-medium text-[#B5B5C3] ">
              <tr className=" border-b border-[#E4E6EF] border-dashed">
                <td className="items-center py-3 hover:cursor-pointer">
                  <HStack>
                    NAME
                    <SortingComponent
                      fieldName="eventName"
                      selectedFieldName={sortField?.field ?? ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "eventName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>
                    OFFER START DATE
                    <SortingComponent
                      fieldName="startDate"
                      selectedFieldName={sortField?.field ?? ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "startDate",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "endDate",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  <HStack>
                    OFFER END DATE
                    <SortingComponent
                      fieldName="endDate"
                      selectedFieldName={sortField?.field ?? ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "endDate",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                {active.name !== "Exercise" && (
                  <>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        setSortField({
                          field: "",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack>TARGETED VALUE</HStack>
                    </td>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        setSortField({
                          field: "",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack> ACTUAL VALUE </HStack>
                    </td>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        setSortField({
                          field: "eventPercentage",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack>
                        PERCENTAGE (%)
                        <SortingComponent
                          fieldName="eventPercentage"
                          selectedFieldName={sortField?.field ?? ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            setSortField({
                              field: "eventPercentage",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </td>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        //price is not directly available
                        setSortField({
                          field: "monetaryDetails",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack>
                        {`${
                          (currentTableData[0] as OptionBuyBackDetails)
                            ?.transactionType ===
                          MonetaryTransactionType.BUYBACK
                            ? "PRICE"
                            : "SALE PRICE"
                        }`}
                        <SortingComponent
                          fieldName="price"
                          selectedFieldName={sortField?.field ?? ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            // price not available
                            setSortField({
                              field: "monetaryDetails",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </td>
                  </>
                )}
                {active.name === "Exercise" && (
                  <>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        setSortField({
                          field: "",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack>TOTAL EXERCISE AMOUNT</HStack>
                    </td>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        setSortField({
                          field: "",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack>OPTIONS EXERCISED</HStack>
                    </td>
                    <td
                      className="py-3 hover:cursor-pointer"
                      onClick={() =>
                        setSortField({
                          field: "",
                          ascending: !sortField?.ascending,
                        })
                      }
                    >
                      <HStack>SHARES RECEIVED</HStack>
                    </td>
                  </>
                )}
              </tr>
            </thead>
            <tbody
              className={` ${
                isPlaceholderData ? "loading" : ""
              } font-medium text-dark`}
            >
              {currentTableData &&
                (currentTableData as OptionBuyBackDetails[])?.map(
                  (listItems, index) => (
                    <tr
                      key={index}
                      className=" border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50"
                      onClick={() => buybackDetails(listItems)}
                    >
                      <td className="py-2">
                        <VStack>
                          <Box>
                            <p>{listItems.eventName}</p>
                            <GrantStatusLabel
                              state={listItems.eventState.toString()}
                            ></GrantStatusLabel>
                          </Box>
                        </VStack>
                      </td>
                      <td className="py-2">
                        <Box className="">
                          <p className="">
                            {formatDisplayDate(listItems.startDate)}
                          </p>
                        </Box>
                      </td>
                      <td className="py-2">
                        <Box className="">
                          <p className="">
                            {formatDisplayDate(listItems.endDate)}
                          </p>
                        </Box>
                      </td>
                      {active.name !== "Exercise" && (
                        <>
                          <td className="py-2">
                            <Box className="">
                              <p>
                                {formatCurrency(
                                  (listItems?.monetaryOffers?.reduce(
                                    (
                                      accumulator: any,
                                      { eligibility }: { eligibility: number }
                                    ) => accumulator + eligibility,
                                    0
                                  ) || 0) *
                                    (listItems?.transactionType ===
                                    MonetaryTransactionType.BUYBACK
                                      ? listItems?.monetaryDetails?.price || 0
                                      : listItems?.monetaryDetails
                                          ?.sellingPrice || 0),
                                  currency
                                )}
                              </p>
                            </Box>
                          </td>
                          <td className="py-2">
                            <Box className="">
                              <p>
                                {formatCurrency(
                                  (listItems?.monetaryOffers
                                    ?.filter(
                                      (offer) =>
                                        offer.offerState ===
                                          OfferState.ACCEPTED ||
                                        offer.offerState ===
                                          OfferState.COMPLETED
                                    )
                                    .reduce(
                                      (
                                        accumulator: any,
                                        { willingness }: { willingness: number }
                                      ) => accumulator + willingness,
                                      0
                                    ) || 0) *
                                    (listItems?.transactionType ===
                                    MonetaryTransactionType.BUYBACK
                                      ? listItems?.monetaryDetails.price || 0
                                      : listItems?.monetaryDetails
                                          .sellingPrice || 0),
                                  currency
                                )}{" "}
                              </p>
                            </Box>
                          </td>
                          <td className="py-2">
                            <Box>
                              <p className="">
                                {(listItems.eventPercentage * 100).toPrecision(
                                  3
                                )}
                              </p>
                            </Box>
                          </td>
                          <td className="py-2">
                            <Box>
                              <p className="">
                                {" "}
                                {formatCurrency(
                                  (currentTableData[0] as OptionBuyBackDetails)
                                    ?.transactionType ===
                                    MonetaryTransactionType.BUYBACK
                                    ? listItems.monetaryDetails.price || 0
                                    : listItems.monetaryDetails.sellingPrice ||
                                        0,
                                  currency
                                )}
                              </p>
                            </Box>
                          </td>
                        </>
                      )}
                      {active.name === "Exercise" && (
                        <>
                          <td className="py-2">
                            <Box>
                              <p className="">
                                {" "}
                                {formatCurrency(
                                  listItems?.monetaryOffers?.reduce(
                                    (
                                      accumulator: any,
                                      {
                                        amountReceived,
                                      }: { amountReceived: number }
                                    ) => accumulator + amountReceived,
                                    0
                                  ) || 0,
                                  currency
                                )}
                              </p>
                            </Box>
                          </td>
                          <td>
                            <p>
                              {listItems?.monetaryOffers
                                ?.filter(
                                  (offer) =>
                                    offer.offerState === OfferState.ACCEPTED ||
                                    offer.offerState === OfferState.COMPLETED
                                )
                                .reduce(
                                  (
                                    accumulator: any,
                                    { willingness }: { willingness: number }
                                  ) => accumulator + willingness,
                                  0
                                ) || 0}
                            </p>
                          </td>
                          <td>
                            <p>
                              {listItems?.monetaryOffers
                                ?.filter(
                                  (offer) =>
                                    offer.offerState === OfferState.ACCEPTED ||
                                    offer.offerState === OfferState.COMPLETED
                                )
                                .reduce(
                                  (
                                    accumulator: any,
                                    {
                                      willingness,
                                      conversionRatio,
                                      conversionMethodology,
                                    }
                                  ) =>
                                    accumulator +
                                    (conversionMethodology ===
                                    ConversionMethodology.ROUND_UP
                                      ? Math.ceil(
                                          willingness * (conversionRatio || 1)
                                        )
                                      : Math.floor(
                                          willingness * (conversionRatio || 1)
                                        )),
                                  0
                                ) || 0}
                            </p>
                          </td>
                        </>
                      )}
                    </tr>
                  )
                )}
            </tbody>
          </table>
        ) : (
          <table
            className={`table-space ${isMobile ? "min-w-[1040px]" : "w-full"} `}
          >
            <thead className="text-xs font-medium text-[#B5B5C3] ">
              <tr className=" border-b border-[#E4E6EF] border-dashed">
                <td className="items-center py-3 hover:cursor-pointer">
                  <HStack>EMPLOYEE NAME</HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>GRANT ID</HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>EXERCISE TYPE</HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>DATE</HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>STATUS</HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>WILLINGNESS GIVEN</HStack>
                </td>
                <td className="py-3 hover:cursor-pointer">
                  <HStack>NOTE</HStack>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody
              className={` ${
                isPlaceholderData ? "loading" : ""
              } font-medium text-dark`}
            >
              {currentTableData &&
                (currentTableData as ExerciseReq[])?.map((data, index) => (
                  <tr
                    key={index}
                    className=" border-b border-[#E4E6EF] border-dashed hover:bg-slate-50 items-center"
                  >
                    <td className="py-4">
                      <p>{data.optionHolderName}</p>
                    </td>
                    <td className="py-2">
                      <p>{data.identifier}</p>
                    </td>
                    <td className="py-2">
                      <p>{data.isCashExercise ? "CASH" : "STOCK"}</p>
                    </td>
                    <td className="py-2">
                      <p>
                        {new Date(data.dateOfExercise)
                          .toUTCString()
                          .slice(5, 17)}
                      </p>
                    </td>
                    <td>
                      <GrantStatusLabel state={data.status}></GrantStatusLabel>
                    </td>
                    <td className="py-2">
                      <HStack>
                        {data.raisedBy === RaisedBy.EMPLOYEE &&
                        data.status === "REQUESTED" ? (
                          <VStack>
                            {editRow.editable &&
                            index === editRow.index &&
                            editRow.id === data.identifier ? (
                              <HStack className="items-center gap-4">
                                <input
                                  type="text"
                                  value={optionsRequested || 0}
                                  onChange={(e) => {
                                    if (
                                      data.numberOfShares >=
                                      parseInt(e.target.value, 10)
                                    ) {
                                      setOptionsRequested(
                                        parseInt(e.target.value, 10)
                                      );
                                      setError(false);
                                    } else if (
                                      data.numberOfShares <
                                      parseInt(e.target.value, 10)
                                    ) {
                                      setError(true);
                                      setOptionsRequested(
                                        parseInt(e.target.value, 10)
                                      );
                                    } else {
                                      setOptionsRequested(
                                        parseInt(e.target.value, 10)
                                      );
                                    }
                                  }}
                                />
                                <Icon
                                  icon={"mingcute:save-fill"}
                                  color="text-orange-501"
                                  width={18}
                                  height={18}
                                  onClick={() => {
                                    setEditRow({
                                      editable: false,
                                      id: "0",
                                      index: 0,
                                    });
                                    saveUpdateOptions(
                                      data?.optionHolderId,
                                      data.isCashExercise,
                                      data.note,
                                      data.id
                                    );
                                  }}
                                />
                              </HStack>
                            ) : (
                              <HStack className="items-center gap-4">
                                <span>{data.numberOfShares}</span>
                                <Icon
                                  icon={"fa-solid:user-edit"}
                                  color="text-orange-501"
                                  width={18}
                                  height={18}
                                  onClick={() => {
                                    setEditRow({
                                      editable: true,
                                      id: data.identifier,
                                      index,
                                    });
                                    setError(false);
                                    setOptionsRequested(data.numberOfShares);
                                  }}
                                />
                              </HStack>
                            )}
                            {error &&
                              data.identifier === editRow.id &&
                              index === editRow.index && (
                                <span className="pt-1 text-xxs text-orange-501">
                                  Can not request more than already requested (
                                  {data.numberOfShares})
                                </span>
                              )}
                          </VStack>
                        ) : (
                          <td className="py-2">{data.numberOfShares}</td>
                        )}
                      </HStack>
                    </td>
                    <td className="py-2">
                      {`${
                        data.raisedBy === RaisedBy.ADMIN ||
                        data.raisedBy === RaisedBy.EMPLOYEE
                          ? "Raised by"
                          : "Raised during"
                      } ${capitalizeFirstLetter(
                        data.raisedBy?.toLowerCase() || "-"
                      )}` || ""}
                    </td>
                    <td>
                      <div className="p-4">
                        <CTADropdown
                          actions={[
                            {
                              name: "Approve/Reject Request",
                              disabled: data.status !== "REQUESTED",
                            },
                          ]}
                          onAction={(action) => handleAction(action, data)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {currentTableData.length === 0 && (
          <EmptyTable
            header={
              active.name !== "Exercise Requests"
                ? "No Event"
                : "No Exercise Requests"
            }
          />
        )}
      </Box>
      <Box className="flex justify-between mt-8">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => setPageSize(value)}
        />
        <Pagination
          className=""
          currentPage={currentPage}
          totalCount={buybackOverview.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}

export default BuybackTable;

export function CreateTransactiontype({
  actions,
  onAction,
  type,
}: {
  actions: BuybackType[];
  type: string;
  onAction: (action: BuybackType) => void;
}) {
  return (
    <DropdownButton
      className="items-end bg-orange-500 rounded-none"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div className="rounded-none ">
          <Icon
            height={24}
            icon="ri:arrow-drop-down-line"
            className="inline "
          />
        </div>
      )}
    />
  );
}
