/* eslint-disable consistent-return */
export function getSalaryRangeForUI(
  salaryRange: { min: number; max: number },
  currencySymbol: string,
  currencyType: string
) {
  const minNumber = convertedFormatCurrency(
    "INR - ₹",
    salaryRange.min ?? 0,
    currencyType
  );
  const maxNumber = convertedFormatCurrency(
    "INR - ₹",
    salaryRange.max ?? 0,
    currencyType
  );

  return `${currencySymbol}${minNumber} - ${currencySymbol}${maxNumber}`;
}

export function getSingleSalaryRangeForUI(
  salaryRange: number,
  currencySymbol: string,
  currencyType: string
) {
  const minNumber = convertedFormatCurrency(
    "INR - ₹",
    salaryRange ?? 0,
    currencyType
  );

  return `${currencySymbol}${minNumber}`;
}

function convertedFormatCurrency(
  currency: string,
  amount: number,
  currencyType: string
): string {
  if (amount === 0) {
    return "0";
  }
  if (
    currency === "INR - ₹" ||
    currency === "undefined" ||
    currency === "null"
  ) {
    return Math.abs(Number(amount)) >= 1.0e7
      ? `${parseFloat((Math.abs(Number(amount)) / 1.0e7).toFixed(2))}Cr`
      : // Six Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e5
      ? `${parseFloat((Math.abs(Number(amount)) / 1.0e5).toFixed(2))}L`
      : // Three Zeroes for Thousands
        `${Math.trunc(Math.abs(Number(amount))).toLocaleString(currencyType)}`;
  } else {
    return Math.abs(Number(amount)) >= 1.0e9
      ? `${parseFloat((Math.abs(Number(amount)) / 1.0e9).toFixed(2))}B`
      : // Six Zeroes for Millions
      Math.abs(Number(amount)) > 1.0e6
      ? `${parseFloat((Math.abs(Number(amount)) / 1.0e6).toFixed(2))}M`
      : Math.abs(Number(amount)) > 1.0e3
      ? `${parseFloat((Math.abs(Number(amount)) / 1.0e3).toFixed(2))}K`
      : `${Math.trunc(Math.abs(Number(amount))).toLocaleString(currencyType)}`;
  }
}
