import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import {
  CellEditingStoppedEvent,
  ColDef,
  IRowNode,
  IsRowSelectable,
} from "ag-grid-community";
import { Dialog } from "@mui/material";
import _ from "lodash";
import { toast } from "react-toastify";
import { usePermissionStore } from "../../store/permissionStore";
import {
  useGetAllPermsissions,
  useGetAssociatedUsers,
  useGetRoles,
  useSaveUsers,
} from "../../queries/usersRoles";
import {
  AclList,
  AssociatedUsers,
  DefaultPermission,
  Permission,
  Roles,
} from "../../utils/interfaces/Companies";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import "./userRoles.css";
import { Input, Label } from "../../components/shared/InputField";
import { useAuthStore } from "../../store";
import { useUserDetails } from "../../queries";

interface AddEditRoleInterface {
  name: string;
  permissions: AclList;
}
function UserRoles() {
  const { permission } = usePermissionStore();
  const { data: user } = useUserDetails();
  const gridRef = useRef<AgGridReact<AssociatedUsers>>(null);
  const { data: associatedUsers } = useGetAssociatedUsers();
  const { data: allRoles } = useGetRoles();
  const [dialog, setDialog] = useState(false);
  const { mutate: addUsers } = useSaveUsers();
  const { data: defaultPermissionList } = useGetAllPermsissions();
  const usersList: AssociatedUsers[] = useMemo(
    () =>
      associatedUsers?.map((user) => ({
        ...user,
        userSince: new Date(user.userSince).toString(),
      })) || [],
    [associatedUsers]
  );
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      editable: true,
      singleClickEdit: true,
      floatingFilter: true,
    }),
    []
  );
  const checkDuplicateUsers = (usersList: AssociatedUsers[]) => {
    const groupedUsers = _.groupBy(usersList, "email");
    const duplicates = Object.values(groupedUsers).some(
      (value) => value.length > 1
    );
    return duplicates;
  };
  function validateEmail(email: string) {
    const emailPattern = /^\S+@\S+\.\S+$/;
    if (!emailPattern.test(email)) {
      return false;
    }
    return true;
  }
  const cellEditingStopped = (e: CellEditingStoppedEvent<AssociatedUsers>) => {
    if (e.valueChanged) {
      const editedColumn = e.colDef.headerName;
      const allUsers = getAllDataFromTable();
      if (editedColumn === "Email") {
        if (!validateEmail(e.newValue)) {
          toast("Please Enter a Valid Email", {
            type: "error",
            autoClose: 2000,
          });
          return;
        }
        const duplicatesExist = checkDuplicateUsers(allUsers);
        if (duplicatesExist) {
          toast("User with Same Email already Exist", {
            type: "error",
            autoClose: 2000,
          });
          return;
        }
      } else if (editedColumn === "Role Name") {
        allUsers.forEach((user) => {
          if (user.email === e.data?.email) {
            user.role.id = allRoles?.find(
              (role) => role.name === user.role.name
            )?.id;
          }
        });
      }

      let editToBeConsidered = false;
      const newUserList = allUsers.map((user) => {
        if ((!user.toBeDeleted || !user.newlyAdded) && user.id === e.data?.id) {
          user.edited = true;
          editToBeConsidered = true;
        }
        const userObj = {
          ...user,
        };
        return userObj;
      });
      if (editToBeConsidered) gridRef?.current?.api?.setRowData(newUserList);
    }
  };
  const columnDefs: ColDef[] = [
    { field: "name", checkboxSelection: true, headerName: "Name" },
    {
      field: "email",
      headerName: "Email",
      editable: ({ data }: { data: AssociatedUsers }) =>
        data.newlyAdded === true,
    },
    {
      field: "role.name",
      cellEditor: "agSelectCellEditor",
      headerName: "Role Name",
      cellEditorParams: {
        values: allRoles?.map((role) => role.name),
        valueListGap: 0,
      },
    },
    { field: "designation" },
    {
      field: "isCompanySpecificRole",
      headerName: "Company Specific Role",
      editable: false,
    },
    { field: "userSince", editable: false },
  ];
  function addANewUser(): void {
    const existingUsers = getAllDataFromTable();
    existingUsers.push({
      id: undefined,
      email: "",
      role: {},
      name: "",
      isCompanySpecificRole: false,
      userSince: new Date().toString(),
      newlyAdded: true,
    });
    gridRef?.current?.api?.setRowData(existingUsers);
  }
  function getAllDataFromTable() {
    const allUsers: AssociatedUsers[] = [];
    gridRef.current?.api?.forEachNode((node) =>
      allUsers.push(node.data as AssociatedUsers)
    );
    return allUsers;
  }
  function saveUsers(): void {
    let currentUserList = getAllDataFromTable();
    const previousUsers = usersList;
    const deletedUsers = _.difference(previousUsers, currentUserList);
    if (deletedUsers.length > 0) {
      currentUserList = currentUserList.concat(
        deletedUsers
          .filter(
            (deletedUser) =>
              deletedUser.edited !== undefined && !deletedUser.edited
          )
          .map((deletedUser) => ({
            ...deletedUser,
            toBeDeleted: true,
          }))
      );
    }
    if (currentUserList.length > 0) {
      addUsers(currentUserList, {
        onSuccess: (e) => {
          // handle
        },
      });
    }
  }

  function deleteUsers(): void {
    const deletedUsers = gridRef?.current?.api?.getSelectedRows() || [];
    const allUsers = getAllDataFromTable().filter((user) =>
      deletedUsers?.some(
        (deletedUser) =>
          deletedUser.email !== user.email && deletedUser.id !== user.id
      )
    );
    gridRef?.current?.api?.setRowData(allUsers);
  }

  function addNewRole(): void {
    setDialog(true);
  }
  const isRowSelectable = useMemo<IsRowSelectable>(
    () => (rowNode: IRowNode) => rowNode.data.userId !== user?.id,
    [user]
  );

  return (
    <VStack>
      <HStack className="justify-between w-full">
        <Box className="p-4 w-full bg-white rounded-lg flex justify-end">
          <ButtonPrimary className="mr-4" onClick={() => addANewUser()}>
            Add User
          </ButtonPrimary>
          <ButtonSecondary onClick={() => deleteUsers()}>
            Delete User
          </ButtonSecondary>
        </Box>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: "400px",
          }}
          className="ag-theme-material w-full h-full max-h-full overflow-x-auto"
        >
          <AgGridReact
            rowData={usersList}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection={"multiple"}
            ref={gridRef}
            onCellEditingStopped={cellEditingStopped}
            suppressRowClickSelection
            isRowSelectable={isRowSelectable}
            pagination={true}
          ></AgGridReact>
        </Box>
      </HStack>
      <HStack className="justify-end w-full bg-white">
        <ButtonPrimary onClick={() => saveUsers()} className="m-4">
          Save
        </ButtonPrimary>
      </HStack>
      <Dialog open={dialog} maxWidth="md">
        <div className="w-[900px]">
          <AddEditRole permission={defaultPermissionList || []} />
        </div>
      </Dialog>
    </VStack>
  );
}
function RoleComponent({
  role,
  permissions,
}: {
  role: Roles;
  permissions: DefaultPermission[];
}) {
  const permissionsForRole = permissions?.filter((permission) =>
    role.permissions
      .map((permissionInRole) => permissionInRole.id)
      .includes(permission.id)
  );
  return (
    <Box className="w-1/5 h-full p-4 m-4 bg-slate-400" key={role.id}>
      <HStack className="justify-center">{role.name}</HStack>
      {permissionsForRole.map((permission) => (
        <li key={permission.id} className="p-1 capitalize">
          {permission.name.replace("_", " ")}
        </li>
      ))}
    </Box>
  );
}
function AddEditRole(props: {
  permission: DefaultPermission[];
  existingRole?: Roles;
}) {
  const { permission, existingRole } = props;
  const groupedPermission = _.groupBy(
    permission,
    (permissionList) => permissionList.acl.resource
  );
  const [role, setRole] = useState<AddEditRoleInterface>();
  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {};
  return (
    <Box className="w-1/5 h-full p-4 m-4">
      <HStack className="justify-center">
        <div className="flex-1">
          <Label className="text-sm font-normal">Role Name</Label>
          <Input
            className="w-48"
            value={role?.name}
            onChange={(e) => onNameChange(e)}
          />
        </div>
      </HStack>
      <HStack className=" flex justify-center w-full">
        <table className="p-4 table justify-center">
          <tbody>
            {Object.keys(groupedPermission).map((resource) => (
              <tr key={resource}>
                <td>{resource}</td>
                {groupedPermission[resource].map((aclName) => (
                  <td key={aclName.id}>
                    <HStack>{aclName.acl.action}</HStack>
                    <HStack>
                      <input type="checkbox" />
                    </HStack>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </HStack>
    </Box>
  );
}
export default UserRoles;
