/* eslint-disable guard-for-in */

import { AddEmployeeReq } from "../../../../types/Employee";
import { KeyPersonnelType, Levels } from "../../../../types/modelling";

export interface SalaryRange {
  min: number;
  max: number;
}

export interface RoleData {
  keyRole: boolean;
  percentageOfSalary: number;
  employeeCount: number;
  salaryRange: SalaryRange;
  employees: { [employeeId: string]: EmployeeData };
}

export interface EmployeeData {
  keyPersonnel: boolean;
  salary: number;
  percentageOfSalary: number;
  employeeData: AddEmployeeReq;
}

export interface LevelData {
  salaryRange: SalaryRange;
  currentEmployeeCount: number;
  totalEmployeeCount: number;
  percentageOfSalary: number;
  roles: { [roleName: string]: RoleData };
  yearlyData: YearlyData;
  futureHires?: AddEmployeeReq;
}

export interface YearlyData {
  [year: string]: number;
}

export interface DepartmentData {
  identifier: string;
  percentageOfSalary: number;
  levels: { [levelName: string]: LevelData };
}

export interface CompanyData {
  teamSize: number;
  companyName: string;
  ppsOfCompany?: number;
  dateOfValuation?: Date;
  yoyGrowth?: number;
  departments: { [departmentName: string]: DepartmentData };
  employeeListUploaded: boolean;
}

export interface OrgDataProcessor {
  companies: { [companyName: string]: CompanyData };
}

export interface TemplateData {
  departmentName: string;
  roles: string[][];
}

export interface OrgSummaryData {
  noOfDepartments: number;
  noOfKeyRoles: number;
  noOfGeneralRoles: number;
  salaryRange: SalaryRange;
}

export interface OrgSummaryDataWithPersonnel {
  noOfDepartments: number;
  noOfKeyRoles: number;
  noOfKeyEmployees: number;
  noOfGeneralEmployees: number;
  noOfKeyPersonnel: number;
  salary: number;
}

export interface HiringPlanData {
  id?: string;
  modelName: string;
  noOfYears: number;
  description: string;
  data?: CompanyData;
}

export interface CompensationModellingData {
  id?: string;
  modelName: string;
  allocationMethod: string;
  allocationType: string;
  description: string;
  data?: CompanyData;
}

class OrganiSationStructureDataProcessor {
  private data: OrgDataProcessor | undefined;

  private companyData: CompanyData | undefined;

  constructor(data?: OrgDataProcessor, companyData?: CompanyData) {
    this.data = data;
    this.companyData = companyData;
  }

  // eslint-disable-next-line class-methods-use-this
  getAllCompaniesFromData() {
    if (!this.data) {
      return [];
    }
    return Object.keys(this.data.companies);
  }

  getAllCompaniesTeamSizes() {
    const teams = new Map<string, number>();
    if (!this.data) {
      return teams;
    }
    for (const [companyName, companyData] of Object.entries(
      this.data.companies
    )) {
      teams.set(companyName, companyData.teamSize);
    }
    return teams;
  }

  getCompanyDataFromCompanyName(companyName: string) {
    let returnData: CompanyData | undefined;
    if (!this.data) {
      return undefined;
    }
    for (const [name, companyData] of Object.entries(this.data.companies)) {
      if (name === companyName) {
        returnData = companyData;
        break;
      }
    }
    return returnData;
  }

  getAllDepartmentsAndLevelsForTemplate() {
    const departments = new Map<string, string[]>();
    if (!this.data) {
      return departments;
    }
    for (const [companyName, companyData] of Object.entries(
      this.data.companies
    )) {
      for (const departmentName in companyData.departments) {
        const departmentData = companyData.departments[departmentName];
        const levels = departments.get(departmentName) || [];

        for (const levelName in departmentData.levels) {
          if (!levels.includes(levelName)) {
            levels.push(levelName);
          }
        }

        departments.set(departmentName, levels);
      }
    }
    return departments;
  }

  getDepartmentsForTemplate(): string[] {
    const departments: string[] = [];
    if (!this.data) {
      return departments;
    }
    for (const [companyName, companyData] of Object.entries(
      this.data.companies
    )) {
      for (const departmentName in companyData.departments) {
        const departmentData = companyData.departments[departmentName];
        departments.push(departmentName);
      }
    }
    const returnData = new Set(departments);
    return Array.from(returnData);
  }

  getRolesAndDepartmentByCompanyForTemplate(): TemplateData[] {
    const rolesByCompany: TemplateData[] = [];
    if (this.companyData) {
      for (const departmentName in this.companyData.departments) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.companyData.departments,
            departmentName
          )
        ) {
          const departmentData: DepartmentData =
            this.companyData.departments[departmentName];
          const rolesByDepartment: string[][] = [];

          for (const levelName in departmentData.levels) {
            if (
              Object.prototype.hasOwnProperty.call(
                departmentData.levels,
                levelName
              )
            ) {
              const levelData: LevelData = departmentData.levels[levelName];
              const roleNames: string[] = Object.keys(levelData.roles);

              rolesByDepartment.push(roleNames);
            }
          }

          rolesByCompany.push({ departmentName, roles: rolesByDepartment });
        }
      }
    }

    return rolesByCompany;
  }

  getDepartmentNames(): string[] {
    const departments: string[] = [];
    if (this.companyData) {
      Object.entries(this.companyData.departments).forEach(
        ([departmentName, data]) => {
          departments.push(departmentName);
        }
      );
    }
    return departments;
  }

  getLevels(departmentName: string): string[] {
    const levels: string[] = [];
    if (this.companyData && this.companyData.departments[departmentName]) {
      Object.entries(
        this.companyData.departments[departmentName].levels
      ).forEach(([levelName, levelData]) => {
        levels.push(levelName);
      });
    }
    return levels;
  }

  getRolesByLevel(
    departmentName: string,
    levelName: string
  ): { label: string; value: string }[] {
    const roles: { label: string; value: string }[] = [];
    if (
      this.companyData &&
      this.companyData.departments[departmentName] &&
      this.companyData.departments[departmentName].levels[levelName]
    ) {
      const department = this.companyData.departments[departmentName];
      const level = department.levels[levelName];

      Object.entries(level.roles).forEach(([roleName, roleData]) => {
        roles.push({ label: roleName, value: roleName });
      });
    }
    return roles;
  }

  getRolesForTemplate(
    companyName: string,
    departmentName: string,
    levelName: string
  ): string[] {
    const roles: string[] = [];
    const companyData = this.getCompanyDataFromCompanyName(companyName);
    if (
      companyData &&
      companyData.departments[departmentName] &&
      companyData.departments[departmentName].levels[levelName]
    ) {
      const department = companyData.departments[departmentName];
      const level = department.levels[levelName];

      Object.entries(level.roles).forEach(([roleName, roleData]) => {
        roles.push(roleName);
      });
    }
    return roles;
  }

  getDataByLevel() {
    const data: Levels[] = [];
    if (this.companyData) {
      Object.entries(this.companyData.departments).forEach(
        ([departmentName, depData]) => {
          Object.entries(depData.levels).forEach(([levelName, levData]) => {
            data.push({
              department: departmentName,
              level: levelName,
              role: Object.keys(levData.roles).join(","),
              salaryRange: {
                min: levData.salaryRange.min,
                max: levData.salaryRange.max,
              },
            });
          });
        }
      );
    }
    return data;
  }

  getSummarySheetDetails(): OrgSummaryData {
    const orgSummaryData: OrgSummaryData = {
      noOfDepartments: 0,
      noOfKeyRoles: 0,
      noOfGeneralRoles: 0,
      salaryRange: {
        min: 0,
        max: 0,
      },
    };
    if (this.companyData) {
      Object.entries(this.companyData.departments).forEach(
        ([depName, depData]) => {
          orgSummaryData.noOfDepartments += 1;
          Object.entries(depData.levels).forEach(([levelName, levelData]) => {
            Object.entries(levelData.roles).forEach(([roleName, roleData]) => {
              if (roleData.keyRole) orgSummaryData.noOfKeyRoles += 1;
              else orgSummaryData.noOfGeneralRoles += 1;
              orgSummaryData.salaryRange.min += roleData.salaryRange.min;
              orgSummaryData.salaryRange.max += roleData.salaryRange.max;
            });
          });
        }
      );
    }
    return orgSummaryData;
  }

  getSummarySheetDetailsWithPersonnel(): OrgSummaryDataWithPersonnel {
    const orgSummaryData: OrgSummaryDataWithPersonnel = {
      noOfDepartments: 0,
      noOfKeyRoles: 0,
      noOfGeneralEmployees: 0,
      noOfKeyEmployees: 0,
      salary: 0,
      noOfKeyPersonnel: 0,
    };
    if (this.companyData) {
      Object.entries(this.companyData.departments).forEach(
        ([depName, depData]) => {
          orgSummaryData.noOfDepartments += 1;
          Object.entries(depData.levels).forEach(([levelName, levelData]) => {
            Object.entries(levelData.roles).forEach(([roleName, roleData]) => {
              if (roleData.keyRole) orgSummaryData.noOfKeyRoles += 1;
              if (roleData.employees) {
                Object.entries(roleData.employees).forEach(
                  ([empId, empData]) => {
                    orgSummaryData.salary += parseInt(
                      empData.salary.toString(),
                      10
                    );
                    if (empData.keyPersonnel) {
                      orgSummaryData.noOfKeyPersonnel += 1;
                    } else if (roleData.keyRole) {
                      orgSummaryData.noOfKeyEmployees += 1;
                    } else {
                      orgSummaryData.noOfGeneralEmployees += 1;
                    }
                  }
                );
              }
            });
          });
        }
      );
    }
    return orgSummaryData;
  }

  getEmployeesWithAllDetails() {
    const employeesByCompany: KeyPersonnelType[] = [];
    if (this.companyData) {
      for (const departmentName in this.companyData.departments) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.companyData.departments,
            departmentName
          )
        ) {
          const departmentData: DepartmentData =
            this.companyData.departments[departmentName];

          for (const levelName in departmentData.levels) {
            if (
              Object.prototype.hasOwnProperty.call(
                departmentData.levels,
                levelName
              )
            ) {
              const levelData: LevelData = departmentData.levels[levelName];
              for (const roleName in levelData.roles) {
                const roleData = levelData.roles[roleName];
                if (roleData.employees) {
                  for (const employee in roleData.employees) {
                    const employeeData = roleData.employees[employee];
                    if (employeeData && !employeeData.keyPersonnel) {
                      employeesByCompany.push({
                        department: departmentName,
                        employeeId: employee,
                        level: levelName,
                        name: employeeData.employeeData.employeeName,
                        role: roleName,
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return employeesByCompany;
  }
}

export default OrganiSationStructureDataProcessor;
