import { CompanyProfileDetails } from "../../types/SiteSettings";
import { formatDate } from "../../utils/date";

export const initialCompanyDetail: CompanyProfileDetails = {
  id: "",
  name: "",
  cin: "",
  companyCurrency: "INR - ₹",
  dateOfIncorporation: formatDate(new Date()),
  address: "",
  email: "",
  companyLogoPath: "",
  operatingAs: "",
  nic: "",
};
