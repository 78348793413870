import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  Error,
} from "../../../../components/utils";
import { Department } from "../../../../types/modelling";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";

const AddDepartmentDialog = ({
  open,
  onClose = () => {},
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const initialValues = {
    name: "",
    identifier: "",
  };
  const { addDepartmentToCompanyData } = useOrgTemplateStore();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    identifier: Yup.string().required("Identifier is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      addDepartmentToCompanyData({
        name: values.name,
        identifier: values.identifier,
      });
      onClose();
    },
  });

  return (
    <>
      <div className="font-semibold border-b p-7">
        <div className="flex items-center justify-between">
          <VStack className="gap-2 text-lg2 text-black-501">
            <p> Product Grade Guide</p>
            <p className="text-sm4 text-gray-401">
              Add department grades within the company.
            </p>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </div>
      <VStack className="w-full gap-4 p-7">
        <HStack>
          <HStack className="gap-6">
            <div className="flex-1">
              <Label className="text-sm font-normal">Name</Label>
              <Input type="text" {...formik.getFieldProps("name")} />
              {formik.touched.name && formik.errors.name && (
                <Error text={formik.errors.name} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Identifier</Label>
              <Input type="text" {...formik.getFieldProps("identifier")} />
              {formik.touched.identifier && formik.errors.identifier && (
                <Error text={formik.errors.identifier} />
              )}
            </div>
          </HStack>
        </HStack>
        <HStack className="justify-end gap-3 pt-3">
          <ButtonSecondary
            onClick={() => onClose()}
            className="p-10 text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddDepartmentDialog;
