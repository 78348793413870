import { useMutation, useQuery } from "react-query";
import { createHiringPlan, getAllHiringPlans } from "../api/hiringPlanApi";

export function useGetAllHiringPlans() {
  return useQuery({
    queryKey: ["getHiringPlans"],
    queryFn: getAllHiringPlans,
  });
}

export function useCreateHiringPlan() {
  return useMutation({
    mutationKey: "createHiringPlan",
    mutationFn: createHiringPlan,
  });
}
