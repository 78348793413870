import { Icon } from "@iconify/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FileInput from "../../components/shared/FileInput";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import DocumentTypes from "../../constants/DocumentTypes";
import { useUpdatePlanDocs } from "../../queries/esopPlan";
import {
  DocumentInterface,
  DocumentType,
} from "../../types/DocumentsInterface";
import {
  convertBase64ToBlob,
  downloadBlobObject,
  downloadS3File,
} from "../../utils/DownloadFile";
import convertToBase64 from "../../utils/convertToBase64";

export default function PlanDocument({
  planId,
  existingPlanDocs,
  isViewMode,
}: {
  planId: string;
  existingPlanDocs: DocumentInterface[];
  isViewMode: boolean;
}) {
  const { mutate: updatePlanDoc, isLoading } = useUpdatePlanDocs();
  const [availablePlanDocs, setAvailablePlanDocs] = useState<
    DocumentInterface[]
  >([]);
  const validationSchema = Yup.array().of(
    Yup.object({
      documentName: Yup.string().required("Document Name is required"),
      documentType: Yup.string()
        .required("Document Type is required")
        .test(
          "unique-documentType",
          "Document type already exists",
          (value) => {
            if (value && value === DocumentType.OTHERS) {
              return true;
            }
            availablePlanDocs.forEach((doc) => {
              if (doc.documentType === value && doc.id) {
                return false;
              } else {
                return true;
              }
            });
            return true;
          }
        ),
    })
  );
  function getDocumentType(documents: DocumentInterface[]) {
    const hasPlanDocument = documents.some(
      (d) => d.documentType === DocumentType.ESOP_PLAN_DOCUMENT
    );
    const hasBoardDocument = documents.some(
      (d) => d.documentType === DocumentType.ESOP_BOARD_DOCUMENT
    );
    if (hasPlanDocument && hasBoardDocument) {
      return DocumentType.OTHERS;
    } else if (hasPlanDocument) {
      return DocumentType.ESOP_BOARD_DOCUMENT;
    } else {
      return DocumentType.ESOP_PLAN_DOCUMENT;
    }
  }
  const allowedExtensions = ["pdf"];

  function handleSubmit(values: DocumentInterface[]) {
    if (existingPlanDocs !== values) {
      updatePlanDoc(values, {
        onSuccess() {
          toast(
            `Document${values.length > 1 ? "s" : ""} modified successfully!`,
            {
              type: "success",
              autoClose: 3000,
            }
          );
        },
        onError(error) {
          toast((error as AxiosError).response?.data as string, {
            type: "error",
            autoClose: 3000,
          });
        },
      });
    }
  }
  async function handleFileInput(
    e: any,
    index: number,
    availablePlanDocs: DocumentInterface[]
  ) {
    const file = e.target.files[0] as File;
    if (file) {
      const splitFileName = file.name.split(".");
      const fileExtension = splitFileName[splitFileName.length - 1];
      if (!allowedExtensions.includes(fileExtension))
        toast(
          `Invalid file type, only ${allowedExtensions.toString()} are allowed`,
          { type: "error", autoClose: 3000 }
        );
      else {
        const b64File = (await convertToBase64(file)) as string;
        formik.setValues(
          availablePlanDocs.map((doc, i) =>
            i === index
              ? {
                  ...doc,
                  b64File,
                  contentType: file.type,
                  isEdited: true,
                  isUserUploaded: true,
                }
              : doc
          )
        );
      }
    }
  }
  function handleAdd(availablePlanDocs: DocumentInterface[]) {
    const newDocument = baseDocument;
    formik.setValues([...availablePlanDocs, newDocument]);
  }
  function fetchAndViewDocument(path: string) {
    downloadS3File(path);
  }

  async function downloadReport(document: DocumentInterface) {
    if (document.b64File) {
      const blob = await convertBase64ToBlob(
        document.b64File,
        document.contentType || "application/pdf"
      );
      downloadBlobObject(
        blob,
        `${document.documentName}.pdf` || "Document.pdf"
      );
    }
  }
  function getdocTypefromValue(key: string) {
    switch (key) {
      case DocumentType.ESOP_PLAN_DOCUMENT:
        return "Plan Document";
      case DocumentType.ESOP_BOARD_DOCUMENT:
        return "Board Documents";
      case DocumentType.OTHERS:
        return "Other Documents";
      default:
        return "";
    }
  }
  function getUpdatedDocumentTypes(documents: DocumentInterface[]) {
    const documentTypesSet = new Set<string>();
    documents.forEach((document) => {
      if (document.id) {
        documentTypesSet.add(document.documentType);
      }
    });
    const documentTypesArray = Array.from(documentTypesSet);
    const documentTypes: {
      text: string;
      value: DocumentType;
    }[] = [];
    if (!documentTypesArray.includes(DocumentType.ESOP_PLAN_DOCUMENT)) {
      documentTypes.push({
        text: "Plan Document",
        value: DocumentType.ESOP_PLAN_DOCUMENT,
      });
    }
    if (!documentTypesArray.includes(DocumentType.ESOP_BOARD_DOCUMENT)) {
      documentTypes.push({
        text: "Board Documents",
        value: DocumentType.ESOP_BOARD_DOCUMENT,
      });
    }
    documentTypes.push({ text: "Other Documents", value: DocumentType.OTHERS });
    return documentTypes;
  }
  function handleDelete(
    deleteIndex: number,
    availablePlanDocs: DocumentInterface[]
  ) {
    formik.setValues(
      availablePlanDocs.reduce((newDocs: DocumentInterface[], doc, index) => {
        if (index === deleteIndex) {
          if (!doc.toBeAdded) {
            newDocs.push({ ...doc, toBeDeleted: true });
          }
        } else {
          newDocs.push(doc);
        }
        return newDocs;
      }, [])
    );
  }
  const formik = useFormik({
    initialValues: existingPlanDocs || [],
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    setAvailablePlanDocs(formik.values);
  }, [formik.values]);
  const baseDocument: DocumentInterface = {
    documentName: "",
    documentType: getDocumentType(formik.values),
    toBeAdded: true,
    entityId: planId ?? "",
    isUserUploaded: false,
  };
  return (
    <div>
      <VStack aria-label="header" className="px-4 gap-y-2">
        {formik.values.length > 0 && (
          <HStack className="mt-4 ml-8 gap-x-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Document Name</Label>
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Type</Label>
            </div>
            <div className="flex-1"></div>
          </HStack>
        )}
        {formik.values.map((document, index) => (
          <HStack className="ml-8 gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                type="text"
                disabled={isViewMode || !!document.id}
                {...formik.getFieldProps(`${[index]}.documentName`)}
              />
              {formik.touched[index]?.documentName &&
                formik.errors[index]?.documentName && (
                  <Error text={formik.errors[index]?.documentName} />
                )}
            </div>
            {document.id ? (
              <div className="flex-1">
                <Input
                  disabled
                  value={getdocTypefromValue(formik.values[index].documentType)}
                />
              </div>
            ) : (
              <div className="flex-1">
                <Select
                  isPlaceHolderDisabled={true}
                  options={getUpdatedDocumentTypes(formik.values)}
                  valueGetter={(o) => o.value}
                  textGetter={(o) => o.text}
                  disabled={isViewMode || !!document.id}
                  {...formik.getFieldProps(`${[index]}.documentType`)}
                />
                {formik.touched[index]?.documentType &&
                  formik.errors[index]?.documentType && (
                    <Error text={formik.errors[index]?.documentType} />
                  )}
              </div>
            )}
            <div className="flex items-center flex-1 gap-4">
              <HStack className="items-center gap-x-2">
                <Icon
                  width={20}
                  className="text-gray-400 cursor-pointer hover:text-orange-501"
                  onClick={() => {
                    if (document.documentPath) {
                      fetchAndViewDocument(document.documentPath ?? "");
                    } else {
                      downloadReport(document);
                    }
                  }}
                  icon={"ic:baseline-remove-red-eye"}
                />
                {!isViewMode &&
                  (document.toBeDeleted ? (
                    <div className="text-xs text-red-600"> Will be deleted</div>
                  ) : (
                    <Icon
                      width={20}
                      className="text-gray-400 cursor-pointer hover:text-orange-501"
                      onClick={() => handleDelete(index, formik.values)}
                      icon={"ic:baseline-delete"}
                    />
                  ))}
                {!isViewMode &&
                  (document.b64File !== undefined ? (
                    document.toBeAdded ? (
                      <div className="text-xs text-green-400">
                        Will be uploaded
                      </div>
                    ) : (
                      <div className="text-xs text-green-400">
                        Will be edited
                      </div>
                    )
                  ) : (
                    <FileInput
                      file={`${
                        document.documentPath ? "Edit Document" : "Upload"
                      }`}
                      className="cursor-not-allowed"
                      id={index.toString()}
                      accept="application/pdf"
                      disabled={!baseDocument}
                      onChange={(e) => {
                        handleFileInput(e, index, formik.values);
                      }}
                    />
                  ))}
              </HStack>
            </div>
            <div className="flex-1 flex items-center justify-center">
              {document.isUserUploaded ? (
                <div className="text-xs text-gray-201">User uploaded</div>
              ) : (
                <div className="text-xs text-gray-201">System Generated</div>
              )}
            </div>
          </HStack>
        ))}
        {!isViewMode ? (
          <HStack className="justify-center">
            <ButtonSecondary
              className="items-center py-1 mt-4 font-medium"
              onClick={() => handleAdd(formik.values)}
            >
              Add a Document
            </ButtonSecondary>
          </HStack>
        ) : (
          <HStack className="mt-8"></HStack>
        )}
      </VStack>
      {!isViewMode ? (
        <HStack className="justify-end pb-8 pt-4 pr-8">
          <div className="px-4">
            <ButtonPrimary
              onClick={() => formik.handleSubmit()}
              loading={isLoading}
            >
              Save
            </ButtonPrimary>
          </div>
        </HStack>
      ) : (
        <HStack className="justify-end  pb-8 pt-4 pr-8"></HStack>
      )}
    </div>
  );
}
