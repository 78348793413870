import TextField from "@mui/material/TextField";
import React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { Icon } from "@iconify/react";

export type AutoCompleteInputProps<T> = {
  options: any[];
  placeholder?: string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  getOptionLabel?: (option: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  className: string;
  value?: any;
};

export default function SearchDropDown<T>(props: AutoCompleteInputProps<T>) {
  const {
    options,
    onChange,
    getOptionLabel,
    isOptionEqualToValue,
    placeholder,
    className,
    value,
  } = props;

  return (
    <Autocomplete
      id="autocomplete-search"
      popupIcon={
        <Icon icon="akar-icons:chevron-down" className="text-slate-600 p-1" />
      }
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      value={value}
      sx={{
        "& .MuiInput-input": {
          padding: "4px 4px 4px 14px !important",
          fontFamily: "Inter",
          color: "rgb(102, 107, 128, 1)",
        },
        "& .MuiSvgIcon-root": {
          fill: "none",
        },
      }}
      renderInput={(params) => (
        <div>
          <TextField
            hiddenLabel
            type="text"
            className={`border-0 form-input px-20 outline-none border-none rounded-lg form-input items-center ${className}`}
            placeholder={placeholder}
            autoComplete="false"
            variant="standard"
            {...params}
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
            }}
          />
        </div>
      )}
    />
  );
}
