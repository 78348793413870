import React from "react";
import { Icon } from "@iconify/react";
import { Box } from "../utils";

type CollapsibleType = {
  label: string;
  labelDetail: string;
  isOpen: boolean;
  onClick: any;
} & React.HTMLAttributes<HTMLElement>;
export function CollapsibleComponent(
  props: CollapsibleType
): React.ReactElement {
  const { label, labelDetail, isOpen, onClick, ...rest } = props;

  return (
    <Box className="p-4 rounded-sm shadow-lg">
      <Box
        className="flex flex-row space-x-2 items-center justify-between align-middle cursor-pointer"
        onClick={onClick}
      >
        <Box className="text-black text-sm font-medium">{label}</Box>
        <Box>{isOpen ? <Icon icon="il:arrow-down" height={20} /> : <></>}</Box>
      </Box>

      {!isOpen ? (
        <Box className="p-4 border-gray-300  ml-2 mt-2 space-y-2">
          <Box className=" space-y-4">
            <p className="text-[#4E4E4E] text-xs">{labelDetail}</p>
          </Box>
          {props.children}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
