import axios from "axios";

export function downloadBlobObject(blob: Blob, fileName: string) {
  const anchor = document.createElement("a");
  const blobURL = URL.createObjectURL(blob);
  anchor.href = blobURL;
  anchor.download = fileName;
  anchor.click();
}

export async function convertBase64ToBlob(base64: string, type: string) {
  return fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
}

export function downloadS3File(file: string, fileName = "") {
  const anchor = document.createElement("a");
  anchor.href = file;
  anchor.target = "_blank";
  anchor.download = fileName;
  anchor.click();
}

export async function fetchAndConvertToDataURL(
  url: string
): Promise<string | null> {
  try {
    const response = await axios.get(url, {
      responseType: "blob",
      headers: { Referer: window.location.host },
    });
    if (response.status !== 200) {
      throw new Error("Failed to fetch resource");
    }
    const reader = new FileReader();
    const dataUrlPromise = new Promise<string>((resolve, reject) => {
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to convert image to data URL"));
        }
      };
      reader.onerror = () => {
        reject(new Error("Failed to read image data"));
      };
    });
    reader.readAsDataURL(response.data);
    return dataUrlPromise;
  } catch (error) {
    throw new Error("Error");
  }
}
