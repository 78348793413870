import { Icon } from "@iconify/react";
import { Dialog, DialogProps } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGenerateEmployeeCredentials } from "../../queries/employees";
import { Employee, EmployeeCredentialGeneration } from "../../types/Employee";
import { ButtonPrimary, ButtonSecondary, HStack, VStack } from "../utils";
import { CheckBoxWithLabel } from "./InputField";

export type GenerateCredentialsTypes = {
  error?: boolean;
  message?: string;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  employeeList: Employee[];
  empWithCred: Employee[];
  empWithoutCred: Employee[];
} & DialogProps;

function GenerateCredentials({
  employeeList,
  empWithCred,
  empWithoutCred,
  error = false,
  message = "Are you sure you want to generate credentials?",
  primaryActionText = "Generate",
  secondaryActionText = "Cancel",
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  ...dialogProps
}: GenerateCredentialsTypes) {
  const [existing, setExisting] = useState(false);
  const [sendEmails, setSendEmails] = useState(true);
  const { mutate: generateEmployeeCredentials } =
    useGenerateEmployeeCredentials();
  let ids: string[] = [];
  function genCred() {
    if (existing) {
      ids = employeeList.map((employee) => employee.id);
    } else if (!existing) {
      ids = employeeList.map((employee) =>
        employee.isLoginProvided !== true ? employee.id : ""
      );
    }
    const generateObj: EmployeeCredentialGeneration = {
      employeeIds: employeeList.map((emp) => emp.id),
      regenerateExisting: existing,
      sendCredentialsGenerationEmail: sendEmails,
    };
    generateEmployeeCredentials(generateObj, {
      onSuccess: () => {
        toast("Credentials Generated successfully!", {
          type: "success",
          autoClose: 2000,
        });
        onSecondaryAction();
      },
      onError: () => {
        toast("Something went wrong", { type: "error", autoClose: 2000 });
      },
    });
  }
  return (
    <Dialog {...dialogProps}>
      <VStack className="justify-between p-9 bg-white rounded h-[321px] ">
        <VStack className="items-center justify-center gap-6 grow">
          {!error ? (
            <Icon icon="ep:warning" color="#FFE99B" height={72} />
          ) : (
            <Icon icon="codicon:error" color="#ff0000" height={72} />
          )}
        </VStack>
        <p className="items-center justify-center gap-4 font-medium text-dark grow text-xs3">
          {message}
        </p>
        <VStack className="justify-start gap-4 grow">
          <CheckBoxWithLabel
            label={`Regenerate for already existing ${
              empWithCred.length > 0 ? empWithCred.length : ""
            } Employees`}
            disabled={empWithCred.length === 0}
            labelclassname={`ml-2 text-xs2 ${
              empWithCred.length === 0 ? "text-slate-300" : "text-dark"
            }`}
            onChange={(e) => setExisting(e.target.checked)}
          />
          <CheckBoxWithLabel
            label={
              "Send credentials email to all selected Employees upon generation"
            }
            labelclassname={"ml-2 text-xs2 text-dark"}
            onChange={(e) => setSendEmails(e.target.checked)}
          />
        </VStack>
        <HStack className="justify-between gap-9">
          <ButtonSecondary
            onClick={() => onSecondaryAction()}
            className="text-gray-400 bg-slate-50"
          >
            {secondaryActionText}
          </ButtonSecondary>
          <ButtonPrimary onClick={() => genCred()}>
            {primaryActionText}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Dialog>
  );
}

export default GenerateCredentials;
